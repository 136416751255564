import React, { useEffect, useState } from 'react'
import {
  Grid, Typography, Button, SwipeableDrawer
} from "@material-ui/core";
import { Form, useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';

import ImageCash from '../../assets/img/cash-payment.png'
// import ImageInstallment from '../../assets/img/installment.png'
import ImageLongTerm from '../../assets/img/long-term-debt.png'
import ImageTax from '../../assets/img/tax.png'

import FormActions from '../FormActions';
import FormContext from '../FormContext';
import Aviso from '../Aviso';

const styles = (theme) => ({
  helpText: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  benefits: {
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 500
  }
});

const EtapaComoEncontrar = (props) => {
  const context = React.useContext(FormContext);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const { fields, etapa } = props;

  const [openFAQ, setOpenFAQ] = useState(false)

  useEffect(() => {
    context.changeValues({
      montante: '',
      objetivo: 'Outro',
      nacionalidade: 'Brasileira',
      prazo: '',
      restricao: '',
      quitado: '',
      fipe_anoCombustivel: '',
      fipe_codigoAno: '',
      fipe_codigoMarca: '',
      fipe_codigoModelo: '',
      fipe_codigoTabelaReferencia: '',
      fipe_codigoTipoCombustivel: '',
      fipe_codigoTipoVeiculo: '',
      veiculo_fipe_tipo_id: '',
      fipe_codigoVeiculo: '',
      veiculo_valor: '',
      proprietario: '',
      tem_veiculo_placa: '',
      veiculo_ano: '',
      veiculo_ano_id: '',
      veiculo_combustivel: '',
      veiculo_marca: '',
      veiculo_modelo: '',
      veiculo_molicar_ano: '',
      veiculo_molicar_modelo_versao: '',
      veiculo_molicar_marca: '',
      veiculo_molicar_modelo: '',
      veiculo_placa: '',
      veiculo_tipo: '',
    })

    // context.changeState({})

  }, [])

  return(
    <>
      <Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>Antecipe um dinheiro que ja é seu</Typography>
      <Typography className={props.classes.helpText}>Essa modalidade permite a antecipação do saldo do FGTS, sem precisar esperar o mês do seu aniversário para resgatar.</Typography>
      <Grid container justifyContent='center' alignItems="center" direction="row" spacing={2}>

        <Grid item container direction="column" alignItems="center" xs={6} sm={4} className={props.classes.benefits}>
          <img src={ImageCash} alt="Imagem Serve para negativados" width={100} />
          <p style={{textAlign: 'center'}}>Crédito para negativados</p>
        </Grid>

        {/* <Grid item container direction="column" alignItems="center" xs={6} sm={3} className={props.classes.benefits}>
          <img src={ImageInstallment} alt="Imagem Não tem parcelas" width={100} />
          <p style={{textAlign: 'center'}}>Não tem parcelas</p>
        </Grid> */}

        <Grid item container direction="column" alignItems="center" xs={6} sm={4} className={props.classes.benefits}>
          <img src={ImageLongTerm} alt="Imagem Dinheiro na hora" width={100} />
          <p style={{textAlign: 'center'}}>Crédito rápido</p>
        </Grid>

        <Grid item container direction="column" alignItems="center" xs={12} sm={4} className={props.classes.benefits}>
          <img src={ImageTax} alt="Imagem Taxa baixa de apenas 1,99% a.m" width={100} />
          <p style={{textAlign: 'center'}}>Taxas a partir de 1,99% a.m</p>
        </Grid>

      </Grid>

       <SwipeableDrawer
          anchor="bottom"
          open={openFAQ}
          onClose={() => setOpenFAQ(false)}
          onOpen={() => setOpenFAQ(true)}
        >
          <div style={{ maxHeight: 500, padding: 10, margin: 10 }}>
            <Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>Perguntas mais frequentes</Typography>

            <Typography variant="h6" gutterBottom>O que é o saque aniversário do FGTS?</Typography>
            <Typography style={{ color: 'grey'}}>O Saque aniversário é uma modalidade oferecida pelo FGTS na qual o trabalhador pode, no mês de seu aniversário, sacar o valor que possui em sua conta de forma parcial.</Typography>

            <Typography variant="h6" style={{marginTop: 20}} gutterBottom>Quem pode contratar a antecipação do saque aniversário?</Typography>
            <Typography style={{ color: 'grey'}}>Todas as pessoas que tiverem um saldo no FGTS acima de R$300,00. A solicitação deve ser realizada 10 dias antes ou 10 dias depois da data do aniversário</Typography>

            <Typography variant="h6" style={{marginTop: 20}} gutterBottom>Quais as vantagens da antecipação do saque aniversário?</Typography>
            <Typography style={{ color: 'grey'}}>Você pode ter o valor do saque-aniversário disponível na sua conta sem precisar esperar pelo mês do seu aniversário para resgatar. Você pode antecipar até 10 anos de saques aniversário do FGTS.</Typography>

            <Typography variant="h6" style={{marginTop: 20}} gutterBottom>Que valor tenho disponível para antecipar?</Typography>
            <Typography style={{ color: 'grey'}}>Você pode antecipar até 10 anos de saque-aniveersário de uma só vez. Os valores podem ser consultados no nosso simulador da Quero Financiar com parceria com o Banco Votorantim</Typography>

            <Typography variant="h6" style={{marginTop: 20}} gutterBottom>Como pagar as parcelas?</Typography>
            <Typography style={{ color: 'grey'}}>As parcelas serão debitadas automaticamente, ano a ano, da sua conta do FGTS.</Typography>

            <Typography variant="h6" style={{marginTop: 20}} gutterBottom>Como fica meu acesso ao FGTS?</Typography>
            <Typography style={{ color: 'grey'}}>Ao optar pelo saque aniversário, você não poderá retornar à opção saque rescição durante 2 anos do dia da efetivação ou até a data final do último contrato.</Typography>
        </div>
      </SwipeableDrawer>

      <Form>
        <FormActions isSubmitting={ isSubmitting } disabled={ etapa!=context.state.etapa }  />
        <Aviso errors={errors} touched={touched}/>
      </Form>

      <Grid item container direction="column" alignItems="center" xs={12}>
        <Button variant='outlined' color="primary" onClick={() => setOpenFAQ(true)}>faq</Button>
      </Grid>

    </>
  )
}

export default withStyles(styles)(EtapaComoEncontrar);
