import React from 'react'
import { Form, useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import FormActions from '../FormActions';
import CampoTexto from '../fields/CampoTexto';
import FormContext from '../FormContext';
import Aviso from '../Aviso';

const styles = {
  etapaTitulo: {
  },
};

const EtapaReferencias = (props) => {
  const context = React.useContext(FormContext);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const { fields, etapa } = props;
  return (
    <Form>
      {
        fields.includes('nome_mae') &&
        <CampoTexto
          label="Nome completo de sua mãe"
          name="nome_mae"
          onFocus={ props.changeFocus }
          onBlur={ (e)=>{
            // context.changeValues({
            //   nome_mae: e.target.value
            // });
            handleBlur(e);
          } }
          value={values.nome_mae || ''}
          onChange={handleChange}
          error={errors.nome_mae && touched.nome_mae}
          helperText={errors.nome_mae && touched.nome_mae ? errors.nome_mae : ''}
        />
      }
      {/* <CampoTexto
        label="Coloque o nome de uma pessoa de referência"
        name="nome_referencia"
        onFocus={ props.changeFocus }
        onBlur={ (e)=>{
            // context.changeValues({
            //   nome_referencia: e.target.value
            // });
            handleBlur(e);
          } }
        value={values.nome_referencia}
        onChange={handleChange}
        error={errors.nome_referencia && touched.nome_referencia}
        helperText={errors.nome_referencia && touched.nome_referencia ? errors.nome_referencia : ''}
      /> */}
      {/* <CampoTelefone
        label="Qual o telefone desta referência?"
        name="tel_referencia"
        onFocus={ props.changeFocus }
        onBlur={ (e)=>{
            // context.changeValues({
            //   tel_referencia: e.target.value
            // });
            handleBlur(e);
          } }
        onChange={ handleChange }
        value={values.tel_referencia}
        error={errors.tel_referencia && touched.tel_referencia}
        helperText={errors.tel_referencia && touched.tel_referencia ? errors.tel_referencia : ''}
      /> */}
      <FormActions isSubmitting={ isSubmitting } disabled={ etapa!=context.state.etapa }  />
      <Aviso errors={errors} touched={touched}/>
    </Form>
  )
}

export default withStyles(styles)(EtapaReferencias);