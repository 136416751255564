import React from 'react';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

const alertStyle = {
  textAlign: "center",
  backgroundColor: 'rgb(64 64 64)',
  color: 'white',
  padding: '10px',
  textTransform: 'uppercase',
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
  fontFamily: 'Arial',
  width: '300px',
  boxSizing: 'border-box'
};

const Alert = ({ style, options, message, close }) => (
  <div style={{...alertStyle,...style}}>
    {options.type === 'info' && <InfoOutlinedIcon style={{margin:8}}/>}
    {options.type === 'success' && <CheckCircleOutlineIcon style={{margin:8}}/>}
    {options.type === 'error' && <ErrorOutlineIcon style={{margin:8}}/>}
    {message}
    <CloseOutlinedIcon onClick={close} style={{margin:8, cursor:'pointer'}}/>
  </div>
)

export default Alert