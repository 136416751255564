import React from 'react'
import {  Form, useFormikContext } from 'formik';
// import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
// import MaskedInput from 'react-text-mask'

// import CampoTexto from '../fields/CampoTexto';
import CampoNumero from '../fields/CampoNumero';
import CampoRadio from '../fields/CampoRadio';
import CampoSelect from '../fields/CampoSelect';
import FormActions from '../FormActions';
import FormContext from '../FormContext';
import Aviso from '../Aviso';
import getBancos from '../../lib/getBancos'
import { Typography } from '@material-ui/core';
// import CampoWhatsApp from './fields/CampoWhatsApp';

const styles = {
  
};

const EtapaDadosBancarios = (props) => {
  const context = React.useContext(FormContext);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const { fields, etapa } = props;

  return(
    <Form>
      <Typography variant="h3" gutterBottom >Em qual conta você quer receber seu empréstimo?</Typography>
      <Typography variant="h6" gutterBottom style={{ fontSize: 15 }}><strong>É de suma importância que os dados bancários sejam preenchidos corretamente para garantir o recebimento do seu crédito de forma rápida.</strong></Typography>
    
      {
        fields.includes('banco_tipo_conta') &&
        <>
          <CampoRadio
            name = 'banco_tipo_conta'
            id = 'banco_tipo_conta'
            value = {values.banco_tipo_conta}
            label = "Tipo de conta"
            options={[
              {label: 'Corrente', value:'Corrente'},
              {label: 'Poupança', value:'Poupança'},
            ]}
            onChange={(value) => {
              setFieldValue('banco_tipo_conta',value.value);
            }}
            // onBlur={(e)=>{
            //   setFieldTouched('banco_tipo_conta', true);
            // }}
            showError={errors.banco_tipo_conta}
            touched={touched.banco_tipo_conta}
          />
        </>
      }
      {
        fields.includes('banco') &&
          <CampoSelect
            label="Banco"
            name="banco"
            id="banco"
            value={values.banco}
            onChange={(data) => {
              console.log(data)
              setFieldValue('banco', data.value);
              setFieldValue('banco_label', data.label);
            }}
            options={getBancos()}
            error={errors.banco}
          />
      }
      {
        fields.includes('banco_agencia') &&
          <CampoNumero
            label="Agência sem digito"
            name="banco_agencia"
            id="banco_agencia"
            placeholder="Agência sem dígito (somente número)"
            type="tel"
            // onFocus={ props.changeFocus }
            // onBlur={ (e)=>{
            //   // context.changeValues({
            //   //   email:values.email
            //   // });
            //   handleBlur(e);
            // }}
            inputProps={{ maxLength: 4 }}
            value={values.banco_agencia }
            onChange={handleChange}
            error={errors.banco_agencia}
            helperText={errors.banco_agencia && touched.banco_agencia ? errors.banco_agencia : ''}
          />
      }
      {
        fields.includes('banco_conta') &&
        <CampoNumero
          label="Conta com dígito"
          name="banco_conta"
          id="banco_conta"
          type="tel"
          placeholder="Conta com dígito (somente números)"
          inputProps={{ maxLength: 13 }}
          value={values.banco_conta}
          onChange={handleChange}
          error={errors.banco_conta}
          helperText={errors.banco_conta && touched.banco_conta ? errors.banco_conta : ''}
        />
      }
      <FormActions isSubmitting={ isSubmitting } disabled={ etapa!=context.state.etapa }  />
      <Aviso errors={errors} touched={touched}/>
    </Form>
  )
}

export default withStyles(styles)(EtapaDadosBancarios);