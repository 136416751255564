import React from "react";
import {
  Dialog, DialogTitle, DialogActions, DialogContentText, DialogContent, withTheme, Button, Snackbar, CircularProgress, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import AddCircle from '@material-ui/icons/AddCircle';

// import CameraAlt from "@material-ui/icons/CameraAlt";
import MuiAlert from '@material-ui/lab/Alert';
import FormContext from '../../FormContext';
import ArrowRightAltRounded from '@material-ui/icons/ArrowRightAltRounded';


const styles = (theme) => ({
  selectData: {
    padding: 10,
    // border: "1px solid grey",
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: 'inset 0px 0px 0px 1px #e7e7e7;',

  },
  box: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // width: 500,
    // height: 500,
  },
  buttons: {

  },
  sendDocumentos: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const modalSimProposal = (props) => {

  const { classes } = props
  const urlParams = new URLSearchParams(window.location.search);

  const context = React.useContext(FormContext);

  const [showOne, setShowOne] = React.useState(false)
  const [showTwo, setShowTwo] = React.useState(false)
  const [showThree, setShowThree] = React.useState(false)
  const [showFour, setShowFour] = React.useState(false)

  return (
    <>

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div><span>1. Análise da Proposta</span></div>
        <div style={{ cursor: 'pointer' }} onClick={() => setShowOne(state=> !state)}><AddCircle color="primary" fontSize="medium" /></div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div><span>2. Pagamento</span></div>
        <div style={{ cursor: 'pointer' }} onClick={() => setShowTwo(state => !state)}><AddCircle color="primary" fontSize="medium" /></div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div><span>3. Envio do carnê</span></div>
        <div style={{ cursor: 'pointer' }} onClick={() => setShowThree(state => !state)}><AddCircle color="primary" fontSize="medium" /></div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div><span>4. Gestão do contrato</span></div>
        <div style={{ cursor: 'pointer' }} onClick={() => setShowFour(state => !state)}><AddCircle color="primary" fontSize="medium" /></div>
      </div>

      <Dialog onClose={() => setShowOne(state => !state)} open={showOne}>
        <DialogTitle>Análise da Proposta</DialogTitle>
        <DialogContent>
          <DialogContentText>Pontos a serem considerados:</DialogContentText>
          <DialogContentText><ArrowRightAltRounded /><span style={{position: 'relative', top: -5}}></span></DialogContentText>
          <DialogContentText><ArrowRightAltRounded /><span style={{position: 'relative', top: -5}}></span></DialogContentText>
          <DialogContentText><ArrowRightAltRounded /><span style={{position: 'relative', top: -5}}></span></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowOne(state => !state)} color="primary">fechar</Button>
        </DialogActions>
      </Dialog>

      <Dialog onClose={() => setShowTwo(state => !state)} open={showTwo}>
        <DialogTitle>Pagamento</DialogTitle>
        <DialogContent>
          <DialogContentText>Pontos a serem considerados</DialogContentText>
          <DialogContentText><ArrowRightAltRounded /><span style={{position: 'relative', top: -5}}></span></DialogContentText>
          <DialogContentText><ArrowRightAltRounded /><span style={{position: 'relative', top: -5}}></span></DialogContentText>
          <DialogContentText><ArrowRightAltRounded /><span style={{position: 'relative', top: -5}}></span></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowTwo(state => !state)} color="primary">fechar</Button>
        </DialogActions>
      </Dialog>

       <Dialog onClose={() => setShowThree(state => !state)} open={showThree}>
        <DialogTitle>Envio do carnê</DialogTitle>
        <DialogContent>
          <DialogContentText>Pontos a serem considerados</DialogContentText>
          <DialogContentText><ArrowRightAltRounded /><span style={{position: 'relative', top: -5}}></span></DialogContentText>
          <DialogContentText><ArrowRightAltRounded /><span style={{position: 'relative', top: -5}}></span></DialogContentText>
          <DialogContentText><ArrowRightAltRounded /><span style={{position: 'relative', top: -5}}></span></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowThree(state => !state)} color="primary">fechar</Button>
        </DialogActions>
      </Dialog>

       <Dialog onClose={() => setShowFour(state => !state)} open={showFour}>
        <DialogTitle>Gestão do contrato</DialogTitle>
        <DialogContent>
          <DialogContentText>Pontos a serem considerados</DialogContentText>
          <DialogContentText><ArrowRightAltRounded /><span style={{position: 'relative', top: -5}}></span></DialogContentText>
          <DialogContentText><ArrowRightAltRounded /><span style={{position: 'relative', top: -5}}></span></DialogContentText>
          <DialogContentText><ArrowRightAltRounded /><span style={{position: 'relative', top: -5}}></span></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowFour(state => !state)} color="primary">fechar</Button>
        </DialogActions>
      </Dialog>


    </>
  );
};

export default withTheme(withStyles(styles)(modalSimProposal));
