import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom'
import CampoLabel from '../fields/CampoLabel';
import FormContext from '../FormContext';
import Typography from '@material-ui/core/Typography';
import { Button, CircularProgress, Container, Grid, useTheme } from '@material-ui/core';
import axios from 'axios'
import Botao from '../Botao';


const styles = theme => ({
});

const EtapaFinalizarPropostaInicio = (props) => {
  const context = React.useContext(FormContext);
  const history = useHistory();
  const [error, set_error] = useState(null);

  useEffect(()=>{

    const getProposal = async ()=>{

      if(!context.state.userLogged){
        set_error('USER_NOT_LOGGED')
        return
      }
      
      let etapas = context.state.etapas

      const proposalData = await context.getProposalData()

      console.log('proposalData',proposalData)

      if(!proposalData){
        set_error('PROPOSAL_NOT_FOUND')
        return
      }

      const completedSteps = []
                
      if(proposalData.dados_bancarios_enviado==true){
        completedSteps.push("FormDadosBancarios")
        completedSteps.push("FormResume")
      }

      if(proposalData.foto_selfie_enviado==true){
        completedSteps.push("FormSelfie")
      }

      if(proposalData.doc_identidade_frente_enviado==true && proposalData.doc_identidade_verso_enviado==true){
        completedSteps.push("FormDocumentos")
      }
              
      if(proposalData.foto_veiculo_motor_enviado==true && proposalData.foto_veiculo_traseira_enviado==true){
        completedSteps.push("FormVeiculo")
      }
              
      if(proposalData.foto_veiculo_crlv_enviado==true){
        completedSteps.push("FormCRLV ")
      }
              
      etapas = etapas.filter((item)=>{
        // console.log(item.component,completedSteps)
        return completedSteps.indexOf(item.component)===-1
      })

      context.changeState({etapas})

      context.submitForm()

    }

    getProposal()
    
  },[])

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} style={{ textAlign:'center' }}>
          {
            error
            ?
              <>
                {
                  error=='USER_NOT_LOGGED' && <>
                    <Typography gutterBottom variant='h3'>Usuário não logado.</Typography>
                    <Typography gutterBottom>Clique no botão abaixo para fazer o login:</Typography>
                    <Botao
                      label="Fazer Login"
                      onClick={()=>{
                        history.push(`/login`)
                      }}
                    />
                  </>
                }
                {
                  error=='PROPOSAL_NOT_FOUND' && <>
                  <Typography gutterBottom variant='h3'>Proposa não encontrada.</Typography>
                  <Typography gutterBottom>Clique no botão abaixo para nosso time comercial te ajudar a encontrar o que deseja:</Typography>
                  <Botao
                    label="Contato"
                    onClick={()=>{
                      const proposalId = new URLSearchParams(location.search).get('proposal')
                      window.open(encodeURI(`https://api.whatsapp.com/send?phone=${context.state.whatsAppNumber}&text=Olá, gostaria de finalizar a proposta ${proposalId}, porém ela não foi encontrada no sistema.`),"_blank")
                    }}
                  />
                </>
                }
              </>
            :
              <>
                <Typography>Buscando proposta...</Typography>
              </>
          }
        </Grid>
      </Grid>
    </Container>
    
  );
}

export default withStyles(styles)(EtapaFinalizarPropostaInicio);
