import React from 'react'
import { Formik, Form, useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import CampoDinheiro from '../fields/CampoDinheiro';
import FormActions from '../FormActions';
import CampoOpcoes from '../fields/CampoOpcoes';
import CampoTexto from '../fields/CampoTexto';
import FormContext from '../FormContext';
import CampoCnpj from '../fields/CampoCnpj';
import Aviso from '../Aviso';
import CampoSugestoes from '../fields/CampoSugestoes';
import _ from 'lodash';

const styles = {
  etapaTitulo: {
  },
};

const EtapaDadosProfissionais = (props) => {
  const context = React.useContext(FormContext);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const { fields, etapa } = props;
  return (
    <Form>
      <CampoOpcoes
        label="Qual é sua ocupação? *"
        name="ocupacao"
        showError={errors.ocupacao}
        classNamePrefix="quero-financiar-select"
        className="campo-selecao"
        value={values.ocupacao}
        touched={touched.ocupacao}
        onChange={ ( data ) => {
          setFieldValue('ocupacao', data.value);

          if(data.value=="Empresário(a)"){
            setFieldValue('cargo', 'ADMINISTRADOR' );
          }else if(data.value=="Autônomo(a)/ Prof. Liberal"){
            setFieldValue('cargo','VENDEDOR');
          }else if(data.value=="Aposentado(a)"){
            setFieldValue('cargo','APOSENTADO');
          }else{
            setFieldValue('cargo', '' );
          }

          setFieldValue('aposentado_num_beneficio', '');
          setFieldValue('renda', '');
          setFieldValue('empresa', '');
          setFieldValue('tempo_servico', '');
          setFieldValue('tem_cnpj', '');
          setFieldValue('cnpj', '');
          
          context.changeValues({});

        } }
        onBlur={ ( e ) => {
          setFieldTouched('ocupacao',true);
        } }
        options={[
          {label:'Aposentado(a)',                       value:'Aposentado(a)'},
          {label:'Assalariado(a)',                      value:'Assalariado(a)'},
          {label:'Autônomo(a)/ Prof. Liberal',          value:'Autônomo(a)/ Prof. Liberal'},
          {label:'Empresário(a)',                       value:'Empresário(a)'},
          {label:'Servidor público',                    value:'Servidor público'},
        ]}
      />
        {
          values.ocupacao=="Aposentado(a)" &&
          <React.Fragment>
            <CampoTexto
              label="Número do benefício"
              name="aposentado_num_beneficio"
              onFocus={ props.changeFocus }
              onBlur={ (e)=>{
                setFieldValue('aposentado_num_beneficio', e.target.value);
                handleBlur(e);
              } }
              value={values.aposentado_num_beneficio}
              onChange={handleChange}
              error={errors.aposentado_num_beneficio && touched.aposentado_num_beneficio}
              helperText={errors.aposentado_num_beneficio && touched.aposentado_num_beneficio ? errors.aposentado_num_beneficio : ''}
            />
          </React.Fragment>
        }

        {
          (values.ocupacao=="Assalariado(a)" || values.ocupacao=="Servidor público") &&
          <React.Fragment>
            <CampoOpcoes
              label="Tempo de serviço *"
              name="tempo_servico"
              showError={errors.tempo_servico}
              classNamePrefix="quero-financiar-select"
              className="campo-selecao"
              value={values.tempo_servico}
              touched={touched.tempo_servico}
              onChange={ ( data ) => {
                setFieldValue('tempo_servico', data.value);
              } }
              onBlur={ ( e ) => {
                setFieldTouched('tempo_servico',true);
              } }
              options={[
                {label:'Menos de 6 meses',      value:'Menos de 6 meses'},
                {label:'Entre 6 meses e 1 ano', value:'Entre 6 meses e 1 ano'},
                {label:'Entre 1 e 2 anos',      value:'Entre 1 e 2 anos'},
                {label:'Entre 2 e 3 anos',      value:'Entre 2 e 3 anos'},
                {label:'Entre 3 e 4 anos',      value:'Entre 3 e 4 anos'},
                {label:'Mais de 4 anos',        value:'Mais de 4 anos'},
              ]}
            />
          </React.Fragment>
        }
        
        {
          values.ocupacao=="Autônomo(a)/ Prof. Liberal" &&
          <React.Fragment>
            <CampoOpcoes
              label="Você possui CNPJ? *"
              name="tem_cnpj"
              showError={errors.tem_cnpj}
              classNamePrefix="quero-financiar-select"
              className="campo-selecao"
              value={values.tem_cnpj}
              touched={touched.tem_cnpj}
              onChange={ ( data ) => {
                setFieldValue('tem_cnpj', data.value);
              } }
              onBlur={ ( data ) => { setFieldTouched('tem_cnpj',true); } }
              options={[
                {label:'Sim',   value:'Sim'},
                {label:'Não',   value:'Não'},
              ]}
            />
            {
              values.tem_cnpj=="Sim" &&
              <CampoCnpj
                label="CNPJ da sua empresa *"
                name="cnpj"
                onFocus={ props.changeFocus }
                onBlur={ (e)=>{
                  handleBlur(e);
                } }
                onChange={ handleChange }
                value={values.cnpj}
                error={errors.cnpj && touched.cnpj}
                helperText={errors.cnpj && touched.cnpj ? errors.cnpj : ''}
              />
            }
            <CampoOpcoes
              label="Tempo de serviço *"
              name="tempo_servico"
              showError={errors.tempo_servico}
              classNamePrefix="quero-financiar-select"
              className="campo-selecao"
              value={values.tempo_servico}
              touched={touched.tempo_servico}
              onChange={ ( data ) => {
                setFieldValue('tempo_servico', data.value);
              } }
              onBlur={ ( value ) => { setFieldTouched('tempo_servico',true); } }
              options={[
                {label:'Menos de 6 meses',      value:'Menos de 6 meses'},
                {label:'Entre 6 meses e 1 ano', value:'Entre 6 meses e 1 ano'},
                {label:'Entre 1 e 2 anos',      value:'Entre 1 e 2 anos'},
                {label:'Entre 2 e 3 anos',      value:'Entre 2 e 3 anos'},
                {label:'Entre 3 e 4 anos',      value:'Entre 3 e 4 anos'},
                {label:'Mais de 4 anos',        value:'Mais de 4 anos'},
              ]}
            />
          </React.Fragment>
        }
      
        {
          values.ocupacao=="Empresário(a)" &&
          <React.Fragment>
            <CampoCnpj
              label="CNPJ da sua empresa *"
              name="cnpj"
              onFocus={ props.changeFocus }
              onBlur={ (e)=>{
                handleBlur(e);
              } }
              onChange={ handleChange }
              value={values.cnpj}
              error={errors.cnpj && touched.cnpj}
              helperText={errors.cnpj && touched.cnpj ? errors.cnpj : ''}
            />
          </React.Fragment>
        }
        {
          fields.includes('cargo') &&
          values.ocupacao!=undefined &&
          values.ocupacao!="Empresário(a)" &&
          values.ocupacao!="Autônomo(a)/ Prof. Liberal" &&
          values.ocupacao!="Aposentado(a)" &&
          <CampoSugestoes
            options={context.state.profissoes}
            onSuggestionSelected={
              (value)=>{
                setFieldValue('cargo',value);
              }
            }
            inputProps={{
              className:"campo-texto",
              name: 'cargo',
              label:"Qual sua profissão? *",
              value: values.cargo || "",
              placeholder: 'Profissão',
              error: errors.cargo && touched.cargo,
              helperText: errors.cargo && touched.cargo ? errors.cargo : '',
              onChange: (event, { newValue }) => {
                handleChange(event);
                setFieldValue('cargo',newValue);
              },
              onBlur: (event, { newValue }) => {
                handleBlur(event);
                setFieldTouched('cargo',true);
                const suggestion_index = _.findIndex(context.state.profissoes, (item)=>{
                  return item.label == values.cargo;
                });
                if(suggestion_index==-1){
                  setFieldValue('cargo','');
                };
              },
            }}
          />
        }
        <CampoDinheiro
          label="Renda mensal *"
          name="renda"
          value={values.renda}
          onFocus={ props.changeFocus }
          onBlur={ (e)=>{
            handleBlur(e);
          } }
          onChange={ handleChange }
          error={errors.renda && touched.renda}
          helperText={errors.renda && touched.renda ? errors.renda : ''}
        />
        {
          ["Casado","União Estável"].includes(context.state.values.estado_civil) &&
          fields.includes('conjuge_compoe_renda') &&
          <CampoOpcoes
            label="Seu cônjuge irá compor renda? *"
            name="conjuge_compoe_renda"
            showError={errors.conjuge_compoe_renda}
            classNamePrefix="quero-financiar-select"
            className="campo-selecao"
            value={values.conjuge_compoe_renda}
            touched={touched.conjuge_compoe_renda}
            onChange={ ( data ) => {
              setFieldValue('conjuge_compoe_renda', data.value);
              if(data.value!="Sim"){
                setFieldValue('conjuge_ocupacao', '')
                setFieldValue('conjuge_renda', '')
              }
              props.updateResize()
            } }
            onBlur={ ( e ) => {
              setFieldTouched('conjuge_compoe_renda',true);
            } }
            options={[
              {label:'Sim',      value:'Sim'},
              {label:'Não', value:'Não'},
            ]}
          />
        }
        {
          ["Casado","União Estável"].includes(context.state.values.estado_civil) &&
          values.conjuge_compoe_renda=="Sim" &&
          fields.includes('conjuge_ocupacao') &&
          <CampoOpcoes
            label="Qual é a ocupação de seu cônjuge? *"
            name="conjuge_ocupacao"
            showError={errors.conjuge_ocupacao}
            classNamePrefix="quero-financiar-select"
            className="campo-selecao"
            value={values.conjuge_ocupacao}
            touched={touched.conjuge_ocupacao}
            onChange={ ( data ) => {
              setFieldValue('conjuge_ocupacao', data.value);

              if(data.value=="Empresário(a)"){
                setFieldValue('conjuge_cargo', 'ADMINISTRADOR' );
              }else if(data.value=="Autônomo(a)/ Prof. Liberal"){
                setFieldValue('conjuge_cargo','VENDEDOR');
              }else if(data.value=="Aposentado(a)"){
                setFieldValue('conjuge_cargo','APOSENTADO');
              }else{
                setFieldValue('conjuge_cargo', '' );
              }

            } }
            onBlur={ ( e ) => {
              setFieldTouched('conjuge_ocupacao',true);
            } }
            options={[
              {label:'Aposentado(a)',                       value:'Aposentado(a)'},
              {label:'Assalariado(a)',                      value:'Assalariado(a)'},
              {label:'Autônomo(a)/ Prof. Liberal',          value:'Autônomo(a)/ Prof. Liberal'},
              {label:'Empresário(a)',                       value:'Empresário(a)'},
              {label:'Servidor público',                    value:'Servidor público'},
            ]}
          />
        }
        {
          ["Casado","União Estável"].includes(context.state.values.estado_civil) &&
          values.conjuge_compoe_renda=="Sim" &&
          fields.includes('conjuge_cargo') &&
          <CampoSugestoes
            options={context.state.profissoes}
            onSuggestionSelected={
              (value)=>{
                setFieldValue('conjuge_cargo',value);
              }
            }
            inputProps={{
              className:"campo-texto",
              name: 'conjuge_cargo',
              label:"Qual a profissão de seu cônjuge? *",
              value: values.conjuge_cargo || "",
              placeholder: 'Profissão',
              error: errors.conjuge_cargo && touched.conjuge_cargo,
              helperText: errors.conjuge_cargo && touched.conjuge_cargo ? errors.conjuge_cargo : '',
              onChange: (event, { newValue }) => {
                handleChange(event);
                setFieldValue('conjuge_cargo',newValue);
              },
              onBlur: (event, { newValue }) => {
                handleBlur(event);
                setFieldTouched('conjuge_cargo',true);
                const suggestion_index = _.findIndex(context.state.profissoes, (item)=>{
                  return item.label == values.conjuge_cargo;
                });
                if(suggestion_index==-1){
                  setFieldValue('conjuge_cargo','');
                };
              },
            }}
          />
        }
        {
          ["Casado","União Estável"].includes(context.state.values.estado_civil) &&
          values.conjuge_compoe_renda=="Sim" &&
          fields.includes('conjuge_renda') &&
          <CampoDinheiro
            label="Renda mensal de seu cônjuge *"
            name="conjuge_renda"
            value={values.conjuge_renda}
            onFocus={ props.changeFocus }
            onBlur={ (e)=>{
              handleBlur(e);
            } }
            onChange={ handleChange }
            error={errors.conjuge_renda && touched.conjuge_renda}
            helperText={errors.conjuge_renda && touched.conjuge_renda ? errors.conjuge_renda : ''}
          />
        }
        {
          ["Casado","União Estável"].includes(context.state.values.estado_civil) &&
          values.conjuge_compoe_renda=="Sim" &&
          fields.includes('conjuge_comprovante_renda_tipo') &&
          <CampoOpcoes
            label="Como seu cônjuge comprova renda? *"
            name="conjuge_comprovante_renda_tipo"
            showError={errors.conjuge_comprovante_renda_tipo}
            classNamePrefix="quero-financiar-select"
            className="campo-selecao"
            value={values.conjuge_comprovante_renda_tipo}
            touched={touched.conjuge_comprovante_renda_tipo}
            onChange={ ( data ) => {
              setFieldValue('conjuge_comprovante_renda_tipo', data.value);
            } }
            onBlur={ ( e ) => {
              setFieldTouched('conjuge_comprovante_renda_tipo',true);
            } }
            options={[
              {label:'Extrato bancário', value:'Extrato bancário'},
              {label:'Contracheque', value:'Contracheque'},
              {label:'Outras formas', value:'Outras formas'},
            ]}
          />
        }
        <FormActions isSubmitting={ isSubmitting } disabled={ etapa!=context.state.etapa }  />
        <Aviso errors={errors} touched={touched}/>
    </Form>
  )
}

export default withStyles(styles)(EtapaDadosProfissionais);