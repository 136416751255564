import Axios from "axios"

export function consultarTabelaDeReferencia(){
  return Axios.post('https://veiculos.fipe.org.br/api/veiculos/ConsultarTabelaDeReferencia');
}

export function consultarMarcas(codigoTabelaReferencia,codigoTipoVeiculo){
  const data = new FormData();
  data.append('codigoTabelaReferencia',codigoTabelaReferencia);
  data.append('codigoTipoVeiculo',codigoTipoVeiculo);
  return fetch('https://veiculos.fipe.org.br/api/veiculos/ConsultarMarcas',{
      method:"POST",
      body: data,
      mode:'cors',
    })
    .then((response)=>{
      return response.json();
    })
    .then((data)=>{
      return data.map((item)=>{
        return {
          "label":item.Label,
          "value":item.Label,
          "id":item.Value,
        }
      });
    });
}

export function consultarModelos(codigoTabelaReferencia,codigoTipoVeiculo,codigoMarca){
  const data = new FormData();
  data.append('codigoTabelaReferencia',codigoTabelaReferencia);
  data.append('codigoTipoVeiculo',codigoTipoVeiculo);
  data.append('codigoMarca',codigoMarca);
  return fetch('https://veiculos.fipe.org.br/api/veiculos/ConsultarModelos',{
      method:"POST",
      body: data,
      mode:'cors',
    })
    .then((response)=>{
      return response.json();
    })
    .then((data)=>{
      const formatedData = {};

      formatedData.anos = data.Anos.map((item)=>{
        return {
          "label": item.Label.replace('32000','Zero KM'),
          "value": item.Value.split('-')[0]=='32000' ? 'zero km' : item.Label.split(' ')[0],
          "id": item.Value,
          "combustivel":item.Label.split(' ')[1],
          "idCombustivel":item.Value.split('-')[1],
        }
      });

      formatedData.modelos = data.Modelos.map((item)=>{
        return {
          "label":item.Label,
          "value":item.Label,
          "id":item.Value,
        }
      });

      return formatedData;

    });
}

export function consultarModelosAtravesDoAno(codigoTabelaReferencia, codigoTipoVeiculo, codigoMarca, ano, codigoTipoCombustivel, anoModelo){
  const data = new FormData();
  data.append('codigoTabelaReferencia',codigoTabelaReferencia);
  data.append('codigoTipoVeiculo',codigoTipoVeiculo);
  data.append('codigoMarca',codigoMarca);
  data.append('ano',ano);
  data.append('codigoTipoCombustivel',codigoTipoCombustivel);
  data.append('anoModelo',anoModelo=='zero km' ? 32000 : anoModelo);
  return fetch('https://veiculos.fipe.org.br/api/veiculos/ConsultarModelosAtravesDoAno',{
      method:"POST",
      body: data,
      mode:'cors',
    })
    .then((response)=>{
      return response.json();
    })
    .then((data)=>{
      return data.map((item)=>{
        return {
          "label":item.Label,
          "value":item.Label,
          "id":item.Value,
        }
      });
    });
}

/*
  Ex:
  "codigoTabelaReferencia": 263,
  "codigoTipoVeiculo": 1,
  "codigoMarca": 59,
  "ano": "2021-1",
  "codigoTipoCombustivel": 1,
  "anoModelo": 2021,
  "codigoModelo": 8463,
  "tipoConsulta": "tradicional"
 */
export function consultarValorComTodosParametros(codigoTabelaReferencia, codigoTipoVeiculo, codigoMarca, ano, codigoTipoCombustivel, anoModelo, codigoModelo ){
  const data = new FormData();
  data.append('codigoTabelaReferencia',codigoTabelaReferencia);
  data.append('codigoTipoVeiculo',codigoTipoVeiculo);
  data.append('codigoMarca',codigoMarca);
  data.append('ano',ano);
  data.append('codigoTipoCombustivel',codigoTipoCombustivel);
  data.append('anoModelo',anoModelo=='zero km' ? 32000 : anoModelo);
  data.append('codigoModelo',codigoModelo);
  data.append('tipoConsulta',"tradicional");
  return fetch('https://veiculos.fipe.org.br/api/veiculos/ConsultarValorComTodosParametros',{
      method:"POST",
      body: data,
      mode:'cors',
    })
    .then((response)=>{
      return response.json();
    })
    .then((data)=>{
      return data;
    });
}
