import React, { useEffect, useState } from 'react';
import FormContext from './FormContext';
import { useTheme, withStyles, withTheme } from '@material-ui/styles';
import axios from "axios";

import {
  Card, CardContent, Typography, Grid, IconButton, CardActions, Button, CircularProgress
} from '@material-ui/core';

import moment from 'moment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import getFirestoreTimestamp from '../lib/getFirestoreTimestamp';

const styles = (theme) => ({
  card: {
    // minWidth: 275,
  },
  cardContent:{
    // display: 'flex',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  partnerLogo:{
    width: 100,
    margin: theme.spacing(),
  },
  proposalDesc:{
    margin: theme.spacing(),
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  proposalAction:{
    margin: theme.spacing(),
  },
});

const CardProposal = (props) => {

  let { classes, data, onSelect } = props;
  const theme = useTheme();
  const context = React.useContext(FormContext);
  let title = "";
  let statusTextColor = "";
  let hasAction = false;

  const [loading, setLoading] = useState(false)

  switch(data.status){
    case "pagamento_realizado":
      title = `Contrato Pago`;
      statusTextColor = 'blue';
      // hasAction = true;
      break;
    case "credito_aprovado": case "pagamento_aprovado":
      title = `Crédito Pré Aprovado`;
      statusTextColor = theme.palette.primary.main;
      hasAction = true;
      break;
    case "pre_analise_aprovada":
    case "proposta_pendente":
    case "proposta_aprovada":
      title = `Em Análise de Crédito`;
      statusTextColor = theme.palette.secondary.main;
      hasAction = true;
      break;
    default:
      title = `Sem ofertas disponíveis`;
      statusTextColor = theme.palette.text.primary.main;
      // hasAction = true;
      break;
  }

  let imgSrc

  try {
    imgSrc = require(`../assets/img/partners/${data.partnerId}.png`).default;
  } catch (e) {
    if (e.code !== 'MODULE_NOT_FOUND') {
      console.log(`../assets/img/partners/${data.partnerId}.png nao encontrado...`)
    }
  }

  return <>
    <Card className={classes.card} style={{cursor: hasAction ? 'pointer': 'default'}} onClick={() => hasAction && onSelect(data)}>
      <CardContent className={classes.cardContent}>
        <Grid container justify='center' alignItems='center'>

            {/* {!loading && */}
              <Grid item xs={5}>
                <img src={imgSrc} className={classes.partnerLogo} alt={data.partnerId} />
              </Grid>
            {/* } */}

          {/* {
            loading ?
              <CircularProgress />
                : */}
              <Grid item xs={7}>
                <div className={classes.proposalDesc}>
                      <>
                        <Typography variant="h5" style={{color: statusTextColor, fontSize: 18}}>{title}</Typography>
                        {
                          data.produto === 'Empréstimo com Saque FGTS' && <Typography style={{ color: 'grey', fontSize: 13 }}>Empréstimo com Saque FGTS</Typography>
                        }
                        {
                          context.state.feature_flag_api_v2_sim === true ?
                            data.partnerId === 'sim' &&
                            data.dados_bancarios_enviado === false ||
                            data.foto_selfie_enviado === false ||
                            data.doc_identidade_frente_enviado === false ||
                            data.doc_identidade_verso_enviado === false ||
                            data.foto_veiculo_motor_enviado === false ||
                            data.foto_veiculo_traseira_enviado === false ||
                            data.foto_veiculo_crlv_enviado === false ? <Typography><span style={{ color: '#fe0064', fontWeight: 'bold', fontSize: 12 }}>Pendente envio de documentos</span></Typography> : ''
                          : ''
                        }
                        {
                          data.partnerId === 'sim' && data.externalId !== undefined && <Typography><span style={{ color: '#fe0064', fontWeight: 'bold', fontSize: 16 }}>SIM #{data.externalId}</span></Typography>
                        }
                        <Typography style={{color: 'grey', fontSize: 13}}>Data da solicitação: {moment.unix( getFirestoreTimestamp(data.createdAt).seconds).format('DD/MM/YYYY HH:mm')}</Typography>
                      </>
                  {/* <Typography style={{color: 'grey', fontSize: 13}}>Data da solicitação: {data.createdAt}</Typography> */}
                </div>
                {/* <div className={classes.proposalAction}>
                  {
                    hasAction &&
                      <IconButton aria-label="ver mais" onClick={(e)=>{
                        onSelect(data);
                      }}>
                        <VisibilityIcon fontSize='large' color='primary'/>
                      </IconButton>
                  }
                </div> */}
              </Grid>
          {/* } */}

        </Grid>
      </CardContent>
        {
          hasAction && !loading &&
          <CardActions className={props.classes.cardAction}>
            <Button size="small" color="primary" onClick={(e) => onSelect(data)}>visualizar pedido</Button>
          </CardActions>
        }
    </Card>
  </>
}

export default withTheme(withStyles(styles)(CardProposal));
