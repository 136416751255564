import FormIcon from '../../../assets/img/form.png';
import WhatsAppIcon from '../../../assets/img/whatsapp.png';
import React from 'react';
import { useTheme, withStyles, withTheme } from '@material-ui/styles';
import {
  Card, CardContent, Paper, Button, Typography, CircularProgress
} from '@material-ui/core';
import { useHistory } from 'react-router-dom'
import moment from 'moment';
import { KeyboardBackspaceOutlined } from '@material-ui/icons';
import { formatFloatToCurrency } from '../../../lib/Utils';
import FormContext from '../../FormContext';
import { CheckCircle, Error } from '@material-ui/icons';
import axios from 'axios';
import getFirestoreTimestamp from '../../../lib/getFirestoreTimestamp';
import CardJeitto from './CardJeitto';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(),
    minHeight: 160,
  },
  card:{
    maxWidth: 450,
  },
  cardHeader:{
    display: `flex`,
    alignItems: `center`,
    justifyContent: `space-around`,
    marginBottom: theme.spacing(2),
  },
  title:{

  },
  partnerLogo:{
    width: '30%',
    margin: theme.spacing(),
  },
  button: {
    width: '80%',
    margin: `${theme.spacing()}px auto`,
  },
  buttonContainer:{
    textAlign: 'center',
    margin: theme.spacing(2),
  },
  buttonIcon:{
    padding: theme.spacing(),
    width: 50,
    marginRight: theme.spacing(),
  },
  disclaimer:{
    textAlign: 'center',
    fontSize: '.85rem',
    marginTop: theme.spacing(3),
  },
  proposalDetails:{
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  spanTitle: {
    position: 'relative',
    top: -5
  },
  descriptionStatus: {
    position: 'relative',
    top: -10
  },
  buttonPendencia: {
    marginLeft: -5,
    marginTop: -15
  },
  titlePendencia: {
    marginTop: 10
  },
  docPendencia: {
    '&:hover': {
      cursor: 'pointer',
    }
  },
  noDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 160,
  },
  containerDocumentsSended: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  messageDocumentSended: {
    width: "95%",
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  titleDocumentSended: {
    fontSize: 15,
    fontWeight: 'bold',
    position: 'relative',
    top: 10
  },
  spanDocumentSended: {
    color: 'rgb(0, 181, 114)',
  }
});

const CardProposalDetails = (props) => {

  const { classes, proposalId, proposal, onClose, updateProposal } = props;
  const history = useHistory();
  const theme = useTheme();
  const context = React.useContext(FormContext)
  const [openSteps, setOpenSteps] = React.useState(false)
  const [openFormDadosBancarios, setOpenFormDadosBancarios] = React.useState(false)
  const [openFormSelfie, setOpenFormSelfie] = React.useState(false)
  // const [openFormDocumentosFrente, setOpenFormDocumentosFrente] = React.useState(false)
  // const [openFormDocumentosVerso, setOpenFormDocumentosVerso] = React.useState(false)
  // const [openFormVeiculoFrente, setOpenFormVeiculoFrente] = React.useState(false)
  // const [openFormVeiculoVerso, setOpenFormVeiculoVerso] = React.useState(false)
  const [openCRLV, setOpenCRLV] = React.useState(false)
  const [proposalData, setProposalData] = React.useState()

  const [loading, setLoading] = React.useState(true)

  const getProposalData = async () => {

    setLoading(true)

    console.log('fetch proposal data with id',proposalId)

    try{
      const token = await context.getFirebaseToken();
      const proposalResult = await axios.get(`${context.state.API_ENDPOINT}/proposals/${proposalId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('proposal fetched',proposalResult.data.data)
      setProposalData(proposalResult.data.data)

    }catch(error){
      console.log(error)
      setProposalData(null)
    }

    setLoading(false)

  }

  let title;
  let statusTextColor;

  if(proposalData){
    switch(proposalData.status){
      case "pagamento_realizado":
        title = `Contrato Pago`;
        statusTextColor = 'blue';
        break;
      case "credito_aprovado": case "pagamento_aprovado":
        title = `Crédito Pré Aprovado`;
        statusTextColor = theme.palette.primary.main;
        break;
      case "pre_analise_aprovada":
      case "proposta_pendente":
      case "proposta_aprovada":
        title = `Em Análise de Crédito`;
        statusTextColor = theme.palette.secondary.main;
        break;
      default:
        title = `Sem ofertas disponíveis`;
        statusTextColor = theme.palette.text.primary.main;
        break;
    }
  }

  React.useEffect(() => {
    getProposalData()
  }, [proposal])

  if(loading) return (
    <div className={classes.noDetails}>
      <CircularProgress/>
    </div>
  )

  if(!proposalData) return (
    <div className={classes.noDetails}>
      <Typography>Não foi possível buscar os detalhes de sua proposta.</Typography>
      <Button variant="contained" color="primary" onClick={getProposalData}><Typography style={{color:'white'}}>Tentar novamente</Typography></Button>
    </div>
  )

  const getPartnerImage = (partnerId) => {
    let imgSrc
    try {
      imgSrc = require(`../../../assets/img/partners/${partnerId}.png`).default;
    } catch (e) {
      if (e.code !== 'MODULE_NOT_FOUND') {
        console.log(`../../../assets/img/partners/${partnerId}.png nao encontrado...`)
      }
    }
    try {
      imgSrc = require(`../../../assets/img/partners/${partnerId}.svg`).default;
    } catch (e) {
      if (e.code !== 'MODULE_NOT_FOUND') {
        console.log(`../../../assets/img/partners/${partnerId}.svg nao encontrado...`)
      }
    }
    return imgSrc
  }

  return <div className={classes.root}>
    {
      proposalData.partnerId=='jeitto' && <CardJeitto proposal={proposalData} onClose={onClose}/>
    }
    {
      proposalData.partnerId!='jeitto' &&
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.cardHeader}>
            <img src={getPartnerImage(proposalData.partnerId)} className={classes.partnerLogo}/>
            <Typography variant="h5" className={classes.title} style={{
              color: statusTextColor,
            }}>{title}</Typography>
          </div>
          {
            proposalData.produto === 'Empréstimo com Saque FGTS' && <Typography style={{ color: 'grey', fontSize: 13 }}>Empréstimo com Saque FGTS</Typography>
          }
          {
            context.state.feature_flag_api_v2_sim === true ?
              proposalData.partnerId === 'sim' &&
              proposalData.dados_bancarios_enviado === false ||
              proposalData.foto_selfie_enviado === false ||
              proposalData.doc_identidade_frente_enviado === false ||
              proposalData.doc_identidade_verso_enviado === false ||
              proposalData.foto_veiculo_motor_enviado === false ||
              proposalData.foto_veiculo_traseira_enviado === false ||
              proposalData.foto_veiculo_crlv_enviado === false ? <Typography><span style={{ color: '#fe0064', fontWeight: 'bold', fontSize: 12 }}>Pendente envio de documentos</span></Typography> : ''
            : ''
          }
          {
            proposalData.partnerId === 'sim' && proposalData.externalId !== undefined && <Typography><span style={{ color: '#fe0064', fontWeight: 'bold', fontSize: 16 }}>SIM #{proposalData.externalId}</span></Typography>
          }
          <Typography style={{color: 'grey', fontSize: 13}} gutterBottom>Data da solicitação: {moment.unix( getFirestoreTimestamp(proposalData.createdAt).seconds ).format('DD/MM/YYYY HH:mm')}</Typography>
          {
            title==`Crédito Pré Aprovado` &&
            <>
              <div className={classes.proposalDetails}>
                {
                  proposalData.amount && proposalData.installmentAmount && proposalData.term && proposalData.jurosMensais && proposalData.cetMensal
                  ?
                    <>
                      <Typography>Valor liberado: <strong>{formatFloatToCurrency(proposalData.amount)}</strong></Typography>
                      <Typography>Valor da prestação: <strong>{`${formatFloatToCurrency(proposalData.installmentAmount)} em ${proposalData.term} parcelas mensais`}</strong></Typography>
                      <Typography>Juros mensal: <strong>{`${proposalData.jurosMensais}%`}</strong></Typography>
                      <Typography>CET mensal: <strong>{`${proposalData.cetMensal}%`}</strong></Typography>
                    </>
                  :
                  // <Typography>Condições: <strong>{proposalData.condicoes}</strong></Typography>

                  proposalData.partnerId == 'bv' ? <Typography>Seu crédito está aprovado, clique no botão abaixo e veja suas condições</Typography> : <Typography>Condições: <strong>{proposalData.condicoes}</strong></Typography>

                }
              </div>
              <div className={classes.buttonContainer}>
                <Button className={classes.button} variant="contained" color="primary" onClick={(e)=>{
                  window.open(encodeURI(`https://api.whatsapp.com/send?phone=${context.state.whatsAppNumber}&text=Olá, estou com o crédito pré aprovado e gostaria de finalizar meu pedido. Meu e-mail é ${proposalData.email}`),"_blank");
                }}>
                  <img src={WhatsAppIcon} className={classes.buttonIcon}/>
                  <Typography style={{color:'white'}}>Clique e veja suas condições</Typography>
                </Button>
              </div>
            </>
          }
          {
            title==`Em Análise de Crédito` &&
            <>
              <div className={classes.proposalDetails}>
                {
                  proposalData.partnerId == 'sim' ? <Typography>Condições: <strong>{proposalData.condicoes}</strong></Typography> : ''
                }
                {
                  proposalData.partnerId == 'sim' && openSteps == false && context.state.feature_flag_api_v2_sim === true ?
                    proposalData.dados_bancarios_enviado === true &&
                    proposalData.foto_selfie_enviado === true &&
                    proposalData.doc_identidade_frente_enviado === true &&
                    proposalData.doc_identidade_verso_enviado === true &&
                    proposalData.foto_veiculo_motor_enviado === true &&
                    proposalData.foto_veiculo_traseira_enviado === true &&
                    proposalData.foto_veiculo_crlv_enviado === true ?
                      <div className={classes.containerDocumentsSended}>
                        <div className={classes.messageDocumentSended}>
                          <CheckCircle color="primary" fontSize="large" style={{position: 'relative', top: 15}} />
                          <p className={classes.titleDocumentSended}>Todos os documentos foram <span className={classes.spanDocumentSended}>enviados com sucesso!</span></p>
                        </div>
                        <p style={{ textAlign: 'center' }}>Aguarde e você receberá uma resposta por e-mail, SMS ou telefone.</p>
                        {/* <div>
                          <p><b>Entenda o que acontece agora:</b></p>
                          <InfoProposalSIM />
                        </div> */}
                      </div>
                      :
                      <div className={classes.buttonContainer}>
                        <p style={{textAlign: 'center'}}>Alguns documentos ainda precisam ser enviados.</p>
                        <Button className={classes.button} variant="contained" color="primary" onClick={() => {
                          // const win = window.open(`/sim-envio-documentos/?proposal=${proposalData.id}`, "_blank");
                          // win.focus();
                          history.push(`/sim-envio-documentos/?proposal=${proposalData.id}`)
                        }}>
                          <Typography style={{color:'white'}}>clique aqui e finalize sua contratação</Typography>
                        </Button>
                      </div>
                  : ''
                }
                {
                  proposalData.partnerId !== 'sim' && <Typography gutterBottom><strong>Esta proposta encontra-se em Análise de Crédito.</strong></Typography>
                }
              </div>
            </>
          }
          {
            title==`Sem ofertas disponíveis` &&
            <div className={classes.proposalDetails}>
              <div className={classes.buttonContainer}>
                <Button className={classes.button} variant="contained" color="primary" onClick={(e)=>{
                  history.push('/area-logada/servicos-produtos');
                }}>
                  <img src={FormIcon} className={classes.buttonIcon}/>
                  <Typography>Clique aqui e veja outros empréstimos pra você!</Typography>
                </Button>
              </div>
            </div>
          }

          <Button
            color="primary"
            startIcon={<KeyboardBackspaceOutlined />}
            onClick={onClose}
          >
          Voltar
          </Button>
        </CardContent>
      </Card>
    }
  </div>
}

export default withTheme(withStyles(styles)(CardProposalDetails));
