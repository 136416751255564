import React, { useRef, useState } from "react";
import {
  Dialog, DialogTitle, DialogActions, DialogContent, withTheme, Button, CircularProgress, Typography, Grid, Hidden
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from "@material-ui/styles";
import CampoNumero from "../../fields/CampoNumero";
import { Form, Formik, useFormikContext} from "formik";
import * as Yup from 'yup';
import axios from "axios";
import FormContext from "../../FormContext";
import Webcam from "react-webcam";
import MuiAlert from '@material-ui/lab/Alert';
import { set } from "lodash";
import CameraAltOutlined from "@material-ui/icons/CameraAltOutlined";
import ModalSelfie from './formSelfieModal'
import FormActions from "../../FormActions";
import CheckCircle from '@material-ui/icons/CheckCircle';
import formatMoney from '../../../lib/formatMoney'

const styles = (theme) => ({
  closeButton: {
    display: "block",
    margin: "0 0 0 auto",
  },
  selectData: {
    padding: 10,
    margin: 20,
    // border: "1px solid grey",
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: 'inset 0px 0px 0px 1px #e7e7e7;',

  },
  selectDataContainer: {
    padding: 10,
    margin: 20,
    borderRadius: '10px',
    boxShadow: 'inset 0px 0px 0px 1px #e7e7e7;',
  },
  box: {
    padding: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // width: 500,
    // height: 500,
  },
  buttons: {

  },
  sendDocumentos: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const formSelfie = (props) => {

  const { classes } = props
  const context = React.useContext(FormContext);
  const urlParams = new URLSearchParams(window.location.search);
  const { isSubmitting } = useFormikContext();
  const proposalId = urlParams.get("proposal");
  // const [openFeedbackError, setOpenFeedbackError] = useState(false)
  // const [feedbackErrorMessage, setFeedbackErrorMessage] = useState('')

  // const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingContract, setLoadingContract] = useState(true)
  const [openModalSelfie, setOpenModalSelfie] = useState(false)
  const [triggerSelfieSended, setTriggerSelfieSended] = useState(false)
  const [showContract, setShowContract] = useState(false)
  const [hasErrorContract, setHasErrorContract] = useState(false)
  const [proposalContractPreviewData, setProposalContractPreviewData] = React.useState()

  const [proposalData, setProposalData] = React.useState()

  const [cetString, setCetString] = React.useState()
  const [jurosString, setJurosString] = React.useState()

  const [contractEmailSended, setContractEmailSended] = React.useState(false)
  const [loadingContractEmailSended, setLoadingContractEmailSended] = React.useState(false)

  const getProposalContractPreview = async () => {
    setLoadingContract(true)
    try{
      const token = await context.getFirebaseToken();
      const proposalContractPreviewResult = await axios.post(`${context.state.API_ENDPOINT}/sim/proposal/${proposalData.externalId}/resumo-contrato`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const regexCET = new RegExp(`(CET: *)([^b]*)`, 'g');
      const regexJuros = new RegExp(`(Taxa de juros:*)([^b]*)`, 'g');
      const cetString = proposalContractPreviewResult.data.data.match(regexCET)[0].slice(0, -1).slice(4)
      const taxaJurosString = proposalContractPreviewResult.data.data.match(regexJuros)[0].slice(0, -1).slice(14)
      setCetString(cetString)
      setJurosString(taxaJurosString)

      setProposalContractPreviewData(proposalContractPreviewResult.data.data)
      setLoadingContract(false)
      setHasErrorContract(false)
      props.updateResize();
    }catch(error){
      console.log(error)
      setProposalContractPreviewData(null)
      setHasErrorContract(true)
      setLoadingContract(false)
    }
  }

  const sendContractToEmail = async () => {
    setLoadingContractEmailSended(true)
    const token = await context.getFirebaseToken();
    const resultSendContratToEmail = await axios.post(`${context.state.API_ENDPOINT}/sim/send-contract-sim/${proposalData.externalId}/${proposalData.email}`, proposalContractPreviewData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(resultSendContratToEmail)
    if (resultSendContratToEmail.data.success) {
      setContractEmailSended(true)
      setLoadingContractEmailSended(false)
    }

  }

  React.useEffect(() => {
    const getProposalData = async () => {
      let proposalIdString;
      try{
        const token = await context.getFirebaseToken();
        const proposalResult = await axios.get(`${context.state.API_ENDPOINT}/proposals/${proposalId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProposalData(proposalResult.data.data)
        proposalIdString = proposalResult.data.data.externalId
        setLoading(false)
      }catch(error){
        console.log(error)
        setProposalData(null)
      }
      props.updateResize()
    }
    getProposalData()
  },[context.state.firebaseLoaded, triggerSelfieSended])

  React.useEffect(() => {
    if (proposalData !== undefined) {
      getProposalContractPreview()
    }
  }, [proposalData])

  return (
    <>
      {
        loading === false ?
          <>
            <div>
              <Typography style={{ textAlign: 'center', fontSize: 24  }}>
                <span style={{ color: '#00b572', fontWeight: 'bold' }}>Proposta Pré Aprovada na </span>
                <span style={{ color: '#fe0064', fontWeight: 'bold' }}>Empréstimo SIM</span>
              </Typography>

              <div className={classes.selectData}>
                <div>
                  <Typography variant="h6" gutterBottom style={{fontWeight: 600, textAlign: 'center'}}>Resumo da Proposta <span style={{ color: '#00b572', fontWeight: 'bold' }}> #{proposalData.externalId}</span></Typography>
                </div>

                {
                  loadingContract ? <CircularProgress /> :
                    hasErrorContract !== true ? <>
                      <p style={{fontSize: 20, fontWeight: 'bold'}}><b>Valor Liberado</b></p>
                      <p style={{ color: '#00b572', fontWeight: 'bold', fontSize: 20, marginTop: -20 }}>{formatMoney(proposalData.montante)}</p>
                      <div style={{ width: '70%' }}>
                        <Hidden xsDown>
                          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between',textAlign: 'center'}}>
                            {/* <Grid container spacing={1} justifyContent="center" alignItems="center" > */}
                              <p>Prestações Mensais</p>
                              <p style={{ color: '#00b572', fontWeight: 'bold' }}>{ proposalData.term} vezes de {formatMoney(proposalData.installmentAmount)}</p>
                            {/* </Grid> */}
                          </div>

                          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between',textAlign: 'center'}}>
                            {/* <Grid container spacing={1} justifyContent="center" alignItems="center"> */}
                              <p>Juros</p>
                              <p style={{ color: '#00b572', fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: jurosString }} />
                            {/* </Grid> */}
                          </div>

                          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between',textAlign: 'center'}} >
                            {/* <Grid container spacing={1} justifyContent="center" alignItems="center"> */}
                              <p>CET (Custo Efetivo Total)</p>
                              <p style={{ color: '#00b572', fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: cetString }} />
                            {/* </Grid> */}
                          </div>
                        </Hidden>

                        <Hidden smUp>
                          <p style={{textAlign: 'center'}}>Prestações Mensais</p>
                          <p style={{ color: '#00b572', fontWeight: 'bold', textAlign: 'center' }}>{ proposalData.term} vezes de {formatMoney(proposalData.installmentAmount)}</p>

                          <p style={{textAlign: 'center'}}>Juros</p>
                          <p style={{ color: '#00b572', fontWeight: 'bold', textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: jurosString }} />

                          <p style={{textAlign: 'center'}}>CET (Custo Efetivo Total)</p>
                          <p style={{ color: '#00b572', fontWeight: 'bold', textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: cetString }} />
                        </Hidden>

                      </div>
                      <Button component="label" color="primary" variant="contained" style={{backgroundColor: '#C9E265', borderRadius: 20}} onClick={() => setShowContract(state => !state)}>ver detalhes do contrato </Button>
                      {
                        contractEmailSended ? <Button component="label" color="primary">Contrato enviado para seu e-mail!</Button> :
                          loadingContractEmailSended ? <CircularProgress/> :<Button component="label" color="primary" onClick={() => sendContractToEmail()}>enviar contrato para meu email </Button>
                      }
                    </>
                      :
                      <div>
                        <p>Houve um erro ao obter o contrato.</p>
                        <Button color="primary" onClick={() => getProposalContractPreview()}>Tentar novamente</Button>
                      </div>
                }

              </div>

              <div className={classes.selectDataContainer}>
                <div>
                  <Typography variant="h6" gutterBottom style={{fontWeight: 600}}>Conta que você vai receber o empréstimo:</Typography>
                </div>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between',}}>
                  <p>Banco:</p>
                  <p>{proposalData.banco_label}</p>
                </div>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between',}}>
                  <p>Conta:</p>
                  <p>Ag: {proposalData.banco_agencia} {proposalData.banco_tipo_conta !== '' ? proposalData.banco_tipo_conta === 'Corrente' ? 'C/C' : 'C/P' : 'C/C'}: {proposalData.banco_conta}</p>
                </div>

              </div>

            </div>

            <ModalSelfie open={openModalSelfie} close={() => setOpenModalSelfie(state => !state)} proposalData={proposalData} selfieSended={ () => setTriggerSelfieSended(state => !state)} />

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
              <FormActions isSubmitting={isSubmitting} label="finalizar pedido!" onClick={() => context.nextStepWithoutSubmitForm()} />
              {/* <FormActions onClick={() => context.nextStepWithoutSubmitForm()} label="passar para a proxima etapa - staging" /> */}
            </div>

            <Dialog onClose={() => setShowContract(state => !state)} open={showContract}>
              <DialogTitle>Contrato</DialogTitle>
              <DialogContent>
                <div dangerouslySetInnerHTML={{__html: proposalContractPreviewData}}></div>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowContract(state => !state)} color="primary">fechar</Button>
              </DialogActions>
            </Dialog>

          </>
        : <div style={{ textAlign: `center` }}><CircularProgress/></div>
      }
    </>
  );
};

export default withTheme(withStyles(styles)(formSelfie));
