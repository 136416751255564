import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import {
 Chip, Badge, withTheme, Hidden, Typography, Grid, CardActionArea, CardContent, CardActions, Card, Button
} from '@material-ui/core';
import Car from '@material-ui/icons/DirectionsCarOutlined';
import Person from '@material-ui/icons/PersonOutlined';
import MoneyOutlined from '@material-ui/icons/MoneyOutlined';
import FormContext from './FormContext';

const styles = theme => ({
  paperCard: {
    '&:hover':{
      cursor: 'pointer',
    },
  },
  card: {
    // backgroundColor: 'rgb(0 222 140)'
  },
  imageCardHome: {
    height: '75px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      height: '60px',
      position: 'relative',
      top: -10
    },
  },
  badgeLoggedArea: {
    position: 'relative',
    left: 20,
    top:-25,
  },
  blinkerAnimation:{
    animation: `$blinker 2s linear infinite`,
  },
  '@keyframes blinker': {
    '50%': {
      opacity: .25,
    },
  },
  badgeProductsServices: {
    margin: 10,
    [theme.breakpoints.down('xs')]: {
      left:0
    },
  },
  typesLoan: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  typesLoanLoggedArea: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
    marginTop: -30
  },
  titleCardLoggedArea: {
    fontSize: 18,
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
    },
  },
  cardLoggedArea: {
    height: 120,
    [theme.breakpoints.down('xs')]: {
      height: 80,
    },
  },
  contentLoggedArea: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      // position: 'relative',
      // top: 10
      // textAlign: 'center'
      // justifyContent: 'center',
      // margin: '0 auto'
    },
  },
  paperCardProductsServices: {
    // margin: theme.spacing(),
    width: 200,
    minHeight: 200,
    textAlign: 'center',
    '&:hover':{
      cursor: 'pointer',
    },
    // display: 'inline-block',
  },
   cardIcon:{
    width: 70,
    margin: theme.spacing(),
  },
  cardLabel:{
    whiteSpace: 'break-spaces',
    fontSize: '.9rem',
    fontWeight: 600
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'center',
  },
  //CARDS NEW LOAN - LOGGED AREA
  paperCardLoan: {
    '&:hover':{
      cursor: 'pointer',
    },
  },
  imageCardHomeLoan: {
    height: '120px',
    display: 'flex',
    justifyContent: 'center',
  },
  contentCardHomeLoan: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  }
})

const CardHomeLoggedArea = (props) => {

  const history = useHistory();
  const context = React.useContext(FormContext);

  const [openEmprestimoImovelGarantia, setOpenEmprestimoImovelGarantia] = useState(false);

  switch (props.type) {
    case 'home-logged-area':
      return (
        <Grid item xs={12} className={props.classes.paperCard} onClick={() => {
          if (props.active !== false) {
            return history.push(props.route)
          }
        }}>
          <Card variant="outlined" className={props.classes.cardLoggedArea}>
            <CardContent>
              <Grid item container direction="row" xs={12}>
                      
                <Grid item xs={3} sm={5} className={props.classes.imageCardHome}>
                  <img src={props.image} alt={props.imageAlt} />
                </Grid>

                <Grid item xs={9} sm={7} className={props.classes.contentLoggedArea}>
                  {props.emBreve && <Badge badgeContent={"BREVE"} color="secondary" className={props.classes.badgeLoggedArea}></Badge>}
                  {props.novidade && <Badge badgeContent={"NOVIDADE!"} color="secondary" className={`${props.classes.badgeLoggedArea} ${props.classes.blinkerAnimation}`}></Badge>}
                  <Typography variant="h6" gutterBottom style={{fontWeight: 600}} className={props.classes.titleCardLoggedArea}>{props.title}</Typography>
                </Grid>
                <Hidden xsDown>
                  <Grid item xs={3} sm={5}/>
                  <Grid item xs={9} sm={7}>
                    {
                      props.route === '/area-logada/novo-emprestimo' &&
                      <>
                        <div className={props.classes.typesLoanLoggedArea}>
                          <Chip label="Empréstimo com veículo em garantia"  size="small" color="primary" />
                        </div>
                      </>
                    }
                  </Grid>
                </Hidden>

              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )
    case 'new-loan-logged-area':
      return (
        <Grid
          item xs={12}
          className={props.classes.paperCardLoan}
          onClick={props.active !== false ? props.modal && !props.alreadyClicked? props.clickModal : props.modal && props.alreadyClicked? '' : props.partner? () => window.open(props.route, '_blank') : () => history.push(props.route) : ''}
        >
          <Card variant="outlined">
            <CardContent>
              <Grid item container direction="row" xs={12}>
                      
                <Grid item xs={12} sm={5} className={props.classes.imageCardHomeLoan}>
                  <img src={props.image} alt={props.imageAlt} />
                </Grid>

                <Grid item xs={12} sm={7} className={props.classes.contentCardHomeLoan}>
                  <Typography variant="h6" gutterBottom style={{ fontWeight: 600 }}>{props.title}</Typography>
                  <Typography variant="caption" color="textSecondary">{props.desc}</Typography>
                  <div className={props.classes.typesLoan}>
                    {props.benefits.map(benefit => <Chip size="small" key={benefit} label={benefit} color="secondary" className={props.animated ? props.classes.blinkerAnimation : ""} />)}
                  </div>
                </Grid>

              </Grid>
            </CardContent>
            <CardActions className={props.classes.cardAction}>
              {
              props.active === false ?
                <CardActions className={props.classes.cardAction}>
                  <Button size="small" color="primary">Em breve</Button>
                </CardActions>
              :
              <CardActions className={props.classes.cardAction}>
                {
                  props.modal && !props.alreadyClicked? 
                  <Button size="small" color="primary" onClick={props.clickModal}>Solicitar Agora!</Button>
                :
                props.modal && props.alreadyClicked ? 
                  <Button size="small" color="primary" disabled>Já Solicitado</Button>
                : 
                  <Button size="small" color="primary" onClick={() => {
                    if (props.insuranceCar || props.partner) {
                      window.open(props.route, '_blank')
                    } else {
                      history.push(props.route)  
                    }  
                    }}>Solicitar Agora!</Button>
                  }
              </CardActions>
            }
            </CardActions>
          </Card>
        </Grid>
      )
    case 'products-services-logged-area':
      return (
        <Grid item xs={12} sm={4}>
          <Card
            className={props.classes.paperCardProductsServices}
            // onClick={props.active !== false ? props.modal? props.clickModal : props.partner? () => window.open(props.route, '_blank') : () => history.push(props.route) : ''}
            onClick={props.active !== false ? props.modal && !props.alreadyClicked? props.clickModal : props.modal && props.alreadyClicked? '' : props.partner? () => window.open(props.route, '_blank') : () => history.push(props.route) : ''}
          >
            <CardContent>
              <Grid
                container
                direction="column"
                alignItems="center"
              >
                <img src={props.image} alt={props.imageAlt} className={props.classes.cardIcon} />
                {props.active === false && <Badge badgeContent={"BREVE"} color="secondary" className={props.classes.badgeProductsServices}/>}
                {props.novidade && <Badge badgeContent={"NOVIDADE!"} color="secondary" className={`${props.classes.badgeProductsServices} ${props.classes.blinkerAnimation}`}/>}
                <Typography className={props.classes.cardLabel}>{props.title}</Typography>
                <Typography variant="caption" color="textSecondary">{props.desc}</Typography>
              </Grid>
            </CardContent>
            {
              props.active === false ?
                <CardActions className={props.classes.cardAction}>
                  <Button size="small" color="primary">Em breve</Button>
                </CardActions>
              :
              <CardActions className={props.classes.cardAction}>
                {
                  props.modal && !props.alreadyClicked? 
                  <Button size="small" color="primary" onClick={props.clickModal}>Solicitar</Button>
                :
                props.modal && props.alreadyClicked ? 
                  <Button size="small" color="primary" disabled>Já Solicitado</Button>
                : 
                  <Button size="small" color="primary" onClick={() => {
                    if (props.insuranceCar || props.partner) {
                      window.open(props.route, '_blank')
                    } else {
                      history.push(props.route)  
                    }  
                    }}>Solicitar</Button>
                  }
              </CardActions>
            }
          </Card>
        </Grid>
      )
  }
}

export default withTheme(withStyles(styles)(CardHomeLoggedArea));