import React from "react";
import {
  FormControl,Dialog, DialogTitle, DialogActions, DialogContent, Button, Snackbar, CircularProgress, Grid, Typography,InputLabel, Select
} from "@material-ui/core";
import * as Yup from 'yup';
import { Formik, Form, useFormikContext } from 'formik';
import CampoNumero from '../../fields/CampoNumero';
import CampoRadio from '../../fields/CampoRadio';
import CampoSelect from '../../fields/CampoSelect';
import ValidationForm from '../../../lib/Validations'
import FormContext from '../../FormContext';
import axios from 'axios';
import MuiAlert from '@material-ui/lab/Alert';
import FormActions from "../../FormActions";
import ArrowRightAltRounded from '@material-ui/icons/ArrowRightAltRounded';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import CheckCircle from '@material-ui/icons/CheckCircle';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const validationSchema = Yup.object().shape({
  banco_tipo_conta: Yup.string()
  .required('Por favor, selecione o tipo de sua conta.'),
  banco: Yup.string()
  .required('Por favor, selecione o banco.'),
  banco_agencia: Yup.string()
  .matches(/^\d+$/, "Por favor, digite apenas números.")
  .required('O campo agência é obrigatório.')
  .test('banco_agencia', 'Sua conta deve ter 4 caracteres .', function (value) {
    if(value==undefined) return;
    const characters = value.toString().length;
    if (characters < 4) {
      return false
    }
    return true;
  }),

  banco_conta: Yup.string()
  .required('O campo conta é obrigatório.')
  .matches(/^\d+$/, "Por favor, digite apenas números.")
  .test('banco_conta', 'Sua conta deve ter entre 3 e 13 digitos.',
    function (value) {
      if(value==undefined) return;
      const characters = value.toString().length;
      if (characters < 3) {
        return false
      }
      if (characters > 13) {
        return false
      }
      return true;
      }),
})

const formDadosBancarios = (props) => {

  const urlParams = new URLSearchParams(window.location.search);
  const proposalId = urlParams.get("proposal");
  const context = React.useContext(FormContext);
  const { isSubmitting, submitForm} = useFormikContext();
  const [hasError, setHasError] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [loadingSend, setLoadingSend] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [updateSuccess, setUpdateSuccess] = React.useState(false)
  const [proposalData, setProposalData] = React.useState()
  const [simCompatibleBanks, setSimCompatibleBanks] = React.useState([])

  const handleSendDadosBancarios = async (values, errors) => {
    setHasError(false)
    if (proposalData.conta_santander_debito_automatico !== undefined && proposalData.conta_santander_debito_automatico === "Sim" && proposalData.dados_bancarios_enviado === true) {
      console.log("proposta debito automatico santander - next step")
      context.nextStepWithoutSubmitForm();
    } else {
      if (values.banco === "" || values.banco_agencia === "" || values.banco_conta === "" || values.banco_tipo_conta === "" ) {
        setErrorMessage("Preencha todos os campos: tipo de conta, banco, agência e conta!")
        setHasError(true)
      } else if (Object.keys(errors).length >= 1) {
        setErrorMessage("Valide os erros no formulário!")
        setHasError(true)
      } else {
        setHasError(false)
        setLoadingSend(true)
          const token = await context.getFirebaseToken()
          const data = {
            ...proposalData,
            banco: values.banco,
            banco_agencia: values.banco_agencia,
            banco_conta: values.banco_conta,
            banco_tipo_conta: values.banco_tipo_conta,
            banco_label: values.banco_label
          }
          const responseUpdateBankAccountProposal = await axios.post(`${context.state.API_ENDPOINT}/sim/proposal-bank-account`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })

          if (responseUpdateBankAccountProposal.data.success) {
            setUpdateSuccess(state => !state)
            context.nextStepWithoutSubmitForm();
          } else {
            setErrorMessage(responseUpdateBankAccountProposal.data.message)
            setHasError(true)
          }
          setLoadingSend(false)
      }
    }
  }

  React.useEffect(() => {
    
    const getProposalData = async () => {
      try{
        const token = await context.getFirebaseToken();
        const proposalResult = await axios.get(`${context.state.API_ENDPOINT}/proposals/${proposalId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProposalData(proposalResult.data.data)
        if(proposalResult.data.data.dados_bancarios_enviado==true) context.nextStepWithoutSubmitForm()
      }catch(error){
        console.log(error)
        setProposalData(null)
      }
      setLoading(false)
      props.updateResize()

    }
    
    setTimeout(() => {
      getProposalData()
    }, 2000);

  }, [context.state.firebaseLoaded])

  React.useEffect(() => {
    
    const getSimCompatibleBanks = async () => {
      
      try{

        const token = await context.getFirebaseToken();
        const proposalResult = await axios.get(`${context.state.API_ENDPOINT}/sim/banks`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        setSimCompatibleBanks(proposalResult.data)
      
      }catch(error){

        console.log(error)
        
      }

    }
    
    getSimCompatibleBanks()
    
  }, [context.state.firebaseLoaded])



  return (<>
      {
        loading === false ?
        <>
            <Typography>Proposta <span style={{ color: '#fe0064', fontWeight: 'bold' }}>SIM #{proposalData.externalId}</span></Typography>
            <Typography>Informe abaixo seus dados bancários para depósito:</Typography>
            <Typography><ArrowRightAltRounded/><span style={{position: 'relative', top: -7}}>A conta deve estar em nome de quem solicita o empréstimo</span></Typography>
            <Typography><ArrowRightAltRounded/><span style={{position: 'relative', top: -7}}>A conta precisa ser de Pessoa Física</span></Typography>
            <Typography><ArrowRightAltRounded/><span style={{position: 'relative', top: -7}}>A conta não pode conter pontos e nem hífens, somente números.</span></Typography>
            <Formik
              initialValues={proposalData}
              validationSchema={validationSchema}
              >
              {({
                values,
                errors,
                touched,
                handleChange,
                setFieldValue
              }) => (
              <Form>
                {
                  values.conta_santander_debito_automatico === "Sim" && <div style={{margin: 10}}><CheckCircle color="primary" fontSize="large"/><span style={{fontWeight: 'bold', position: 'relative', top: -10, fontSize: 20 }}>Você optou pelo <span style={{color: 'rgb(0, 181, 114)'}}>débito em conta</span>! Você receberá seu empréstimo na mesma conta informada abaixo</span></div>
                }
                {
                  values.conta_santander_debito_automatico !== undefined && values.conta_santander_debito_automatico === "Não" &&
                  <CampoRadio
                    name = 'banco_tipo_conta'
                    id = 'banco_tipo_conta'
                    value = {values.banco_tipo_conta}
                    label = "Tipo de conta"
                    options={[
                      {label: 'Corrente', value:'Corrente'},
                      {label: 'Poupança', value:'Poupança'},
                    ]}
                    onChange={(value) => {
                      setFieldValue('banco_tipo_conta',value.value);
                    }}
                    showError={errors.banco_tipo_conta}
                    helperText={errors.banco_tipo_conta && touched.banco_tipo_conta ? errors.banco_tipo_conta : ''}
                    touched={touched.banco_tipo_conta}
                  />
                }
                <Grid container spacing={2}>

                <Grid item xs={12}>
                  {values.conta_santander_debito_automatico === "Sim" ?
                    <FormControl variant="outlined" style={{width: '100%'}}>
                      <InputLabel>Banco</InputLabel>
                      <Select
                        disabled
                        value="033"
                        label="Banco"
                        >
                        <option key={values.banco} value={values.banco}>{values.banco_label}</option>
                      </Select>
                      </FormControl>
                    :
                    <CampoSelect
                      label="Banco"
                      name="banco"
                      id="banco"
                      value={values.banco}
                      onChange={(data) => {
                          console.log(data)
                          setFieldValue('banco', data.value);
                          setFieldValue('banco_label', data.label);
                        }}
                        options={simCompatibleBanks}
                        error={errors.banco}
                        helperText={errors.banco && touched.banco ? errors.banco : ''}
                        touched={touched.banco}
                      />
                    }
                  </Grid>

                  <Grid item xs={6}>
                    <CampoNumero
                      label="Agência sem digito"
                      name="banco_agencia"
                      id="banco_agencia"
                      placeholder="Agência sem dígito (somente número)"
                      type="tel"
                      inputProps={{ maxLength: 4 }}
                      disabled={values.conta_santander_debito_automatico === 'Sim'}
                      value={values.banco_agencia }
                      onChange={handleChange}
                      error={errors.banco_agencia}
                      // showError={errors.banco_agencia}
                      helperText={errors.banco_agencia}
                    />

                  </Grid>
                    <Grid item xs={6}>
                      <CampoNumero
                        label="Conta com dígito"
                        name="banco_conta"
                        id="banco_conta"
                        type="tel"
                        disabled={values.conta_santander_debito_automatico === 'Sim'}
                        placeholder="Conta com dígito (somente números)"
                        inputProps={{ maxLength: 13 }}
                        value={values.banco_conta}
                        onChange={handleChange}
                        error={errors.banco_conta}
                        // showError={errors.banco_conta}
                        helperText={errors.banco_conta}
                      />

                  </Grid>
                </Grid>

                <div style={{ margin: "0 auto" }}>
                    {
                      loadingSend && <div style={{ textAlign: `center` }}><CircularProgress/></div>
                    }
                    {
                      hasError && <div><ErrorOutline fontSize="large" style={{color: 'red'}} /><span style={{ fontWeight: 'bold', position: 'relative', top: -10 }}>{errorMessage}</span></div>
                  }
                  <FormActions isSubmitting={isSubmitting} disabled={loadingSend} onClick={() => handleSendDadosBancarios(values, errors)} />
                  {/* <FormActions onClick={() => context.nextStepWithoutSubmitForm()} label="passar para a proxima etapa - staging" /> */}
                    {/* <Aviso errors={errors} touched={touched}/> */}
                  </div>
                </Form>
              )}
            </Formik>

            <Snackbar open={updateSuccess} autoHideDuration={3000} onClose={() => setUpdateSuccess(state => !state)}>
              <Alert onClose={() => setUpdateSuccess(state => !state)} severity={'success'}>Sucesso ao enviar os dados bancários :)</Alert>
            </Snackbar>
          </>
        : <div style={{ textAlign: `center` }}><CircularProgress/></div>
    }
    </>);
};

export default formDadosBancarios;
