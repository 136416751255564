import React from "react";
import {
  Dialog, DialogTitle, DialogActions, DialogContentText, DialogContent, withTheme, Button, Snackbar, CircularProgress, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import {useHistory} from 'react-router-dom'

import { isMobile } from 'react-device-detect';
import AddIcon from "@material-ui/icons/Add";
import CheckCircle from '@material-ui/icons/CheckCircle';
import HelpOutlineOutlined from '@material-ui/icons/HelpOutlineOutlined';
import FormActions from "../../FormActions";
import ErrorOutline from '@material-ui/icons/ErrorOutline';
// import CameraAlt from "@material-ui/icons/CameraAlt";
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios'
import FormContext from '../../FormContext';
import ArrowRightAltRounded from '@material-ui/icons/ArrowRightAltRounded';

import CRLVDocumento from '../../../assets/img/crlv-photo.jpg'
import InfoProposalSIM from "../../modal/modalSIMProposal/modal";

const styles = (theme) => ({
  selectData: {
    padding: 10,
    // border: "1px solid grey",
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: 'inset 0px 0px 0px 1px #e7e7e7;',

  },
  box: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // width: 500,
    // height: 500,
  },
  buttons: {

  },
  sendDocumentos: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});


const formFinal = (props) => {

  const { classes } = props
  const history = useHistory();
  const context = React.useContext(FormContext);

  return (
    <div style={{width: '70%', margin: '0 auto'}}>
      <CheckCircle color="primary" fontSize="large"/><span style={{fontWeight: 'bold', position: 'relative', top: -10, fontSize: 20 }}>Empréstimo <span style={{color: 'rgb(0, 181, 114)'}}>Solicitado!</span></span>
      <DialogContentText>Agora basta somente aguardar. Você receberá uma resposta por e-mail, SMS ou telefone. </DialogContentText>
      <div className={classes.selectData}>
        <div className={classes.box}>
          <div style={{textAlign: 'center'}}>
            <Typography variant="h3" gutterBottom><b>Importante!</b></Typography>
          </div>
        </div>

        <div className={classes.buttons}>
          <DialogContentText>Você deverá reemitir o CRV/ Dut no Detran, em 30 dias do início do contrato, conforme previsto nos Artigo123 e 233 do Código de Trânsito Brasileiro.</DialogContentText>
        </div>
      </div>

      {/* <div>
        <p><b>Entenda o que acontece agora:</b></p>
        <InfoProposalSIM />
      </div> */}

      <br/>

      <div className={classes.sendDocumentos}>
        <Button component="label" variant="contained" color="primary" style={{color: 'white'}} onClick={() => history.push('/area-logada')}>
          acessar área logada
        </Button>
      </div>

    </div>

  );
};

export default withTheme(withStyles(styles)(formFinal));
