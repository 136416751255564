import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  queroFinanciar:{
    contentMaxWidth: 800,
  },
  palette: {
    primary: {
      main: '#00b572',
      light: '#bbe9d4',
    },
    amarelo: {
      main: '#FFD20D',
    },
    secondary: {
      main: '#FFD20D',
    }
  },
  typography: {
    // htmlFontSize: 16,
    // fontSize: 16,
    fontFamily: [
      'Source Sans Pro',
      // '-apple-system',
      // 'BlinkMacSystemFont',
      // '"Segoe UI"',
      // 'Roboto',
      // '"Helvetica Neue"',
      // 'Arial',
      // 'sans-serif',
      // '"Apple Color Emoji"',
      // '"Segoe UI Emoji"',
      // '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiTypography: {
      h3:{
        fontSize: '2rem',
        fontWeight: 300,
      },
    },
  },
});