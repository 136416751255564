'use strict';

import Autosuggest from 'react-autosuggest';
import { ReactAutosuggestStyles } from '../../GlobalStyles';
import TextField from '@material-ui/core/TextField';

const React = require('react');

class CampoSugestoes extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
    	value: '',
    	suggestions: []
    }

  }

  getSuggestions = value => {
		const inputValue = value.trim().toLowerCase().normalize();
		const inputLength = inputValue.length;

		return inputLength === 0 ? [] : this.props.options.filter(item =>
			item.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(inputValue.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))!=-1
		);
	};

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  getSuggestionValue(suggestion) {
    return suggestion.value;
  }

  onSuggestionSelected(e){
    // console.log(e.currentTarget,e.currentTarget.dataset,e.currentTarget.dataset.suggestionIndex);
    const i = Number(e.currentTarget.dataset.suggestionIndex);
    // console.log('index:',i,this.state.suggestions[i]);
    if(this.props.onSuggestionSelected){
      this.props.onSuggestionSelected(
        this.state.suggestions[i].value
      );
    }
  }

  renderSuggestion(suggestion) {
	  return (
      <span id={suggestion.value}>{suggestion.value}</span>
	  );
  }

  renderInputComponent(inputProps) {
    return (
      <TextField
        {...inputProps}
        valu={inputProps.value}
        variant="outlined"
        fullWidth
      />
    )
  }

  render() {
    
    return (
      <div className="campo-wrapper">
        <Autosuggest
          alwaysVisible={true}
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested.bind(this)}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested.bind(this)}
          getSuggestionValue={this.getSuggestionValue.bind(this)}
          renderSuggestion={this.renderSuggestion.bind(this)}
          renderInputComponent={this.renderInputComponent.bind(this)}
          inputProps={{
            ...this.props.inputProps,
            value: this.props.inputProps.value || "",
          }}
          onSuggestionSelected={this.onSuggestionSelected.bind(this)}
          theme={ReactAutosuggestStyles}
        />
      </div>
    );
  }

}

export default CampoSugestoes;