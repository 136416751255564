import { Container, useTheme } from '@material-ui/core';
import React from 'react';

const Content = (props) => {
  const theme = useTheme()
  return (
    <Container maxWidth="md" style={{paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2)}}>
      {props.children}
    </Container>
  );
};

export default Content;