import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom'
import CampoLabel from '../fields/CampoLabel';
import FormContext from '../FormContext';
import Typography from '@material-ui/core/Typography';
import { Button, CircularProgress, useTheme } from '@material-ui/core';
import axios from 'axios'
import Loading from '../Loading';
import bvLogo from '../../assets/img/partners/bv.png'
import wimoLogo from '../../assets/img/partners/wimo.png'
import creditasLogo from '../../assets/img/partners/creditas.png'
import easyCreditoLogo from '../../assets/img/partners/easycredito.png'
import omniLogo from '../../assets/img/partners/omni.png'
import simLogo from '../../assets/img/partners/sim.png'
import geruLogo from '../../assets/img/partners/geru.png'


const logos = {
  'bv': bvLogo,
  'creditas': creditasLogo,
  'easycredito': easyCreditoLogo,
  'omni': omniLogo,
  'sim': simLogo,
  'geru': geruLogo,
  'wimo': wimoLogo,
}

const styles = theme => ({
  wrapper: {
    // maxWidth: 450,
    margin: '0 auto',
  },
  fullWidth:{
    width: '100%',
  },
  card:{
    cursor: 'pointer',
    margin: '1%',
    width: '48%',
    [theme.breakpoints.down('sm')]: {
      width: '99%',
    },
  },
  cardParceiroLogo:{
    width: 200,
    margin: '1%',
  },
  cardContent:{
    display: 'flex',
    justifyContent: 'space-between',
  },
  parceiroLogoWrapper:{
    display: 'flex',
    width:'30%',
    padding:theme.spacing(),
  },
  parceiroInfosWrapper:{
    width:'70%',
    textAlign:'right',
  },
  parceiroEnviadoLogo:{
    width: '200px',
    height: '80px',
    padding: '16px',
    objectFit: 'contain',
    display: 'block',
  },
  parceiroOfertaLogo:{
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  parceiroMontanteWrapper:{
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  parceiroMontante:{
    fontSize: '1.15rem',
    fontWeight: 600,
    lineHeight: 1.27,
    letterSpacing: '0.38px',
    color: 'rgba(0,0,0,.87)',
  },
  parceiroParcelasWrapper:{
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
    lineHeight: 1.25,
    letterSpacing: '0.28px',
    fontSize: '1.125rem',
    color: 'rgba(0,0,0,.6)',
  },
  parceiroTaxa:{
    marginLeft: theme.spacing(),
    fontSize: '1rem',
  },
  parceirosEnviados:{
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  parceirosOfertas:{
    display: 'flex',
    flexWrap: 'wrap',
  },
  sectionTitle:{
    marginBottom: theme.spacing(),
  },
  sectionSubTitle:{
    marginBottom: theme.spacing()*2,
  },
  sectionWrapper:{
    marginTop: theme.spacing()*3,
    marginBottom: theme.spacing()*3,
    "&:first-child":{
      marginTop: 0,
    }
  },
  btContinuar: {
    // color: theme.palette.common.white,
    // backgroundColor: theme.palette.primary.main,
    padding: `${theme.spacing(1.2)}px ${theme.spacing(5)}px`,
  },
  button: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    padding: `${theme.spacing(1.2)}px ${theme.spacing(5)}px`,
    marginBottom: theme.spacing(),
  },
  txtPropostasEnviadas:{
    textAlign: 'center',
    fontSize: '1.25rem',
    padding: theme.spacing(2),
  },
  "@keyframes blinker": {
    "50%": {
      transform: 'scale(.9)',
    },
  },
  blinker:{
    animation: "$blinker 1.5s",
    animationIterationCount:'infinite',
  }
});

const EtapaResultado = (props) => {
  // const [selectedPartner, selectPartner] = React.useState();
  // const [token, setToken] = React.useState();
  const context = React.useContext(FormContext);
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const theme = useTheme();
  // console.log("context",context)
  // let proposalSim;

  React.useEffect(() => {

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'tela_obrigado'
    });

    setTimeout(() => {
      context.changeValues({ etapa: undefined, etapa_action: undefined, etapa_label: undefined, skipEtapaContext: true})
      context.changeState({ etapas: [], etapa: undefined})
      console.log('proposals: ', context.state.proposals)
      const isBvApproved = context.state.proposals.find(proposal => proposal.partnerId === "sim" && proposal.status === 'proposta_aprovada' || proposal.status === 'pre_analise_aprovada')
      console.log('is bv apprroved? ', isBvApproved)
      isBvApproved !== undefined ? handleRedirectToProposal(isBvApproved.id) : verifyLogin()
    }, 5000);

  }, []);


  const verifyLogin = async () => {
    setLoading(true)
    if (context.state.userLogged !== true) {
      // console.log('nao esta logado....logando automaticamente.')
      const token = await context.getFirebaseToken();
      const data = context.state.values

      try {
        const formatedData = await context.formatValues(data, 'submit');
        const result = await axios
        .post(`${context.state.API_ENDPOINT}/auth/login-flow/`, formatedData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (result.data.success) {
          const userCredential = await context.signInWithCustomToken(result.data.token);
          await context.getUserData(userCredential.uid);
          context.changeState({ userLogged: true })
          setLoading(false)
          history.push('/area-logada')
        }
      } catch (err) {
        // console.log("error on verify login")
      }

    } else {
      // console.log('logado !!!!!')
      setLoading(false)
      history.push('/area-logada')
    }
  }

  const handleRedirectToProposal = async (proposalId) => {
    if (context.state.userLogged !== true) {
      const token = await context.getFirebaseToken();
      const data = context.state.values
      try {
        const formatedData = await context.formatValues(data, 'submit');
        const result = await axios
          .post(`${context.state.API_ENDPOINT}/auth/login-flow/`, formatedData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        if (result.data.success) {
          const userCredential = await context.signInWithCustomToken(result.data.token);
          await context.getUserData(userCredential.uid);
          context.changeState({ userLogged: true })
          setLoading(false)
          history.push(`/sim-envio-documentos/?proposal=${proposalId}`)
          // const win = window.open(`/sim-envio-documentos/?proposal=${proposalId}`, "_blank");
          // win.focus();
        }
      } catch (err) {
        // console.log("error on verify login")
      }
    } else {
      history.push(`/sim-envio-documentos/?proposal=${proposalId}`)
      // const win = window.open(`/sim-envio-documentos/?proposal=${proposalId}`, "_blank");
      // win.focus();
    }
  }

  return (
    <div>
      {/* <div className={`${props.classes.sectionWrapper}`} style={{padding:'16px', width:'100%', textAlign:'center'}}>
        <Typography variant="h3" gutterBottom>Muito obrigado! Seu pedido foi enviado.</Typography>
        <CampoLabel className={`${props.classes.sectionSubTitle} campo-titulo`}>Acesse sua área logada e acompanhe sua solicitação</CampoLabel>
        <Button
          type="submit"
          onClick={()=> verifyLogin()}
          variant="contained"
          color="primary"
          disabled={loading}
          className={`${props.classes.button}`}
        >ACESSAR ÁREA LOGADA</Button><br/>
        {loading && <Loading visible={true}/>}
        <Typography style={{ fontSize: 14 }}>Atenção: A Quero Financiar nunca solicita depósitos ou taxas antecipadas para liberação do dinheiro!</Typography>

        {
          proposalSimAccepted &&
          <div id="proposal-sim-accepted" className={`${props.classes.sectionWrapper}`} style={{ border: '1px solid #ccc', borderRadius: '4px', padding: '16px', width: '100%', cursor: 'pointer' }} onClick={() => handleRedirect()} >
            <Typography variant="h3" gutterBottom>Seu Crédito foi <span style={{color: 'rgb(0, 181, 114)'}}>PRÉ APROVADO</span> na <span style={{ color: '#fe0064', fontWeight: 'bold' }}>SIM</span></Typography>
            <Typography variant="h3" gutterBottom><span style={{ color: '#fe0064', fontWeight: 'bold' }}>#{proposalSim.externalId}</span></Typography>
            <Typography gutterBottom
              style={{
                textAlign:"center",
                fontSize: '1.5rem',
                padding: theme.spacing(2),
              }}
            >
              Oba! Clique aqui e comece a enviar os documentos agora mesmo e finalize sua proposta!
            </Typography>
          </div>
        }

      </div> */}
      <div className={`${props.classes.sectionWrapper}`} style={{padding:'16px', width:'100%', textAlign:'center'}}>
        <Typography variant="h3" gutterBottom>Aguarde um momento</Typography>
        <CampoLabel className={`${props.classes.sectionSubTitle} campo-titulo`}>Estamos validando suas informações...</CampoLabel>
        <CircularProgress/>
      </div>
    </div>
  );
}

export default withStyles(styles)(EtapaResultado);
