import React, { useState } from "react";
import { Form, useFormikContext } from "formik";
import { useTheme, withStyles } from "@material-ui/core/styles";
import FormActions from "../FormActions";
import FormContext from "../FormContext";
import CampoOpcoes from "../fields/CampoOpcoes";
import Aviso from "../Aviso";
import CampoVeiculoPlaca from "../fields/CampoVeiculoPlaca";
import { useHistory } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";

const styles = (theme) => ({
  etapaTitulo: {},
});

const EtapaVeiculoInfos = (props) => {
  const context = React.useContext(FormContext);
  const {
    values,
    errors,
    isSubmitting,
    handleChange,
    touched,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();
  const { fields, etapa } = props;
  const history = useHistory();
  const theme = useTheme();

  const tem_veiculo_placa_options = [
    { label: "Sim", value: "Sim" },
    { label: "Não", value: "Não" },
  ]

  return (
    <Form>
      
      {
        fields.includes("tem_veiculo_placa") &&
        (
          <CampoOpcoes
            label="Você sabe qual a placa do veículo? *"
            name="tem_veiculo_placa"
            value={values.tem_veiculo_placa}
            touched={touched.tem_veiculo_placa}
            showError={errors.tem_veiculo_placa}
            options={tem_veiculo_placa_options}
            classNamePrefix="quero-financiar-select"
            className="campo-selecao"
            onFocus={props.changeFocus}
            onChange={(data) => {
              setFieldValue("tem_veiculo_placa", data.value);
              setFieldValue('veiculo_placa', '')
              setFieldValue('interesse', '')
              setFieldValue('quitado', '')
              setFieldValue('banco_financiamento', '')
              setFieldValue('proprietario', '')
              context.changeValues({
                tem_veiculo_placa: data.value,
              });
            }}
            onBlur={(e) => {
              setFieldTouched("tem_veiculo_placa", true);
            }}
          />
        )
      }

      {fields.includes("veiculo_placa") && values.tem_veiculo_placa == "Sim" && (
        <>
          <CampoVeiculoPlaca
            label="Qual é a placa do seu veículo? *"
            name="veiculo_placa"
            onFocus={props.changeFocus}
            onBlur={(e) => {
              handleBlur(e);
            }}
            onChange={handleChange}
            value={values.veiculo_placa || ""}
            error={errors.veiculo_placa && touched.veiculo_placa}
            helperText={
              errors.veiculo_placa && touched.veiculo_placa
                ? errors.veiculo_placa
                : ""
            }
          />
        </>
      )}

      {
        // context.state.feature_flag_offer_fgts_in_egv === true &&
        values.tem_veiculo_placa == "Não" && (
          <>
            <CampoOpcoes
              label="Qual o seu interesse? *"
              name="interesse"
              value={values.interesse}
              touched={touched.interesse}
              showError={errors.interesse}
              onChange={(data) => {
                setFieldValue("interesse", data.value);
                setFieldValue('quitado', '');
                setFieldValue('banco_financiamento', '');
                setFieldValue('proprietario', '');
                context.changeValues({
                  interesse: data.value,
                });
              }}
              onBlur={(value) => {
                setFieldTouched("interesse", true);
              }}
              options={[
                {
                  label: "Fazer um empréstimo com meu veículo em garantia",
                  value: "Fazer um empréstimo com meu veículo em garantia",
                },
                {
                  label: "Comprar um veículo novo ou semi novo",
                  value: "Comprar um veículo novo ou semi novo",
                },
              ]}
              onFocus={props.changeFocus}
            />
          </>
        )
      }

      {
        (
          fields.includes("quitado") &&
          // !context.state.feature_flag_offer_fgts_in_egv ||
          values.tem_veiculo_placa=="Sim" ||
          values.interesse=="Fazer um empréstimo com meu veículo em garantia"
        ) && (
          <CampoOpcoes
            label="Este veículo está quitado? *"
            name="quitado"
            value={values.quitado}
            touched={touched.quitado}
            showError={errors.quitado}
            onChange={(data) => {
              setFieldValue("quitado", data.value);
              context.changeValues({
                quitado: data.value,
              });
            }}
            onBlur={(value) => {
              setFieldTouched("quitado", true);
            }}
            options={[
              { label: "Sim", value: "Sim" },
              { label: "Não", value: "Não" },
            ]}
            onFocus={props.changeFocus}
          />
        )
      }
      
      {
        values.quitado && values.quitado == "Não" && (
          <CampoOpcoes
            label="Está financiado por qual banco? *"
            name="banco_financiamento"
            value={values.banco_financiamento}
            touched={touched.banco_financiamento}
            showError={errors.banco_financiamento}
            onChange={(data) => {
              setFieldValue("banco_financiamento", data.value);
              context.changeValues({
                banco_financiamento: data.value,
              });
            }}
            onBlur={(value) => {
              setFieldTouched("banco_financiamento", true);
            }}
            options={[
              { label: "Banco BV", value: "Banco BV" },
              { label: "Outros", value: "Outros" },
            ]}
            onFocus={props.changeFocus}
          />
        )
      }

      {
        (
          // !context.state.feature_flag_offer_fgts_in_egv ||
          values.tem_veiculo_placa=="Sim" ||
          fields.includes("proprietario") && values.interesse=='Fazer um empréstimo com meu veículo em garantia' && values.quitado
        ) && (
          <CampoOpcoes
            label="Este veículo está em seu nome? *"
            name="proprietario"
            value={values.proprietario}
            touched={touched.proprietario}
            showError={errors.proprietario}
            onChange={(data) => {
              setFieldValue("proprietario", data.value);
              context.changeValues({
                proprietario: data.value,
              });
            }}
            onBlur={(value) => {
              setFieldTouched("proprietario", true);
            }}
            options={[
              { label: "Sim", value: "Sim" },
              { label: "Não", value: "Não" },
            ]}
            onFocus={props.changeFocus}
          />
        )
      }
      
      <FormActions
        isSubmitting={isSubmitting}
        disabled={etapa != context.state.etapa}
      />
      <Aviso errors={errors} touched={touched} />
    </Form>
  );
};

export default withStyles(styles)(EtapaVeiculoInfos);
