import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";

import Header from "../../components/Header"
import Content from "../../components/Content"
import Footer from "../../components/Footer"
import { Formik, Form } from 'formik';
import { Link, useHistory } from "react-router-dom";

import CampoTexto from '../../components/fields/CampoTexto';
import CampoEmail from "../../components/fields/CampoEmail";
import CampoCpf from "../../components/fields/CampoCpf";
import Breadcrumb from '../../components/Breadcrumb'
import FormContext from '../../components/FormContext';
import Botao from '../../components/Botao';

import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from '@material-ui/icons/Check';
import { withStyles } from '@material-ui/core/styles';

import avatarHoldingDoc from '../../assets/img/avatar-holding-document.svg'

import { isMobile } from 'react-device-detect';

import * as Yup from 'yup';

import {
  Container, Snackbar, Typography, Grid, withTheme, Card, CardContent, Button, useTheme, List, ListItem, ListItemIcon
} from '@material-ui/core';

const styles = theme => ({
  buttonUpdateData: {
    position: 'sticky',
    bottom: 15,
    margin: '0 auto',
  },
  clickHereRedirect: {
    cursor: 'pointer'
  },
  modal: {
    position: 'absolute',
    top: `${50}%`,
    left: `${50}%`,
    transform: `translate(-${50}%, -${50}%)`,
    width: 250,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #00b572',
    borderRadius: '6px 6px 6px 6px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  container:{
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  imgUpload:{
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  disclaimer:{
    marginBottom: theme.spacing(3)
  }
})

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Privacidade = (props) => {

  const context = React.useContext(FormContext);
  const [loading, set_loading] = useState(false);
  const [requestResult, set_requestResult] = useState(null);
  const [requestSelectedOption, set_requestSelectedOption] = useState(null);

  const sendRequest = async (params, resetForm) => {

    set_loading(true)

    const token = await context.getFirebaseToken();

    try{

      const response = await axios.post(
        `${context.state.API_ENDPOINT}/leads/privacy/request`,
        {
          ...params,
          subject: requestSelectedOption.label,
          requestId: requestSelectedOption.requestId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      set_requestResult(response.data)

      if(resetForm){
        resetForm({
          values:{
            nome:'',
            email:'',
            cpf:'',
            arquivo:null,
          }
        })
      }

    }catch(error){

      console.log(error)

      if(error.response && error.response.data){

        set_requestResult(error.response.data)

      }else{

        set_requestResult({
          success: false,
          message: 'Erro no envio.',
        })

      }

    }
      
    set_loading(false)

  }

  useEffect(()=>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  },[ requestSelectedOption ])

  return (
    <>
      <Header />
      <Content>
        <Grid container>
          <Typography variant="h4" gutterBottom>Privacidade</Typography>
        </Grid>
        
        {
          !requestSelectedOption &&
          <>

            <Grid container className={props.classes.container}>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>Nossos avisos de privacidade</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom>Aqui você encontra os nossos avisos de privacidade ativos no momento. Por meio deles, você terá clareza sobre tudo o que fazemos com os seus dados pessoais, como prezamos pela segurança de dados, como utilizamos os cookies em nossos sites, entre outras. Clique sobre o aviso desejado para ver o seu conteúdo.</Typography>
              </Grid>
              <Grid item xs={12}>
                <a href='https://querofinanciar.com/sobre/termos-e-condicoes-de-uso/' target='_blank'><Typography>Termos de uso</Typography></a>
              </Grid>
              <Grid item xs={12}>
              <a href='https://querofinanciar.com/sobre/politica-de-privacidade/' target='_blank'><Typography>Política de privacidade</Typography></a>
              </Grid>
            </Grid>

            <Grid container className={props.classes.container}>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>Como podemos ajudar?</Typography>
              </Grid>
              <RequestOptions onChange={ (option) => {
                set_requestSelectedOption(option)
              }}/>
            </Grid>

            <Grid container className={props.classes.container}>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>Contato do nosso Encarregado de Dados</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom style={{ marginBottom: 16 }}>Ainda tem alguma dúvida? Quer saber mais detalhes? Você pode entrar em contato com o nosso encarregado para assuntos de privacidade. Atenderemos à sua consulta ou solicitação no menor tempo possível.</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom><strong>Charles Uhlmann</strong></Typography>
                <a href="mailto:lgpd@querofinanciar.com" style={{ display: `inline-block` }}><Typography gutterBottom>E-mail</Typography></a>
              </Grid>
            </Grid>

          </>
        }

        {
          requestSelectedOption &&
          <Formik
            initialValues={{
              nome:'',
              email:'',
              cpf:'',
              arquivo:null,
            }}
            validationSchema={ Yup.object().shape({
              nome: Yup.string().required('Por favor, insira seu nome.'),
              email: Yup.string().required('Por favor, insira seu email.'),
              cpf: Yup.string().required('Por favor, insira seu cpf.'),
            }) }
            onSubmit={async (values, {resetForm})=>{
              console.log('values', values);
              await sendRequest(values, resetForm)
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue
            }) => (
              <Form>
                  <Grid
                    container
                  >
                    <Grid item xs={12}>
                      <Typography variant='h6' gutterBottom>{requestSelectedOption.requestId}</Typography>
                    </Grid>
                    {
                      requestSelectedOption.description &&
                      <Grid item xs={12}>
                        <Typography gutterBottom>{requestSelectedOption.description}</Typography>
                      </Grid>
                    }
                    <Grid item xs={12}>
                      <Typography gutterBottom className={props.classes.disclaimer}>Com intuito de proteger seus dados pessoais de acessos não autorizados, conforme previsto na Lei Geral de Proteção de Dados (art. 46), solicitamos algumas confirmações que comprovem que você é o titular  das informações para que possamos dar prosseguimento à sua solicitação:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <CampoTexto
                        label="Seu nome"
                        name="nome"
                        id="nome"
                        value={values.nome}
                        onChange={handleChange}
                        error={errors.nome && touched.nome}
                        helperText={errors.nome}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CampoEmail
                        label="Email"
                        name="email"
                        placeholder="josesilva@email.com.br"
                        onFocus={ props.changeFocus }
                        value={values.email}
                        onChange={handleChange}
                        error={errors.email && touched.email}
                        helperText={errors.email && touched.email ? errors.email : ''}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CampoCpf
                        label="Informe o CPF"
                        name="cpf"
                        onFocus={ props.changeFocus }
                        onChange={ handleChange }
                        value={values.cpf}
                        error={errors.cpf && touched.cpf}
                        helperText={errors.cpf && touched.cpf ? errors.cpf : ''}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='h6' gutterBottom>Foto do seu documento</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography gutterBottom>Precisamos de uma foto sua segurando o seu documento de identidade. Faça como na ilustração abaixo:</Typography>
                    </Grid>
                    <Grid container className={props.classes.imgUpload} justify='center'>
                      <Grid item xs={12}>
                        <img src={avatarHoldingDoc}/>
                      </Grid>
                      <Grid item xs={12}>
                        <Button component="label" color="primary" style={{backgroundColor: '#C9E265', borderRadius: 20, color: 'black'}}>
                          <AddIcon /> <span style={{ color: "primary" }}>{ isMobile ? 'Tirar/Anexar': 'Anexar'} foto</span>
                          <input type="file" hidden onChange={(event)=>{
                            console.log('event.file', event.currentTarget.files[0])
                            setFieldValue('arquivo',event.currentTarget.files[0])
                            event.target.value = null;
                          }} accept=".jpg,.jpeg,.png"/>
                        </Button>
                        {
                          values.arquivo &&
                          <Typography>{values.arquivo.name}</Typography>
                        }
                      </Grid>
                    </Grid>
                    {
                      !loading &&
                      <Grid container justify='center'>
                        <Grid item xs={12} container justify='center'>
                          <Botao
                            type="submit"
                            label='Enviar Solicitação'
                          >
                          </Botao>
                        </Grid>
                        <Grid item xs={12} container justify='center'>
                          <Button
                            onClick={()=>{ set_requestSelectedOption(null) }}
                            variant='text'
                          >
                            Voltar
                          </Button>
                        </Grid>
                      </Grid>
                    }
                    {
                      loading &&
                      <Grid container justify='center'>
                        <CircularProgress/>
                      </Grid>
                    }
                    {
                      errors &&
                      <Grid container justify='center'>
                        {Object.values(errors).map((key, index) => 
                          <Grid item style={{color: 'red', textAlign:`center`}} xs={12} key={index}>{key}</Grid>
                        )}
                      </Grid>
                    }
                  </Grid>
              </Form>
            )}
          </Formik>
        }
        
        {
          requestResult && 
          <Snackbar
            open={true}
            onClose={() => {
              set_requestResult(null)
            }}
          >
            <Alert
              severity={requestResult.success === true ? 'success' : 'error'}
            >
              {requestResult.message}
            </Alert>
          </Snackbar>
        }

      </Content>
      <Footer />
    </>
  )
}

const RequestOptions = (props) => (
  <Grid
    container
    alignItems="center"
    spacing={3}
  >
    {
      [
        {
          requestId: 'Confirmação de tratamento de dados',
          label: `Quero confirmar se há existência do tratamento dos meus dados pessoais`,
          description: `Somos uma empresa preocupada com a sua privacidade. Você pode saber se a Quero Financiar possui dados sobre você em nosso banco de dados. Para isso, basta preencher os dados e enviar sua solicitação. Se quiser saber mais detalhes, você também pode entrar em contato conosco por meio do nosso encarregado de LGPD.`,
        },
        {
          requestId: 'Confirmação de posse de dados',
          label: `Quero saber quais dados pessoais possuem sobre mim`,
        },
        {
          requestId: 'Atualização de dados pessoais',
          label: `Quero solicitar a correção ou atualização dos meus dados pessoais`,
        },
        {
          requestId: 'Portabilidade de dados pessoais',
          label: `Quero solicitar a portabilidade dos meus dados pessoais`,
        },
        {
          requestId: 'Exclusão de dados pessoais',
          label: `Quero solicitar a exclusão de meus dados pessoais`,
        },
        {
          requestId: 'Informações sobre uso de dados compartilhados',
          label: `Quero informações sobre o uso compartilhado dos meus dados pessoais`,
        },
        {
          requestId: 'Possibilidade de não fornecer consentimento',
          label: `Quero informações sobre a possibilidade de não fornecer consentimento`,
        },
        {
          requestId: 'Revogar um consentimento',
          label: `Quero revogar um consentimento`,
        },
        {
          requestId: 'Decisões com base em tratamento automatizado',
          label: `Quero informações sobre as decisões tomadas com base em tratamento automatizado`,
        }
      ].map((item)=>(
        <Grid item lg={4} xs={6} key={item.requestId}>
          <Card onClick={()=>{ props.onChange(item) }} style={{ cursor:'pointer' }}>
            <CardContent>
              {item.label}
            </CardContent>
          </Card>
        </Grid>
      ))
    }
  </Grid>
)

export default withTheme(withStyles(styles)(Privacidade));