import React from 'react'
import { Form, useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import FormActions from '../FormActions';
import FormContext from '../FormContext';
import CampoOpcoes from '../fields/CampoOpcoes';
import CampoEstado from '../fields/CampoEstado';
import Aviso from '../Aviso';
import Loading from '../Loading';

const styles = theme => ({
  etapaTitulo: {
  },
});

const EtapaVocePossuiVeiculo = (props) => {
  const context = React.useContext(FormContext);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setValues, setFieldTouched, initialValues } = useFormikContext();
  const { fields, etapa } = props;
  const [isBusy, setIsBusy] = React.useState(false)

  // const getVehicleTypes = async () => {
  //   setIsBusy(true)
  //   const types = await context.getVehicleTypes()
  //   context.changeState({
  //     veiculo_tipo_options: types,
  //   })
  //   setIsBusy(false)
  // }

  // React.useEffect(()=>{
  //   console.log("================> values.veiculo_tipo",values.veiculo_tipo)
  //   getVehicleTypes()
  // },[])

  const init = async () => {
    setIsBusy(true)
    
    const types = await context.getVehicleTypes()
    context.changeState({
      veiculo_tipo_options: types,
    })

    const fuels = await context.getVehicleFuels()
    context.changeState({
      veiculo_combustivel_options: fuels,
    })

    if(context.state.produto=="Financiamento de Veículo"){
      context.changeValues({
        veiculo_tabela: "fipe",
        veiculo_licenciamento_uf: "SP",
      })
    }

    setIsBusy(false)
  }

  React.useEffect(()=>{
    init()
  },[])

  return (
    <Form>
      <CampoOpcoes
        label="Selecione o tipo do seu veículo *"
        name="veiculo_tipo"
        value={values.veiculo_tipo}
        touched={touched.veiculo_tipo}
        showError={errors.veiculo_tipo}
        options={context.state.veiculo_tipo_options || []}
        classNamePrefix="quero-financiar-select"
        className="campo-selecao"
        onFocus={ props.changeFocus }
        onChange={ ( data ) => {
          setValues({
            veiculo_tipo: data.value,
            veiculo_combustivel: '',
            veiculo_marca: '',
            veiculo_modelo: '',
            veiculo_ano: '',
          })
          context.changeValues({
            veiculo_combustivel: '',
            veiculo_ano: '',
            veiculo_marca: '',
            veiculo_modelo: '',
            veiculo_versao: '',
            veiculo_fipe_tipo_id: '',
            veiculo_valor: '',
          })
          
        }}
      />
      {
        fields.includes('veiculo_combustivel') &&
        <CampoOpcoes
          options={context.state.veiculo_combustivel_options || []}
          label="Qual é o tipo de combustível de seu veículo? *"
          name="veiculo_combustivel"
          value={values.veiculo_combustivel}
          touched={touched.veiculo_combustivel}
          showError={errors.veiculo_combustivel}
          classNamePrefix="quero-financiar-select"
          className="campo-selecao"
          onFocus={ props.changeFocus }
          onChange={ ( data ) => {
            setFieldValue('veiculo_combustivel',data.value)
            // context.changeValues({
            //   veiculo_ano: '',
            //   veiculo_marca: '',
            //   veiculo_modelo: '',
            //   veiculo_versao: '',
            // })
          }}
        />
      }
      {
        fields.includes('veiculo_licenciamento_uf') &&
        <CampoEstado
          name="veiculo_licenciamento_uf"
          label="Estado de licenciamento *"
          value={ values.veiculo_licenciamento_uf }
          error={errors.veiculo_licenciamento_uf}
          touched={touched.veiculo_licenciamento_uf}
          onChange={ async ( data ) => {
            setFieldValue('veiculo_licenciamento_uf', data.value);
          }}
          onBlur={ ( e ) => {
            handleBlur(e);
            setFieldTouched('veiculo_licenciamento_uf',true);
          } }
        />
      }
      {
        isBusy &&
        <div style={{textAlign: `center`, width: `100%`}}>
          <Loading visible={true}/>
        </div>

      }
      <FormActions isSubmitting={ isSubmitting } disabled={ etapa!=context.state.etapa || isBusy } />
      <Aviso errors={errors} touched={touched}/>
    </Form>
  )
};

export default withStyles(styles)(EtapaVocePossuiVeiculo);
