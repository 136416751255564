import "@babel/polyfill";
import 'whatwg-fetch'
import 'promise-polyfill/src/polyfill';
import './assets/css/index.css'

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import QueroFinanciarBaseApp from './components/QueroFinanciarBaseApp'
import FormContext, { FormProvider } from './components/FormContext';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './Theme';
import globalStyles from './GlobalStyles';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import Alert from './components/Alert';

//PAGES
import LoggedArea from './components/logged-area'
import MeusPedidos from './pages/logged-area/MeusPedidos'
import SolicitarNovoEmprestimo from './pages/logged-area/SolicitarNovoEmprestimo'
import ServicosEProdutos from './pages/logged-area/ServicosEProdutos'
import DadosCadastrais from './pages/logged-area/DadosCadastrais'
import Privacidade from "./pages/logged-area/Privacidade";
// import EnviarDocumentosSim from './components/logged-area/meus-pedidos/EnvioDocumentos'
// import ProductEmprestimoFGTS from './components/logged-area/products-and-services/ProductEmprestimoFGTS'
// import ProductCartaoCredito from './components/logged-area/products-and-services/ProductCartaoCredito'
import LoginPage from './pages/login/'
import FirstAccess from './pages/first-access/'

import * as Sentry from "@sentry/react";
import NotFound from "./pages/not-found";

Sentry.init({
  environment: process.env.TARGET_ENV,
  dsn: "https://3db10683366d8cd89096bac6b7cf4617@o4507261479419904.ingest.us.sentry.io/4507261480861696",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/querofinanciar\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const App = (props) => {

  useEffect(()=>{
    const font = document.createElement("link");
    font.rel = "stylesheet";
    font.href =
      "https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap";
    document.head.appendChild(font);
  },[]);

  return (
    <Router>
      <FormProvider>
        <CssBaseline/>
        <Switch>
          <Route exact path={[
            "/",
            "/solicitacao-emprestimo-garantia-veiculo",
            "/solicitacao-refinanciamento-de-veiculo",
            "/solicitacao-financiamento-de-veiculo",
            "/solicitacao-emprestimo-com-imovel-em-garantia",
            "/solicitacao-emprestimo-pessoal",
            "/sim-envio-documentos",
          ]}>
            <AlertProvider template={Alert}>
              <ThemeProvider theme={theme}>
                <QueroFinanciarBaseApp/>
              </ThemeProvider>
            </AlertProvider>
          </Route>
          <Route exact path="/login">
            <AlertProvider template={Alert}>
              <ThemeProvider theme={theme}>
                <LoginPage/>
              </ThemeProvider>
            </AlertProvider>
          </Route>
          <Route exact path="/area-logada">
            <AlertProvider template={Alert}>
              <ThemeProvider theme={theme}>
                <LoggedArea/>
              </ThemeProvider>
            </AlertProvider>
          </Route>
          <Route exact path="/area-logada/dados-cadastrais">
            <AlertProvider template={Alert}>
              <ThemeProvider theme={theme}>
                <DadosCadastrais/>
              </ThemeProvider>
            </AlertProvider>
          </Route>
          <Route exact path="/privacidade">
            <AlertProvider template={Alert}>
              <ThemeProvider theme={theme}>
                <Privacidade/>
              </ThemeProvider>
            </AlertProvider>
          </Route>
          <Route exact path="/area-logada/meus-pedidos">
            <AlertProvider template={Alert}>
              <ThemeProvider theme={theme}>
                <MeusPedidos/>
              </ThemeProvider>
            </AlertProvider>
          </Route>
          <Route exact path="/area-logada/novo-emprestimo">
            <AlertProvider template={Alert}>
              <ThemeProvider theme={theme}>
                <SolicitarNovoEmprestimo/>
              </ThemeProvider>
            </AlertProvider>
          </Route>
          <Route exact path="/area-logada/servicos-produtos">
            <AlertProvider template={Alert}>
              <ThemeProvider theme={theme}>
                <ServicosEProdutos/>
              </ThemeProvider>
            </AlertProvider>
          </Route>
          <Route exact path="/primeiro-acesso">
            <AlertProvider template={Alert}>
              <ThemeProvider theme={theme}>
                <FirstAccess/>
              </ThemeProvider>
            </AlertProvider>
          </Route>
          <Route path="*">
            <AlertProvider template={Alert}>
              <ThemeProvider theme={theme}>
                <NotFound/>
              </ThemeProvider>
            </AlertProvider>
          </Route>
        </Switch>
      </FormProvider>
    </Router>
  )
}

const Render = withStyles(globalStyles)(App);

ReactDOM.render( <Render/>, document.querySelector('#quero-financiar-app-container'));
