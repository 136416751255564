import React from 'react'
import { Form, useFormikContext } from 'formik';
// import * as Yup from 'yup';
// import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
// import CampoDinheiro from './fields/CampoDinheiro';
import FormActions from '../FormActions';
import FormContext from '../FormContext';
import CampoData from '../fields/CampoData';
import CampoSugestoes from '../fields/CampoSugestoes';
// import moment from 'moment';
import Aviso from '../Aviso';
import CampoOpcoes from '../fields/CampoOpcoes';
import CampoCpf from '../fields/CampoCpf';
import _ from 'lodash';
import CampoTexto from '../fields/CampoTexto';
import CampoTelefone from '../fields/CampoTelefone';
import CampoEstado from '../fields/CampoEstado';

const styles = {
  etapaTitulo: {
  },
};

const EtapaDadosPessoais = (props) => {
  
  const context = React.useContext(FormContext);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const { fields, etapa } = props;
  
  return (
    <Form>
      {
        fields.includes('genero') &&
        <CampoOpcoes
          label="Gênero *"
          name="genero"
          showError={errors.genero}
          classNamePrefix="quero-financiar-select"
          className="campo-selecao"
          value={values.genero}
          touched={touched.genero}
          onChange={ ( data ) => {
            setFieldValue('genero', data.value);
            // context.changeValues({
            //   genero:value
            // });
          } }
          onBlur={ ( e ) => {
            setFieldTouched('genero',true);
          } }
          options={[
            {label:'Feminino', value:'Feminino'},
            {label:'Masculino', value:'Masculino'},
          ]}
          placeholder="Digite ou selecione uma opção"
        />
      }
      <CampoData
        label="Data de nascimento *"
        name="data_nascimento"
        onFocus={ props.changeFocus }
        onBlur={ (e)=>{
          handleBlur(e);
        } }
        onChange={ handleChange }
        value={values.data_nascimento}
        error={errors.data_nascimento && touched.data_nascimento}
        helperText={errors.data_nascimento && touched.data_nascimento ? errors.data_nascimento : ''}
      />
      <CampoEstado
        name="estado_nascimento"
        label="Estado de Nascimento *"
        value={values.estado_nascimento}
        error={errors.estado_nascimento}
        touched={touched.estado_nascimento}
        onChange={ async ( data ) => {
          setFieldValue('estado_nascimento', data.value);
          const cities = await context.getBrazilianCitiesByState(data.value)
          context.changeState({
            cidade_nascimento_options: cities
          });
        }}
        onBlur={ ( value ) => { setFieldTouched('estado_nascimento',true); } }
      />
      <CampoSugestoes
        options={context.state.cidade_nascimento_options}
        onSuggestionSelected={
          (value)=>{
            // context.changeValues({
            //   cidade_nascimento: value
            // });
          }
        }
        inputProps={{
          className:"campo-texto",
          name: 'cidade_nascimento',
          label:"Cidade de Nascimento *",
          value: values.cidade_nascimento || "",
          placeholder: 'Cidade de Nascimento',
          error: errors.cidade_nascimento && touched.cidade_nascimento,
          helperText: errors.cidade_nascimento && touched.cidade_nascimento ? errors.cidade_nascimento : '',
          onChange: (event, { newValue }) => {
            handleChange(event);
            setFieldValue('cidade_nascimento',newValue);
            // context.changeValues({
            //   cidade_nascimento:newValue
            // });
          },
          onBlur: (event, { highlightedSuggestion }) => {
            handleBlur(event);
            setFieldTouched('cidade_nascimento',true);
            const suggestion_index = _.findIndex(context.state.cidade_nascimento_options, (item)=>{
              return item.label == values.cidade_nascimento;
            });
            if(suggestion_index==-1){
              setFieldValue('cidade_nascimento','');
            };
          },
        }}
      />
      {
        fields.includes('estado_civil') &&
        <CampoOpcoes
          label="Estado Civil *"
          name="estado_civil"
          showError={errors.estado_civil}
          classNamePrefix="quero-financiar-select"
          className="campo-selecao"
          value={values.estado_civil}
          touched={touched.estado_civil}
          onChange={ ( data ) => {
            setFieldValue('estado_civil', data.value);
            if(! ["Casado","União Estável"].includes(data.value)){
              setFieldValue('conjuge_cpf', '')
              setFieldValue('conjuge_email', '')
              setFieldValue('conjuge_nomeCompleto', '')
              setFieldValue('conjuge_celular', '')
              setFieldValue('conjuge_data_nascimento', '')
            }
            props.updateResize()
          } }
          onBlur={ ( e ) => {
            setFieldTouched('estado_civil',true);
          } }
          options={[
            {label:'Solteiro', value:'Solteiro'},
            {label:'Casado', value:'Casado'},
            {label:'Viúvo', value:'Viúvo'},
            {label:'Divorciado', value:'Divorciado'},
            {label:'União Estável', value:'União Estável'},
          ]}
          placeholder="Digite ou selecione uma opção"
        />
      }
      {
        ["Casado","União Estável"].includes(values.estado_civil) &&
        fields.includes('conjuge_cpf') &&
        <CampoCpf
          label="CPF de seu cônjuge *"
          name="conjuge_cpf"
          onFocus={ props.changeFocus }
          onBlur={ handleBlur }
          onChange={ handleChange }
          value={values.conjuge_cpf}
          error={errors.conjuge_cpf && touched.conjuge_cpf}
          helperText={errors.conjuge_cpf && touched.conjuge_cpf ? errors.conjuge_cpf : ''}
        />
      }
      {
        ["Casado","União Estável"].includes(values.estado_civil) &&
        fields.includes('conjuge_nomeCompleto') &&
        <CampoTexto
          label="Qual é o nome completo de seu cônjuge? *"
          name="conjuge_nomeCompleto"
          id="conjuge_nomeCompleto"
          placeholder="José da Silva"
          onFocus={ props.changeFocus }
          value={values.conjuge_nomeCompleto}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.conjuge_nomeCompleto && touched.conjuge_nomeCompleto}
          helperText={errors.conjuge_nomeCompleto && touched.conjuge_nomeCompleto ? errors.conjuge_nomeCompleto : ''}
        />
      }
      {
        ["Casado","União Estável"].includes(values.estado_civil) &&
        fields.includes('conjuge_email') &&
        <CampoTexto
          label="Qual é o email de seu cônjuge? *"
          name="conjuge_email"
          id="conjuge_email"
          placeholder="José da Silva"
          onFocus={ props.changeFocus }
          value={values.conjuge_email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.conjuge_email && touched.conjuge_email}
          helperText={errors.conjuge_email && touched.conjuge_email ? errors.conjuge_email : ''}
        />
      }
      {
        ["Casado","União Estável"].includes(values.estado_civil) &&
        fields.includes('conjuge_celular') &&
        <CampoTelefone
            label="Qual é o celular de seu cônjuge? *"
            name="conjuge_celular"
            onFocus={ props.changeFocus }
            onBlur={ handleBlur }
            onChange={ handleChange }
            value={values.conjuge_celular || ""}
            error={errors.conjuge_celular && touched.conjuge_celular}
            helperText={errors.conjuge_celular && touched.conjuge_celular ? errors.conjuge_celular : ''}
        />
      }
      {
        ["Casado","União Estável"].includes(values.estado_civil) &&
        fields.includes('conjuge_data_nascimento') &&
        <CampoData
          label="Data de nascimento de seu cônjuge *"
          name="conjuge_data_nascimento"
          onFocus={ props.changeFocus }
          onBlur={ handleBlur }
          onChange={ handleChange }
          value={values.conjuge_data_nascimento}
          error={errors.conjuge_data_nascimento && touched.conjuge_data_nascimento}
          helperText={errors.conjuge_data_nascimento && touched.conjuge_data_nascimento ? errors.conjuge_data_nascimento : ''}
        />
      }
      <FormActions isSubmitting={ isSubmitting } disabled={ etapa!=context.state.etapa }  />
      <Aviso errors={errors} touched={touched}/>
    </Form>
  )
}

export default withStyles(styles)(EtapaDadosPessoais);