import React from 'react'
import { Form, useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import FormActions from '../FormActions';
import FormContext from '../FormContext';
import CampoCpf from '../fields/CampoCpf';
import CampoTexto from '../fields/CampoTexto';
import Aviso from '../Aviso';

const styles = {
  etapaTitulo: {
  },
};

const EtapaRgCpf = (props) => {
  const context = React.useContext(FormContext);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const { fields, etapa } = props;
  return (
    <Form>
      {
        fields.includes('cpf') &&
        <CampoCpf
          label="CPF *"
          name="cpf"
          onFocus={ props.changeFocus }
          onBlur={ (e)=>{
            handleBlur(e);
          } }
          onChange={ handleChange }
          value={values.cpf}
          error={errors.cpf && touched.cpf}
          helperText={errors.cpf && touched.cpf ? errors.cpf : ''}
          disabled={Boolean(context.state.values.cpf)}
        />
      }
      {
        fields.includes('rg') &&
        <CampoTexto
          label="RG *"
          name="rg"
          onFocus={ props.changeFocus }
          onBlur={ (e)=>{
            handleBlur(e);
          } }
          value={values.rg}
          onChange={handleChange}
          error={errors.rg && touched.rg}
          helperText={errors.rg && touched.rg ? errors.rg : ''}
        />
      }
      {
        fields.includes('nome_mae') &&
        <CampoTexto
          label="Nome completo de sua mãe"
          name="nome_mae"
          onFocus={ props.changeFocus }
          onBlur={ (e)=>{
            handleBlur(e);
          } }
          value={values.nome_mae || ''}
          onChange={handleChange}
          error={errors.nome_mae && touched.nome_mae}
          helperText={errors.nome_mae && touched.nome_mae ? errors.nome_mae : ''}
        />
      }
      <FormActions isSubmitting={ isSubmitting } disabled={ etapa!=context.state.etapa }  />
      <Aviso errors={errors} touched={touched}/>
    </Form>
  )
}

export default withStyles(styles)(EtapaRgCpf);