import React, { useRef, useState } from "react";
import {
  Dialog, DialogTitle, DialogActions, DialogContentText, DialogContent, withTheme, Button, IconButton, CircularProgress, useTheme, Snackbar, Typography
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from "@material-ui/styles";
import CampoNumero from "../../fields/CampoNumero";
import { Form, Formik, useFormikContext} from "formik";
import * as Yup from 'yup';
import axios from "axios";
import FormContext from "../../FormContext";
import Webcam from "react-webcam";
import MuiAlert from '@material-ui/lab/Alert';
import { set } from "lodash";
import CameraAltOutlined from "@material-ui/icons/CameraAltOutlined";
import ModalSelfie from './formSelfieModal'
import FormActions from "../../FormActions";
import CheckCircle from '@material-ui/icons/CheckCircle';
import ArrowRightAltRounded from '@material-ui/icons/ArrowRightAltRounded';

const styles = (theme) => ({
  closeButton: {
    display: "block",
    margin: "0 0 0 auto",
  },
  selectData: {
    padding: 10,
    margin: 20,
    // border: "1px solid grey",
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: 'inset 0px 0px 0px 1px #e7e7e7;',

  },
  selectDataContainer: {
    padding: 10,
    margin: 20,
    borderRadius: '10px',
    boxShadow: 'inset 0px 0px 0px 1px #e7e7e7;',

  },
  box: {
    padding: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // width: 500,
    // height: 500,
  },
  buttons: {

  },
  sendDocumentos: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const formSelfie = (props) => {

  const { classes } = props
  const context = React.useContext(FormContext);
  const urlParams = new URLSearchParams(window.location.search);
  const { isSubmitting } = useFormikContext();
  const proposalId = urlParams.get("proposal");
  // const [openFeedbackError, setOpenFeedbackError] = useState(false)
  // const [feedbackErrorMessage, setFeedbackErrorMessage] = useState('')

  // const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(true)
  const [openModalSelfie, setOpenModalSelfie] = useState(false)
  const [triggerSelfieSended, setTriggerSelfieSended] = useState(false)

  const [proposalData, setProposalData] = React.useState()

  React.useEffect(() => {
    const getProposalData = async () => {
      let proposalIdString;
      try{
        const token = await context.getFirebaseToken();
        const proposalResult = await axios.get(`${context.state.API_ENDPOINT}/proposals/${proposalId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProposalData(proposalResult.data.data)
        proposalIdString = proposalResult.data.data.externalId
        setLoading(false)

        if(proposalResult.data.data.foto_selfie_enviado===true) context.nextStepWithoutSubmitForm()

      }catch(error){
        console.log(error)
        setProposalData(null)
      }
      props.updateResize()
    }
    getProposalData()
  },[context.state.firebaseLoaded, triggerSelfieSended])

  return (
    <>
      {
        loading === false ?
          <>
            <div>
              <Typography>Proposta <span style={{ color: '#fe0064', fontWeight: 'bold' }}>SIM #{proposalData.externalId}</span></Typography>
              <Typography>Essa é a etapa da assinatura do contrato. A assinatura do contrato nada mais é do que uma foto do seu rosto (selfie).</Typography><br/>
              <Typography>Como fazer?</Typography>
              <Typography><ArrowRightAltRounded/><span style={{position: 'relative', top: -7}}>Clique no botão abaixo "Tirar Foto"</span></Typography>
              <Typography><ArrowRightAltRounded/><span style={{position: 'relative', top: -7}}>Enquadre o seu rosto dentro da moldura apresentada</span></Typography>
              <Typography><ArrowRightAltRounded/><span style={{position: 'relative', top: -7}}>Bata a foto (selfie) de seu rosto dentro da moldura</span></Typography>
              <Typography><ArrowRightAltRounded/><span style={{position: 'relative', top: -7}}>Após bater a foto, clique no botão "Continuar"</span></Typography>
              <div className={classes.selectData}>
                <div className={classes.box}>
                    {
                    !proposalData.foto_selfie_enviado ?
                      <p style={{ textAlign: 'center' }}>Só falta <span style={{ color: 'rgb(0, 181, 114)', fontWeight: 'bold' }}>assinar com sua foto</span> para finalizar o pedido</p>
                      :
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div><DialogContentText>Foto já enviada</DialogContentText></div>
                        <div><CheckCircle color="primary" fontSize="large"/><span style={{fontWeight: 'bold', position: 'relative', top: -10}}>Foto <span style={{color: 'rgb(0, 181, 114)'}}>enviada com sucesso!</span></span></div>
                      </div>
                    }
                </div>

                {
                  !proposalData.foto_selfie_enviado ?
                    <div className={classes.buttons}>
                      <Button component="label" color="primary" style={{backgroundColor: '#C9E265', borderRadius: 20, color: 'black'}} disabled={proposalData.foto_selfie_enviado===true} onClick={() => setOpenModalSelfie(state => !state)}>
                        <CameraAltOutlined/> <span> Tirar foto</span>
                        {/* <input hidden type="file" accept=".jpg,.jpeg,.png" onChange={changeHandlerEngineVehicle} /> */}
                      </Button>
                    </div> : ''
                }

              </div>

            </div>

            <ModalSelfie
              open={ openModalSelfie }
              close={ () => setOpenModalSelfie(state => !state) }
              proposalData={ proposalData }
              selfieSended={ () => setTriggerSelfieSended(state => !state)}
            />

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
              <FormActions isSubmitting={isSubmitting} label="Continuar" disabled={!proposalData.foto_selfie_enviado} onClick={() => context.nextStepWithoutSubmitForm()} />
              {/* <FormActions onClick={() => context.nextStepWithoutSubmitForm()} label="passar para a proxima etapa - staging" /> */}

            </div>

          </>
        : <div style={{ textAlign: `center` }}><CircularProgress/></div>
      }
    </>
  );
};

export default withTheme(withStyles(styles)(formSelfie));
