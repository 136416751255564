import React, { useEffect, useContext } from "react";
import { 
  Container, Grid, withTheme
} from "@material-ui/core";
// import { useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import LoginForm from "../../components/login/LoginForm";
import Header from "../../components/Header";
import Footer from "../../components/Footer"
import FormContext from "../../components/FormContext";
import { useHistory } from "react-router-dom";
// import FormContext from '../../components/FormContext';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(4),
  },
});

const LoginPage = (props) => {

  const context = React.useContext(FormContext);
  const history = useHistory();

  useEffect(()=>{
    if (context.state.userLogged) {
      // history.push('/area-logada')
      const redirectPath = new URLSearchParams(location.search).get('redirectPath')
      history.push(redirectPath ? redirectPath : '/area-logada');
    }
  },[context.state.firebaseLoaded])

  return (
    <>
      <Header inOrderFlow={false}></Header>
      <div className={props.classes.root}>
        <Container maxWidth="sm">
          <Grid container spacing={2}>
            <Grid item md={12}>
              <LoginForm/>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default withTheme(withStyles(styles)(LoginPage));
