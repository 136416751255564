"use strict";

import React from "react";
import ReactModal from "react-modal";
import axios from "axios";
import jQuery from "jquery";
import _ from "lodash";
import FormContext from "./FormContext";
import Header from "./Header";
import Footer from "./Footer";
import QueroFinanciarFormulario from "./QueroFinanciarFormulario";
import { withAlert } from "react-alert";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, withTheme } from "@material-ui/core";
import LoginScreen from "./login/LoginModal";
import {
  CircularProgress
} from '@material-ui/core';
import logo from '../assets/img/logo-quero-financiar-black.png';
import Botao from "./Botao";

class QueroFinanciarBaseApp extends React.Component {
  static contextType = FormContext;

  constructor(props) {
    super(props);

    ReactModal.setAppElement("#quero-financiar-app-container");

  }

  componentDidMount() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "etapa_carregada",
      etapa: this.context.state.etapa,
    });

    setInterval(() => {
      jQuery(".quero-financiar-formulario-etapas").height(
        jQuery(".quero-financiar-formulario-etapas .etapa-ativa").height()
      );
    }, 50);

    jQuery("form").keydown(function (e) {
      // console.log(e.keyCode,document.activeElement);
      if (e.keyCode === 9) {
        if (document.activeElement.type == "submit") return false;
      }
    });
  }

  render() {
    if (!this.context.state.firebaseLoaded) return <div style={{ textAlign:'center', marginTop: 300 }}>
      <CircularProgress /><br/>
      <img style={{ width: 200, height: 'auto', position: 'relative'}} src={logo} alt="Logo QueroFinanciar.com"/>
    </div>;
    return (
      <>
        
        {this.context.state.showLogin && <LoginScreen />}

        {
          this.context.state.showPopup && <Dialog
            open={this.context.state.showPopup}
            onClose={() => this.context.showPopup(false)}
          >
            <DialogTitle>{this.context.state.popupData.title}</DialogTitle>
            <DialogContent>
              <DialogContentText>{this.context.state.popupData.message}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Botao label="Ok" onClick={()=>{ this.context.showPopup(false) }}/>
            </DialogActions>
          </Dialog>
        }
        <Header inOrderFlow={true} />
        <QueroFinanciarFormulario onSubmit={this.context.submitForm} />
        <Footer />
      </>
    );
  }
}

export default withTheme(withAlert()(QueroFinanciarBaseApp));
