import React from 'react'
import {Form, useFormikContext } from 'formik';
// import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import FormActions from '../FormActions';
import FormContext from '../FormContext';
import CampoCpf from '../fields/CampoCpf';
// import CPF from "@fnando/cpf/dist/node";
import Aviso from '../Aviso';
import CampoData from '../fields/CampoData';
// import moment from 'moment';

const styles = theme => ({
});

const EtapaCpfImovel = (props) => {
  const context = React.useContext(FormContext);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const { fields, etapa } = props;
  
  return (
    <Form>
      <CampoCpf
        label="CPF *"
        name="cpf"
        onFocus={ props.changeFocus }
        onBlur={ (e)=>{
          // context.changeValues({
          //   cpf:e.target.value
          // });
          handleBlur(e);
        } }
        onChange={ handleChange }
        value={values.cpf}
        error={errors.cpf && touched.cpf}
        helperText={errors.cpf && touched.cpf ? errors.cpf : ''}
        disabled={Boolean(context.state.values.cpf)}
      />
      <CampoData
        label="Data de nascimento *"
        name="data_nascimento"
        onFocus={ props.changeFocus }
        onBlur={ (e)=>{
          // context.changeValues({
          //   data_nascimento:e.target.value
          // });
          handleBlur(e);
        } }
        onChange={ handleChange }
        value={values.data_nascimento}
        error={errors.data_nascimento && touched.data_nascimento}
        helperText={errors.data_nascimento && touched.data_nascimento ? errors.data_nascimento : ''}
      />
      <FormActions isSubmitting={ isSubmitting } disabled={ etapa!=context.state.etapa }  />
      <Aviso errors={errors} touched={touched}/>
    </Form>
  )
}

export default withStyles(styles)(EtapaCpfImovel);