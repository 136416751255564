import React from 'react';
import { withStyles, withTheme } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent, Grid, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom'
import Botao from '../../Botao';
import FormContext from '../../FormContext';
import CardJeitto from './CardJeitto';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(),
  },
  card:{
    // maxWidth: `70%`,
  },
  blinkerAnimation:{
    animation: `$blinker 1s linear infinite`,
  },
  '@keyframes blinker': {
    '75%': {
      opacity: .7,
    },
  },
  buttonIcon:{
    padding: theme.spacing(),
    width: 50,
    marginRight: theme.spacing(),
  },
  disclaimer:{
    textAlign: 'center',
    fontSize: '.85rem',
    marginTop: theme.spacing(3),
  }
});



const NoOffer = (props) => {

  const { classes, data, onClose } = props;
  const history = useHistory();
  const context = React.useContext(FormContext);
  const theme = useTheme()

  console.log('context.state.parceiros_ativos', context.state.parceiros_ativos);

  return <div className={classes.root}>
    {
      (context.state.parceiros_ativos && context.state.parceiros_ativos.indexOf('jeitto')!=-1) && (context.state.values.restricao!="Sim")
      ?
      <CardJeitto onClose={onClose}/>
      :
      <Card className={classes.card}>
        <CardContent>
          <Typography gutterBottom style={{ textAlign: 'center' }}>Não encontramos ofertas disponíveis para esta modalidade de empréstimo.</Typography>
        </CardContent>
      </Card>
    }
    
  </div>
}

export default withTheme(withStyles(styles)(NoOffer));