import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme, withStyles, withTheme } from '@material-ui/core';
import { CheckCircle, CheckCircleOutline, KeyboardArrowRight } from '@material-ui/icons';
import FormContext from '../../FormContext';
import logoJeitto from '../../../assets/img/logo-jeitto.svg'
import axios from 'axios';
import formatMoney from '../../../lib/formatMoney';
import Botao from '../../Botao';

const styles = (theme) => ({
  blinkerAnimation:{
    animation: `$blinker 1s linear infinite`,
  },
  '@keyframes blinker': {
    '75%': {
      opacity: .7,
    },
  },
})

const CardJeitto = (props) => {

  const { classes, data, onClose } = props;
  const context = React.useContext(FormContext);
  const theme = useTheme()
  let initialStep = 'initial'
  if(props.proposal){
    if(props.proposal.status=="pre_analise_aprovada"){
      if(props.proposal.externalData?.offers){
        if(props.proposal.externalData.offers.length>0){
          initialStep = 'offers'
        }else{
          initialStep = 'noOffer'
        }
      }else{
        initialStep = 'proposalSent'
      }
    }
  }
  const [step, setStep] = useState(initialStep)
  const [proposal, setProposal] = useState(props.proposal ? props.proposal : null)
  const [selectedOffer, setSelectedOffer] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)

  // const getLastProposal = async()=>{
  //   setStep('loading')
  //   try{
  //     const token = await context.getFirebaseToken();
  //     const result = await axios.get(
  //       `${context.state.API_ENDPOINT}/jeitto/last-proposal`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     console.log('proposal fetched',result.data.data)
  //     setProposal(result.data.data)
  //     setStep('offers')
  //   }catch(error){
  //     console.log(error)
  //     setStep('initial')
  //   }
  // }

  const captureOffer = async () => {
    setStep('loading')
    try{
      const token = await context.getFirebaseToken();
      const result = await axios.post(
        `${context.state.API_ENDPOINT}/jeitto/${selectedOffer.proposal_id}/capture-offer`,
        proposal,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('proposal fetched',result.data.data)
      setProposal(result.data.data)
      setStep(result.data.success ? 'proposalSent' : 'proposalNotSent')
      setErrorMessage(result.data.message)
    }catch(error){
      console.log(error)
      setErrorMessage(error.response?.data?.message || error.message)
      setStep('proposalNotSent')
    }
  }
  const requestOffers = async () => {
    setStep('loading')
    const formatedData = await context.formatValues(context.state.values, 'updateUserData');
    try{
      const token = await context.getFirebaseToken();
      const result = await axios.post(
        `${context.state.API_ENDPOINT}/jeitto/request-offers`,
        formatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('proposal fetched',result.data.data)
      setProposal(result.data.data)
      setStep(result.data.data.externalData?.offers?.length>0 ? 'offers' : 'noOffer')
    }catch(error){
      console.log(error)
      setProposal(null)
      setStep('noOffer')
    }
  }

  // useEffect(()=>{
  //   const init = async()=>{
  //     await getLastProposal()
  //   }
  //   init()
  // },[])

  return(
    <Card style={{minWidth:'70%'}}>
      {
        step=="initial" && <CardContent>
          <Typography gutterBottom variant='h5' style={{ textAlign: 'center', width:`90%`, margin:`8 auto` }}>{context.state.values.nome}, encontramos uma <strong style={{textDecoration:'underline'}}>OFERTA ESPECIAL</strong> para você:</Typography>
          <div style={{border:1, borderColor: theme.palette.primary.main, borderRadius:8, borderStyle:'solid', padding: 24}}>
            <Grid container alignItems='center' justify='center'>
              <Grid item sm={4}  style={{padding: 8}}>
                <img src={logoJeitto} style={{width:`90%`}}/>
              </Grid>
              <Grid item sm={8} style={{padding: 8}}>
                <Typography><strong style={{backgroundColor:`rgb(239 235 235)`, padding:4}}>Informações da Oferta:</strong></Typography>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemIcon style={{minWidth:0}}><KeyboardArrowRight color='primary'/></ListItemIcon>
                    <ListItemText>Crédito para você usar onde quiser</ListItemText>
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemIcon  style={{minWidth:0}}><KeyboardArrowRight color='primary'/></ListItemIcon>
                    <ListItemText>Aprova 3x mais do que outros bancos</ListItemText>
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemIcon  style={{minWidth:0}}><KeyboardArrowRight color='primary'/></ListItemIcon>
                    <ListItemText>Conte com um limite todos meses, sem anuidade</ListItemText>
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemIcon  style={{minWidth:0}}><KeyboardArrowRight color='primary'/></ListItemIcon>
                    <ListItemText>Compatível com sistema operacional Android</ListItemText>
                  </ListItem>
                </List>
                <Typography><strong style={{backgroundColor:`rgb(255 217 184)`, padding:4}}>Validade da Oferta: Encerra-se hoje!</strong></Typography>
              </Grid>
            </Grid>
            {/* <Button
              style={{marginTop: 24, marginBottom:8, width:`100%`}}
              variant='contained'
              color='primary'
              className={classes.blinkerAnimation}
              href={context.state.jeitto_url_redirect}
              target='_blank'
            >
              Clique aqui e finalize em menos de 2 minutos!
            </Button> */}
            <Button
              style={{marginTop: 24, marginBottom:8, width:`100%`, color:'white'}}
              onClick={requestOffers}
              variant='contained'
              color='primary'
            >
              Consultar oferta
            </Button>
          </div>
        </CardContent>
      }
      {
        step=="loading" &&
        <CardContent>
          <div style={{border:1, borderColor: theme.palette.primary.main, borderRadius:8, borderStyle:'solid', padding: 24}}>
            <Grid container alignItems='center' justify='center'>
              <Typography style={{padding:8}}>{selectedOffer ? `Enviando proposta...` : `Consultando ofertas...`}</Typography>
              <CircularProgress />
            </Grid>
          </div>
        </CardContent>
      }
      {
        step=="offers" && <CardContent>
          <div style={{border:1, borderColor: theme.palette.primary.main, borderRadius:8, borderStyle:'solid', padding: 24}}>
            <Typography variant='h5' style={{textAlign:'center'}}>Escolha uma das ofertas abaixo:</Typography>
            <div style={{ display:'flex', justifyContent:'center'}}>
              {
                proposal.externalData.offers.map((offer)=>(
                  <div
                    key={offer.proposal_id}
                    onClick={()=>{ setSelectedOffer(offer); setShowConfirmationDialog(true); }}
                    style={{
                      cursor: 'pointer',
                      margin: 8,
                      border: `1px solid ${selectedOffer!=null && selectedOffer.proposal_id==offer.proposal_id ? 'rgb(34 181 114)' : '#ccc'}`,
                      borderRadius: 8,
                    }}
                  >
                    <div style={{padding:8}}>
                      <div style={{display:'flex', flexDirection:'column'}}>
                        <Typography><strong>Valor</strong>: {`${ formatMoney(Number(offer.value)) }`}</Typography>
                        <Typography><strong>Parcelas</strong>: {`${ Number(offer.installments) }x`}</Typography>
                        <Typography><strong>Valor da parcela</strong>: {`${ formatMoney(Number(offer.installments_value)) }`}</Typography>
                        <Typography><strong>Juros mensais</strong>: {`${ Number(offer.interest_rate_monthly) }%`}</Typography>
                        <Typography><strong>Custo Efetivo Total mensal</strong>: {`${ Number(offer.cet_rate_monthly) }%`}</Typography>
                      </div>
                      <div style={{display:'flex', alignItems:'center', marginTop:8, paddingTop:8, borderTop:'1px solid #ccc'}}>
                        { (selectedOffer!=null && selectedOffer.proposal_id == offer.proposal_id) && <CheckCircle color='primary'/> }
                        { (selectedOffer==null || selectedOffer.proposal_id != offer.proposal_id) && <CheckCircleOutline color='disabled'/> }
                        <Typography style={{marginRight:8}} variant='h6'>Clique aqui e selecione esta oferta</Typography>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
            {/* <Button
              style={{marginTop: 24, marginBottom:8, width:`100%`, color:'white'}}
              onClick={captureOffer}
              variant='contained'
              color='primary'
              disabled={!selectedOffer}
            >
              Solicitar crédito
            </Button> */}
            {
              showConfirmationDialog && <Dialog
                open={true}
                // onClose={() => setShowConfirmationDialog(false) }
              >
                <DialogTitle>Deseja escolher essa oferta?</DialogTitle>
                <DialogContent>
                  <DialogContentText>Ao clicar em "Sim", enviaremos um proposta para o nosso parceiro, com a oferta selecionada.</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Botao label="Sim" onClick={async ()=>{ setShowConfirmationDialog(false); await captureOffer() }}/>
                  <Botao label="Não" onClick={()=>{ setShowConfirmationDialog(false); }}/>
                </DialogActions>
              </Dialog>
            }
          </div>
        </CardContent>
      }
      {
        step=="noOffer" &&
        <CardContent>
          <Typography variant='h5' style={{textAlign:'center'}}>Não encontramos ofertas no momento.</Typography>
          {/* <Typography style={{textAlign:'center'}}>{errorMessage}</Typography> */}
          <Button
              style={{marginTop: 24, marginBottom:8, width:`100%`, color:'white'}}
              onClick={()=>{ setStep('initial') }}
              variant='contained'
              color='primary'
            >
              Voltar
            </Button>
        </CardContent>
      }
      {
        step=="proposalSent" &&
        <CardContent>
          <Typography variant='h5' style={{textAlign:'center'}}>Tudo certo!</Typography>
          <Typography style={{textAlign:'center'}}>Sua proposta foi criada. Agora basta clicar no botão abaixo e baixar o App Jeitto: </Typography>
          <Button
              style={{marginTop: 24, marginBottom:8, width:`100%`, color:'white'}}
              onClick={()=>{
                window.open(proposal.externalData.redirect_url,"_blank");
              }}
              variant='contained'
              color='primary'
            >
              Baixar App Jeitto
            </Button>
        </CardContent>
      }
      {
        step=="proposalNotSent" &&
        <CardContent>
          <Typography variant='h5' style={{textAlign:'center'}}>Proposta não enviada</Typography>
          <Typography style={{textAlign:'center'}}>{errorMessage}</Typography>
          <Button
              style={{marginTop: 24, marginBottom:8, width:`100%`, color:'white'}}
              onClick={onClose}
              variant='contained'
              color='primary'
            >
              Voltar
            </Button>
        </CardContent>
      }
    </Card>
  )
}

export default withTheme(withStyles(styles)(CardJeitto));