import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import FormContext from '../FormContext';
import {
  Typography, LinearProgress, LinearProgressProps, Box, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import Loading from '../Loading';
import CampoLabel from '../fields/CampoLabel';
import {useHistory} from 'react-router-dom'
import axios from 'axios'

import moneyGif from '../../assets/img/money-gif.gif'
import Botao from '../Botao';

const styles = theme => ({
  wrapper: {
    // maxWidth: 450,
    margin: '0 auto',
  },
  fullWidth:{
    width: '60%',
    margin: '0 auto'
  },
  card:{
    cursor: 'pointer',
    margin: '1%',
    width: '48%',
    [theme.breakpoints.down('sm')]: {
      width: '99%',
    },
  },
  cardParceiroLogo:{
    width: 200,
    margin: '1%',
  },
  cardContent:{
    display: 'flex',
    justifyContent: 'space-between',
  },
  parceiroLogoWrapper:{
    display: 'flex',
    width:'30%',
    padding:theme.spacing(),
  },
  parceiroInfosWrapper:{
    width:'70%',
    textAlign:'right',
  },
  parceiroEnviadoLogo:{
    width: '200px',
    height: '80px',
    padding: '16px',
    objectFit: 'contain',
    display: 'block',
  },
  parceiroOfertaLogo:{
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  parceiroMontanteWrapper:{
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  parceiroMontante:{
    fontSize: '1.15rem',
    fontWeight: 600,
    lineHeight: 1.27,
    letterSpacing: '0.38px',
    color: 'rgba(0,0,0,.87)',
  },
  parceiroParcelasWrapper:{
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
    lineHeight: 1.25,
    letterSpacing: '0.28px',
    fontSize: '1.125rem',
    color: 'rgba(0,0,0,.6)',
  },
  parceiroTaxa:{
    marginLeft: theme.spacing(),
    fontSize: '1rem',
  },
  parceirosEnviados:{
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  parceirosOfertas:{
    display: 'flex',
    flexWrap: 'wrap',
  },
  sectionTitle:{
    marginBottom: theme.spacing(),
  },
  sectionSubTitle:{
    marginBottom: theme.spacing()*2,
  },
  sectionWrapper:{
    marginTop: theme.spacing()*3,
    marginBottom: theme.spacing()*3,
    "&:first-child":{
      marginTop: 0,
    }
  },
  btContinuar: {
    // color: theme.palette.common.white,
    // backgroundColor: theme.palette.primary.main,
    padding: `${theme.spacing(1.2)}px ${theme.spacing(5)}px`,
  },
  txtPropostasEnviadas:{
    textAlign: 'center',
    fontSize: '1.25rem',
    padding: theme.spacing(2),
  }
});

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary" style={{textAlign: 'center', color: 'rgb(0, 126, 79)', fontSize: 20, fontWeight: 'bold' }}>{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
}

const EtapaEnviar = (props) => {
  const context = React.useContext(FormContext);
  const { submitForm } = useFormikContext();
  const history = useHistory();
  const [progress, setProgress] = React.useState(0);
  const [timeoutID, setTimeoutID] = React.useState();
  const [timeoutReached, setTimeoutReached] = React.useState(false);
  const [hasSimProposal, setHasSimProposal] = React.useState(false);
  const [hasCashmeProposal, setHasCashmeProposal] = React.useState(false);
  const [proposalIdResponse, setProposalIdResponse] = React.useState('');
  const [popupMessage, setPopupMessage] = React.useState('');
  const [busy, setBusy] = React.useState(false);


  const loginAutomatically = async () => {
    
    setBusy(true)

    if (context.state.userLogged !== true) {
      // console.log('nao esta logado....logando automaticamente.')
      const token = await context.getFirebaseToken();
      const data = context.state.values

      try {
        const formatedData = await context.formatValues(data, 'submit');
        const result = await axios
        .post(`${context.state.API_ENDPOINT}/auth/login-flow/`, formatedData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (result.data.success) {
          const userCredential = await context.signInWithCustomToken(result.data.token);
          await context.getUserData(userCredential.uid);
          context.changeState({ userLogged: true })
          history.push('/area-logada')
        }
      } catch (err) {
        console.log(err)
      }

    } else {
      history.push('/area-logada')
    }

    setBusy(false)

  }

  const loginAutomaticallyToProposalSIM = async (proposalId) => {
    if (context.state.userLogged !== true) {
      const token = await context.getFirebaseToken();
      const data = context.state.values
      try {
        const formatedData = await context.formatValues(data, 'submit');
        const result = await axios
        .post(`${context.state.API_ENDPOINT}/auth/login-flow/`, formatedData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (result.data.success) {
            const userCredential = await context.signInWithCustomToken(result.data.token);
            await context.getUserData(userCredential.uid);
            context.changeState({ userLogged: true })
            history.push(`/sim-envio-documentos/?proposal=${proposalId}`)
            // const win = window.open(`/sim-envio-documentos/?proposal=${proposalId}`, "_blank");
            // win.focus();
          }
        } catch (err) {
          console.log(err)
        }
      } else {
      history.push(`/sim-envio-documentos/?proposal=${proposalId}`)
      // const win = window.open(`/sim-envio-documentos/?proposal=${proposalId}`, "_blank");
      // win.focus();
    }
  }

  const checkProgress = ()=>{
    const proposalsPerPartner = context.state.parceiros ? Object.keys( context.state.parceiros ).length : 5;
    const percentProgressBar = 100 / proposalsPerPartner
    const progress = context.state.proposals.length * percentProgressBar

    if (context.state.proposals.length !== 0) {
      setProgress(progress)
    }

    const simApproved = context.state.proposals.find(proposal => proposal.partnerId === "sim" && (proposal.status === 'proposta_aprovada' || proposal.status === 'pre_analise_aprovada'))
    if(simApproved !== undefined) {
      setHasSimProposal(true)
      setProposalIdResponse(simApproved.id)
    }

    const cashmeApproved = context.state.proposals.find(
      proposal =>
      proposal.partnerId === "cashme" &&
      (
        proposal.status === 'proposta_aprovada' ||
        proposal.status === 'pre_analise_aprovada'
      )
    )
    if(cashmeApproved !== undefined) {
      setHasCashmeProposal(true)
    }

    props.updateResize()

    if(timeoutReached){
      setProgress(100)
    }
  }

  React.useEffect(() => {
    console.log('proposals change')
    console.log(context.state.proposals)
    checkProgress()

  }, [context.state.proposals, timeoutReached]);

  React.useEffect(() => {
    
    setTimeout(() => {
      // console.log('entrou no redirect - useEffect')
      if (progress === 100) {

        clearTimeout(timeoutID)
        context.cancelListenToProposalChanges()

        if (proposalIdResponse !== '' && hasSimProposal === true && context.state.feature_flag_api_v2_sim === true) {
          loginAutomaticallyToProposalSIM(proposalIdResponse)
        } else if(hasCashmeProposal){
          setPopupMessage(`Você receberá um SMS de nosso parceiro CashMe com orientações para concluir o seu pedido de empréstimo.`)
        }
        else {
          loginAutomatically()
        }

      }
    }, 3000);

  }, [progress, proposalIdResponse, hasSimProposal, hasCashmeProposal])

  React.useEffect(()=>{
    const timeout = setTimeout(()=>{
      setTimeoutReached(true)
    },120000) //2min
    setTimeoutID(timeout)
  },[])

  return (
    <div className={`${props.classes.sectionWrapper} ${props.classes.fullWidth}`}>
      <div>
        {/* <Typography variant="h2">Aguarde</Typography> */}
        {/* <Loading visible={true} /> */}
        <Box sx={{ width: '100%' }}>
          <LinearProgressWithLabel value={progress} />
        </Box>
      </div>
      {
        progress !== 100 ? <>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <img src={moneyGif} width='200' />
          </div>
          <Typography gutterBottom color="primary" style={{ fontSize: '1.5rem', textAlign: 'center' }}>Aguarde um momento.</Typography>
          <Typography gutterBottom color="primary" style={{ fontSize: '1.5rem', textAlign: 'center' }}>Estamos simulando e analisando as ofertas de nossos parceiros...</Typography>
        </>
          :
          <Typography gutterBottom color="primary" style={{fontSize:'1.5rem', textAlign: 'center'}}>Ofertas enviadas aos nossos parceiros!</Typography>
      }

      <div className={`${props.classes.sectionWrapper}`} style={{padding:'16px', width:'100%', textAlign:'center'}}>
        {
          progress === 100 ?
            hasSimProposal === true && context.state.feature_flag_api_v2_sim === true ?
              <>
                <Typography variant="h3" gutterBottom>Parabéns!!</Typography>
                <Typography variant="h3" gutterBottom>Seu crédito foi pré aprovado na <span style={{ color: '#fe0064', fontWeight: 'bold', fontSize: 20 }}>SIM</span></Typography>
                <Typography variant="h3" gutterBottom>Aguarde um momento</Typography>
                <CampoLabel className={`${props.classes.sectionSubTitle} campo-titulo`}>Iremos redirecionar você para a proposta da SIM</CampoLabel>
                <CircularProgress/>
              </>
              :
              <>
                <Typography variant="h3" gutterBottom>Aguarde um momento</Typography>
                <CampoLabel className={`${props.classes.sectionSubTitle} campo-titulo`}>Iremos redirecionar você para a área logada</CampoLabel>
                <CircularProgress/>
              </>
          : ''
        }
      </div>

      {
        popupMessage!='' &&
        <Dialog
          open={popupMessage!=''}
          // onClose={() => setPopupMessage('')}
        >
          <DialogTitle>Atenção</DialogTitle>
          <DialogContent>
            <DialogContentText>{ popupMessage }</DialogContentText>
          </DialogContent>
          <DialogActions>
            {
              busy===true
              ?
              <CircularProgress/>
              :
              <Botao label="Ok" onClick={ loginAutomatically }/>
            }
          </DialogActions>
        </Dialog>
      }


    </div>
  );
}

export default withStyles(styles)(EtapaEnviar);
