import React from 'react'
import { Form, useFormikContext } from 'formik';
// import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
// import MaskedInput from 'react-text-mask'

import CampoTexto from '../fields/CampoTexto';
// import CampoRadio from '../fields/CampoRadio';
import CampoSelect from '../fields/CampoSelect';
import CampoEstado from '../fields/CampoEstado';
import FormActions from '../FormActions';
import CampoData from '../fields/CampoData';
import FormContext from '../FormContext';
import Aviso from '../Aviso';
import { orgao_emissor } from '../../lib/orgao_emissor'

import {
  Typography
} from '@material-ui/core';

const styles = {
  
};

const EtapaDocIdentificacao = (props) => {
  const context = React.useContext(FormContext);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const { fields, etapa } = props;

  return(
    <Form>
      <Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>Documento de Identificação</Typography>
    
      {
        fields.includes('rg') &&
        <CampoTexto
          label="RG *"
          name="rg"
          inputProps={{ maxLength: 14 }}
          onFocus={ props.changeFocus }
          onBlur={ (e)=>{
            handleBlur(e);
          }}
          value={values.rg}
          onChange={handleChange}
          error={errors.rg}
          helperText={errors.rg}
        />
      }
      {
        fields.includes('rg_orgao_emissor') &&
          <CampoSelect
            label="Orgão emissor *"
            name="rg_orgao_emissor"
            id="rg_orgao_emissor"
            value={values.rg_orgao_emissor}
            onChange={(data) => {
              // console.log(data)
              setFieldValue('rg_orgao_emissor', data.value);
              
            }}
            options={orgao_emissor}
            error={errors.rg_orgao_emissor}
          />
      }
      {
        fields.includes('rg_data_emissao') &&
          <CampoData
            label="Data de emissão *"
            name="rg_data_emissao"
            id="rg_data_emissao"
            onFocus={ props.changeFocus }
            onBlur={ handleBlur }
            onChange={ handleChange }
            value={values.rg_data_emissao}
            error={errors.rg_data_emissao}
            helperText={errors.rg_data_emissao}
          />
      }
      {
        fields.includes('rg_estado_emissao') &&
          <CampoEstado
            name="rg_estado_emissao"
            label="Estado de Emissão *"
            value={values.rg_estado_emissao}
            error={errors.rg_estado_emissao}
            touched={touched.rg_estado_emissao}
            onChange={ ( data ) => {
              setFieldValue('rg_estado_emissao', data.value);
            }}
            onBlur={ ( value ) => { setFieldTouched('rg_estado_emissao',true); } }
        />
      }
      {
        fields.includes('estado_nascimento') &&
        <CampoEstado
          name="estado_nascimento"
          label="Estado de nascimento *"
          value={values.estado_nascimento }
          error={errors.estado_nascimento}
          touched={touched.estado_nascimento}
          onChange={ ( data ) => {
            setFieldValue('estado_nascimento', data.value );
          }}
          onBlur={ ( e ) => {
            handleBlur(e);
            setFieldTouched('estado_nascimento',true);
          }}
        />
      }
      {
        fields.includes('nome_mae') &&
          <CampoTexto
            label="Nome da mãe *"
            name="nome_mae"
            onFocus={ props.changeFocus }
            onBlur={ (e)=>{
              handleBlur(e);
              // context.changeValues({
              //   nome_mae:e.target.value
              // });
            } }
            value={values.nome_mae}
            onChange={handleChange}
            error={errors.nome_mae && touched.nome_mae}
            helperText={errors.nome_mae && touched.nome_mae ? errors.nome_mae : ''}
            placeholder="Maria Aparecida"
          />
      }
      <FormActions isSubmitting={ isSubmitting } disabled={ etapa!=context.state.etapa }  />
      <Aviso errors={errors} touched={touched}/>
    </Form>
  )
}

export default withStyles(styles)(EtapaDocIdentificacao);