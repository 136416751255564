import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import { Form, Formik, validateYupSchema } from "formik";
import {
  Typography, withTheme, CircularProgress, Grid, Button, Snackbar
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { useTheme } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';

import CampoTexto from "../fields/CampoTexto";
import CampoNumero from "../fields/CampoNumero";
import FormContext from "../FormContext";
import CampoCpf from "../fields/CampoCpf";
import CampoEmail from "../fields/CampoEmail";
import CPF from "@fnando/cpf/dist/node";

const styles = (theme) => ({
  submitButton: {
    color: theme.palette.common.white,
    padding: `${theme.spacing(1.2)}px ${theme.spacing(5)}px`,
    width: `100%`,
  },
  button: {
    color: theme.palette.common.white,
    padding: `${theme.spacing(1.2)}px ${theme.spacing(5)}px`,
  },
  form: {
    justifyContent: "center",
  },
  helpText: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  codeValidationHelpText: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
    fontSize: `1.4rem`,
  },
  feedbackText: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const validationCode = Yup.object().shape({
  code: Yup.number()
    .typeError('Ops, digite apenas números no código de verificação.')
    .required('Por favor, insira o código de verificação.'),
})

const validationLoginForm = Yup.object().shape({
  cpf: Yup.string().nullable()
    .test('test-cpf', 'Ops, o CPF é inválido.', (value) => {
      return value !== undefined ? CPF.isValid(value) : true
    }),
   email: Yup.string().nullable()
    .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,'Formato de email está incompleto.'),
})

const LoginForm = (props) => {

  const [step, setStep] = useState(props.inOrderFlow ? "inOrderFlow" : "initial");
  const [alreadyHasCode, setAlreadyHasCode] = useState(false);
  const [feedbackText, setFeedbackText] = useState();
  const [codeValidationFeedbackText, setCodeValidationFeedbackText] = useState();
  const [disabledRequestCode, setDisabledRequestCode] = useState(false);
  const [disabledValidateCode, setDisabledValidateCode] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [loginCredentials, setLoginCredentials] = useState();
  const [nullableFields, setNullableFields] = useState(false)
  const context = React.useContext(FormContext);
  const theme = useTheme();
  const history = useHistory();
  const [redirectPath, set_redirectPath] = useState(null);

  useEffect(()=>{
    const url = new URLSearchParams(location.search).get('redirectPath')
    // console.log('url ******************',url)
    set_redirectPath(url)
  })

  const checkCode = async (code) => {
    setDisabledValidateCode(true)
    try{
      const result = await context.checkLoginCode(code.toString(), loginCredentials);
      console.log(result)
      if(result.token){
        const userCredential = await context.signInWithCustomToken(result.token);
        const userData = await context.getUserData(userCredential.uid);
        if (props.inOrderFlow) {
          console.log('updating with userData',userData)
          console.log('updating with loginData',context.state.loginData)
          context.showLogin(false);
          const changes = {
            ...userData,
            ...context.state.loginData
          }

          if(userData.email != changes.email){
            changes.email = userData.email
          }

          if(userData.cpf != changes.cpf){
            changes.cpf = userData.cpf
          }
          context.changeState({userPersisted:true})
          context.submitForm(changes, null, true);
        } else {
          context.changeState({userLogged: true})
          return history.push(redirectPath ? redirectPath : '/area-logada');
        }
      }
    }catch(error){
      console.log("error",error);
      if(error.response && error.response.data){
        setCodeValidationFeedbackText(error.response.data.message);
      }
    }
    setDisabledRequestCode(false)
    setDisabledValidateCode(false)
  };

  return (
    <div>
      <Typography variant="h2" gutterBottom style={{ textAlign: "center" }}>
        Login
      </Typography>
      {step == "initial" && (
        <>
          <Typography className={props.classes.helpText}>
            Você já recebeu o código de acesso?
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              width: '100%'
            }}
          >
            <Button
              color="primary"
              variant="contained"
              className={props.classes.button}
              onClick={()=>{ setStep('sendCode') }}
            >
              Não
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={props.classes.button}
              onClick={()=>{ 
                setAlreadyHasCode(true)
                setStep('sendCode')
              }}
            >
              Sim
            </Button>
          </div>
        </>
      )}
      {step == "sendCode" && (
        <>
          <Typography className={props.classes.helpText}>
            Por favor, informe seu e-mail para obter o código de acesso:
          </Typography>
          <Formik
            onSubmit={ async (values) => {
              if (values.email === '' && values.cpf === '') {
                setNullableFields(true)
              } else {
                setDisabledRequestCode(true)
                const payload = {
                  email: values.email,
                  cpf: values.cpf,
                };
                setLoginCredentials(payload);
                try{
                  if(!alreadyHasCode){
                    const result = await context.login(payload);
                    setFeedbackText(result.data.message);
                  }
                  setStep("codeValidation");
                }catch(error){
                  console.log(error.response);
                  if(error.response && error.response.data){
                    setFeedbackText(error.response.data.message);
                    setTimeout(() => {
                      history.push('/primeiro-acesso')
                    }, 2000);
                  }
                }
                setDisabledRequestCode(false)
              }
            }}
            initialValues={{
              email: "",
              cpf: "",
            }}
            validationSchema={validationLoginForm}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form className={props.classes.form}>
                <CampoEmail
                  label="Email"
                  name="email"
                  placeholder="josesilva@email.com.br"
                  onFocus={ props.changeFocus }
                  onBlur={ handleBlur }
                  value={values.email}
                  onChange={handleChange}
                  error={errors.email && touched.email}
                  helperText={errors.email && touched.email ? errors.email : ''}
                />
                {/* <Typography style={{ marginBottom: theme.spacing(3) }}>Ou</Typography> */}
                { forgotPassword && <CampoCpf
                  label="Informe o CPF"
                  name="cpf"
                  onFocus={ props.changeFocus }
                  onBlur={ handleBlur }
                  onChange={ handleChange }
                  value={values.cpf}
                  error={errors.cpf && touched.cpf}
                  helperText={errors.cpf && touched.cpf ? errors.cpf : ''}
                  disabled={Boolean(context.state.values.cpf)}
                />
                }
                {
                  feedbackText &&
                  <Typography className={props.classes.feedbackText}>
                    {feedbackText}
                  </Typography>
                }
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    width: '100%'
                  }}
                >
                  <Button
                    disabled={disabledRequestCode}
                    type="submit"
                    color="primary"
                    variant="contained"
                    className={props.classes.button}
                  >
                    {!alreadyHasCode ? "Solicitar código" : "Preencher código"}
                  </Button>

                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    width: '100%'
                  }}
                >
                  <Button
                    onClick={() => setForgotPassword(!forgotPassword)}
                    color="primary"
                  >
                    Esqueci meu e-mail
                  </Button>
                  <Button
                    onClick={() => history.push('/primeiro-acesso')}
                    color="primary"
                  >
                    não tenho conta
                  </Button>
                </div>
                {disabledRequestCode && <div style={{marginTop: 20, textAlign: `center`}}><CircularProgress/></div>}
              </Form>
            )}
          </Formik>
        </>
      )}
      {step == "inOrderFlow" && (
        <>
          <Typography className={props.classes.helpText}>
            Ops, parece que você já tem uma conta.<br/>Clique abaixo para solicitar seu código de acesso.
          </Typography>
          {
            feedbackText &&
            <Typography className={props.classes.feedbackText}>
              {feedbackText}
            </Typography>
          }
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              marginBottom: theme.spacing(2)
            }}
          >
            <Button
              disabled={disabledRequestCode}
              color="primary"
              variant="contained"
              className={props.classes.button}
              onClick={async (e) => {
                setDisabledRequestCode(true)
                try{
                  const result = await context.login(context.state.loginData);
                  setFeedbackText(result.data.message);
                  setStep("codeValidation");
                }catch(error){
                  console.log(error.response);
                  if(error.response && error.response.data){
                    setFeedbackText(error.response.data.message);
                  }
                }
                setDisabledRequestCode(false)
              }}
            >
              Solicitar código
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => setStep('codeValidation') }
              color="primary"
            >
              Já tenho um código de acesso
            </Button>
          </div>
          {disabledRequestCode && <div style={{marginTop: 20, textAlign: `center`}}><CircularProgress/></div>}
        </>
      )}
      {step == "codeValidation" && (
        <>
          <Typography className={props.classes.feedbackText}>
            {feedbackText && feedbackText.split(/\r\n|\n|\r/gm).map( (line, index) => {
              return <React.Fragment key={index}>{line}<br /></React.Fragment>
            })}
          </Typography>
          <Typography className={props.classes.codeValidationHelpText}>
            Insira abaixo o código recebido:
          </Typography>
          <Formik
            onSubmit={(values) => {}}
            initialValues={{
              code: "",
            }}
            validationSchema={validationCode}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form className={props.classes.form}>
                <CampoNumero
                  label="Código"
                  name="code"
                  id="code"
                  placeholder="123456"
                  value={values.code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.code && touched.code}
                  helperText={errors.code && touched.code ? errors.code : ''}
                />
                {
                  codeValidationFeedbackText &&
                  <Grid item xs={12}>
                    <Typography className={props.classes.feedbackText}>
                      {codeValidationFeedbackText}
                    </Typography>
                  </Grid>
                }
                <div
                  style={{
                    display: "flex",
                    flexDirection: 'column',
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    disabled={disabledValidateCode}
                    color="primary"
                    variant="contained"
                    className={props.classes.submitButton}
                    onClick={(event) => {
                      checkCode(values.code);
                    }}
                  >
                  Acessar Conta
                  </Button>
                  {disabledValidateCode && <div style={{ marginTop: 20 }}><CircularProgress /></div>}
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
      <Snackbar open={nullableFields} autoHideDuration={3000} onClose={() => setNullableFields(false)}>
        <Alert onClose={() => setNullableFields(false)} severity={'error'}>Preencha o campo CPF ou Email para prosseguir</Alert>
      </Snackbar>
    </div>
  );
};

export default withTheme(withStyles(styles)(LoginForm));
