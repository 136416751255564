import React, { useEffect, useState } from 'react'
import { Form, useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import FormContext from '../FormContext';
import Aviso from '../Aviso';
import CampoCpf from '../fields/CampoCpf';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, Typography, useTheme } from '@material-ui/core';
import Botao from '../Botao';
import axios from 'axios';

const styles = {
  modal:{
    width: `100%`,
    justifyContent:`center`,
  }
};

const EtapaElegibilidade = (props) => {
  
  const context = React.useContext(FormContext);
  const theme = useTheme();

  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched, submitForm, isValid } = useFormikContext();
  const { fields, etapa, params, classes } = props;
  const [busy, setBusy] = useState(false)
  const [showModal, setShowModal] = useState(undefined)

  const verifyEligibility = async (e) => {

    if(!isValid){
      console.log('form not valid')
      return
    }

    props.updateResize()

    setBusy(true)

    try{
      const token = await context.getFirebaseToken();
      const result = await axios.post(`${context.state.API_ENDPOINT}/bv/elegibilidade`,{
        cpf: values.cpf,
      },{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      let tests = String(context.state.values.tests).replace(`{Alto Potencial de Aprovação}`,``)
      if(result.data.success) tests += `{Alto Potencial de Aprovação}`
      context.changeValues({
        tests,
        bv_alto_potencial_de_aprovacao: result.data.success,
        restricao: result.data.success===true ? `Não` : ``,
      })

      if(result.data.success){
        setShowModal(true)
      }else{
        submitForm()
      }

    }catch(error){
      console.log(error)
      submitForm()
    }

    setBusy(false)

  }

  const nextStep = (e) => {

    setShowModal(false)
    submitForm()

  }

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  useEffect(()=>{
    context.changeValues({
      tests: String(context.state.values.tests).replace(`{Alto Potencial de Aprovação}`,``),
      bv_alto_potencial_de_aprovacao: false,
    })
  },[])

  return(
    <Form onKeyDown={onKeyDown}>
      <CampoCpf
        label="Informe o CPF"
        name="cpf"
        onFocus={ props.changeFocus }
        onBlur={ handleBlur }
        onChange={ handleChange }
        value={values.cpf}
        error={errors.cpf && touched.cpf}
        helperText={errors.cpf && touched.cpf ? errors.cpf : ''}
        disabled={Boolean(context.state.values.cpf)}
      />
      <Typography align='center' gutterBottom>
        Esta informação é importante para que possamos passar uma oferta sob medida. Fique tranquilo, seus dados estão seguros!
      </Typography>
      <div style={{ margin:`0 auto`, textAlign:`center` }}>
        <Botao label="Próximo passo" onClick={verifyEligibility} disabled={busy || context.state.loadingNextStep} />
        <div>
          { (busy || context.state.loadingNextStep) && <CircularProgress style={{margin:10}}/> }
        </div>
      </div>
      <Aviso errors={errors} touched={touched}/>
      {
        showModal && 
        <Dialog open={showModal} style={{textAlign:`center`}}>
          <DialogContent>
            <Typography variant='h3' gutterBottom>Uau, parabéns!</Typography>
            <DialogContentText>Você tem grandes chances de conseguir uma excelente oferta de crédito.</DialogContentText>
            <DialogContentText>Agora precisamos coletar alguns dados adicionais para encontrar o melhor empréstimo.</DialogContentText>
          </DialogContent>
          <DialogActions classes={{ root: classes.modal }}>
            <Botao label="Continuar" onClick={nextStep} />
          </DialogActions>
        </Dialog>
      }
    </Form>
  )
}

export default withStyles(styles)(EtapaElegibilidade);