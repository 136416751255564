import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";

import Header from "../../components/logged-area/Header"
import Footer from "../../components/logged-area/Footer"
import { Formik, Form } from 'formik';
import { useHistory } from "react-router-dom";

import CampoTexto from '../../components/fields/CampoTexto';
import CampoOpcoes from "../../components/fields/CampoOpcoes";
import CampoTelefone from "../../components/fields/CampoTelefone";
import CampoEmail from "../../components/fields/CampoEmail";
import CampoCep from "../../components/fields/CampoCep";
import CampoCpf from "../../components/fields/CampoCpf";
import CampoData from "../../components/fields/CampoData";
import CampoEstado from "../../components/fields/CampoEstado";
import CampoSugestoes from "../../components/fields/CampoSugestoes";
import CampoDinheiro from "../../components/fields/CampoDinheiro";
import CampoCnpj from '../../components/fields/CampoCnpj'
import Breadcrumb from '../../components/Breadcrumb'
import FormContext from '../../components/FormContext';
import ValidationsSchemaLoggedArea from '../../lib/ValidationsLoggedArea'

import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import {
  DialogTitle, DialogContentText, DialogContent, DialogActions, Dialog, Container, Snackbar, Button, Typography, Grid, withTheme
} from '@material-ui/core';

import ContactsRoundedIcon from '@material-ui/icons/PhoneAndroidRounded';
import HouseRoundedIcon from '@material-ui/icons/HouseRounded';
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import WorkRoundedIcon from '@material-ui/icons/WorkRounded';
import Content from '../../components/Content';

const styles = theme => ({
  buttonUpdateData: {
    position: 'sticky',
    bottom: 15,
    margin: '0 auto',
  },
  clickHereRedirect: {
    cursor: 'pointer'
  },
  modal: {
    position: 'absolute',
    top: `${50}%`,
    left: `${50}%`,
    transform: `translate(-${50}%, -${50}%)`,
    width: 250,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #00b572',
    borderRadius: '6px 6px 6px 6px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
})

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const TitleMyData = (props) => {
  let componentTitle;
  switch (props.type) {
    case 'contato':
      componentTitle = <><Typography variant="h6"><ContactsRoundedIcon style={{ position: 'relative', top: 3 }} /><b>{props.children}</b></Typography>
        <p>Para facilitar o contato de nosso time da Quero Financiar com você, precisamos do seu e-mail ou do número do celular. Caso seja necessário alterar algum dado de contato, sinta-se à vontade</p>
      </>
      break;
    case 'pessoal':
      componentTitle = <><br /><br /><Typography variant="h6"><AccountBoxRoundedIcon style={{ position: 'relative', top: 3 }} /><b>{props.children}</b></Typography>
        <p>Alguns dados pessoais que ficam em nossa base de dados. Esses dados ajudam nosso time e nossos parceiros a fazerem a análise de crédito.</p>
      </>
      break;
    case 'residencial':
      componentTitle = <><br /><br /><Typography variant="h6"><HouseRoundedIcon style={{ position: 'relative', top: 3 }} /><b>{props.children}</b></Typography>
        <p>Caso alguma proposta for aprovada, seus dados residenciais serão de suma importância caso enviarmos alguma carta para a sua casa.</p>
      </>
      break;
    case 'profissional':
      componentTitle = <><br /><br /><Typography variant="h6"><WorkRoundedIcon style={{ position: 'relative', top: 3 }} /><b>{props.children}</b></Typography>
        <p>Esses dados ajudam nosso time a encontrar os melhores produtos para você de acordo com seu perfil. Mantenha atualizado sempre que possível.</p>
      </>
      break;
  }
  return componentTitle;
}

const DadosCadastrais = (props) => {

  const context = React.useContext(FormContext);
  const history = useHistory();
  const refDeleteData = useRef();
  const refStartPage = useRef();

  const[openModalConfirmDeleteData, setOpenModalConfirmDeleteData] = useState(false)
  const[openFeedbackDeleteData, setOpenFeedbackDeleteData] = useState(false)
  const[feedbackDeleteDataMessage, setFeedbackDeleteDataMessage] = useState('')
  const [feedbackDeleteDataType, setFeedbackDeleteDataType] = useState('')
  const [deleteDataButton, setDeleteDataButton] = useState(false)

  const initCities = async (uf)=>{
    const cities = await context.getBrazilianCitiesByState(uf)
    context.changeState({
      cidade_nascimento_options: cities
    });
  }

  useEffect(() => {
    if (context.state.firebaseLoaded) {
      if (!context.state.userLogged) {
        history.push('/login')
      } else {
        if(context.state.values.estado){
          initCities(context.state.values.estado)
        }
      }
    }
  }, [])

  const FormularioMeusDados = ({ handleSubmit, handleChange, setFieldValue, handleBlur, values, errors, touched }) => {
    return (
      <Form>
        {/* ============================= DADOS PARA CONTATO ============================= */}
        <Grid container spacing={2} style={{marginTop: 20}}>
          <Grid item xs={12}>
            <TitleMyData type="contato">Dados para contato</TitleMyData>
          </Grid>
          <Grid item xs={12} md={6}>
            <CampoEmail
              disabled={true}
              label="Email"
              name="email"
              id="email"
              value={values.email}
              onChange={handleChange}
              error={errors.email && touched.email}
              helperText={errors.email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CampoTelefone
              label="Celular"
              name="celular"
              id="celular"
              value={values.celular}
              onChange={ handleChange }
              error={errors.celular && touched.celular}
              helperText={errors.celular}
              // showError={errors.celular}
            />
          </Grid>
        </Grid>
        {/* ============================= DADOS PESSOAIS ============================= */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TitleMyData type="pessoal">Dados pessoais</TitleMyData>
          </Grid>
          <Grid item xs={12}>
            <CampoTexto
              label="Nome completo"
              name="nomeCompleto"
              id="nomeCompleto"
              value={values.nomeCompleto}
              onChange={handleChange}
              error={errors.nomeCompleto && touched.nomeCompleto}
              helperText={errors.nomeCompleto && touched.nomeCompleto ? errors.nomeCompleto : ''}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <CampoTexto
              disabled={true}
              label="RG"
              name="rg"
              id="rg"
              value={values.rg}
              onChange={handleChange}
              error={errors.rg && touched.rg}
              helperText={errors.rg && touched.rg ? errors.rg : ''}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <CampoCpf
              style={{zIndex: 0}}
              disabled={true}
              label="CPF"
              name="cpf"
              id="cpf"
              value={values.cpf}
              onChange={handleChange}
              error={errors.cpf && touched.cpf}
              helperText={errors.cpf && touched.cpf ? errors.cpf : ''}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CampoTexto
              label="Nome da mãe"
              name="nome_mae"
              id="nome_mae"
              value={values.nome_mae}
              onChange={handleChange}
              error={errors.nome_mae && touched.nome_mae}
              helperText={errors.nome_mae && touched.nome_mae ? errors.nome_mae : ''}
              />
          </Grid>
          <Grid item xs={12} md={6}>
            <CampoOpcoes
              label="Nacionalidade"
              name="nacionalidade"
              id="nacionalidade"
              value={values.nacionalidade}
              classNamePrefix="quero-financiar-select"
              className="campo-selecao"
              onChange={(data) => {
                setFieldValue('nacionalidade', data.value);
              }}
              options={[
                  {label:'Brasileira', value:'Brasileira'},
                  {label:'Estrangeira', value:'Estrangeira'},
                ]}
              showError={errors.nacionalidade}
              helperText={errors.nacionalidade}
              error={errors.nacionalidade && touched.nacionalidade}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CampoOpcoes
              label="Gênero"
              name="genero"
              id="genero"
              value={values.genero}
              classNamePrefix="quero-financiar-select"
              className="campo-selecao"
              onChange={(data) => {
                setFieldValue('genero', data.value);
              }}
              options={[
                {label:'Feminino', value:'Feminino'},
                {label:'Masculino', value:'Masculino'},
              ]}
              showError={errors.genero}
              helperText={errors.genero}
              error={errors.genero && touched.genero}
            />
          </Grid>
          <Grid item xs={12} >
            <CampoOpcoes
              label="Estado Civil"
              name="estado_civil"
              id="estado_civil"
              value={values.estado_civil}
              classNamePrefix="quero-financiar-select"
              className="campo-selecao"
              showError={errors.estado_civil && touched.estado_civil}
              error={errors.estado_civil && touched.estado_civil}
              helperText={errors.estado_civil}
              onChange={(data) => {
                setFieldValue('estado_civil', data.value);
              }}
              options={[
                {label:'Solteiro', value:'Solteiro'},
                {label:'Casado', value:'Casado'},
                {label:'Viúvo', value:'Viúvo'},
                {label:'Divorciado', value:'Divorciado'},
                {label:'União Estável', value:'União Estável'},
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <CampoData
              disabled={true}
              label="Data de nascimento"
              name="data_nascimento"
              id="data_nascimento"
              value={values.data_nascimento}
              onChange={handleChange}
              error={errors.data_nascimento && touched.data_nascimento}
              helperText={errors.data_nascimento && touched.data_nascimento ? errors.data_nascimento : ''}
            />
          </Grid>
          <Grid item xs={12}>
            <CampoEstado
              label="Estado de nascimento"
              name="estado_nascimento"
              id="estado_nascimento"
              value={values.estado_nascimento}
              onChange={ async (data) => {
                setFieldValue('estado_nascimento', data.value);
                const cities = await context.getBrazilianCitiesByState(data.value)
                if(data.value){
                  context.changeState({
                    cidade_nascimento_options: cities
                  });
                }
              }}
              error={errors.estado_nascimento && touched.estado_nascimento}
              helperText={errors.estado_nascimento}
              // showError={errors.estado_nascimento}
            />
          </Grid>
          <Grid item xs={12}>
            <CampoSugestoes
              options={context.state.cidade_nascimento_options}
              onSuggestionSelected={
                (value)=>{
                  setFieldValue('cidade_nascimento',value);
                  // context.changeValues({
                  //   cidade_nascimento: value
                  // });
                }
              }
              inputProps={{
                className:"campo-texto",
                name: 'cidade_nascimento',
                id: 'cidade_nascimento',
                label:"Cidade de nascimento",
                value: values.cidade_nascimento,
                error: errors.cidade_nascimento,
                helperText: errors.cidade_nascimento,
                onChange: (event, { newValue }) => {
                    handleChange(event);
                    setFieldValue('cidade_nascimento',newValue);
                    // context.changeValues({
                    //   cidade_nascimento:newValue
                    // });
                },
              }}
            />
          </Grid>
        </Grid>
        {/* ============================= DADOS RESIDENCIAIS ============================= */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TitleMyData type="residencial">Dados residenciais</TitleMyData>
          </Grid>
          <Grid item xs={12}>
            <CampoCep
              label="CEP"
              name="cep"
              id="cep"
              value={values.cep}
              onBlur={ (e)=> {
                handleBlur(e);
                const regx = /[\(\)_-]/g;
                if(e.target.value.replace(regx,'').length==8){
                  context.buscarCep(e.target.value).then((changes=>{
                    setFieldValue('endereco', changes.endereco);
                    setFieldValue('bairro', changes.bairro);
                    setFieldValue('estado', changes.estado);
                    setFieldValue('cidade', changes.cidade);
                  }));
                }
              }}
              onChange={
                (e)=> {
                    handleChange(e);
                    const regx = /[\(\)_-]/g;
                    if(e.target.value.replace(regx,'').length==8){
                      context.buscarCep(e.target.value).then((changes=>{
                        setFieldValue('endereco', changes.endereco);
                        setFieldValue('bairro', changes.bairro);
                        setFieldValue('estado', changes.estado);
                        setFieldValue('cidade', changes.cidade);
                      }));
                    }
                }
              }
              error={errors.cep && touched.cep}
              helperText={errors.cep}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <CampoTexto
              label="Estado"
              name="estado"
              id="estado"
              value={values.estado}
              onChange={handleChange}
              error={errors.estado && touched.estado}
              helperText={errors.estado && touched.estado ? errors.estado : ''}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <CampoTexto
              style={{zIndex: 0}}
              label="Cidade"
              name="cidade"
              id="cidade"
              value={values.cidade}
              onChange={handleChange}
              error={errors.cidade && touched.cidade}
              helperText={errors.cidade && touched.cidade ? errors.cidade : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CampoTexto
              style={{zIndex: 0}}
              label="Bairro"
              name="bairro"
              id="bairro"
              value={values.bairro}
              onChange={handleChange}
              error={errors.bairro && touched.bairro}
              helperText={errors.bairro && touched.bairro ? errors.bairro : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CampoTexto
              label="Endereço"
              name="endereco"
              id="endereco"
              value={values.endereco}
              onChange={handleChange}
              error={errors.endereco && touched.endereco}
              helperText={errors.endereco && touched.endereco ? errors.endereco : ''}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <CampoTexto
              style={{zIndex: 0}}
              label="Número"
              name="numero"
              id="numero"
              value={values.numero}
              onChange={handleChange}
              error={errors.numero && touched.numero}
              helperText={errors.numero && touched.numero ? errors.numero : ''}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <CampoTexto
              style={{zIndex: 0}}
              label="Complemento"
              name="complemento"
              id="complemento"
              value={values.complemento}
              onChange={handleChange}
              error={errors.complemento && touched.complemento}
              helperText={errors.complemento && touched.complemento ? errors.complemento : ''}
            />
          </Grid>
        </Grid>
        {/* ============================= DADOS PROFISSIONAIS ============================= */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TitleMyData type="profissional">Dados profissionais</TitleMyData>
          </Grid>
          <Grid item xs={12}>
            <CampoOpcoes
              label="Ocupação"
              name="ocupacao"
              id="ocupacao"
              value={values.ocupacao}
              classNamePrefix="quero-financiar-select"
              className="campo-selecao"
              onChange={(data) => {
                setFieldValue('ocupacao', data.value);
              }}
              options={[
                {label:'Aposentado(a)',                       value:'Aposentado(a)'},
                {label:'Assalariado(a)',                      value:'Assalariado(a)'},
                {label:'Autônomo(a)/ Prof. Liberal',          value:'Autônomo(a)/ Prof. Liberal'},
                {label:'Empresário(a)',                       value:'Empresário(a)'},
                {label:'Servidor público',                    value:'Servidor público'},
              ]}
              error={errors.ocupacao && touched.ocupacao}
              showError={errors.ocupacao}
              helperText={errors.ocupacao}
            />
          </Grid>
          {
            values.ocupacao === 'Assalariado(a)' || values.ocupacao === 'Servidor público' ? 
              <Grid item xs={12}>
                <CampoOpcoes
                  label="Tempo de serviço"
                  id="tempo_servico"
                  name="tempo_servico"
                  classNamePrefix="quero-financiar-select"
                  className="campo-selecao"
                  value={values.tempo_servico}
                  onChange={ ( data ) => {
                    setFieldValue('tempo_servico', data.value);
                  } }
                  options={[
                    {label:'Menos de 6 meses',      value:'Menos de 6 meses'},
                    {label:'Entre 6 meses e 1 ano', value:'Entre 6 meses e 1 ano'},
                    {label:'Entre 1 e 2 anos',      value:'Entre 1 e 2 anos'},
                    {label:'Entre 2 e 3 anos',      value:'Entre 2 e 3 anos'},
                    {label:'Entre 3 e 4 anos',      value:'Entre 3 e 4 anos'},
                    {label:'Mais de 4 anos',        value:'Mais de 4 anos'},
                  ]}
                  error={errors.tempo_servico && touched.tempo_servico}
                  showError={errors.tempo_servico}
                  helperText={errors.tempo_servico}

                />
              </Grid>
              :
                null
          }
          {
            values.ocupacao == "Aposentado(a)" ?
            <Grid item xs={12}>
               <CampoTexto
                label="Número do benefício"
                name="aposentado_num_beneficio"
                id="aposentado_num_beneficio"
                value={values.aposentado_num_beneficio}
                onChange={handleChange}
                error={errors.aposentado_num_beneficio && touched.aposentado_num_beneficio}
                helperText={errors.aposentado_num_beneficio && touched.aposentado_num_beneficio ? errors.aposentado_num_beneficio : ''}
              />
            </Grid>
            :
            null
          }
          {
            values.ocupacao == "Empresário(a)" ?
            <Grid item xs={12}>
              <CampoCnpj
                label="CNPJ da sua empresa"
                name="cnpj"
                id="cnpj"
                onBlur={ (e)=>{
                  setFieldValue('tem_cnpj', "Sim");
                }}
                onChange={ handleChange }
                value={values.cnpj}
                error={errors.cnpj && touched.cnpj}
                helperText={errors.cnpj}
                // showError={errors.cnpj}
              />
            </Grid>
            :
            null
          }
          {
            values.ocupacao === "Autônomo(a)/ Prof. Liberal"  ?
              <>
              <Grid item xs={12}>
                <CampoOpcoes
                  label="Você possui CNPJ? *"
                  name="tem_cnpj"
                  id="tem_cnpj"
                  showError={errors.tem_cnpj}
                  classNamePrefix="quero-financiar-select"
                  className="campo-selecao"
                  value={values.tem_cnpj}
                  onChange={ ( data ) => {
                    setFieldValue('tem_cnpj', data.value);
                  }}
                  // onBlur={ (e) => { setFieldValue('tem_cnpj',"Sim"); } }
                  options={[
                    {label:'Sim',   value:'Sim'},
                    {label:'Não',   value:'Não'},
                  ]}
                />
                </Grid>
                {
                  values.tem_cnpj=="Sim" &&
                  <Grid item xs={12}>
                    <CampoCnpj
                      label="CNPJ da sua empresa *"
                      name="cnpj"
                      // onFocus={ props.changeFocus }
                      // onBlur={ (e)=>{
                      //   handleBlur(e);
                      // } }
                      onChange={ handleChange }
                      value={values.cnpj}
                      error={errors.cnpj && touched.cnpj}
                      helperText={errors.cnpj}
                      // showError={errors.cnpj}
                    />
                  </Grid>
                }
                <Grid item xs={12}>
                  <CampoOpcoes
                  label="Tempo de serviço"
                  id="tempo_servico"
                  name="tempo_servico"
                  classNamePrefix="quero-financiar-select"
                  className="campo-selecao"
                  value={values.tempo_servico}
                  onChange={ ( data ) => {
                    setFieldValue('tempo_servico', data.value);
                  } }
                  options={[
                    {label:'Menos de 6 meses',      value:'Menos de 6 meses'},
                    {label:'Entre 6 meses e 1 ano', value:'Entre 6 meses e 1 ano'},
                    {label:'Entre 1 e 2 anos',      value:'Entre 1 e 2 anos'},
                    {label:'Entre 2 e 3 anos',      value:'Entre 2 e 3 anos'},
                    {label:'Entre 3 e 4 anos',      value:'Entre 3 e 4 anos'},
                    {label:'Mais de 4 anos',        value:'Mais de 4 anos'},
                  ]}
                  // onBlur={ ( e ) => {
                  //   setFieldTouched('tempo_servico',true);
                  // } }
                  showError={errors.tempo_servico}
                  error={errors.tempo_servico && touched.tempo_servico}
                  helperText={errors.tempo_servico}
                  />
                </Grid>
              </>
            :
            null
          }

          <Grid item xs={12} md={6}>
            <CampoSugestoes
            options={context.state.profissoes}
            // onSuggestionSelected={
            //   (value)=>{
            //     setFieldValue('cargo',value);
            //   }
            // }
            inputProps={{
              className:"campo-texto",
              name: 'cargo',
              id: 'cargo',
              label:"Profissão",
              value: values.cargo || "",
              placeholder: 'Profissão',
              error: errors.cargo,
              helperText: errors.cargo,
              onChange: (event, { newValue }) => {
                handleChange(event);
                setFieldValue('cargo',newValue);
              },
            }}
          />
          </Grid>

          <Grid item xs={12} md={6}>
            <CampoDinheiro
              style={{zIndex: 0}}
              label="Renda Mensal"
              name="renda"
              id="renda"
              value={values.renda}
              onChange={(e)=>{
                setFieldValue('renda',e.target.value);
              }}
              error={errors.renda && touched.renda}
              // showError={errors.renda}
              helperText={errors.renda}
            />
          </Grid>
        </Grid>

        <div className={props.classes.buttonUpdateData}>
            {
              errors?
              renderFeedbackErros(errors)
              : null
            }
            {
            context.state.loadingPersonalDataUpdate ? <CircularProgress /> : <>
              <Button style={{color: 'white', margin: 10}} variant="contained" color="primary" onClick={handleSubmit}>Atualizar Dados</Button>
            </>
            }
        </div>
        <Grid container direction="column" justifyContent="center" alignItems="center" ref={refDeleteData}>
          <Grid item xs={12}>
            <Button style={{color: 'red', margin: 10}} color="secondary" onClick={() => setOpenModalConfirmDeleteData(true)}>Excluir meus dados cadastrais</Button>
          </Grid>
        </Grid>
      </Form>
    )
  }
  
  const handleDeleteData = async () => {
    
    setDeleteDataButton(true)

    const token = await context.getFirebaseToken();

    const response = await axios.post(
      `${context.state.API_ENDPOINT}/leads/${context.state.values.userId}/delete-all-data`,
      undefined,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      
    setDeleteDataButton(false)
    context.handleLogoutUser()
        
  }

  const handleClosefeedBackDeleteData = () => {
    setFeedbackDeleteDataMessage('')
    setFeedbackDeleteDataType('')
    setOpenFeedbackDeleteData(false)
    setOpenModalConfirmDeleteData(false)
    console.log(context.state)
    // context.state.changeState()
  }

  const renderFeedbackErros = (errors) => {
    if (!(Object.keys(errors).length === 0 && Object.getPrototypeOf(errors) === Object.prototype)) {
      return <>
        {Object.values(errors).map((key, index) => 
          <>
            <p style={{color: 'red'}}>{key}</p>
          </>
        )}
      </> 
    }
  }

  const handleCloseSnackbar = () => {
    context.changeState({showPersonalDataUpdateStatus: false, personalDataUpdateStatus: ''})
  }

  if(!context.state.firebaseLoaded) return null;
  if (context.state.personalDataUpdateStatus === 'success') {
    const Coordinates = refStartPage.current.getBoundingClientRect();
    window.scrollTo({ top: Coordinates, behavior: 'smooth' });
  }

  const redirectToDeleteData = () => {
    const Coordinates = refDeleteData.current.getBoundingClientRect();
    window.scrollTo({top: Coordinates.y, behavior: 'smooth'});
  }

  return (
    <>
      <Header />
      <Content>
        <Breadcrumb actualPage="Dados Cadastrais" hierarchyPages={[{namePage: 'Área Logada', route: '/area-logada'}]}/>
        <Typography variant="h4" ref={refStartPage}>Dados Cadastrais</Typography>

        <Formik initialValues={context.state.values} onSubmit={(values) => {
          context.submitFormAtualizaDados(values)
        }} component={FormularioMeusDados} validationSchema={ValidationsSchemaLoggedArea} />

        {
          context.state.personalDataUpdateStatus === 'warning' && 
            <Snackbar open={context.state.showPersonalDataUpdateStatus} autoHideDuration={3000} onClose={() => handleCloseSnackbar()}>
              <Alert onClose={() => handleCloseSnackbar()} severity={context.state.personalDataUpdateStatus}>Erro ao atualizar dados cadastrais na base de dados. Entre em contato com a Quero Financiar</Alert>  
            </Snackbar>
        }

        <Snackbar open={openFeedbackDeleteData} autoHideDuration={3000} onClose={() => handleClosefeedBackDeleteData()}>
          <Alert onClose={() => handleCloseSnackbar()} severity={feedbackDeleteDataType}>{feedbackDeleteDataMessage}</Alert>  
        </Snackbar>

        <Dialog
          open={openModalConfirmDeleteData}
          onClose={() => setOpenModalConfirmDeleteData(false)}
        >
          <DialogTitle>Tem certeza que deseja excluir?</DialogTitle>
          <DialogContent>
            <DialogContentText>Com a sua confirmação, nós da Quero Financiar iremos excluir todos seus dados cadastrais de nosso banco de dados.</DialogContentText>
            <DialogContentText>Sendo assim, não enviaremos mais nenhum comunicado para seu e-mail e nem entraremos em contato com você para oferecer nenhum produto.</DialogContentText>
          </DialogContent>
          <DialogActions>
            {
              !deleteDataButton && <Button onClick={() => setOpenModalConfirmDeleteData(false)} style={{color: 'white'}} variant="contained" color="primary">Não quero excluir</Button>
            }
            {
              deleteDataButton ? <Button disabled color="primary" autoFocus><CircularProgress/></Button> : <Button onClick={() => handleDeleteData()} color="primary" autoFocus>Quero excluir meus dados cadastrais</Button>
            }
          </DialogActions>
        </Dialog>

        <Dialog
          open={context.state.showPersonalDataUpdateStatus && context.state.personalDataUpdateStatus === 'success'}
          onClose={() => handleCloseSnackbar()}
        >
          <DialogTitle>Seus dados foram atualizados com sucesso!</DialogTitle>
          <DialogContent>
            <DialogContentText>Seus dados foram atualizados com sucesso na nossa base de dados. Obrigado por manter seu cadastro atualizado.</DialogContentText>
            <DialogContentText>Você será redirecionado para a página principal.</DialogContentText>
            {/* <DialogContentText>Sendo assim, não enviaremos mais nenhum comunicado para seu e-mail e nem entraremos em contato com você para oferecer nenhum produto.</DialogContentText> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              handleCloseSnackbar()
              history.push('/area-logada')
            }} style={{color: 'white'}} variant="contained" color="primary">entendi</Button>
          </DialogActions>
        </Dialog>

      </Content>
      <Footer />
    </>
  )
}

export default withTheme(withStyles(styles)(DadosCadastrais));