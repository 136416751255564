import React from 'react'
import { Form, useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import FormActions from '../FormActions';
import FormContext from '../FormContext';
import Aviso from '../Aviso';
import CampoSelectNative from '../fields/CampoSelectNative';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const styles = theme => ({
  etapaTitulo: {
  },
});

const EtapaVeiculoAno = (props) => {
  
  const context = React.useContext(FormContext)
  const theme = useTheme()
  const history = useHistory()
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext()
  const { fields, etapa } = props
  const [isBusy, setIsBusy] = React.useState(false)

  // const checkVehicleYear = async (year) => {
  //   setIsBusy(true)
  //   const result = await context.checkVehicleYear(year)
  //   setIsBusy(false)
  // }

  const init = async () => {
    setIsBusy(true)
    const brands = await context.getVehicleYears()
    context.changeState({
      veiculo_ano_options: brands,
    })
    setIsBusy(false)
  }

  React.useEffect(()=>{
    init()
  },[])

  return (
    <Form>
      <CampoSelectNative
        options={context.state.veiculo_ano_options || []}
        name="veiculo_ano"
        label="Qual o ano do modelo de seu veículo? *"
        value={values.veiculo_ano}
        error={errors.veiculo_ano}
        touched={touched.veiculo_ano}
        helperText={errors.veiculo_ano && touched.veiculo_ano ? errors.veiculo_ano : ''}
        onChange={ async (event, data) => {
          console.log("veiculo_ano data",data)
          setFieldValue('veiculo_ano', data.value);
          setFieldValue('veiculo_marca', '');
          setFieldValue('veiculo_modelo', '');
          setFieldValue('veiculo_versao', '');

          // await checkVehicleYear(data.value)

          setIsBusy(false)

        }}
      />
      
      <FormActions isSubmitting={ isSubmitting } disabled={ etapa!=context.state.etapa || isBusy } />
      
      <Aviso errors={errors} touched={touched}/>
    </Form>
  )
};

export default withStyles(styles)(EtapaVeiculoAno);
