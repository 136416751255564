import React from 'react'
import { Form, useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import FormContext from '../FormContext';
import FormActions from '../FormActions';
import CampoOpcoes from '../fields/CampoOpcoes';
import CampoSelect from '../fields/CampoSelect';
import Aviso from '../Aviso';
import CampoTexto from '../fields/CampoTexto';
import CampoDinheiro from '../fields/CampoDinheiro';
import CampoCnpj from '../fields/CampoCnpj';
// import CNPJ from "@fnando/cnpj/dist/node";
// import ProfessionList from '../lib/ProfessionList';
// import { stripRenda } from '../lib/Utils';

const styles = theme => ({
  etapaTitulo: {
  },
});

const EtapaCreditoPessoalDadosProfissionais = (props) => {
  const context = React.useContext(FormContext);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const { fields, etapa } = props;
  return (
    <Form>
      {
        fields.includes('ocupacao') &&
        <CampoOpcoes
          label="Qual é sua ocupação? *"
          name="ocupacao"
          value={values.ocupacao}
          touched={touched.ocupacao}
          showError={errors.ocupacao}
          options={[
            {label:'Empregado', value:'Empregado Setor Privado'},
            {label:'Empregado Setor Público', value:'Empregado Setor Público'},
            {label:'Profissional Liberal', value:'Profissional Liberal'},
            {label:'Empresário', value:'Empresário'},
            {label:'Aposentado ou Pensionista', value:'Aposentado ou Pensionista'},
            {label:'Autônomo', value:'Autônomo'},
            {label:'Outro', value:'Outro'},
          ]}
          classNamePrefix="quero-financiar-select"
          className="campo-selecao"
          onFocus={ props.changeFocus }
          onChange={ ( data ) => {
            setFieldValue('ocupacao', data.value);
            // context.changeValues({
            //   ocupacao:value
            // });
          } }
          onBlur={ ( e ) => {
            setFieldTouched('ocupacao',true);
          } }
        />
      }
      {
        fields.includes('cargo') &&
        <CampoSelect
          name="cargo"
          label="Qual sua profissão? *"
          value={ values.cargo }
          error={errors.cargo}
          touched={touched.cargo}
          onChange={ ( data ) => {
            setFieldValue('cargo', data.value);
            // context.changeValues({
            //   cargo:value
            // });
          }}
          onBlur={ ( e ) => {
            handleBlur(e);
            setFieldTouched('cargo',true);
          } }
          options={ context.state.profissoes }
        />
      }
      {
        values.ocupacao=="Aposentado ou Pensionista" && fields.includes('aposentado_num_beneficio') &&
        <CampoTexto
          label="Número do benefício *"
          name="aposentado_num_beneficio"
          onFocus={ props.changeFocus }
          onBlur={ (e)=>{
            handleBlur(e);
          } }
          value={values.aposentado_num_beneficio}
          onChange={handleChange}
          error={errors.aposentado_num_beneficio && touched.aposentado_num_beneficio}
          helperText={errors.aposentado_num_beneficio && touched.aposentado_num_beneficio ? errors.aposentado_num_beneficio : ''}
        />
      }

      {
        (values.ocupacao=="Empregado Setor Privado" || values.ocupacao=="Empregado Setor Público") &&
        <React.Fragment>
          {
            fields.includes('empresa') &&
            <CampoTexto
              label="Nome da empresa *"
              name="empresa"
              // placeholder="Quero Financiar"
              onFocus={ props.changeFocus }
              value={values.empresa}
              onChange={handleChange}
              onBlur={ (e)=>{
                handleBlur(e);
              } }
              error={errors.empresa && touched.empresa}
              helperText={errors.empresa && touched.empresa ? errors.empresa : ''}
            />
          }
        </React.Fragment>
      }
      
      {
        (values.ocupacao=="Autônomo" || values.ocupacao=="Profissional Liberal") &&
        <React.Fragment>
          {
            fields.includes('tem_cnpj') &&
            <CampoOpcoes
              label="Você possui CNPJ? *"
              name="tem_cnpj"
              showError={errors.tem_cnpj}
              classNamePrefix="quero-financiar-select"
              className="campo-selecao"
              value={values.tem_cnpj}
              touched={touched.tem_cnpj}
              onChange={ ( data ) => {
                setFieldValue('tem_cnpj', data.value);
                if(data.value=="Não") setFieldValue('cnpj',undefined)
              } }
              onBlur={ ( value ) => { setFieldTouched('tem_cnpj',true); } }
              options={[
                {label:'Sim',   value:'Sim'},
                {label:'Não',   value:'Não'},
              ]}
            />
          }
          {
            values.tem_cnpj=="Sim" &&
            <React.Fragment>
              {
                fields.includes('cnpj') &&
                <CampoCnpj
                  label="CNPJ da sua empresa *"
                  name="cnpj"
                  onFocus={ props.changeFocus }
                  onBlur={ (e)=>{
                    handleBlur(e);
                  } }
                  onChange={ handleChange }
                  value={values.cnpj}
                  error={errors.cnpj && touched.cnpj}
                  helperText={errors.cnpj && touched.cnpj ? errors.cnpj : ''}
                />
              }
              {
                fields.includes('empresa') &&
                <CampoTexto
                  label="Nome da empresa *"
                  name="empresa"
                  // placeholder="Quero Financiar"
                  onFocus={ props.changeFocus }
                  value={values.empresa}
                  onChange={handleChange}
                  onBlur={ (e)=>{
                    handleBlur(e);
                  } }
                  error={errors.empresa && touched.empresa}
                  helperText={errors.empresa && touched.empresa ? errors.empresa : ''}
                />
              }
            </React.Fragment>
          }
        </React.Fragment>
      }
    
      {
        values.ocupacao=="Empresário" &&
        <React.Fragment>
          {
            fields.includes('cnpj') &&
            <CampoCnpj
              label="CNPJ da sua empresa *"
              name="cnpj"
              onFocus={ props.changeFocus }
              onBlur={ (e)=>{
                handleBlur(e);
              } }
              onChange={ handleChange }
              value={values.cnpj}
              error={errors.cnpj && touched.cnpj}
              helperText={errors.cnpj && touched.cnpj ? errors.cnpj : ''}
            />
          }
          {
            fields.includes('empresa') &&
            <CampoTexto
              label="Nome da empresa *"
              name="empresa"
              // placeholder="Quero Financiar"
              onFocus={ props.changeFocus }
              value={values.empresa}
              onChange={handleChange}
              onBlur={ (e)=>{
                handleBlur(e);
              } }
              error={errors.empresa && touched.empresa}
              helperText={errors.empresa && touched.empresa ? errors.empresa : ''}
            />
          }
        </React.Fragment>
      }
      {
        fields.includes('renda') &&
        <CampoDinheiro
          label="Renda mensal *"
          name="renda"
          value={values.renda}
          onFocus={ props.changeFocus }
          onBlur={ (e)=>{
            handleBlur(e);
          } }
          onChange={ handleChange }
          error={errors.renda && touched.renda}
          helperText={errors.renda && touched.renda ? errors.renda : ''}
        />
      }
      {
        fields.includes('patrimonio') &&
        <CampoDinheiro
          label="Patrimônio *"
          name="patrimonio"
          value={values.patrimonio}
          onFocus={ props.changeFocus }
          onBlur={ handleBlur }
          onChange={ handleChange }
          error={errors.patrimonio}
          helperText={errors.patrimonio}
        />
      }
      <FormActions isSubmitting={ isSubmitting } disabled={ etapa!=context.state.etapa }  />
      <Aviso errors={errors} touched={touched}/>
    </Form>
  );
}

export default withStyles(styles)(EtapaCreditoPessoalDadosProfissionais);