import React from 'react';
import MaskedInput from 'react-text-mask'
import TextField from '@material-ui/core/TextField';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      type="tel"
      mask={(rawValue)=>{
        return [/\d/,/\d/,'/',
                /\d/,/\d/,'/',
                /\d/,/\d/,/\d/,/\d/];
      }}
      placeholder="__/__/____"
      guide={true}
    />
  );
}

const CampoData = (props) => {
  
  return (
    <div className="campo-wrapper">
      <TextField
        {...props}
        InputProps={{
          inputComponent: TextMaskCustom,
        }}
        variant="outlined"
        fullWidth
      />
    </div>
  );

}

export default CampoData