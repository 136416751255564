import currency from 'currency.js';

export function stripRenda(value){
  return currency(value,{
    symbol: 'R$ ', decimal: ',', separator: '.'
  }).value;
}

export const formatFloatToCurrency = (value) => {
  return currency(value,{
    symbol: 'R$ ', decimal: ',', separator: '.'
  }).format();
}

export const formatPorcentage = (value) => {
  return currency(value,{
    symbol: '', decimal: ',', separator: '.', precision: 2
  }).format();
}