import React from 'react'
import { useFormikContext,  Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import FormActions from '../FormActions';
import FormContext from '../FormContext';
import CampoCep from '../fields/CampoCep';
import CampoSugestoes from '../fields/CampoSugestoes';
import CampoTexto from '../fields/CampoTexto';
import CampoTelefone from '../fields/CampoTelefone';
import CampoOpcoes from '../fields/CampoOpcoes';
import Aviso from '../Aviso';
import CampoEmail from '../fields/CampoEmail';
import CampoEstado from '../fields/CampoEstado';

const styles = theme => ({
  campoEndereco:{
    [theme.breakpoints.up(1024)]:{
      width: '48% !important',
      marginRight: '2% !important',
    }
  },
  campoComplementoResidencia:{
    [theme.breakpoints.up(1024)]:{
      width: '20% !important',
      // marginRight: '2% !important',
    }
  },
  campoNumeroResidencia:{
    [theme.breakpoints.up(1024)]:{
      width: '28% !important',
      marginRight: '2% !important',
    }
  },
});

const EtapaOndeEncontrar = (props) => {
  const context = React.useContext(FormContext);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const { fields, etapa } = props;
  const [cepLoaded, setCepLoaded] = React.useState(false)

  React.useEffect(()=>{
    if(values.cep) setCepLoaded(true)
  },[])
  
  return (
    <Form>
      {
        fields.includes('nome') &&
        <CampoTexto
          label="Qual é seu nome completo? *"
          name="nome"
          placeholder="José da Silva"
          onFocus={ props.changeFocus }
          value={values.nome}
          onChange={handleChange}
          onBlur={ (e)=>{
            // context.changeValues({
            //   nome:e.target.value
            // });
            handleBlur(e);
          } }
          error={errors.nome && touched.nome}
          helperText={errors.nome && touched.nome ? errors.nome : ''}
        />
      }
      {
        fields.includes('celular') &&
        <CampoTelefone
          label="Qual é seu celular? *"
          name="celular"
          onFocus={ props.changeFocus }
          onBlur={ (e)=>{
            // context.changeValues({
            //   celular:e.target.value
            // });
            handleBlur(e);
          } }
          onChange={ handleChange }
          value={values.celular}
          error={errors.celular && touched.celular}
          helperText={errors.celular && touched.celular ? errors.celular : ''}
        />
      }
      {
        fields.includes('email') &&
        <CampoEmail
          label="Seu email? *"
          name="email"
          placeholder="josesilva@email.com.br"
          onFocus={ props.changeFocus }
          onBlur={ (e)=>{
            // context.changeValues({
            //   email:e.target.value
            // });
            handleBlur(e);
          } }
          value={values.email}
          onChange={handleChange}
          error={errors.email && touched.email}
          helperText={errors.email && touched.email ? errors.email : ''}
        />
      }
      {
        fields.includes('cep') &&
        <CampoCep
          label="Seu CEP? *"
          name="cep"
          value={values.cep}
          onBlur={ (e)=> {
            handleBlur(e);
            const regx = /[\(\)_-]/g;
            if(e.target.value.replace(regx,'').length==8){
              context.buscarCep(e.target.value).then((changes=>{
                setCepLoaded(true)
                setFieldValue('endereco', changes.endereco);
                setFieldValue('bairro', changes.bairro);
                setFieldValue('estado', changes.estado);
                setFieldValue('cidade', changes.cidade);
                props.updateResize()
              }));
            }
          }}
          onChange={
            (e)=> {
                handleChange(e);
                const regx = /[\(\)_-]/g;
                if(e.target.value.replace(regx,'').length==8){
                  context.buscarCep(e.target.value).then((changes=>{
                    setCepLoaded(true)
                    setFieldValue('endereco', changes.endereco);
                    setFieldValue('bairro', changes.bairro);
                    setFieldValue('estado', changes.estado);
                    setFieldValue('cidade', changes.cidade);
                    props.updateResize()
                  }));
                }
            }
          }
          error={errors.cep && touched.cep}
          helperText={errors.cep && touched.cep ? errors.cep : ''}
        />
      }

      {
        cepLoaded &&
        <>
          {
          fields.includes('estado') &&
          <CampoEstado
            name="estado"
            label="Estado *"
            value={ values.estado }
            error={errors.estado}
            touched={touched.estado}
            onChange={ async ( data ) => {
              setFieldValue('estado', data.value);
              const cities = await context.getBrazilianCitiesByState(data.value)
              context.changeState({
                cidade_options: cities
              });
            }}
            onBlur={ ( e ) => {
              handleBlur(e);
              setFieldTouched('estado',true);
            } }
          />
        }
        {
          fields.includes('cidade') &&
          <CampoSugestoes
            options={context.state.cidade_options}
            onSuggestionSelected={
              (value)=>{
                setFieldValue('cidade',value);
                // context.changeValues({
                //   cidade: value
                // });
              }
            }
            inputProps={{
                className:"campo-texto",
                name: 'cidade',
                label:"Cidade *",
                value: values.cidade || "",
                placeholder: 'Cidade',
                error: errors.cidade && touched.cidade,
                helperText: errors.cidade && touched.cidade ? errors.cidade : '',
                onChange: (event, { newValue }) => {
                    handleChange(event);
                    setFieldValue('cidade',newValue);
                    // context.changeValues({
                    //   cidade:newValue
                    // });
                },
                onBlur: (event, { newValue }) => {
                    handleBlur(event);
                    setFieldTouched('cidade',true);
                },
            }}
          />
        }
        {
          fields.includes('bairro') &&
          <CampoTexto
            wrapperClassName={props.classes.campoBairro}
            label="Bairro *"
            name="bairro"
            onFocus={ props.changeFocus }
            onBlur={ (e)=>{
              // context.changeValues({
              //   bairro:e.target.value
              // });
              handleBlur(e);
            } }
            value={values.bairro}
            onChange={handleChange}
            error={errors.bairro && touched.bairro}
            helperText={errors.bairro && touched.bairro ? errors.bairro : ''}
          />
        }
        {
          fields.includes('endereco') &&
          <CampoTexto
            wrapperClassName={props.classes.campoEndereco}
            label="Endereço Residencial *"
            name="endereco"
            onFocus={ props.changeFocus }
            onBlur={ (e)=>{
              // context.changeValues({
              //   endereco:e.target.value
              // });
              handleBlur(e);
            } }
            value={values.endereco}
            onChange={handleChange}
            error={errors.endereco && touched.endereco}
            helperText={errors.endereco && touched.endereco ? errors.endereco : ''}
          />
        }
        {
          fields.includes('numero') &&
          <CampoTexto
            wrapperClassName={props.classes.campoNumeroResidencia}
            label="Número *"
            name="numero"
            onFocus={ props.changeFocus }
            onBlur={ (e)=>{
              // context.changeValues({
              //   numero:e.target.value
              // });
              handleBlur(e);
            } }
            value={values.numero}
            onChange={handleChange}
            error={errors.numero && touched.numero}
            helperText={errors.numero && touched.numero ? errors.numero : ''}
          />
        }
        {
          fields.includes('complemento') &&
          <CampoTexto
            wrapperClassName={props.classes.campoComplementoResidencia}
            label="Complemento"
            name="complemento"
            onFocus={ props.changeFocus }
            onBlur={ (e)=>{
              // context.changeValues({
              //   complemento:e.target.value
              // });
              handleBlur(e);
            } }
            value={values.complemento}
            onChange={handleChange}
            error={errors.complemento && touched.complemento}
            helperText={errors.complemento && touched.complemento ? errors.complemento : ''}
          />
        }
        </>
      }

      {
        fields.includes('tempo_residencia') &&
        <CampoOpcoes
          label="A quanto tempo você mora neste endereço? *"
          name="tempo_residencia"
          value={values.tempo_residencia}
          touched={touched.tempo_residencia}
          showError={errors.tempo_residencia}
          onChange={ ( data ) => {
            setFieldValue('tempo_residencia',data.value);
            // context.changeValues({
            //   tempo_residencia:value
            // });
          } }
          onBlur={ ( value ) => { setFieldTouched('tempo_residencia',true); } }
          options={[    
            {label:'Menos de 6 meses', value:'Menos de 6 meses'},
            {label:'Entre 6 meses e 1 ano', value:'Entre 6 meses e 1 ano'},
            {label:'Entre 1 e 2 anos', value:'Entre 1 e 2 anos'},
            {label:'Entre 2 e 3 anos', value:'Entre 2 e 3 anos'},
            {label:'Entre 3 e 4 anos', value:'Entre 3 e 4 anos'},
            {label:'Mais de 4 anos', value:'Mais de 4 anos'},
          ]}
          onFocus={ props.changeFocus }
        />
      }
      {
        fields.includes('tel') &&
        <CampoTelefone
          label="Qual seu telefone fixo residencial?"
          name="tel"
          onFocus={ props.changeFocus }
          onBlur={ (e)=>{
            // context.changeValues({
            //   tel:e.target.value
            // });
            handleBlur(e);
          } }
          onChange={ handleChange }
          value={values.tel}
          error={errors.tel && touched.tel}
          helperText={errors.tel && touched.tel ? errors.tel : ''}
        />
      }
      {
        fields.includes('tipo_casa') &&
        <CampoOpcoes
          label="Tipo de casa *"
          name="tipo_casa"
          showError={errors.tipo_casa}
          classNamePrefix="quero-financiar-select"
          className="campo-selecao"
          value={values.tipo_casa}
          touched={touched.tipo_casa}
          onChange={ ( value ) => {
            setFieldValue('tipo_casa',value);
            // context.changeValues({
            //   tipo_casa:value
            // });
          } }
          onBlur={ ( e ) => {
            setFieldTouched('tipo_casa',true);
          } }
          options={[
            {label:'Própria Financiada', value:'Própria Financiada'},
            {label:'Própria Quitada', value:'Própria Quitada'},
            {label:'Alugada', value:'Alugada'},
          ]}
          placeholder="Digite ou selecione uma opção"
        />
      }
      {/* {
        fields.includes('tipo_residencia') &&
        <CampoOpcoes
          label="Tipo da residência *"
          name="tipo_residencia"
          showError={errors.tipo_residencia}
          classNamePrefix="quero-financiar-select"
          className="campo-selecao"
          value={values.tipo_residencia}
          touched={touched.tipo_residencia}
          onChange={ ( data ) => {
            setFieldValue('tipo_residencia', data.value);
          }}
          onBlur={ ( e ) => {
            setFieldTouched('tipo_residencia',true);
          }}
          options={[
            {label:'Próprio', value:'Próprio'},
            {label:'Familiar', value:'Familiar'},
            {label:'Alugado', value:'Alugado'},
            {label:'Não informado', value:'Não informado'},
            {label:'Próprio Financiado', value:'Próprio Financiado'},
            {label:'Funcional', value:'Funcional'},
            {label:'Outros', value:'Outros'},
            {label:'Cedido', value:'Cedido'},
          ]}
          placeholder="Digite ou selecione uma opção"
        />
      } */}
      {/* {
        fields.includes('qtdeMesesResidencia') &&
        <CampoNumero
          label="Quantidade de meses na residência *"
          name="qtdeMesesResidencia"
          inputProps={{ maxLength: 4 }}
          onFocus={ props.changeFocus }
          onBlur={ (e)=>{
            // context.changeValues({
            //   complemento:e.target.value
            // });
            handleBlur(e);
          }}
          value={values.qtdeMesesResidencia}
          onChange={handleChange}
          error={errors.qtdeMesesResidencia}
          helperText={errors.qtdeMesesResidencia}
        />
      } */}
      <FormActions isSubmitting={ isSubmitting } disabled={ etapa!=context.state.etapa }  />
      <Aviso errors={errors} touched={touched}/>
    </Form>
  )
}

export default withStyles(styles)(EtapaOndeEncontrar);