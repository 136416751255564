import React, {useState, useEffect, useContext} from 'react';

import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core/styles';
import {
  CircularProgress, Button
} from '@material-ui/core';
import Loading from './Loading';
import FormContext from './FormContext';

const styles = theme => ({
  continuar: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    padding: `${theme.spacing(1.2)}px ${theme.spacing(5)}px`,
  },
  rodape:{
    margin: `${theme.spacing(2)}px auto`,
  },
});

const FormActions= (props) => {

  const context = useContext(FormContext);

  return (
    <div className={props.classes.rodape} style={props.style}>
      <Button
        type="submit"
        onClick={(e) => {
          setTimeout(()=>{context.changeValues({});},1);
          if(props.onClick) props.onClick(e);
        }}
        disabled={context.state.loadingNextStep || props.disabled}
        variant={`${props.variant ? props.variant : 'contained'}`}
        color="primary"
        className={`${props.className ? props.className : props.classes.continuar}`}
      >
        {props.label ? props.label : 'Próximo passo'}
      </Button>
      {/* <Loading visible={context.state.loadingNextStep} style={{ position: 'absolute' }} /> */}
      {context.state.loadingNextStep && <div style={{display: 'flex', position:'relative', left: 70, top: 20}}><CircularProgress/></div>}
    </div>
  );
}

export default withTheme(withStyles(styles)(FormActions));
