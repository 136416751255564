import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/styles";
import axios from 'axios';
import { 
  Grid, Typography, withTheme, Container, DialogTitle, DialogContentText, DialogContent, DialogActions, Dialog, Button
} from "@material-ui/core";

import FormContext from '../../components/FormContext';
import Breadcrumb from '../../components/Breadcrumb'
import Header from "../../components/logged-area/Header"
import Footer from "../../components/logged-area/Footer"
import Card from '../../components/Card'

import EmprestimoGarantiaVeiculo from '../../assets/img/products/car-insurance.png'
// import EmprestimoFinanZero from '../../assets/img/partners/finanzero-logo-rounded.png'
import Cash from '../../assets/img/cash.png'
// import EmprestimoGarantiaImovel from '../../assets/img/house.png'
// import EmprestimoConstrucao from '../../assets/img/construction.png'
// import EmprestimoPessoalBC from '../../assets/img/partners/bpc.png'
import EmprestimoConsignado from '../../assets/img/money-house.png'
// import EmprestimoClickCash from '../../assets/img/partners/click-cash.png'
import Content from "../../components/Content";
// import FinanciamentoImobiliario from '../../assets/img/contract.png'

const styles = (theme) => ({
  root: {
    // margin: '20px 0px 20px 0px',
  },
  noResultsContainer:{
    textAlign: `center`,
    margin: `${theme.spacing(4)}px auto`,
  },
  noResultsImg:{
    width: 128,
    marginBottom: theme.spacing(2),
  }
});

const SolicitarNovoEmprestimo = (props) => {

  const context = React.useContext(FormContext);
  const [openEmprestimoImovelGarantia, setOpenEmprestimoImovelGarantia] = useState(false)
  const [openEmprestimoConstrucao, setOpenEmprestimoConstrucao] = useState(false)

  return (
    <>
      <Header />
      <Content>
        <Breadcrumb actualPage="Solicitar Novo Empréstimo" hierarchyPages={[{namePage: 'Área Logada', route: '/area-logada'}]}/>
        
        <Typography variant="h4" gutterBottom>Solicitar Novo Empréstimo</Typography>
        
        <Grid container spacing={4} className={props.classes.root}>
          
          <Card
            type="new-loan-logged-area"
            title="Empréstimo com Veículo em Garantia"
            benefits={['Menores juros do mercado', 'Até 90% do valor do veículo em empréstimo', 'Prazos de até 60 meses', 'Carros, motos, caminhões e utilitários', 'Processo 100% Online', 'Liberação rápida do dinheiro', 'Maiores chances de aprovação']}
            route="/solicitacao-emprestimo-garantia-veiculo"
            image={EmprestimoGarantiaVeiculo}
            imageAlt="Icone empréstimo com garantia de veículo"
          />

          <Card
            type="new-loan-logged-area"
            title="Empréstimo Consignado"
            partner={true}
            benefits={['INSS', 'SIAPE', 'Taxas a partir de 1,30% ao mês', 'Compare taxas do empréstimo consignado em diversos bancos', 'Parceria BxBlue']}
            route="https://bxblue.com.br/pedido/?utm_source=querofinanciar&utm_medium=affiliate&utm_campaign="
            image={EmprestimoConsignado}
            imageAlt="Icone Empréstimo Consignado"
          />

        </Grid>

        {/* //modals */}
        <Dialog
          open={openEmprestimoImovelGarantia}
          onClose={() => setOpenEmprestimoImovelGarantia()}
        >
          <DialogTitle>Empréstimo com Imóvel em Garantia</DialogTitle>
          <DialogContent>
            <DialogContentText>{context.state.values.nome}, agradecemos seu interesse!</DialogContentText>
            <DialogContentText>Em breve um consultor estará entrando em contato com você!</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setOpenEmprestimoImovelGarantia(false)
            }} style={{color: 'white'}} variant="contained" color="primary">entendi</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openEmprestimoConstrucao}
          onClose={() => setOpenEmprestimoConstrucao()}
        >
          <DialogTitle>Empréstimo para Construção</DialogTitle>
          <DialogContent>
            <DialogContentText>{context.state.values.nome}, agradecemos seu interesse!</DialogContentText>
            <DialogContentText>Em breve um consultor estará entrando em contato com você!</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setOpenEmprestimoConstrucao(false)
            }} style={{color: 'white'}} variant="contained" color="primary">entendi</Button>
          </DialogActions>
        </Dialog>       

      </Content>
      <Footer/>
    </>
  );
};

export default withTheme(withStyles(styles)(SolicitarNovoEmprestimo));
