import React from 'react'
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import FormActions from '../FormActions';
import FormContext from '../FormContext';
import Aviso from '../Aviso';
import CampoVeiculoPlaca from '../fields/CampoVeiculoPlaca';
import CampoOpcoes from '../fields/CampoOpcoes';

const styles = theme => ({
  etapaTitulo: {
  },
});

const EtapaVeiculoPlaca = (props) => {
  const context = React.useContext(FormContext);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const { fields, etapa } = props;

  return (
    <Form>
      {
        <CampoOpcoes
          label="Você sabe qual a placa do veículo?"
          name="tem_veiculo_placa"
          value={values.tem_veiculo_placa}
          touched={touched.tem_veiculo_placa}
          showError={errors.tem_veiculo_placa}
          options={[
            {label:"Sim", value:"Sim"},
            {label:"Não", value:"Não"},
          ]}
          classNamePrefix="quero-financiar-select"
          className="campo-selecao"
          onFocus={ props.changeFocus }
          onChange={ (data)=>{
            setFieldValue('tem_veiculo_placa', data.value);
          } }
          onBlur={ ( e ) => {
              setFieldTouched('tem_veiculo_placa',true);
          } }
        />
      }
      {
        values.tem_veiculo_placa=='Sim' &&
        <CampoVeiculoPlaca
          label="Qual é a placa do seu veículo?"
          name="veiculo_placa"
          onFocus={ props.changeFocus }
          onBlur={ (e)=>{
            handleBlur(e);
          } }
          onChange={handleChange}
          value={values.veiculo_placa || ""}
          error={errors.veiculo_placa && touched.veiculo_placa}
          helperText={errors.veiculo_placa && touched.veiculo_placa ? errors.veiculo_placa : ''}
        />
      }
      <FormActions isSubmitting={ isSubmitting } disabled={ etapa!=context.state.etapa }  />
      <Aviso errors={errors} touched={touched}/>
    </Form>
  )
};

export default withStyles(styles)(EtapaVeiculoPlaca);