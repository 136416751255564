import React from 'react'
import { Form, useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import FormActions from '../FormActions';
import FormContext from '../FormContext';
import Aviso from '../Aviso';
import CampoSelectNative from '../fields/CampoSelectNative';
import { Typography } from '@material-ui/core';
import Loading from '../Loading';

const styles = theme => ({
  etapaTitulo: {
  },
});

const EtapaVeiculoModelo = (props) => {
  const context = React.useContext(FormContext);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const [isBusy, setIsBusy] = React.useState(false);

  const init = async () => {
    setIsBusy(true)
    const brands = await context.getVehicleModels()
    context.changeState({
      veiculo_modelo_options: brands,
    })
    setIsBusy(false)
  }

  React.useEffect(()=>{
    init()
  },[])


  return (
    <Form>
      <CampoSelectNative
        options={context.state.veiculo_modelo_options}
        name="veiculo_modelo"
        label="Qual o modelo deste veículo? *"
        value={values.veiculo_modelo}
        error={errors.veiculo_modelo}
        touched={touched.veiculo_modelo}
        helperText={errors.veiculo_modelo && touched.veiculo_modelo ? errors.veiculo_modelo : ''}
        onChange={(event, data) => {
          console.log('==========================> modelo:',data)
          handleChange(event);
          setFieldValue('veiculo_modelo', data && data.value ? data.value : data);
          setFieldValue('veiculo_versao', '');
          

        }}
      />
      {
        isBusy &&
        <div style={{textAlign: `center`, width: `100%`}}>
          <Loading visible={true}/>
        </div>

      }
      {
        <FormActions isSubmitting={ isSubmitting } disabled={ isBusy  }  />
      }
      <Aviso errors={errors} touched={touched}/>
    </Form>
  )
};

export default withStyles(styles)(EtapaVeiculoModelo);
