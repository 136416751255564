import React from 'react'
import {
  Grid, Typography, Button, CircularProgress, DialogTitle, DialogContentText, DialogContent, DialogActions, Dialog
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import FormContext from '../FormContext';
import Botao from '../Botao';

const styles = (theme) => ({
  content: {
    height: 350
  },
  helpText: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  callToActionText: {
    textAlign: "center",
    marginBottom: theme.spacing(),
  },
  button:{
    marginBottom: theme.spacing(3),
  },
});


const EtapaProdutoFGTSInstrucoes = (props) => {
  
  const context = React.useContext(FormContext);
  const { classes } = props;

  return(
    <Grid item container direction="column" xs={12} alignItems='center'>
      <Typography variant="h3" className={classes.helpText}>Para receber a sua oferta, você precisa seguir os seguintes passos:</Typography>
      <Typography className={classes.helpText}>Primeiramente, você precisa baixar o Aplicativo do FGTS em seu celular.</Typography>
      <Typography className={classes.callToActionText}>Clique no botão abaixo para baixar o aplicativo:</Typography>
      <Button
        variant='contained'
        color="secondary"
        className={classes.button}
        href="https://onelink.to/q2x58k"
        target='_blank'
      >
        Baixar Aplicativo Meu FGTS
      </Button>
      <Botao
        style={{ maxWidth: 300, marginBottom: 0 }}
        className={classes.button}
        label="Clique aqui se o aplicativo do FGTS já está instalado em seu celular"
        onClick={ context.nextStepWithoutSubmitForm }
      />
    </Grid>
  )
}

export default withStyles(styles)(EtapaProdutoFGTSInstrucoes);