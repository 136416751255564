import React from 'react'
import { Form, useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import FormActions from '../FormActions';
import FormContext from '../FormContext';
import CampoTexto from '../fields/CampoTexto';
import CampoOpcoes from '../fields/CampoOpcoes';
import Aviso from '../Aviso';
import CampoDinheiro from '../fields/CampoDinheiro';
import CampoCep from '../fields/CampoCep';
import CampoSugestoes from '../fields/CampoSugestoes';

const styles = theme => ({
  campoEndereco:{
    [theme.breakpoints.up(1024)]:{
      width: '58% !important',
      marginRight: '2% !important',
    }
  },
  campoNumeroResidencia:{
    [theme.breakpoints.up(1024)]:{
      width: '18% !important',
      marginRight: '2% !important',
    }
  },
  campoComplementoResidencia:{
    [theme.breakpoints.up(1024)]:{
      width: '20% !important',
    }
  }
});

const EtapaSobreSeuImovel = (props) => {
  const context = React.useContext(FormContext);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const { fields, etapa } = props;
  return (
    <Form>
      <CampoDinheiro
        label="Qual o valor aproximado do seu imóvel? *"
        name="imovel_valor"
        value={values.imovel_valor}
        onFocus={ props.changeFocus }
        onChange={ (e)=>{
          setFieldValue('imovel_valor',e.target.value);
        } }
        onBlur={ (e)=>{
          setFieldTouched('imovel_valor',true);
          handleBlur(e);
        } }
        error={errors.imovel_valor && touched.imovel_valor}
        helperText={errors.imovel_valor && touched.imovel_valor ? errors.imovel_valor : ''}
      />
      <CampoOpcoes
        label="Qual é o tipo do seu imóvel? *"
        name="imovel_tipo"
        value={values.imovel_tipo}
        touched={touched.imovel_tipo}
        showError={errors.imovel_tipo}
        options={[
          {label:'Apartamento', value:'Apartamento'},
          {label:'Casa de Alvenaria', value:'Casa de Alvenaria'},
          {label:'Casa de Madeira', value:'Casa de Madeira'},
          {label:'Casa mista (Alvenaria e Madeira)', value:'Casa mista (Alvenaria e Madeira)'},
          {label:'Imóvel Comercial', value:'Imóvel Comercial'},
          {label:'Terreno', value:'Terreno'},
          {label:'Outro', value:'Outro'},
        ]}
        classNamePrefix="quero-financiar-select"
        className="campo-selecao"
        onFocus={ props.changeFocus }
        onChange={ ( data ) => {
          setFieldValue('imovel_tipo', data.value);
        } }
        onBlur={ ( value ) => {
          setFieldTouched('imovel_tipo',true);
        } }
      />
      <CampoOpcoes
        label="Este imóvel está quitado? *"
        name="imovel_quitado"
        value={values.imovel_quitado}
        touched={touched.imovel_quitado}
        showError={errors.imovel_quitado}
        options={[
          {label:'Sim, está quitado', value:'Sim, está quitado'},
          {label:'Ainda está financiado', value:'Ainda está financiado'},
        ]}
        classNamePrefix="quero-financiar-select"
        className="campo-selecao"
        onFocus={ props.changeFocus }
        onChange={ ( data ) => {
          setFieldValue('imovel_quitado', data.value);
        } }
        onBlur={ ( value ) => {
          setFieldTouched('imovel_quitado',true);
          // this.state.values.imovel_quitado = value;
        } }
      />
      {
        values.imovel_quitado=='Ainda está financiado' &&
        <CampoDinheiro
          label="Qual o valor restante da dívida do seu imóvel? *"
          name="imovel_divida"
          value={values.imovel_divida}
          onFocus={ props.changeFocus }
          onChange={ (e)=>{
            setFieldValue('imovel_divida',e.target.value);
          } }
          onBlur={ (e)=>{
            // context.changeValues({
            //   imovel_divida:e.target.value
            // });
            handleBlur(e);
          } }
          error={errors.imovel_divida && touched.imovel_divida}
          helperText={errors.imovel_divida && touched.imovel_divida ? errors.imovel_divida : ''}
        />
      }
      <CampoOpcoes
        label="Este imóvel está alugado? *"
        name="imovel_alugado"
        value={values.imovel_alugado}
        touched={touched.imovel_alugado}
        showError={errors.imovel_alugado}
        options={[
          {label:'Sim', value:'Sim'},
          {label:'Não', value:'Não'},
        ]}
        classNamePrefix="quero-financiar-select"
        className="campo-selecao"
        onFocus={ props.changeFocus }
        onChange={ ( data ) => {
          setFieldValue('imovel_alugado', data.value);
          props.updateResize()
        } }
        onBlur={ ( value ) => {
          setFieldTouched('imovel_alugado',true);
        } }
      />
      {
        values.imovel_alugado=='Sim' &&
        <CampoDinheiro
          label="Qual o valor de aluguel do imóvel? *"
          name="imovel_aluguel_valor"
          value={values.imovel_aluguel_valor}
          onFocus={ props.changeFocus }
          onChange={ (e)=>{
            setFieldValue('imovel_aluguel_valor',e.target.value);
          } }
          onBlur={ (e)=>{
            // context.changeValues({
            //   imovel_aluguel_valor:e.target.value
            // });
            handleBlur(e);
          } }
          error={errors.imovel_aluguel_valor && touched.imovel_aluguel_valor}
          helperText={errors.imovel_aluguel_valor && touched.imovel_aluguel_valor ? errors.imovel_aluguel_valor : ''}
        />
      }
      <CampoOpcoes
        label="A matrícula do imóvel está em seu nome? *"
        name="imovel_matricula_proprietario"
        value={values.imovel_matricula_proprietario}
        touched={touched.imovel_matricula_proprietario}
        showError={errors.imovel_matricula_proprietario}
        options={[
          {label:'Sim', value:'Sim'},
          {label:'Não', value:'Não'},
        ]}
        classNamePrefix="quero-financiar-select"
        className="campo-selecao"
        onFocus={ props.changeFocus }
        onChange={ ( data ) => {
          setFieldValue('imovel_matricula_proprietario', data.value);
          context.changeValues({ imovel_matricula_proprietario: data.value})
        } }
        onBlur={ ( value ) => {
          setFieldTouched('imovel_matricula_proprietario',true);
        } }
      />
      {
        values.imovel_matricula_proprietario=='Não' &&
        <CampoOpcoes
          label="A matrícula do imóvel consta em nome de quem? *"
          name="imovel_matricula_nome"
          value={values.imovel_matricula_nome}
          touched={touched.imovel_matricula_nome}
          showError={errors.imovel_matricula_nome}
          options={[
            {label:'Cônjuge', value:'Cônjuge'},
            {label:'Pais', value:'Pais'},
            {label:'Antigo dono', value:'Antigo dono'},
          ]}
          classNamePrefix="quero-financiar-select"
          className="campo-selecao"
          onFocus={ props.changeFocus }
          onChange={ ( data ) => {
            setFieldValue('imovel_matricula_nome', data.value);
          } }
          onBlur={ ( value ) => {
            setFieldTouched('imovel_matricula_nome',true);
          } }
        />
      }
      <CampoOpcoes
        label="O imóvel está averbado? *"
        name="imovel_averbado"
        value={values.imovel_averbado}
        touched={touched.imovel_averbado}
        showError={errors.imovel_averbado}
        options={[
          {label:'Sim', value:'Sim'},
          {label:'Não', value:'Não'},
        ]}
        classNamePrefix="quero-financiar-select"
        className="campo-selecao"
        onFocus={ props.changeFocus }
        onChange={ ( data ) => {
          setFieldValue('imovel_averbado', data.value);
        } }
        onBlur={ ( value ) => {
          setFieldTouched('imovel_averbado',true);
        } }
      />
      {
        fields.includes('imovel_igual_residencia') &&
        <CampoOpcoes
          label="Você mora no imóvel que vai dar em garantia? *"
          name="imovel_igual_residencia"
          showError={errors.imovel_igual_residencia}
          classNamePrefix="quero-financiar-select"
          className="campo-selecao"
          value={values.imovel_igual_residencia}
          touched={touched.imovel_igual_residencia}
          onChange={ ( data ) => {
            setFieldValue('imovel_igual_residencia', data.value)
            if(data.value=="Sim"){
              setFieldValue('imovel_cep', context.state.values.cep)
              setFieldValue('imovel_endereco', context.state.values.endereco)
              setFieldValue('imovel_numero', context.state.values.numero)
              setFieldValue('imovel_complemento', context.state.values.complemento)
              setFieldValue('imovel_bairro', context.state.values.bairro)
              setFieldValue('imovel_estado', context.state.values.estado)
              setFieldValue('imovel_cidade', context.state.values.cidade)
            }else{
              setFieldValue('imovel_cep', '')
              setFieldValue('imovel_endereco', '')
              setFieldValue('imovel_numero', '')
              setFieldValue('imovel_complemento', '')
              setFieldValue('imovel_bairro', '')
              setFieldValue('imovel_estado', '')
              setFieldValue('imovel_cidade', '')
            }
            props.updateResize()
          } }
          onBlur={ ( e ) => {
            setFieldTouched('imovel_igual_residencia',true);
          } }
          options={[
            {label:'Sim', value:'Sim'},
            {label:'Não', value:'Não'},
          ]}
        />
      }
      {
        values.imovel_igual_residencia=="Não" &&
        <>
          <CampoCep
            label="CEP do seu imóvel *"
            name="imovel_cep"
            value={values.imovel_cep}
            onBlur={ async (e)=> {
              handleBlur(e);
              const regx = /[\(\)_-]/g;
              if(e.target.value.replace(regx,'').length==8){
                const cepResult = await context.buscarCep(e.target.value)
                setFieldValue('imovel_cep', cepResult.cep);
                setFieldValue('imovel_endereco', cepResult.endereco);
                setFieldValue('imovel_bairro', cepResult.bairro);
                setFieldValue('imovel_estado', cepResult.estado);
                setFieldValue('imovel_cidade', cepResult.cidade);
                const cities = await context.getBrazilianCitiesByState(cepResult.estado)
                context.changeState({ cidade_options: cities });

              }
            }}
            onChange={
              async (e)=> {
                  handleChange(e);
                  const regx = /[\(\)_-]/g;
                  if(e.target.value.replace(regx,'').length==8){
                    const cepResult = await context.buscarCep(e.target.value)
                    setFieldValue('imovel_cep', cepResult.cep);
                    setFieldValue('imovel_endereco', cepResult.endereco);
                    setFieldValue('imovel_bairro', cepResult.bairro);
                    setFieldValue('imovel_estado', cepResult.estado);
                    setFieldValue('imovel_cidade', cepResult.cidade);
                    const cities = await context.getBrazilianCitiesByState(cepResult.estado)
                    context.changeState({ cidade_options: cities });

                  }
              }
            }
            error={errors.imovel_cep && touched.imovel_cep}
            helperText={errors.imovel_cep && touched.imovel_cep ? errors.imovel_cep : ''}
          />
          <CampoTexto
            wrapperClassName={props.classes.campoEndereco}
            label="Endereço do seu imóvel *"
            name="imovel_endereco"
            onFocus={ props.changeFocus }
            onBlur={ (e)=>{
              // context.changeValues({
              //   imovel_endereco:e.target.value
              // });
              handleBlur(e);
            } }
            value={values.imovel_endereco}
            onChange={handleChange}
            error={errors.imovel_endereco && touched.imovel_endereco}
            helperText={errors.imovel_endereco && touched.imovel_endereco ? errors.imovel_endereco : ''}
          />
          <CampoTexto
            wrapperClassName={props.classes.campoNumeroResidencia}
            label="Número *"
            name="imovel_numero"
            onFocus={ props.changeFocus }
            onBlur={ (e)=>{
              // context.changeValues({
              //   imovel_numero:e.target.value
              // });
              handleBlur(e);
            } }
            value={values.imovel_numero}
            onChange={ handleChange }
            error={errors.imovel_numero && touched.imovel_numero}
            helperText={errors.imovel_numero && touched.imovel_numero ? errors.imovel_numero : ''}
          />
          <CampoTexto
            wrapperClassName={props.classes.campoComplementoResidencia}
            label="Complemento"
            name="imovel_complemento"
            onFocus={ props.changeFocus }
            onBlur={ handleBlur }
            value={values.imovel_complemento}
            onChange={ handleChange }
            error={errors.imovel_complemento && touched.imovel_complemento}
            helperText={errors.imovel_complemento && touched.imovel_complemento ? errors.imovel_complemento : ''}
          />
          {
            fields.includes('imovel_bairro') &&
            <CampoTexto
              wrapperClassName={props.classes.campoBairro}
              label="Bairro *"
              name="imovel_bairro"
              onFocus={ props.changeFocus }
              onBlur={handleBlur}
              value={values.imovel_bairro}
              onChange={handleChange}
              error={errors.imovel_bairro && touched.imovel_bairro}
              helperText={errors.imovel_bairro && touched.imovel_bairro ? errors.imovel_bairro : ''}
            />
          }
          {
            fields.includes('imovel_estado') &&
            <CampoEstado
              name="imovel_estado"
              label="Estado *"
              value={ values.imovel_estado }
              error={errors.imovel_estado}
              touched={touched.imovel_estado}
              onChange={ async ( data ) => {
                setFieldValue('imovel_estado', data.value);
                const cities = context.getBrazilianCitiesByState(data.value)
                context.changeState({
                  cidade_options: cities
                });
              }}
              onBlur={ ( e ) => {
                handleBlur(e);
                setFieldTouched('imovel_estado',true);
              } }
            />
          }
          {
            fields.includes('imovel_cidade') &&
            <CampoSugestoes
              options={context.state.cidade_options}
              onSuggestionSelected={
                (value)=>{
                  // context.changeValues({
                  //   imovel_cidade: value
                  // });
                }
              }
              inputProps={{
                  className:"campo-texto",
                  name: 'imovel_cidade',
                  label:"Cidade *",
                  value: values.imovel_cidade,
                  placeholder: 'Cidade',
                  error: errors.imovel_cidade && touched.imovel_cidade,
                  helperText: errors.imovel_cidade && touched.imovel_cidade ? errors.imovel_cidade : '',
                  onChange: (event, { newValue }) => {
                      handleChange(event);
                      setFieldValue('imovel_cidade',newValue);
                      // context.changeValues({
                      //   imovel_cidade:newValue
                      // });
                  },
                  onBlur: (event, { newValue }) => {
                      handleBlur(event);
                      setFieldTouched('imovel_cidade',true);
                  },
              }}
            />
          }
        </>
      }
      {
        fields.includes('ocupacao') &&
        <CampoOpcoes
          label="Qual é sua ocupação? *"
          name="ocupacao"
          showError={errors.ocupacao}
          classNamePrefix="quero-financiar-select"
          className="campo-selecao"
          value={values.ocupacao}
          touched={touched.ocupacao}
          onChange={ ( data ) => {
            setFieldValue('ocupacao', data.value);
          } }
          onBlur={ ( e ) => {
            setFieldTouched('ocupacao',true);
          } }
          options={[
            {label:'Aposentado(a)',                       value:'Aposentado(a)'},
            {label:'Assalariado(a)',                      value:'Assalariado(a)'},
            {label:'Autônomo(a)/ Prof. Liberal',          value:'Autônomo(a)/ Prof. Liberal'},
            {label:'Empresário(a)',                       value:'Empresário(a)'},
            {label:'Servidor público',                    value:'Servidor público'},
          ]}
        />
      }
      {
        fields.includes('renda') &&
        <CampoDinheiro
          label="Qual sua renda mensal? *"
          name="renda"
          value={values.renda}
          onFocus={ props.changeFocus }
          onChange={ (e)=>{
            setFieldValue('renda',e.target.value);
          } }
          onBlur={ (e)=>{
            // context.changeValues({
            //   renda:e.target.value
            // });
            handleBlur(e);
          } }
          error={errors.renda && touched.renda}
          helperText={errors.renda && touched.renda ? errors.renda : ''}
        />
      }
      <FormActions isSubmitting={ isSubmitting } disabled={ etapa!=context.state.etapa }  />
      <Aviso errors={errors} touched={touched}/>
  </Form>
  )
};

export default withStyles(styles)(EtapaSobreSeuImovel);