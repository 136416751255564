'use strict';

import Autosuggest from 'react-autosuggest';
import { ReactAutosuggestStyles } from '../../GlobalStyles';

import TextField from '@material-ui/core/TextField';

const React = require('react');

import FormContext from '../FormContext';

import _ from 'lodash';

class CampoMarca extends React.Component {

  static contextType = FormContext;
  
  constructor(props) {
    super(props);

    this.state = {
      firstUpdate:true,
      value: '',
      suggestions: [],
      current_suggestion: null,
    }

  }

  getFilteredSuggestions(value){
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if(inputLength === 0) return [];
    return this.props.options.filter( (item, index, arr) => ( item.label.toLowerCase().search(inputValue) != -1 ) );

  }

  onSuggestionsFetchRequested = ({ value }) => {
    
    if(value.length<1) return;
    this.setState({suggestions:this.getFilteredSuggestions(value)});

  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  getSuggestionValue(suggestion) {
    return (suggestion.value);
  }

  renderInputComponent(inputProps) {
    return (
      <TextField
        {...inputProps}
        variant="outlined"
        fullWidth
      />
    )
  }

  renderSuggestion(suggestion) {
    return(<span id={'"'+suggestion.label+'"'}>{suggestion.label}</span>);
  }

  onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }){
    console.log('suggestion', suggestion);
    this.props.onSuggestionSelected(event, suggestion);
  }

  render() {

    let propz = this.props.inputProps;

    propz.onBlur = (event, highlightedSuggestion) => {

      let _suggestions = this.getFilteredSuggestions(this.props.value);
      const valueOfSuggestion = _suggestions.find( (item) => {
        return item.value.toLowerCase()==this.props.inputProps.value.toLowerCase()
      });

      if(valueOfSuggestion!=undefined){
        this.props.onBlur(event,valueOfSuggestion);
      }else{
        this.props.onBlur(event,'');
      }

    }

    return (
      <div className="campo-wrapper">
        <Autosuggest
          alwaysVisible={true}
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested.bind(this)}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested.bind(this)}
          getSuggestionValue={this.getSuggestionValue.bind(this)}
          renderSuggestion={this.renderSuggestion.bind(this)}
          renderInputComponent={this.renderInputComponent.bind(this)}
          inputProps={{
            ...propz,
            value: propz.value || "",
          }}
          onSuggestionSelected={this.onSuggestionSelected.bind(this)}
          theme={ReactAutosuggestStyles}
        />
      </div>
    );
  }

}

export default CampoMarca;