import React from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    fontSize: '1.1rem',
  },
});

const CampoLabel = (props) => (
  <Typography className={`${props.classes.root} ${props.className}`}>{props.children}</Typography>
);

export default withStyles(styles)(CampoLabel);