import React from 'react';
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import TextField from '@material-ui/core/TextField';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      type="tel"
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={(rawValue)=>{
          const regx = /[\(\)_-]/g;
          if(rawValue.replace(regx,'').length>10){
              return ['(',/\d/,/\d/,')',/\d/,/\d/,/\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/,/\d/];
          }else{
              return ['(',/\d/,/\d/,')',/\d/,/\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/,/\d/];
          }
          
      }}
      placeholder="(__)____-____"
      guide={true}
    />
  );
}

const CampoTelefone = (props) => {
  
  return (
    <div className="campo-wrapper">
      <TextField
        {...props}
        InputProps={{
          inputComponent: TextMaskCustom,
        }}
        variant="outlined"
        fullWidth
      />
    </div>
  );

}

export default CampoTelefone