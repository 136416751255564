import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  '@keyframes lds-ellipsis1': {
    '0%': {
      transform: 'scale(0)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  '@keyframes lds-ellipsis3': {
    '0%': {
      transform: 'scale(1)',
    },
    '100%': {
      transform: 'scale(0)',
    },
  },
  '@keyframes lds-ellipsis2': {
    '0%': {
      transform: 'translate(0, 0)',
    },
    '100%': {
      transform: 'translate(19px, 0)',
    },
  },
  root:{
    display: 'inline-block',
    position: 'relative',
    width: '64px',
    height: '64px',
    verticalAlign: 'middle',
    '& div': {
      position: 'absolute',
      top: '27px',
      width: '6px',
      height: '6px',
      borderRadius: '50%',
      background: theme.palette.primary.main,
      animationTimingFunction: 'cubic-bezier(0, 1, 1, 0)',
    },
    '& div:nth-child(1)': {
      left: '6px',
      animation: '$lds-ellipsis1 0.6s infinite',
    },
    '& div:nth-child(2)': {
      left: '6px',
      animation: '$lds-ellipsis2 0.6s infinite',
    },
    '& div:nth-child(3)': {
      left: '26px',
      animation: '$lds-ellipsis2 0.6s infinite',
    },
    '& div:nth-child(4)': {
      left: '45px',
      animation: '$lds-ellipsis3 0.6s infinite',
    },
  },
})

const Loading = (props) => (
  <div className={props.classes.root} style={{
    ...props.style, 
    display: (props.visible) ? "inline-block" : "none",
  }}><div></div><div></div><div></div><div></div></div>
)

export default withStyles(styles)(Loading);