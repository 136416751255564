import React from 'react'
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import FormActions from '../FormActions';
import FormContext from '../FormContext';
import Aviso from '../Aviso';
import CampoVeiculoPlaca from '../fields/CampoVeiculoPlaca';
import CampoOpcoes from '../fields/CampoOpcoes';

const styles = theme => ({
  etapaTitulo: {
  },
});

const EtapaAgradecimento = (props) => {
  const context = React.useContext(FormContext);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const { fields, etapa } = props;

  return (
    <h1>Obrigado!</h1>
  )
};

export default withStyles(styles)(EtapaAgradecimento);