import React, { useEffect, useState } from 'react';
import FormContext from '../FormContext';
import { useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import CampoLabel from './CampoLabel';
import Select from 'react-select';

const styles = theme => ({
  select: {
    margin: `${theme.spacing()}px 0`,
  },
});

const CampoSelect = (props) => {
  
  const theme = useTheme();
  const context = React.useContext(FormContext);

  useEffect(()=>{
    if(props.value){
      // console.log("checking select field",props.name,props.value)
      // if( !props.options.find( (item) => props.value==item.value ) ){
      //   props.onChange({label:'',value:''})
      // }
    }
  },[])

  

  return (
    <div className="campo-wrapper">
      <fieldset
        name={props.name}
        onFocus={ props.onFocus }
        className={ (context.campoAtivo==props.name) ? "ativo" : "" }
        style={{ borderColor: props.error && props.touched ? 'red' : '' }}
      >
        {
          props.label &&
          <CampoLabel className="campo-titulo">{props.label}</CampoLabel>
        }
        {/* {
          props.options &&
          <Select
            classNamePrefix="quero-financiar-select"
            className={`campo-selecao ${props.classes.select}`}
            {...props}
            getOptionValue={(option) => option[props.valueField ? props.valueField : 'value']}
            value={ props.options.find( (option) => option[props.valueField ? props.valueField : 'value'] === props.value ) }
            placeholder="Digite ou selecione uma opção"
            styles={{
              menuPortal: base => ({ ...base, zIndex: 9999 }),
              menuList:  base => ({ ...base, maxHeight: 150 }),
            }}
            menuPortalTarget={document.body}
          />
        } */}
         <Select
          classNamePrefix="quero-financiar-select"
          className={`campo-selecao ${props.classes.select}`}
          {...props}
          getOptionValue={(option) => option[props.valueField ? props.valueField : 'value']}
          value={ props.options ? props.options.find( (option) => option[props.valueField ? props.valueField : 'value'] === props.value ) : undefined }
          placeholder="Digite ou selecione uma opção"
          styles={{
            menuPortal: base => ({ ...base, zIndex: 9999 }),
            menuList:  base => ({ ...base, maxHeight: 150 }),
          }}
          menuPortalTarget={document.body}
        />
      </fieldset>
      { props.error && props.touched && <div className="campo-erro">{props.error}</div> }
    </div>
  )
}

export default withStyles(styles)(CampoSelect);