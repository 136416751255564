import React from 'react';
import TextField from '@material-ui/core/TextField';
import MaskedInput from 'react-text-mask'

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
    />
  );
}

const CampoNumero = (props) => {
  const { wrapperClassName, ...rest } = props;
  return (
    <div className={`${props.wrapperClassName} campo-wrapper`}>
      <TextField
        {...rest}
        type="tel"
        value={props.value || ""}
        inputProps={props.inputProps ? props.inputProps : {maxLength: 90}}
        InputProps={props.mask ? {
          inputComponent: TextMaskCustom,
        } : null}
        variant="outlined"
        fullWidth
      />
    </div>
  );

}

export default CampoNumero;