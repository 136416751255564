import React, { useEffect, useContext, useState } from 'react'
import { withStyles } from '@material-ui/core/styles';
import FormContext from '../FormContext';
import CampoLabel from '../fields/CampoLabel';
import {Typography, Grid, CircularProgress, Button, DialogContentText} from '@material-ui/core';
import axios from 'axios';
import ArrowRightAltRounded from '@material-ui/icons/ArrowRightAltRounded';

const styles = theme => ({
  helpText: {
    // maxWidth: 450,
    // margin: '0 auto',
  },
  wrapper: {
    // maxWidth: 450,
    margin: '0 auto',
  },
  sectionSubTitle:{
    marginBottom: theme.spacing()*2,
  },
  sectionWrapper:{
    marginTop: theme.spacing()*3,
    marginBottom: theme.spacing()*3,
    "&:first-child":{
      marginTop: 0,
    }
  },
  button: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    padding: `${theme.spacing(1.2)}px ${theme.spacing(5)}px`,
    marginBottom: theme.spacing(),
  },
  points: {
    position: 'relative',
    top: -8
  }
});

const EtapaProdutoFGTSEnviar = (props) => {

  const context = useContext(FormContext);
  const[error, setError] = useState(false)
  const[loading, setLoading] = useState(false)
  const[success, setSuccess] = useState(false)
  const[errorMessage, setErrorMessage] = useState('')
  const[errorDescription, setErrorDescription] = useState('')
  
  const [dataProposal, setDataProposal] = useState({})
  const [errorCapture, setErrorCapture] = useState(false)
  
  const verifyProposal = async () => {
    
    const token = await context.getFirebaseToken()
    const formatedData = await context.formatValues(context.state.values, 'fgts');
      
    const data = {
      ...formatedData,
      dontSendToPartners: true,
    }

    if (errorCapture) {
      // console.log('if - ja tem dados de proposta')
      return {
        ...dataProposal, ...data, 
      }

    } else {
      // console.log('else - nao tem dados de proposta')
      const respondeIdOrder = await axios.post(`${context.state.API_ENDPOINT}/orders/`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      
      setDataProposal({orderId: respondeIdOrder.data.data})
      
      return {
        ...data, orderId: respondeIdOrder.data.data
      }
    }
  }

  const sendData = async () => {

    setLoading(true)
    setError(false)
    const token = await context.getFirebaseToken()
    const proposalData = await verifyProposal();
    const responseCapturaProposta = await axios.post(`${context.state.API_ENDPOINT}/bv/emprestimo-fgts/captura-proposta`, proposalData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (responseCapturaProposta.data.success !== true) {
      setLoading(false)
      setError(true)
      setErrorCapture(true)
      setDataProposal({
        ...dataProposal,
        zohoDealId:responseCapturaProposta.data.zohoDealId,
        orderId:responseCapturaProposta.data.orderId,
        id: responseCapturaProposta.data.proposalId
      })
      setErrorMessage(responseCapturaProposta.data.message)
      setErrorDescription(responseCapturaProposta.data.description)
      props.updateResize()
    } else {
      setErrorCapture(false)
      setLoading(false)
      setError(false)
      setSuccess(true)
      
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'tela_obrigado'
      });
      
    }
    props.updateResize()
    
  }

  useEffect(() => {
    sendData()
  }, [])
  
  return (
    <>
      <Grid item container direction="column" alignItems="center" xs={12}>
        {
          error &&
          <>
            <Grid item container direction="column" alignItems="center" xs={12}>
              <Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>Houve um erro ao realizar a finalização de proposta por parte do Banco Votorantim</Typography>
                <Typography className={props.classes.helpText2}>{errorMessage}</Typography>
                <Typography className={props.classes.helpText1}>{errorDescription}</Typography>
                <Button variant='contained' color="primary" style={{ color: 'white', marginTop: 20 }} onClick={() => sendData()}>tentar novamente</Button>
            </Grid>
          </>
        }
      </Grid>

      {
        loading && 
          <Grid item container direction="column" alignItems="center" xs={12}>
            <Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>Aguarde um momento</Typography>
            <Typography className={props.classes.helpText}>Estamos enviando seus dados de solicitação para o Banco Votorantim</Typography>
            <CircularProgress />
          </Grid>
      }

      {
        success && loading === false && 
          <div className={`${props.classes.sectionWrapper}`} style={{border:'1px solid #ccc', borderRadius:'4px', padding:'16px', width:'100%', textAlign:'center'}}>
            {/* <Typography variant="h3" gutterBottom>Sua solicitação de empréstimo com Saque FGTS foi enviada para o Banco Votorantim</Typography> */}
            <Typography variant="h3" gutterBottom>Em instantes você receberá um SMS/Email para assinar o contrato digital</Typography>
            <CampoLabel className={`${props.classes.sectionSubTitle} campo-titulo`}>Após a assinatura do contrato digital, o dinheiro será depositado na sua conta em até 1 dia útil.</CampoLabel>
            {/* <br/> */}
            {/* <CampoLabel className={`${props.classes.sectionSubTitle} campo-titulo`}>Acesse sua área logada, acompanhe sua solicitações de empréstimos e conheça outros produtos</CampoLabel> */}
            <Button
              type="submit"
              onClick={() => window.open(encodeURI(`https://api.whatsapp.com/send?phone=${context.state.whatsAppNumber}&text=Olá, meu nome é ${context.state.values.nome} e quero dar continuidade no Empréstimo Saque Aniversário FGTS. Meu e-mail é ${context.state.values.email}`),"_blank")}
              variant="contained"
              color="primary"
              className={`${props.classes.button}`}
            >Clique aqui para finalizar o pedido</Button>
            <br/>
            <DialogContentText><ArrowRightAltRounded/><span className={props.classes.points}>A Quero Financiar nunca solicita depósitos ou taxas antecipadas para liberação do dinheiro!</span></DialogContentText>
            <DialogContentText><ArrowRightAltRounded/><span className={props.classes.points}>O processo de assinatura de contrato e liberação do dinheiro é realizada inteiramente pelo Banco Votorantim</span></DialogContentText>
            {/* <Typography style={{fontSize:14}}>Atenção ¹: A Quero Financiar nunca solicita depósitos ou taxas antecipadas para liberação do dinheiro!</Typography> */}
            {/* <Typography style={{fontSize:14}}>Atenção ²: O processo de assinatura de contrato e liberação do dinheiro é realizada inteiramente pelo Banco Votorantim</Typography> */}
          </div>
      }
    </>
  );
}

export default withStyles(styles)(EtapaProdutoFGTSEnviar);