export const orgao_emissor = [
 {label: 'ABNC - Academia Brasileira de Neurocirurgia', value: 'ABNC'},
 {label: 'AGU - Advocacia-Geral da União', value: 'AGU'},
 {label: 'ANAC - Agência Nacional de Aviação Civil', value: 'ANAC'},
 {label: 'CAER - Clube de Aeronáutica', value: 'CAER'},
 {label: 'CAU - Conselho de Arquitetura e Urbanismo', value: 'CAU'},
 {label: 'CBM - Corpo de Bombeiro Militar', value: 'CBM'},
 {label: 'CFA - Conselho Federal Administração', value: 'CFA'},
 {label: 'CFB - Conselho Federal de Biblioteconomia', value: 'CFB'},
 {label: 'CFBIO - Conselho Federal de Biologia', value: 'CFBIO'},
 {label: 'CFBM - Conselho Federal de Biomedicina', value: 'CFBM'},
 {label: 'CFC - Conselho Federal de Contabilidade', value: 'CFC'},
 {label: 'CFESS - Conselho Federal de Serviço Social', value: 'CFESS'},
 {label: 'CFF - Conselho Regional de Farmácia', value: 'CFF'},
 {label: 'CFFA - Conselho Federal de Fonoaudiologia', value: 'CFFA'},
 {label: 'CFM - Conselho Federal de Medicina', value: 'CFM'},
 {label: 'CFMV - Conselho Federal de Medicina Veterinária', value: 'CFMV'},
 {label: 'CFN - Conselho Federal de Nutrição', value: 'CFN'},
 {label: 'CFO - Conselho Federal de Odontologia', value: 'CFO'},
 {label: 'CFP - Conselho Federal de Psicologia', value: 'CFP'},
 {label: 'CFQ - Conselho Regional de Química', value: 'CFQ'},
 {label: 'CFT - Conselho Federal dos Técnicos Industriais', value: 'CFT'},
 {label: 'CFTA - Conselho Federal dos Técnicos Agrícolas', value: 'CFTA'},
 {label: 'CGPI - Coordenação Geral de Privilégios e Imunidades', value: 'CGPI'},
 {label: 'CGPMAF - Coordenadoria Geral de Polícia Marítima, Aeronáutica e de Fronteiras', value: 'CGPMAF'},
 {label: 'CIPC - Centro de Inteligência da Polícia Civil', value: 'CIPC'},
 {label: 'CNIG - Conselho Nacional de Imigração', value: 'CNIG'},
 {label: 'CNT - Confederação Nacional do Transporte', value: 'CNT'},
 {label: 'CNTV - Confederação Nacional de Vigilantes & Prestadores de Serviços', value: 'CNTV'},
 {label: 'COFECI - Conselho Federal de Corretores de Imóveis', value: 'COFECI'},
 {label: 'COFECON - Conselho Federal de Economia', value: 'COFECON'},
 {label: 'COFEM - Conselho Federal de Museologia', value: 'COFEM'},
 {label: 'COFEN - Conselho Federal de Enfermagem', value: 'COFEN'},
 {label: 'COFFITO - Conselho Regional de Fisioterapia e Terapia Ocupacional', value: 'COFFITO'},
 {label: 'COMAER - Comando da Aeronáutica', value: 'COMAER'},
 {label: 'CONFE - Conselho Federal de Estatística', value: 'CONFE'},
 {label: 'CONFEA - Conselho Federal de Engenharia e Agronomia', value: 'CONFEA'},
 {label: 'CONFEF - Conselho Federal de Educação Física', value: 'CONFEF'},
 {label: 'CONFERE - Conselho Federal dos Representantes Comerciais', value: 'CONFERE'},
 {label: 'CONRE - Conselho Regional de Estatística', value: 'CONRE'},
 {label: 'CONRERP - Conselho Federal de Profissionais de Relações Públicas', value: 'CONRERP'},
 {label: 'CORE - Conselho Regional dos Representantes Comerciais', value: 'CORE'},
 {label: 'CORECON - Conselho Regional de Economia', value: 'CORECON'},
 {label: 'COREM - Conselho Regional de Museologia', value: 'COREM'},
 {label: 'COREN - Conselho Regional de Enfermagem', value: 'COREN'},
 {label: 'CRA - Conselho Regional de Administração', value: 'CRA'},
 {label: 'CRAS - Centro de Referência de Assistência Social', value: 'CRAS'},
 {label: 'CRB - Conselho Regional de Biblioteconomia', value: 'CRB'},
 {label: 'CRBIO - Conselho Regional de Biologia', value: 'CRBIO'},
 {label: 'CRBM - Conselho Regional de Biomedicina', value: 'CRBM'},
 {label: 'CRC - Conselho Regional de Contabilidade', value: 'CRC'},
 {label: 'CREA - Conselho Regional de Engenharia e Agronomia', value: 'CREA'},
 {label: 'CRECI - Conselho Regional de Corretores de Imóveis', value: 'CRECI'},
 {label: 'CREF - Conselho Regional de Educação Física', value: 'CREF'},
 {label: 'CREFITO - Conselho Regional de Fisioterapia e Terapia Ocupacional', value: 'CREFITO'},
 {label: 'CRESS - Conselho Regional de Serviço Social', value: 'CRESS'},
 {label: 'CRF - Conselho Regional de Farmácia', value: 'CRF'},
 {label: 'CRFA - Conselho Regional de Fonoaudiologia', value: 'CRFA'},
 {label: 'CRM - Conselho Regional de Medicina', value: 'CRM'},
 {label: 'CRMV - Conselho Regional de Medicina Veterinária', value: 'CRMV'},
 {label: 'CRN - Conselho Regional de Nutrição', value: 'CRN'},
 {label: 'CRO - Conselho Regional de Odontologia', value: 'CRO'},
 {label: 'CRP - Conselho Regional de Psicologia', value: 'CRP'},
 {label: 'CRPRE - Conselho Regional de Profissionais de Relações Públicas', value: 'CRPRE'},
 {label: 'CRQ - Conselho Regional de Química', value: 'CRQ'},
 {label: 'CRT - Conselho Regional dos Técnicos Industriais', value: 'CRT'},
 {label: 'CRTA - Conselho Regional de Técnicos de Administração', value: 'CRTA'},
 {label: 'CTPS - Carteira de Trabalho e Previdência Social', value: 'CTPS'},
 {label: 'CV - Cartório Civil', value: 'CV'},
 {label: 'DELEMIG - Delegacia de Polícia de Imigração', value: 'DELEMIG'},
 {label: 'DETRAN - Departamento Estadual de Trânsito', value: 'DETRAN'},
 {label: 'DGPC - Diretoria Geral da Polícia Civil', value: 'DGPC'},
 {label: 'DIC - Diretoria de Identificação Civil', value: 'DIC'},
 {label: 'DICC - Diretoria de Identificação Civil e Criminal', value: 'DICC'},
 {label: 'DIREX - Diretoria Executiva', value: 'DIREX'},
 {label: 'DPF - Departamento de Polícia Federal', value: 'DPF'},
 {label: 'DPMAF - Divisão de Polícia Marítima Aérea e de Fronteiras', value: 'DPMAF'},
 {label: 'DPT - Departamento de Polícia Técnica Geral', value: 'DPT'},
 {label: 'DPTC - Departamento de Polícia Técnico Científica', value: 'DPTC'},
 {label: 'DREX - Delegacia Regional Executiva', value: 'DREX'},
 {label: 'DRT - Delegacia Regional do Trabalho', value: 'DRT'},
 {label: 'EB - Exército Brasileiro', value: 'EB'},
 {label: 'FAB - Força Aérea Brasileira', value: 'FAB'},
 {label: 'FENAJ - Federação Nacional dos Jornalistas', value: 'FENAJ'},
 {label: 'FGTS - Fundo de Garantia do Tempo de Serviço', value: 'FGTS'},
 {label: 'FIPE - Fundação Instituto de Pesquisas Econômicas', value: 'FIPE'},
 {label: 'FLS - Fundação Lyndolpho Silva', value: 'FLS'},
 {label: 'FUNAI - Fundação Nacional do Índio', value: 'FUNAI'},
 {label: 'GEJSP - Gerência de Estado de Justiça, Segurança Pública e Cidadania', value: 'GEJSP'},
 {label: 'GEJSPC - Gerência de Estado de Justiça, Segurança Pública e Cidadania', value: 'GEJSPC'},
 {label: 'GEJUSPC - Gerência de Estado de Justiça, Segurança Pública e Cidadania', value: 'GEJUSPC'},
 {label: 'GESP - Gerência de Estado de Segurança Pública', value: 'GESP'},
 {label: 'GOVGO - Governo do Estado de Goiás', value: 'GOVGO'},
 {label: 'I CLA - Carteira de Identidade Classista', value: 'I'},
 {label: 'ICP - Instituto de Polícia Científica', value: 'ICP'},
 {label: 'IDAMP - Instituto de Identificação Dr. Aroldo Mendes Paiva', value: 'IDAMP'},
 {label: 'IFP - Instituto Félix Pacheco', value: 'IFP'},
 {label: 'IGP - Instituto Geral de Perícias', value: 'IGP'},
 {label: 'IIACM - Instituto de Identificação Aderson Conceição de Melo', value: 'IIACM'},
 {label: 'IICC - Instituto de Identificação Civil e Criminal', value: 'IICC'},
 {label: 'IICCECF - Instituto de Identificação Civil e Criminal Engrácia da Costa Francisco', value: 'IICCECF'},
 {label: 'IICM - Instituto de Identificação Carlos Menezes', value: 'IICM'},
 {label: 'IIGP - Instituto de Identificação Gonçalo Pereira', value: 'IIGP'},
 {label: 'IIJDM - Instituto de Identificação João de Deus Martins', value: 'IIJDM'},
 {label: 'IIPC - Instituto de Identificação da Polícia Civil', value: 'IIPC'},
 {label: 'IIPC - Instituto de Identificação Pedro Mello', value: 'IIPC'},
 {label: 'IIRGD - Instituto de Identificação Ricardo Gumbleton Daunt', value: 'IIRGD'},
 {label: 'IIRHM - Instituto de Identificação Raimundo Hermínio de Melo', value: 'IIRHM'},
 {label: 'IITB - Instituto de Identificação Tavares Buril', value: 'IITB'},
 {label: 'IML - Instituto Médico-Legal', value: 'IML'},
 {label: 'INI - Instituto Nacional de Identificação', value: 'INI'},
 {label: 'IPF - Instituto Pereira Faustino', value: 'IPF'},
 {label: 'ITEP - Instituto Técnico-Científico de Perícia', value: 'ITEP'},
 {label: 'ITCP - Instituto Técnico-Científico de Perícia', value: 'ITCP'},
 {label: 'MAER - Ministério da Aeronáutica', value: 'MAER'},
 {label: 'MB - Marinha do Brasil', value: 'MB'},
 {label: 'MD - Ministério da Defesa', value: 'MD'},
 {label: 'MDS - Ministério da Cidadania', value: 'MDS'},
 {label: 'MEC - Ministério da Educação e Cultura', value: 'MEC'},
 {label: 'MEX - Ministério do Exército', value: 'MEX'},
 {label: 'MINDEF - Ministério da Defesa', value: 'MINDEF'},
 {label: 'MJ - Ministério da Justiça', value: 'MJ'},
 {label: 'MM - Ministério da Marinha', value: 'MM'},
 {label: 'MMA - Ministério da Marinha', value: 'MMA'},
 {label: 'MPAS - Ministério da Previdência e Assistência Social', value: 'MPAS'},
 {label: 'MPE - Ministério Público Estadual', value: 'MPE'},
 {label: 'MPF - Ministério Público Federal', value: 'MPF'},
 {label: 'MPT - Ministério Público do Trabalho', value: 'MPT'},
 {label: 'MRE - Ministério das Relações Exteriores', value: 'MRE'},
 {label: 'MT - Ministério do Trabalho', value: 'MT'},
 {label: 'MTE - Ministério da Economia', value: 'MTE'},
 {label: 'MTPS - Ministério do Trabalho e Previdência Social', value: 'MTPS'},
 {label: 'NUMIG - Núcleo de Polícia de Imigração', value: 'NUMIG'},
 {label: 'OAB - Ordem dos Advogados do Brasil', value: 'OAB'},
 {label: 'OMB - Ordens dos Músicos do Brasil', value: 'OMB'},
 {label: 'PC - Polícia Civil', value: 'PC'},
 {label: 'PF - Polícia Federal', value: 'PF'},
 {label: 'PGFN - Procuradoria Geral da Fazenda Nacional', value: 'PGFN'},
 {label: 'PM - Polícia Militar', value: 'PM'},
 {label: 'POLITEC - Perícia Oficial e Identificação Técnica', value: 'POLITEC'},
 {label: 'PRF - Polícia Rodoviária Federal', value: 'PRF'},
 {label: 'PTC - Polícia Tecnico-Científica', value: 'PTC'},
 {label: 'SCC - Secretaria de Estado da Casa Civil', value: 'SCC'},
 {label: 'SCJDS - Secretaria Coordenadora de Justiça e Defesa Social', value: 'SCJDS'},
 {label: 'SDS - Secretaria de Defesa Social', value: 'SDS'},
 {label: 'SECC - Secretaria de Estado da Casa Civil', value: 'SECC'},
 {label: 'SECCDE - Secretaria de Estado da Casa Civil e Desenvolvimento Econômico', value: 'SECCDE'},
 {label: 'SEDS - Secretaria de Estado da Defesa Social', value: 'SEDS'},
 {label: 'SEGUP - Secretaria de Estado da Segurança Pública e da Defesa Social', value: 'SEGUP'},
 {label: 'SEJSP - Secretaria de Estado de Justiça e Segurança Pública', value: 'SEJSP'},
 {label: 'SEJUC - Secretaria de Estado da Justica', value: 'SEJUC'},
 {label: 'SEJUSP - Secretaria de Estado de Justiça e Segurança Pública', value: 'SEJUSP'},
 {label: 'SEPC - Secretaria de Estado da Polícia Civil', value: 'SEPC'},
 {label: 'SES - Secretaria de Estado da Segurança', value: 'SES'},
 {label: 'SESC - Secretaria de Estado da Segurança e Cidadania', value: 'SESC'},
 {label: 'SESDC - Secretaria de Estado da Segurança, Defesa e Cidadania', value: 'SESDC'},
 {label: 'SESDEC - Secretaria de Estado da Segurança, Defesa e Cidadania', value: 'SESDEC'},
 {label: 'SESEG - Secretaria Estadual de Segurança', value: 'SESEG'},
 {label: 'SESP - Secretaria de Estado da Segurança Pública', value: 'SESP'},
 {label: 'SESPAP - Secretaria de Estado da Segurança Pública e Administração Penitenciária', value: 'SESPAP'},
 {label: 'SESPDC - Secretaria de Estado de Segurança Publica e Defesa do Cidadão', value: 'SESPDC'},
 {label: 'SESPDS - Secretaria de Estado de Segurança Pública e Defesa Social', value: 'SESPDS'},
 {label: 'SGPC - Superintendência Geral de Polícia Civil', value: 'SGPC'},
 {label: 'SGPJ - Superintendência Geral de Polícia Judiciária', value: 'SGPJ'},
 {label: 'SIM - Serviço de Identificação da Marinha', value: 'SIM'},
 {label: 'SJ - Secretaria da Justiça', value: 'SJ'},
 {label: 'SJCDH - Secretaria da Justiça e dos Direitos Humanos', value: 'SJCDH'},
 {label: 'SJDS - Secretaria Coordenadora de Justiça e Defesa Social', value: 'SJDS'},
 {label: 'SJS - Secretaria da Justiça e Segurança', value: 'SJS'},
 {label: 'SJTC - Secretaria da Justiça do Trabalho e Cidadania', value: 'SJTC'},
 {label: 'SJTS - Secretaria da Justiça do Trabalho e Segurança', value: 'SJTS'},
 {label: 'SNJ - Secretaria Nacional de Justiça / Departamento de Estrangeiros', value: 'SNJ'},
 {label: 'SPMAF - Serviço de Polícia Marítima, Aérea e de Fronteiras', value: 'SPMAF'},
 {label: 'SPTC - Secretaria de Polícia Técnico-Científica', value: 'SPTC'},
 {label: 'SRDPF - Superintendência Regional do Departamento de Polícia Federal', value: 'SRDPF'},
 {label: 'SRF - Receita Federal', value: 'SRF'},
 {label: 'SRTE - Superintendência Regional do Trabalho', value: 'SRTE'},
 {label: 'SSDC - Secretaria da Segurança, Defesa e Cidadania', value: 'SSDC'},
 {label: 'SSDS - Secretaria da Segurança e da Defesa Social', value: 'SSDS'},
 {label: 'SSI - Secretaria de Segurança e Informações', value: 'SSI'},
 {label: 'SSP - Secretaria de Segurança Pública', value: 'SSP'},
 {label: 'SSPCGP - Secretaria de Segurança Pública e Coordenadoria Geral de Perícias', value: 'SSPCGP'},
 {label: 'SSPDC - Secretaria de Segurança Pública e Defesa do Cidadão', value: 'SSPDC'},
 {label: 'SSPDS - Secretaria de Segurança Pública e Defesa Social', value: 'SSPDS'},
 {label: 'SSPPC - Secretaria de Segurança Pública Polícia Civil', value: 'SSPPC'},
 {label: 'SUSEP - Superintendência de Seguros Privados', value: 'SUSEP'},
 {label: 'SUSEPE - Superintendência dos Serviços Penitenciários', value: 'SUSEPE'},
 {label: 'TJ - Tribunal de Justiça', value: 'TJ'},
 {label: 'TJAEM - Tribunal Arbitral e Mediação dos Estados Brasileiros', value: 'TJAEM'},
 {label: 'TRE - Tribunal Regional Eleitoral', value: 'TRE'},
 {label: 'TRF - Tribunal Regional Federal', value: 'TRF'},
 {label: 'TSE - Tribunal Superior Eleitoral', value: 'TSE'},
 {label: 'XXX - Orgão Estrangeiro', value: 'XXX'},
 {label: 'ZZZ - Outro', value: 'ZZZ'},
]