import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/styles";
import { Typography, Container, Button,  withTheme, Grid, DialogTitle, DialogContentText, DialogContent, DialogActions, Dialog} from '@material-ui/core';
// import axios from 'axios'

import FormContext from '../../components/FormContext';
import Breadcrumb from '../../components/Breadcrumb'
import Header from "../../components/logged-area/Header"
import Footer from "../../components/logged-area/Footer"
import Card from '../../components/Card'

import EmprestimoGarantiaVeiculo from '../../assets/img/products/car-insurance.png'
// import EmprestimoGarantiaImovel from '../../assets/img/house.png'
// import EmprestimoConstrucao from '../../assets/img/construction.png'
// import EmprestimoPessoalBC from '../../assets/img/partners/bpc.png'
import EmprestimoConsignado from '../../assets/img/money-house.png'
// import EmprestimoFinanZero from '../../assets/img/partners/finanzero-logo-rounded.png'
// import EmprestimoClickCash from '../../assets/img/partners/click-cash.png'
// import FinanciamentoImobiliario from '../../assets/img/contract.png'
// import EmprestimoPessoalSemGarantias from '../../assets/img/products/money-bag.png'
// import CreditCard from '../../assets/img/credit-card.png'
import Car from '../../assets/img/products/deal.png'
// import CarInsurance from '../../assets/img/car-insurance.png'
import Cash from '../../assets/img/cash.png'
import Content from "../../components/Content";


const styles = (theme) => ({
  root: {
    // margin: '20px 0px 20px 0px',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
  },
  cardContainer:{
    marginBottom: theme.spacing(2)
  }
});

const ServicosEProdutos = (props) => {

  const context = React.useContext(FormContext);
  const [openEmprestimoImovelGarantia, setOpenEmprestimoImovelGarantia] = useState(false)
  const [openEmprestimoConstrucao, setOpenEmprestimoConstrucao] = useState(false)
  const [openFinanciamentoImobiliario, setOpenFinanciamentoImobiliario] = useState(false)

  return (
    <>
      <Header />
      <Content>
        <Breadcrumb actualPage="Produtos e Serviços" hierarchyPages={[{namePage: 'Área Logada', route: '/area-logada'}]}/>
        <Typography variant="h4" gutterBottom>Serviços e Produtos</Typography>
        <Typography variant="h5" gutterBottom>Empréstimos</Typography>
        <Grid container spacing={2} className={props.classes.cardContainer}>

          <Card
            type="products-services-logged-area"
            title="Empréstimo com Veículo em Garantia"
            desc="Menor taxa de juros do mercado e melhores chances de aprovação"
            route="/solicitacao-emprestimo-garantia-veiculo"
            image={EmprestimoGarantiaVeiculo}
            imageAlt="Icone empréstimo com garantia de veículo"
          />


          <Card
            type="products-services-logged-area"
            title="Empréstimo Consignado"
            desc="Taxas a partir de 1,30% ao mês paraseu consignado INSS ou SIAPE"
            route="https://bxblue.com.br/pedido/?utm_source=querofinanciar&utm_medium=affiliate&utm_campaign="
            partner={true}
            image={EmprestimoConsignado}
            imageAlt="Icone empréstimo consignado"
          />

        </Grid>

        <Typography variant="h5" gutterBottom>Financiamentos</Typography>
        <Grid container spacing={2} className={props.classes.cardContainer}>
          <Card
            type="products-services-logged-area"
            title="Financiamento de Veículo"
            desc="Realize seu sonho de ter seu veículo pŕoprio. Clique aqui e confira"
            route="/solicitacao-financiamento-de-veiculo"
            image={Car}
            imageAlt="Icone financiamento de veículo"
          />
        </Grid>

        {/* <Typography variant="h5" gutterBottom>Seguros Automotivos</Typography>
        <Grid container spacing={2} className={props.classes.cardContainer}>
          <Card
            type="products-services-logged-area"
            title="Seguro de Veículo"
            partner={true}
            desc="Faça uma cotação com os melhores preços de Seguro para seu Veículo"
            route="https://www.minutoseguros.com.br/parcerias/quero-financiar?utm_source=parceiros&utm_medium=quero-financiar&utm_campaign=cobranded"
            insuranceCar={true}
            image={CarInsurance}
            imageAlt="Icone seguro de veículo"
          />
        </Grid> */}

        {/* <Typography variant="h5" gutterBottom>Cartões de Crédito</Typography>
        <Grid container spacing={2} className={props.classes.cardContainer}>
          <Card
            type="products-services-logged-area"
            title="Cartão de Crédito"
            desc=""
            route="/area-logada/servicos-produtos-cartao-credito"
            image={CreditCard}
            imageAlt="Icone cartão de crédito"
            active={false}
          />
        </Grid> */}

        



        {/* //modals */}
        <Dialog
          open={openEmprestimoImovelGarantia}
          onClose={() => setOpenEmprestimoImovelGarantia(false)}
        >
          <DialogTitle>Empréstimo com Imóvel em Garantia</DialogTitle>
          <DialogContent>
            <DialogContentText>{context.state.values.nome}, agradecemos seu interesse!</DialogContentText>
            <DialogContentText>Em breve um consultor estará entrando em contato com você!</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setOpenEmprestimoImovelGarantia(false)
            }} style={{color: 'white'}} variant="contained" color="primary">entendi</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openEmprestimoConstrucao}
          onClose={() => setOpenEmprestimoConstrucao(false)}
        >
          <DialogTitle>Empréstimo para Construção</DialogTitle>
          <DialogContent>
            <DialogContentText>{context.state.values.nome}, agradecemos seu interesse!</DialogContentText>
            <DialogContentText>Em breve um consultor estará entrando em contato com você!</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setOpenEmprestimoConstrucao(false)
            }} style={{color: 'white'}} variant="contained" color="primary">entendi</Button>
          </DialogActions>
        </Dialog>
        
        <Dialog
          open={openFinanciamentoImobiliario}
          onClose={() => setOpenFinanciamentoImobiliario(false)}
        >
          <DialogTitle>Financiamento Imobiliário</DialogTitle>
          <DialogContent>
            <DialogContentText>{context.state.values.nome}, agradecemos seu interesse!</DialogContentText>
            <DialogContentText>Em breve um consultor estará entrando em contato com você!</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setOpenFinanciamentoImobiliario(false)
            }} style={{color: 'white'}} variant="contained" color="primary">entendi</Button>
          </DialogActions>
        </Dialog>  

      </Content>
      <Footer/>
    </>
  );
};

export default withTheme(withStyles(styles)(ServicosEProdutos));
