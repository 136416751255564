import React, { useEffect, useContext, useState } from "react";
import {
  Container, Grid, IconButton, withTheme, Typography, Button, Snackbar, CircularProgress
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import axios from 'axios';
import * as Yup from 'yup';
// import LoginForm from "./LoginForm";
import Header from "../../components/Header";
import Footer from "../../components/Footer"
import FormContext from '../../components/FormContext';
import Aviso from '../../components/Aviso';
import { Form, Formik, validateYupSchema } from "formik";
import CampoEmail from "../../components/fields/CampoEmail";
import CampoTexto from "../../components/fields/CampoTexto";
import CampoCheckBox from "../../components/fields/CampoCheckBox";
import MuiAlert from '@material-ui/lab/Alert';

import { useHistory } from "react-router-dom";

const styles = (theme) => ({
  root: {
    margin: theme.spacing(4),
  },
  helpText: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  button: {
    color: theme.palette.common.white,
    padding: `${theme.spacing(1.2)}px ${theme.spacing(5)}px`,
  },
  form: {
    justifyContent: "center",
  },
  formFields: {
    width: 200,
    justifyContent: "center"
  }
});

const NotFound = (props) => {

  const context = useContext(FormContext);
  const history = useHistory();

  return (
    <>
      <Header/>
      <div className={props.classes.root}>
        <Container maxWidth="sm">
          <Typography variant="h2" gutterBottom style={{ textAlign: "center" }}>Ops!</Typography>
          <Typography className={props.classes.helpText}>Não conseguimos localizar o conteúdo que você está buscando.</Typography>
        </Container>
      </div>
      <Footer/>
    </>
  );
};

export default withTheme(withStyles(styles)(NotFound));
