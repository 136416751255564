import React from 'react'
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import MaskedInput from 'react-text-mask'

import CampoTexto from '../fields/CampoTexto';
import CampoTelefone from '../fields/CampoTelefone';
import FormActions from '../FormActions';
import FormContext from '../FormContext';
import CampoCep from '../fields/CampoCep';
import Aviso from '../Aviso';
import CampoEmail from '../fields/CampoEmail';
import CampoTermos from '../fields/CampoTermos';
import CampoCpf from '../fields/CampoCpf';
import CampoCheckBox from '../fields/CampoCheckBox';
import { Typography } from '@material-ui/core';
// import CampoWhatsApp from './fields/CampoWhatsApp';

const styles = {
};

const EtapaComoEncontrar = (props) => {
  const context = React.useContext(FormContext);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const { fields, etapa, params } = props;
  
  return(
    <Form>
      {
        fields.includes('nome') &&
        <CampoTexto
          label="Qual é seu nome completo? *"
          name="nomeCompleto"
          id="nomeCompleto"
          placeholder="José da Silva"
          onFocus={ props.changeFocus }
          value={values.nomeCompleto}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.nomeCompleto && touched.nomeCompleto}
          helperText={errors.nomeCompleto && touched.nomeCompleto ? errors.nomeCompleto : ''}
        />
      }
      {
        fields.includes('celular') &&
        <CampoTelefone
            label="Qual é seu celular? *"
            name="celular"
            onFocus={ props.changeFocus }
            onBlur={ (e)=>{
              // context.changeValues({
              //   celular:values.celular
              // });
              handleBlur(e);
            } }
            onChange={ handleChange }
            value={values.celular || ""}
            error={errors.celular && touched.celular}
            helperText={errors.celular && touched.celular ? errors.celular : ''}
        />
      }
      {
        fields.includes('email') &&
        <CampoEmail
            label="Seu email? *"
            name="email"
            inputProps={{ maxLength: 60 }}
            placeholder="josesilva@email.com.br"
            onFocus={ props.changeFocus }
            onBlur={ (e)=>{
              // context.changeValues({
              //   email:values.email
              // });
              handleBlur(e);
            } }
            value={values.email || ""}
            onChange={handleChange}
            error={errors.email && touched.email}
            helperText={errors.email && touched.email ? errors.email : ''}
            disabled={Boolean(context.state.values.email)}
        />
      }
      {
        fields.includes('cep') &&
        <CampoCep
          label="Seu CEP? *"
          name="cep"
          value={values.cep || ""}
          onBlur={ (e)=> {
            handleBlur(e);
            const regx = /[\(\)_-]/g;
            if(e.target.value.replace(regx,'').length==8){
              context.buscarCep(e.target.value).then((changes=>{
                setFieldValue('endereco', changes.endereco);
                setFieldValue('bairro', changes.bairro);
                setFieldValue('estado', changes.estado);
                setFieldValue('cidade', changes.cidade);
              }));
            }
          }}
          onChange={
            (e)=> {
                handleChange(e);
                const regx = /[\(\)_-]/g;
                if(e.target.value.replace(regx,'').length==8){
                  context.buscarCep(e.target.value).then((changes=>{
                    setFieldValue('endereco', changes.endereco);
                    setFieldValue('bairro', changes.bairro);
                    setFieldValue('estado', changes.estado);
                    setFieldValue('cidade', changes.cidade);
                  }));
                }
            }
          }
          error={errors.cep && touched.cep}
          helperText={errors.cep && touched.cep ? errors.cep : ''}
        />
      }
      {
        fields.includes('cpf') &&
        <CampoCpf
          label="CPF"
          name="cpf"
          id="cpf"
          onFocus={ props.changeFocus }
          onBlur={ (e)=>{
            // context.changeValues({ 
            //   cpf:e.target.value
            // });
            handleBlur(e);
          }}
          onChange={ handleChange }
          value={values.cpf}
          error={errors.cpf && touched.cpf}
          helperText={errors.cpf && touched.cpf ? errors.cpf : ''}
          disabled={Boolean(context.state.values.cpf)}
        />
      }
      {
        fields.includes('terms') &&
        <CampoCheckBox
          name="terms"
          onFocus={ props.changeFocus }
          value={values.terms ? values.terms : false}
          onChange={ (e)=>{
            handleChange(e);
          } }
          error={errors.terms && touched.terms}
        >
          {
            !params.hideScrTerms &&
            <Typography className="aviso">Eu aceito os <a href="https://querofinanciar.com/sobre/termos-e-condicoes-de-uso/" target="_blank">termos de uso</a>, <a href="https://querofinanciar.com/sobre/politica-de-privacidade/" target="_blank">política de privacidade</a> e <a href="https://querofinanciar.com/sobre/termo-autorizacao-consulta/" target="_blank">termo de autorização de consulta (SCR)</a>. Além disso, aceito receber comunicações por e-mail, Whatsapp e SMS.</Typography>
          }
          {
            params.hideScrTerms &&
            <Typography className="aviso">Eu aceito os <a href="https://querofinanciar.com/sobre/termos-e-condicoes-de-uso/" target="_blank">termos de uso</a> e <a href="https://querofinanciar.com/sobre/politica-de-privacidade/" target="_blank">política de privacidade</a>. Além disso, aceito receber comunicações por e-mail, Whatsapp e SMS da Quero Financiar.</Typography>
          }
        </CampoCheckBox>
      }
      <FormActions isSubmitting={ isSubmitting } disabled={ etapa!=context.state.etapa }  />
      <Aviso errors={errors} touched={touched}/>
    </Form>
  )
}

export default withStyles(styles)(EtapaComoEncontrar);