import React from 'react';
import CampoSelect from './CampoSelect';
import FormContext from '../FormContext';

const CampoEmail = (props) => {
  
  const context = React.useContext(FormContext);
  const [isBusy, setIsBusy] = React.useState(false);
  const [states, setStates] = React.useState([]);

  const init = async () => {
    setIsBusy(true)
    const states = await context.getBrazilianStates()
    setStates(states)
    setIsBusy(false)
  }

  React.useEffect(()=>{
    init()
  },[])

  return (
    <CampoSelect
      isDisabled={isBusy}
      {...props}
      options={states}
    />
  );

}

export default CampoEmail;