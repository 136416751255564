import React from 'react';
import ReactDOM from 'react-dom';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

const styles = theme => ({
  root: {
    left: theme.spacing(2),
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    maxWidth: '25%',
    '& .MuiSnackbarContent-message':{
      width:'100%',
    },
    '& .MuiSnackbarContent-root':{
      width:'100%',
      backgroundColor:red[500],
    },
    [theme.breakpoints.down('xs')]:{
      maxWidth: 'none',
    },
  },
});

const Aviso = props => {

  let errors = [];
  Object.entries(props.errors).map((item)=>{
    // console.log('is touched?',item[0],Object.keys(props.touched).indexOf(item[0]));
    if( props.touched[item[0]] == true ){
      errors.push(typeof item[1] === 'string' ? item[1] : item[1].value);
    }

  });

  return ReactDOM.createPortal(
    <Snackbar
      // style={}
      className={props.classes.root}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={errors && errors.length>0}
      message={<React.Fragment>
        {
          errors.slice(0,3).map((item, index)=>(
            <span key={index} style={{
              display:'block',
              textAlign:'left',
              padding:4,
              fontWeight: '600',
            }}>{item}</span>
          ))
        }
        </React.Fragment>}
    >
      
    </Snackbar>,
    document.body
  )
};

export default withStyles(styles)(Aviso);