import React, { useEffect, useState } from 'react'
import { Form,useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import FormActions from '../FormActions';
import FormContext from '../FormContext';
import CampoOpcoes from '../fields/CampoOpcoes';
import Aviso from '../Aviso';
import GrupoVeiculoFipe from './GrupoVeiculoFipe';
import CampoVeiculoPlaca from '../fields/CampoVeiculoPlaca';
import convertVeiculoTipoToFipe from '../../lib/convertVeiculoTipoToFipe';

const styles = theme => ({
  etapaTitulo: {
  },
});

const EtapaVeiculoFinanciamento = (props) => {
  const context = React.useContext(FormContext);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const { fields, etapa } = props;
  const [isBusy, setIsBusy] = useState(false);

  useEffect(()=>{
    setIsBusy(true);

    const updateData = async()=>{

      if(context.state.values.veiculo_tipo){
      
        await context.getVehicleBrands({
          veiculo_tipo: context.state.values.veiculo_tipo,
          veiculo_combustivel: params.veiculo_combustivel || state.values.veiculo_combustivel,
          veiculo_zero_km: false,
          veiculo_ano: params.veiculo_ano || state.values.veiculo_ano,
          veiculo_licenciamento_uf: params.veiculo_licenciamento_uf || state.values.veiculo_licenciamento_uf,
          veiculo_tabela: params.veiculo_tabela || state.values.veiculo_tabela,
        })
  
        if(context.state.values.fipe_codigoMarca){
          await context.consultarModelosFipe(context.state.values.veiculo_tipo, context.state.values.fipe_codigoMarca)
        }else{
          setFieldValue('veiculo_marca', '');
          setFieldValue('veiculo_modelo', '');
          setFieldValue('veiculo_ano', '');
        }

      }else{
        setFieldValue('veiculo_tipo', '')
        setFieldValue('veiculo_marca', '');
        setFieldValue('veiculo_modelo', '');
        setFieldValue('veiculo_ano', '');
      }

      setIsBusy(false)

    }

    updateData()
    
  },[]);

  return (
    <Form>
      <CampoOpcoes
        label="Selecione o tipo do seu veículo *"
        name="veiculo_tipo"
        value={values.veiculo_tipo}
        touched={touched.veiculo_tipo}
        showError={errors.veiculo_tipo}
        options={props.veiculo_tipo_options ? props.veiculo_tipo_options : [
          {label:'Carro, caminhonete ou utilitário',value:'carro'},
          {label:'Moto',value:'moto'},
          {label:'Caminhão',value:'caminhao'},
        ]}
        classNamePrefix="quero-financiar-select"
        className="campo-selecao"
        onFocus={ props.changeFocus }
        onChange={ ( data ) => {
          setFieldValue('veiculo_tipo', data.value );
          setFieldValue('veiculo_marca', '');
          setFieldValue('veiculo_modelo', '');
          setFieldValue('veiculo_ano', '');
          setFieldValue('veiculo_tabela', 'fipe');
          context.changeState({veiculo_anos:[]});
          setIsBusy(true)
          context.consultarMarcasFipe( convertVeiculoTipoToFipe(data.value ) ).finally((result)=>{
            setIsBusy(false)
          });
        } }
        onBlur={ ( value ) => { setFieldTouched('fipe_tipo',true); } }
      />
      {
        values.veiculo_tipo!='' &&
        <GrupoVeiculoFipe
          changeFocus = {props.changeFocus}
          handleChange = {handleChange}
          setFieldValue = {setFieldValue}
          setFieldTouched = {setFieldTouched}
          handleBlur = {handleBlur}
          values = {values}
          errors = {errors}
          touched = {touched}
        />
      }
      <FormActions isSubmitting={ isSubmitting } disabled={etapa!=context.state.etapa || isBusy} />
      <Aviso errors={errors} touched={touched}/>
    </Form>
  )
};

export default withStyles(styles)(EtapaVeiculoFinanciamento);