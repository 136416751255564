import React from "react";
import ReactModal from "react-modal";
import { Dialog, DialogContent, DialogTitle, withTheme } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import LoginForm from "./LoginForm";
import FormContext from "../FormContext";

const styles = (theme) => ({
  closeButton: {
    display: "block",
    margin: "0 0 0 auto",
  },
});

// const modalStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     transform: "translate(-50%, -50%)",
//   },
// };

const LoginScreen = (props) => {

  const context = React.useContext(FormContext);

  return (
    <Dialog
      open={context.state.showLogin}
      onClose={()=>{
        context.showLogin(false)
      } }
      // style={modalStyles}
    >
      <DialogContent>
        <LoginForm inOrderFlow/>
      </DialogContent>
    </Dialog>
  );
};

export default withTheme(withStyles(styles)(LoginScreen));
