'use strict';

import React from 'react';
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import TextField from '@material-ui/core/TextField';

function TextMaskCustom(props) {

  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      type="tel"
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={
        createNumberMask({
          prefix: 'R$ ',
          // suffix: ',00',
          thousandsSeparatorSymbol: '.',
          decimalSymbol: ',',
        })
      }
    />
  );
}

class CampoDinheiro extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    
    return (
      <div className="campo-wrapper">
        <TextField
          {...this.props}
          inputProps={this.props.inputProps ? this.props.inputProps : {maxLength: 90}}
          InputProps={{
            inputComponent: TextMaskCustom,
          }}
          variant="outlined"
          fullWidth
        />
      </div>
    );
  }
}

export default CampoDinheiro