import React, { useEffect, useRef, useState } from "react";
import FormContext from '../../components/FormContext';
import { withStyles } from "@material-ui/styles";
import { Card, Grid, Typography, withTheme } from "@material-ui/core";
import CardProposal from "../../components/CardProposal";
import Breadcrumb from '../../components/Breadcrumb'
import Container from '@material-ui/core/Container'
import Header from "../../components/logged-area/Header"
import Footer from "../../components/logged-area/Footer"
import NoOffer from "../../components/logged-area/meus-pedidos/NoOffer";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from "react-router-dom";
import CardProposalDetails from "../../components/logged-area/meus-pedidos/CardProposalDetails";
import Content from "../../components/Content";

const fakeProposals = [
  {
      "celular": "(35)99234-1828",
      "amount": 70000,
      "veiculo_tipo": "carro",
      "cidade": "Imbituba",
      "banco": "",
      "bairro": "Residencial Vale Encantado",
      "tem_cnpj": "Sim",
      "agentId": "",
      "produto": "Refinanciamento de Veículo",
      "status": "pre_analise_aprovada",
      "email": "nalissonmoretti@gmail.com",
      "externalStatus": "REJECTED",
      "aposentado_num_beneficio": "",
      "externalData":
      {
          "feeds":
          [
              {
                  "id": "c4662b02-d4c0-4cd8-be67-fd9c33431ee6",
                  "createdAt": "2024-06-26T13:24:22.626519",
                  "name": "REJECTED",
                  "description": "DUPLICITY"
              }
          ],
          "steps":
          [
              {
                  "name": "PROPOSAL_CREATED",
                  "progress":
                  {
                      "finishedAt": "2024-06-26T13:24:22.611790",
                      "startedAt": "2024-06-26T13:24:22.331359"
                  },
                  "rejectionReason": "DUPLICITY"
              },
              {
                  "progress":
                  {},
                  "name": "PRE_QUALIFICATION"
              },
              {
                  "name": "PROCESSING",
                  "progress":
                  {}
              },
              {
                  "progress":
                  {},
                  "name": "LOAN_PROPOSAL_ANALYSIS"
              },
              {
                  "name": "DOCUMENTS",
                  "progress":
                  {}
              },
              {
                  "name": "COLLATERAL_INSPECTION",
                  "progress":
                  {}
              },
              {
                  "name": "CONTRACT",
                  "progress":
                  {}
              },
              {
                  "name": "LOAN_PAYMENT",
                  "progress":
                  {}
              }
          ],
          "id": "B2B-FB64894F-409B-4B27-8761-F2341B22CAF9",
          "status": "REJECTED",
          "data":
          {
              "rejectionReason": "DUPLICITY"
          },
          "productType": "AUTO_REFI",
          "name": "REJECTED"
      },
      "cnpj": "47.589.009/0001-43",
      "conjuge_cpf": "",
      "veiculo_versao": "COMPASS LONGITUDE 4X4 2.0 TB AT9 4P (DD) Completo",
      "conjuge_celular": "",
      "conjuge_nomeCompleto": "",
      "app_commit_hash": "fc5f1fc",
      "reprocessing": false,
      "etapa_action": "resultado",
      "currentBrowser": "Google Chrome",
      "tests": "{Form V1}{Tabela Molicar}",
      "nomeCompleto": "Mirella Martins Rezende",
      "userId": "zcHRWzIugfTIcwf0Wk2153qtFwy2",
      "montante": 70000,
      "term": 36,
      "veiculo_fipe_tipo_id": "",
      "restricao": "Não",
      "objetivo": "Investir",
      "externalId": "B2B-FB64894F-409B-4B27-8761-F2341B22CAF9",
      "banco_financiamento": "",
      "ipCliente": "191.253.40.247",
      "terms": true,
      "restricao_valor": "",
      "cidade_nascimento": "Bom Sucesso",
      "id": "0uDvvMEembZbtl57cwEg",
      "veiculo_modelo": "COMPASS LONGITUDE 4X4 2.0 TB AT9",
      "possui_saldo_fgts": "",
      "operatingSystem": "Linux OS",
      "partnerId": "creditas",
      "veiculo_valor": "",
      "cpf": "081.327.816-35",
      "prazo": "36",
      "numero": "01",
      "apiVersion": "2.0.0",
      "message": "checking proposal",
      "veiculo_versao_label": "COMPASS LONGITUDE 4X4 2.0 TB AT9 4P (DD) Completo",
      "fipe_codigoTabelaReferencia": 310,
      "fonteDoLead": "Site: Refinanciamento de Veículos",
      "empresa": "",
      "banco_label": "",
      "createdAt":
      {
          "_seconds": 1719408262,
          "_nanoseconds": 493000000
      },
      "nome_mae": "Lucia Helena Martins Magalhães Narciso",
      "estado": "SC",
      "zohoLeadId": "3220979000499942875",
      "ocupacao": "Autônomo(a)/ Prof. Liberal",
      "data_nascimento": "1987-10-07",
      "qfCorrelationId": "00-cc1724ac946624b2caacb6c8df41e845-1caf82f17dd7acc2-00",
      "banco_tipo_conta": "",
      "nome": "Mirella",
      "veiculo_ano": 2017,
      "veiculo_placa": "PKO6I72",
      "rg": "14100163",
      "installmentAmount": 1944.4444444444443,
      "veiculo_marca_label": "JEEP",
      "conjuge_email": "",
      "proprietario": "Sim",
      "renda": 8000,
      "zoho_dealStatus": "Recusado",
      "updatedAt":
      {
          "_nanoseconds": 439000000,
          "_seconds": 1719414480
      },
      "etapa_label": "Suas referências",
      "etapa": 16,
      "userAgentCliente": "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/119.0.0.0 Mobile Safari/537.36",
      "zoho_dealStage": "Perdido",
      "quitado": "Sim",
      "estado_civil": "Divorciado",
      "interesse": "",
      "estado_nascimento": "MG",
      "refreshedAt": 1719414480,
      "zoho_motivoPerda": "Crédito recusado no parceiro",
      "veiculo_marca": "JEEP",
      "complemento": "Casa 01",
      "veiculo_tabela": "molicar",
      "conta_santander_debito_automatico": "Não",
      "parceiro": "3220979000386447001",
      "cargo": "VENDEDOR",
      "veiculo_fornecedor": "creditas",
      "cep": "88780-000",
      "tem_veiculo_placa": "Sim",
      "sobrenome": "Martins Rezende",
      "zohoDealId": "3220979000499916884",
      "genero": "Feminino",
      "tempo_servico": "Entre 2 e 3 anos",
      "veiculo_combustivel": "",
      "endereco": "Caminho Alto do Morro",
      "orderId": "rpUiWnz3pQqodpXDPBtE",
      "conjuge_data_nascimento": ""
  },
  {
      "restricao": "Não",
      "conjuge_nomeCompleto": "",
      "aposentado_num_beneficio": "",
      "cnpj": "47.589.009/0001-43",
      "apiVersion": "2.0.0",
      "cargo": "VENDEDOR",
      "message": "checking proposal",
      "tem_cnpj": "Sim",
      "currentBrowser": "Google Chrome",
      "objetivo": "Investir",
      "nomeCompleto": "Mirella Martins Rezende",
      "numero": "01",
      "veiculo_valor": "",
      "rg": "14100163",
      "zohoDealId": "3220979000499942906",
      "fonteDoLead": "Site: Refinanciamento de Veículos",
      "cep": "88780-000",
      "veiculo_combustivel": "",
      "email": "nalissonmoretti@gmail.com",
      "veiculo_placa": "PKO6I72",
      "etapa_action": "resultado",
      "renda": 8000,
      "conjuge_data_nascimento": "",
      "endereco": "Caminho Alto do Morro",
      "conjuge_email": "",
      "userId": "zcHRWzIugfTIcwf0Wk2153qtFwy2",
      "createdAt":
      {
          "_nanoseconds": 295000000,
          "_seconds": 1719408275
      },
      "ipCliente": "191.253.40.247",
      "partnerId": "sim",
      "estado": "SC",
      "banco_financiamento": "",
      "tempo_servico": "Entre 2 e 3 anos",
      "proprietario": "Sim",
      "veiculo_ano": 2017,
      "veiculo_marca_label": "JEEP",
      "conjuge_cpf": "",
      "userAgentCliente": "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/119.0.0.0 Mobile Safari/537.36",
      "zohoLeadId": "3220979000499942875",
      "banco_tipo_conta": "",
      "restricao_valor": "",
      "veiculo_tabela": "molicar",
      "nome_mae": "Lucia Helena Martins Magalhães Narciso",
      "externalData":
      {
          "message": "O valor da parcela está muito alto e pode não ser aprovado por crédito. Tente novamente solicitando um valor mais baixo ou informando um prazo maior para pagamento.",
          "maxPmtViolated": true
      },
      "agentId": "",
      "cidade_nascimento": "Bom Sucesso",
      "etapa_label": "Suas referências",
      "sobrenome": "Martins Rezende",
      "estado_civil": "Divorciado",
      "montante": 41979,
      "banco": "",
      "parceiro": "3220979000386447001",
      "empresa": "",
      "data_nascimento": "1987-10-07",
      "updatedAt":
      {
          "_nanoseconds": 295000000,
          "_seconds": 1719408275
      },
      "conjuge_celular": "",
      "installmentAmount": 1166.0833333333333,
      "ocupacao": "Autônomo(a)/ Prof. Liberal",
      "cidade": "Imbituba",
      "complemento": "Casa 01",
      "term": 36,
      "interesse": "",
      "quitado": "Sim",
      "externalStatus": "PMT máximo ultrapassado.",
      "produto": "Refinanciamento de Veículo",
      "qfCorrelationId": "00-cc1724ac946624b2caacb6c8df41e845-1caf82f17dd7acc2-00",
      "veiculo_tipo": "carro",
      "reprocessing": false,
      "genero": "Feminino",
      "etapa": 16,
      "veiculo_marca": "JEEP",
      "conta_santander_debito_automatico": "Não",
      "orderId": "rpUiWnz3pQqodpXDPBtE",
      "veiculo_fornecedor": "creditas",
      "cpf": "081.327.816-35",
      "banco_label": "",
      "nome": "Mirella",
      "terms": true,
      "celular": "(35)99234-1828",
      "veiculo_modelo": "COMPASS LONGITUDE 4X4 2.0 TB AT9",
      "veiculo_versao": "COMPASS LONGITUDE 4X4 2.0 TB AT9 4P (DD) Completo",
      "estado_nascimento": "MG",
      "possui_saldo_fgts": "",
      "fipe_codigoTabelaReferencia": 310,
      "prazo": 36,
      "bairro": "Residencial Vale Encantado",
      "veiculo_versao_label": "COMPASS LONGITUDE 4X4 2.0 TB AT9 4P (DD) Completo",
      "veiculo_fipe_tipo_id": "",
      "tem_veiculo_placa": "Sim",
      "app_commit_hash": "fc5f1fc",
      "id": "shRUEZu6sKSg0dwcUGq1",
      "tests": "{Form V1}{Tabela Molicar}",
      "amount": 41979,
      "operatingSystem": "Linux OS",
      "status": "pre_analise_erro"
  },
  {
      "conta_santander_debito_automatico": "Não",
      "sobrenome": "Martins Rezende",
      "etapa": 16,
      "proprietario": "Sim",
      "nome": "Mirella",
      "zohoLeadId": "3220979000499942875",
      "banco_label": "",
      "celular": "(35)99234-1828",
      "veiculo_fipe_tipo_id": "",
      "veiculo_tipo": "carro",
      "conjuge_celular": "",
      "term": 36,
      "banco": "",
      "nome_mae": "Lucia Helena Martins Magalhães Narciso",
      "bv_maxMontante": 55669,
      "veiculo_valor": "",
      "estado_civil": "Divorciado",
      "installmentAmount": 1546.361111111111,
      "restricao": "Não",
      "produto": "Refinanciamento de Veículo",
      "app_commit_hash": "fc5f1fc",
      "terms": true,
      "zohoDealId": "3220979000499916929",
      "veiculo_placa": "PKO6I72",
      "userAgentCliente": "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/119.0.0.0 Mobile Safari/537.36",
      "interesse": "",
      "bv_submissionChannel_filial_code": "P384028.1",
      "veiculo_marca_label": "JEEP",
      "tem_veiculo_placa": "Sim",
      "apiVersion": "2.0.0",
      "id": "S2x6yRkHth8n0Cr5eoH7",
      "cnpj": "47.589.009/0001-43",
      "cep": "88780-000",
      "etapa_action": "resultado",
      "veiculo_fornecedor": "creditas",
      "externalData": "",
      "parceiro": "3220979000386447001",
      "data_nascimento": "1987-10-07",
      "endereco": "Caminho Alto do Morro",
      "veiculo_marca": "JEEP",
      "sentToPartner": true,
      "conjuge_email": "",
      "bv_submissionChannel_gerente_code": "11522",
      "tem_cnpj": "Sim",
      "cargo": "Administrador",
      "ocupacao": "Empresário(a)",
      "currentBrowser": "Google Chrome",
      "bv_submissionChannel_type": "filial",
      "message": "checking proposal",
      "rg": "14100163",
      "reprocessing": false,
      "possui_saldo_fgts": "",
      "conjuge_nomeCompleto": "",
      "qfCorrelationId": "00-cc1724ac946624b2caacb6c8df41e845-1caf82f17dd7acc2-00",
      "ipCliente": "191.253.40.247",
      "email": "nalissonmoretti@gmail.com",
      "objetivo": "Investir",
      "bv_maxPrazo": 36,
      "renda_ajustada": 8000,
      "conjuge_data_nascimento": "",
      "cpf": "081.327.816-35",
      "amount": 55669,
      "cidade_nascimento": "Bom Sucesso",
      "bairro": "Residencial Vale Encantado",
      "startOver": false,
      "etapa_label": "Suas referências",
      "banco_financiamento": "",
      "veiculo_ano": 2017,
      "complemento": "Casa 01",
      "conjuge_cpf": "",
      "createdAt":
      {
          "_nanoseconds": 631000000,
          "_seconds": 1719408265
      },
      "estado_nascimento": "MG",
      "prazo": 36,
      "bv_dataPrimeiroVencimento": "2024-07-26",
      "empresa": "",
      "status": "pre_analise_negada",
      "banco_tipo_conta": "",
      "fonteDoLead": "Site: Refinanciamento de Veículos",
      "veiculo_modelo": "COMPASS LONGITUDE 4X4 2.0 TB AT9",
      "pilotoBV": "api",
      "userId": "zcHRWzIugfTIcwf0Wk2153qtFwy2",
      "tempo_servico": "Entre 2 e 3 anos",
      "quitado": "Sim",
      "partnerId": "bv",
      "cidade": "Imbituba",
      "fipe_codigoTabelaReferencia": 310,
      "aposentado_num_beneficio": "",
      "operatingSystem": "Linux OS",
      "veiculo_combustivel": "",
      "veiculo_versao_label": "COMPASS LONGITUDE 4X4 2.0 TB AT9 4P (DD) Completo",
      "agentId": "",
      "genero": "Feminino",
      "orderId": "rpUiWnz3pQqodpXDPBtE",
      "tests": "{Form V1}{Tabela Molicar}{bv_elegibilidade_api}",
      "veiculo_tabela": "molicar",
      "updatedAt":
      {
          "_nanoseconds": 631000000,
          "_seconds": 1719408265
      },
      "veiculo_versao": "COMPASS LONGITUDE 4X4 2.0 TB AT9 4P (DD) Completo",
      "restricao_valor": "",
      "numero": "01",
      "externalStatus": "Filtro de Politica - Rating EVG",
      "estado": "SC",
      "nomeCompleto": "Mirella Martins Rezende",
      "montante": 55669,
      "renda": 8000
  },
  {
      "rg": "14100163",
      "veiculo_versao_label": "COMPASS LONGITUDE 4X4 2.0 TB AT9 4P (DD) Completo",
      "etapa_action": "resultado",
      "tem_veiculo_placa": "Sim",
      "conjuge_celular": "",
      "veiculo_marca": "JEEP",
      "operatingSystem": "Linux OS",
      "message": "checking proposal",
      "app_commit_hash": "fc5f1fc",
      "externalData": "",
      "status": "lead_desqualificado",
      "cnpj": "47.589.009/0001-43",
      "currentBrowser": "Google Chrome",
      "updatedAt":
      {
          "_seconds": 1719408262,
          "_nanoseconds": 300000000
      },
      "banco_label": "",
      "veiculo_versao": "COMPASS LONGITUDE 4X4 2.0 TB AT9 4P (DD) Completo",
      "endereco": "Caminho Alto do Morro",
      "conjuge_nomeCompleto": "",
      "agentId": "",
      "montante": 70000,
      "id": "HYsFF7B0PViQWaUWaUQr",
      "zohoDealId": "3220979000499934897",
      "prazo": "36",
      "empresa": "",
      "veiculo_valor": "",
      "possui_saldo_fgts": "",
      "objetivo": "Investir",
      "partnerId": "financiatudo",
      "nome_mae": "Lucia Helena Martins Magalhães Narciso",
      "externalStatus": "API desativada",
      "renda": 8000,
      "interesse": "",
      "veiculo_tabela": "molicar",
      "createdAt":
      {
          "_seconds": 1719408262,
          "_nanoseconds": 300000000
      },
      "cidade_nascimento": "Bom Sucesso",
      "veiculo_placa": "PKO6I72",
      "veiculo_combustivel": "",
      "tem_cnpj": "Sim",
      "terms": true,
      "installmentAmount": 1944.4444444444443,
      "veiculo_fipe_tipo_id": "",
      "tests": "{Form V1}{Tabela Molicar}",
      "data_nascimento": "1987-10-07",
      "aposentado_num_beneficio": "",
      "banco_tipo_conta": "",
      "veiculo_tipo": "carro",
      "tempo_servico": "Entre 2 e 3 anos",
      "celular": "(35)99234-1828",
      "veiculo_modelo": "COMPASS LONGITUDE 4X4 2.0 TB AT9",
      "conjuge_cpf": "",
      "proprietario": "Sim",
      "veiculo_ano": 2017,
      "userAgentCliente": "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/119.0.0.0 Mobile Safari/537.36",
      "email": "nalissonmoretti@gmail.com",
      "apiVersion": "2.0.0",
      "etapa_label": "Suas referências",
      "complemento": "Casa 01",
      "userId": "zcHRWzIugfTIcwf0Wk2153qtFwy2",
      "bairro": "Residencial Vale Encantado",
      "genero": "Feminino",
      "estado_nascimento": "MG",
      "amount": 70000,
      "term": 36,
      "conjuge_data_nascimento": "",
      "qfCorrelationId": "00-cc1724ac946624b2caacb6c8df41e845-1caf82f17dd7acc2-00",
      "cidade": "Imbituba",
      "veiculo_fornecedor": "creditas",
      "fonteDoLead": "Site: Refinanciamento de Veículos",
      "ipCliente": "191.253.40.247",
      "conta_santander_debito_automatico": "Não",
      "produto": "Refinanciamento de Veículo",
      "nome": "Mirella",
      "cpf": "081.327.816-35",
      "cargo": "VENDEDOR",
      "cep": "88780-000",
      "conjuge_email": "",
      "parceiro": "3220979000386447001",
      "banco_financiamento": "",
      "banco": "",
      "restricao": "Não",
      "estado_civil": "Divorciado",
      "fipe_codigoTabelaReferencia": 310,
      "veiculo_marca_label": "JEEP",
      "etapa": 16,
      "nomeCompleto": "Mirella Martins Rezende",
      "sentToPartner": false,
      "estado": "SC",
      "orderId": "rpUiWnz3pQqodpXDPBtE",
      "numero": "01",
      "zohoLeadId": "3220979000499942875",
      "ocupacao": "Autônomo(a)/ Prof. Liberal",
      "sobrenome": "Martins Rezende",
      "quitado": "Sim",
      "restricao_valor": ""
  },
  {
      "numero": "01",
      "interesse": "",
      "etapa_label": "Suas referências",
      "cidade": "Imbituba",
      "veiculo_marca": "JEEP",
      "app_commit_hash": "fc5f1fc",
      "rg": "14100163",
      "updatedAt":
      {
          "_seconds": 1719408262,
          "_nanoseconds": 273000000
      },
      "fonteDoLead": "Site: Refinanciamento de Veículos",
      "tests": "{Form V1}{Tabela Molicar}",
      "quitado": "Sim",
      "veiculo_valor": "",
      "zohoDealId": "3220979000499941654",
      "veiculo_tipo": "carro",
      "banco_tipo_conta": "",
      "restricao_valor": "",
      "conjuge_nomeCompleto": "",
      "createdAt":
      {
          "_nanoseconds": 273000000,
          "_seconds": 1719408262
      },
      "cidade_nascimento": "Bom Sucesso",
      "zohoLeadId": "3220979000499942875",
      "veiculo_marca_label": "JEEP",
      "operatingSystem": "Linux OS",
      "nome": "Mirella",
      "conjuge_email": "",
      "banco_label": "",
      "agentId": "",
      "banco_financiamento": "",
      "status": "proposta_pendente",
      "conta_santander_debito_automatico": "Não",
      "estado_nascimento": "MG",
      "tem_cnpj": "Sim",
      "empresa": "",
      "tem_veiculo_placa": "Sim",
      "terms": true,
      "produto": "Refinanciamento de Veículo",
      "id": "LudO2uSBRwLnK2rQ2jnV",
      "term": 36,
      "etapa": 16,
      "cpf": "081.327.816-35",
      "partnerId": "santander",
      "veiculo_versao_label": "COMPASS LONGITUDE 4X4 2.0 TB AT9 4P (DD) Completo",
      "reprocessing": false,
      "userAgentCliente": "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/119.0.0.0 Mobile Safari/537.36",
      "veiculo_versao": "COMPASS LONGITUDE 4X4 2.0 TB AT9 4P (DD) Completo",
      "bairro": "Residencial Vale Encantado",
      "veiculo_tabela": "molicar",
      "restricao": "Não",
      "banco": "",
      "fipe_codigoTabelaReferencia": 310,
      "veiculo_ano": 2017,
      "message": "checking proposal",
      "cargo": "VENDEDOR",
      "estado_civil": "Divorciado",
      "installmentAmount": 1944.4444444444443,
      "apiVersion": "2.0.0",
      "email": "nalissonmoretti@gmail.com",
      "objetivo": "Investir",
      "nome_mae": "Lucia Helena Martins Magalhães Narciso",
      "renda": 8000,
      "proprietario": "Sim",
      "ocupacao": "Autônomo(a)/ Prof. Liberal",
      "endereco": "Caminho Alto do Morro",
      "prazo": "36",
      "sobrenome": "Martins Rezende",
      "estado": "SC",
      "externalStatus": "Envio manual",
      "complemento": "Casa 01",
      "userId": "zcHRWzIugfTIcwf0Wk2153qtFwy2",
      "conjuge_cpf": "",
      "parceiro": "3220979000386447001",
      "ipCliente": "191.253.40.247",
      "externalData": "",
      "nomeCompleto": "Mirella Martins Rezende",
      "veiculo_modelo": "COMPASS LONGITUDE 4X4 2.0 TB AT9",
      "veiculo_placa": "PKO6I72",
      "genero": "Feminino",
      "conjuge_celular": "",
      "qfCorrelationId": "00-cc1724ac946624b2caacb6c8df41e845-1caf82f17dd7acc2-00",
      "tempo_servico": "Entre 2 e 3 anos",
      "montante": 70000,
      "veiculo_combustivel": "",
      "cnpj": "47.589.009/0001-43",
      "data_nascimento": "1987-10-07",
      "aposentado_num_beneficio": "",
      "currentBrowser": "Google Chrome",
      "conjuge_data_nascimento": "",
      "cep": "88780-000",
      "etapa_action": "resultado",
      "amount": 70000,
      "veiculo_fornecedor": "creditas",
      "veiculo_fipe_tipo_id": "",
      "orderId": "rpUiWnz3pQqodpXDPBtE",
      "celular": "(35)99234-1828",
      "possui_saldo_fgts": ""
  },
  {
      "agentId": "",
      "veiculo_ano": 2017,
      "banco_label": "",
      "prazo": "36",
      "veiculo_marca_label": "JEEP",
      "zohoDealId": "3220979000499991336",
      "conta_santander_debito_automatico": "Não",
      "cnpj": "47.589.009/0001-43",
      "id": "ifZ0wTB8HLufSmnrKTHZ",
      "quitado": "Sim",
      "numero": "01",
      "cidade_nascimento": "Bom Sucesso",
      "etapa_action": "resultado",
      "partnerId": "cashme",
      "proprietario": "Sim",
      "estado": "SC",
      "etapa_label": "Suas referências",
      "nome": "Mirella",
      "empresa": "",
      "tem_cnpj": "Sim",
      "banco_tipo_conta": "",
      "veiculo_combustivel": "",
      "veiculo_modelo": "COMPASS LONGITUDE 4X4 2.0 TB AT9",
      "renda": 8000,
      "complemento": "Casa 01",
      "nome_mae": "Lucia Helena Martins Magalhães Narciso",
      "message": "checking proposal",
      "fipe_codigoTabelaReferencia": 310,
      "cep": "88780-000",
      "parceiro": "3220979000386447001",
      "conjuge_nomeCompleto": "",
      "objetivo": "Investir",
      "ipCliente": "191.253.40.247",
      "data_nascimento": "1987-10-07",
      "conjuge_data_nascimento": "",
      "veiculo_valor": "",
      "produto": "Refinanciamento de Veículo",
      "nomeCompleto": "Mirella Martins Rezende",
      "estado_nascimento": "MG",
      "restricao_valor": "",
      "app_commit_hash": "fc5f1fc",
      "interesse": "",
      "apiVersion": "2.0.0",
      "veiculo_fornecedor": "creditas",
      "sobrenome": "Martins Rezende",
      "veiculo_versao_label": "COMPASS LONGITUDE 4X4 2.0 TB AT9 4P (DD) Completo",
      "banco_financiamento": "",
      "cargo": "VENDEDOR",
      "status": "lead_desqualificado",
      "veiculo_fipe_tipo_id": "",
      "installmentAmount": 1944.4444444444443,
      "banco": "",
      "createdAt":
      {
          "_seconds": 1719408261,
          "_nanoseconds": 732000000
      },
      "genero": "Feminino",
      "conjuge_email": "",
      "veiculo_tabela": "molicar",
      "externalData": "",
      "cpf": "081.327.816-35",
      "estado_civil": "Divorciado",
      "operatingSystem": "Linux OS",
      "veiculo_tipo": "carro",
      "fonteDoLead": "Site: Refinanciamento de Veículos",
      "term": 36,
      "celular": "(35)99234-1828",
      "orderId": "rpUiWnz3pQqodpXDPBtE",
      "rg": "14100163",
      "currentBrowser": "Google Chrome",
      "tests": "{Form V1}{Tabela Molicar}",
      "zohoLeadId": "3220979000499942875",
      "conjuge_celular": "",
      "userId": "zcHRWzIugfTIcwf0Wk2153qtFwy2",
      "reprocessing": false,
      "terms": true,
      "restricao": "Não",
      "conjuge_cpf": "",
      "aposentado_num_beneficio": "",
      "tem_veiculo_placa": "Sim",
      "endereco": "Caminho Alto do Morro",
      "veiculo_marca": "JEEP",
      "montante": 70000,
      "email": "nalissonmoretti@gmail.com",
      "veiculo_versao": "COMPASS LONGITUDE 4X4 2.0 TB AT9 4P (DD) Completo",
      "amount": 70000,
      "cidade": "Imbituba",
      "ocupacao": "Autônomo(a)/ Prof. Liberal",
      "externalStatus": "Produto Refinanciamento de Veículo não compatível com o parceiro.",
      "veiculo_placa": "PKO6I72",
      "userAgentCliente": "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/119.0.0.0 Mobile Safari/537.36",
      "tempo_servico": "Entre 2 e 3 anos",
      "etapa": 16,
      "possui_saldo_fgts": "",
      "updatedAt":
      {
          "_nanoseconds": 732000000,
          "_seconds": 1719408261
      },
      "qfCorrelationId": "00-cc1724ac946624b2caacb6c8df41e845-1caf82f17dd7acc2-00",
      "bairro": "Residencial Vale Encantado"
  }
]

// import mockProposals from '../../components/logged-area/mocks/UserProposals'

const styles = (theme) => ({
  proposalsGrid: {
    // maxWidth: 500,
    // margin: `0 auto`,
    marginBottom: theme.spacing(),
  },
  noResultsContainer:{
    textAlign: `center`,
    margin: `${theme.spacing(4)}px auto`,
  },
  noResultsImg:{
    width: 128,
    marginBottom: theme.spacing(2),
  },
  proposalList:{
    maxHeight: 600,
    maxWidth: '60vh',
    overflow: 'scroll',
    // margin: theme.spacing(),
    border: `1px solid #ccc`,
    borderRadius: theme.spacing(),
    padding: theme.spacing(),
    margin: '0 auto'
  },
  proposalItem:{
    margin: theme.spacing(),
  },
});

const MeusPedidos = (props) => {

  const context = React.useContext(FormContext);
  const [userProposals, setUserProposals] = useState();
  // const [userProposals, setUserProposals] = useState(mockProposals);
  const [selectedProposal, setSelectedProposal] = useState();
  const history = useHistory();
  const { classes } = props;
  const mounted = useRef(false);

  const hasNoOffer = (proposals) => {
    if(!proposals) return false;

    let noOffer = true;

    proposals.map((item)=>{
      switch(item.status){
        case "pagamento_realizado":
          noOffer = false;
          break;
        case "credito_aprovado": case "pagamento_aprovado":
          noOffer = false;
          break;
        case "pre_analise_aprovada":
        case "proposta_pendente":
        case "proposta_aprovada":
          noOffer = false;
          break;
      }
    });

    return noOffer;
  }

  useEffect(()=>{
    mounted.current = true;

    const getUserProposals = async ()=>{
      try{
        const result = await context.getUserProposals();
        console.log('mounted.current', mounted.current);
        if(mounted.current) setUserProposals( result.data.data );
        // if(mounted.current) setUserProposals( fakeProposals );
      }catch(error){
        console.log("[UserProposals] error",error);
      }
    }

    if(context.state.firebaseLoaded){
      if(!context.state.userLogged) {
        history.push('/login')
      }else{
        getUserProposals();
      }
    }

    return () => {
      mounted.current = false;
    };

  },[context.state.firebaseLoaded]);

  const handleUpdateProposal = async (proposal) => {
    let proposals = [...userProposals]
    for (let [i, proposalUser] of proposals.entries()) {
      if (proposalUser.id === proposal.id) {
        proposalUser = proposal;
        proposals.splice(i, 1)
        break;
      }
    }
    proposals.push(proposal)
    setUserProposals(proposals)
    // setUserProposals(fakeProposals)
    setSelectedProposal(proposal)
  }

  return (
    <>
      <Header />
      <Content>
        <Breadcrumb actualPage="Meus Pedidos" hierarchyPages={[{namePage: 'Área Logada', route: '/area-logada'}]}/>
        <Typography variant="h4">Meus Pedidos</Typography>
        {
          selectedProposal
          ?
            <CardProposalDetails proposalId={selectedProposal.id} proposal={selectedProposal}
            onClose={() => {
              console.log('setSelectedProposal')
              setSelectedProposal()
            }}
            updateProposal={handleUpdateProposal} />
          :
          <>
            {
              hasNoOffer(userProposals)
              ?
              <NoOffer onClose={() => {
                console.log('setSelectedProposal')
                setSelectedProposal()
              }}/>
              :
              <>
              {
                userProposals && userProposals.length>0 &&
                <div className={classes.proposalList}>
                  <Grid container  className={classes.proposalsGrid} direction="column" alignContent="center">
                  {
                    userProposals.map((proposal,index)=>
                      <Grid item key={index} className={classes.proposalItem}>
                        <CardProposal data={proposal} onSelect={(proposalData)=>{
                          setSelectedProposal(proposalData);
                        }}/>
                      </Grid>
                    )
                  }
                  </Grid>
                </div>
              }
              </>
            }
            {
              !userProposals &&
                <div className={classes.noResultsContainer}>
                <Typography variant="h4">Aguarde um momento</Typography>
                <Typography variant="h6">Estamos atualizando o status de seus pedidos...</Typography>
                <CircularProgress/>
              </div>
            }
          </>
        }
      </Content>
    <Footer/>
    </>
  );
};

export default withTheme(withStyles(styles)(MeusPedidos));
