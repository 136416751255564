import React, { useRef, useState } from "react";
import {
  Dialog, DialogTitle, DialogActions, DialogContentText, DialogContent, withTheme, Button, IconButton, CircularProgress, useTheme, Snackbar, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import CampoNumero from "../../fields/CampoNumero";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
import axios from "axios";
import FormContext from "../../FormContext";
import Webcam from "react-webcam";
import MuiAlert from '@material-ui/lab/Alert';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { set } from "lodash";
import CameraAltOutlined from "@material-ui/icons/CameraAltOutlined";
import { isMobile } from 'react-device-detect';
import AddIcon from "@material-ui/icons/Add";
import ArrowRightAltRounded from '@material-ui/icons/ArrowRightAltRounded';

const styles = (theme) => ({
  closeButton: {
    display: "block",
    margin: "0 0 0 auto",
  },
  selectData: {
    padding: 10,
    margin: 20,
    // border: "1px solid grey",
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: 'inset 0px 0px 0px 1px #e7e7e7;',

  },
  box: {
    padding: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // width: 500,
    // height: 500,
  },
  buttons: {

  },
  sendDocumentos: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const formSelfie = (props) => {

  const context = React.useContext(FormContext);
  const urlParams = new URLSearchParams(window.location.search);
  const proposalId = urlParams.get("proposal");
  const { classes, open, close, proposal,  } = props
  // const [currentStep, setCurrentStep] = useState('takeSelfie')
  const [currentStep, setCurrentStep] = useState('loading')
  const [axiosController, setAxiosController] = useState(new AbortController())
  const mountedRef = useRef(true)
  const webcamRef = React.useRef(null);
  const [webcamImgSrc, setWebcamImgSrc] = React.useState(null);
  const [selfiePhotoDocument, setSelfiePhotoDocument] = React.useState();
	const [isSelfiePhotoDocumentPicked, setIsSelfiePhotoDocumentPicked] = React.useState(false);

  const theme = useTheme()
  const [openFeedbackError, setOpenFeedbackError] = useState(false)
  const [feedbackErrorMessage, setFeedbackErrorMessage] = useState('')

  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot({width: 800, height: 600});
    setWebcamImgSrc(imageSrc);
  }, [webcamRef, setWebcamImgSrc]);

  const captureAgain = () => {
    setOpenFeedbackError(false)
    setWebcamImgSrc(null);
  }

  const b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data); // window.atob(b64Data)
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  const validateImageFormat = (format) => {
    if (
      format === 'image/jpeg' ||
      format === 'image/jpg' ||
      format === 'image/png'
    ) {
      return true
    } else {
      return false;
    }
  }

  const sendDocumentPhoto = async () => {
    
    setLoading(true)

    try{

      const token = await context.getFirebaseToken()
      var block = webcamImgSrc.split(";");
      var contentType = block[0].split(":")[1];
      var realData = block[1].split(",")[1];
      var blob = b64toBlob(realData, contentType);

      const formData = new FormData();
      formData.append('file', blob)

      await axios.post(`${context.state.API_ENDPOINT}/sim/proposal-document/${props.proposalData.id}/DOC_SELFIE/selfie`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
      })

      
      setSuccess(true)
      props.selfieSended()

    }catch(error){
      
      let messageError = ''
      if (error.response==undefined || error.response.data.message === 'Todos os campos devem ser informados!') {
        messageError = "Houve um erro ao enviar a foto. Por favor, tente novamente."
      } else {
        messageError = error.response.data.message
      }
      setFeedbackErrorMessage(messageError)
      setOpenFeedbackError(true)

    }

    setLoading(false)

  }

  const sendDocumentPhotoMobile = async (event) => {

    setOpenFeedbackError(false)

    if (validateImageFormat(event.target.files[0].type)) {

      event.persist();
      setLoading(true)
      setSelfiePhotoDocument(event.target.files[0]);
      setIsSelfiePhotoDocumentPicked(true);

      try{
        
        const token = await context.getFirebaseToken()
        const formData = new FormData();
        formData.append('file', event.target.files[0])
  
        await axios.post(`${context.state.API_ENDPOINT}/sim/proposal-document/${props.proposalData.id}/DOC_SELFIE/selfie`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          },
        })

        setSuccess(true)
        props.selfieSended()

      }catch(error){

        let messageError = ''
        if (error.response==undefined || error.response.data.message === 'Todos os campos devem ser informados!') {
          messageError = "Houve um erro ao enviar a foto. Por favor, tente novamente."
        } else {
          messageError = error.response.data.message
        }

        setFeedbackErrorMessage(messageError)
        setOpenFeedbackError(true)

      }

    } else {

      setFeedbackErrorMessage('Formato inválido. Envie uma foto do documento com um dos seguintes formatos: PNG ou JPG/JPEG')
      setOpenFeedbackError(true)
      
    }

    setLoading(false)

  }

  const onCheckTokenSubmit = async (values) => {

    try{

      const token = await context.getFirebaseToken();
      const proposalDataSend = {
        ...props.proposalData,
        sim_user_code: values.code,
      }
      await axios.post(`${context.state.API_ENDPOINT}/sim/validate-user-token`, proposalDataSend, {headers: {Authorization: `Bearer ${token}`}})

      setCurrentStep('takeSelfie')

    }catch(error){

      if(!mountedRef.current) return

      setFeedbackErrorMessage(error.response ? error.response.data.message : `Erro desconhecido` )
      setOpenFeedbackError(true)

    }
  }

  const sendToken = async () => {

    setCurrentStep('loading')

    try{
      const token = await context.getFirebaseToken();
      await axios.post(`${context.state.API_ENDPOINT}/sim/send-user-token`, props.proposalData, {headers: {Authorization: `Bearer ${token}`}})
      setCurrentStep('checkToken')

    }catch(error){

      if(!mountedRef.current) return
      setFeedbackErrorMessage(error.response ? error.response.data.message : `Erro desconhecido` )
      setCurrentStep('errorSendingToken')

    }

  }

  const DesktopComponent = () => {

    return <>
      <DialogContent>
        <Typography>Algumas dicas para uma boa foto:</Typography>
        <Typography><ArrowRightAltRounded/><span style={{position: 'relative', top: -7}}>Tire a foto em um ambiente claro em que apareça bem seu rosto</span></Typography>
        <Typography><ArrowRightAltRounded/><span style={{position: 'relative', top: -7}}>Tente deixar o seu rosto centralizado na foto</span></Typography>
        <Typography><ArrowRightAltRounded/><span style={{position: 'relative', top: -7}}>Tire a foto em um fundo claro e limpo, sem interferências de objetos ou qualquer coisa do gênero</span></Typography>
        {
          webcamImgSrc &&
          <div>
            <img src={webcamImgSrc} style={{ width:`100%` }}/>
          </div>
        }
        {
          !webcamImgSrc &&
          <div style={{position: 'relative', display: 'flex', justifyContent: 'center', minHeigth: 200}}>
            {/* <div style={{position: 'relative'}}> */}
              <Webcam
                videoConstraints={{
                  facingMode: "user",
                }}
                style={{
                  width: `100%`,
                  // minHeight: '200px'
                }}
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
              />
            {/* </div> */}

            {/* <div style={{ width: '100%',display: 'flex', justifyContent: 'center', position: 'absolute', backgroundColor: 'grey' }}> */}
              <div style={{ width: `80%`, height: '80%',position: 'absolute', top: 20, display: 'flex', justifyContent: 'center', margin: '0 auto'}}>
                <div style={{ width: '60%', border: '3px outset #00B572', borderRadius: 150}} />
              </div>
              {/* <p>testeenvio</p> */}
            {/* </div> */}
          </div>
        }
      </DialogContent>
      <DialogActions>
        {
          webcamImgSrc ? loading === false? <>
            <div style={{ margin: '0 auto' }}>
              {
                openFeedbackError === true && <div><ErrorOutline fontSize="large" style={{color: 'red'}} /><span style={{ fontWeight: 'bold', position: 'relative', top: -10 }}>{feedbackErrorMessage}</span></div>
                }
              <Button onClick={captureAgain} color="primary">Tirar outra</Button>
              <Button onClick={sendDocumentPhoto} color="primary" variant="contained" style={{ color: theme.palette.common.white }}>Enviar Foto</Button>
            </div>
          </> : <CircularProgress/> : ''
        }
        {
          !webcamImgSrc && <div style={{margin: '0 auto'}}><Button onClick={capture} color="primary" variant="contained" style={{ color: theme.palette.common.white }}>Tirar Selfie</Button></div>
        }
      </DialogActions>
    </>
  }

  const steps = {
    checkToken: (
      <Formik
        initialValues={{
          code:'',
        }}
        validationSchema={
          Yup.object().shape({
            code: Yup.string().required('Por favor, informe o código recebido por SMS'),
          })
        }
        onSubmit={onCheckTokenSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          submitForm,
          setFieldValue,
          isSubmitting
        }) => (
          <Form>
            <DialogContent>
              <p>Enviamos um SMS com um código de segurança. Insira este código abaixo.</p>
              <CampoNumero
                label="Código recebido"
                name="code"
                id="code"
                placeholder="123456"
                type="tel"
                // inputProps={{ maxLength: 4 }}
                value={values.code }
                onChange={handleChange}
                error={errors.code}
                helperText={errors.code}
              />
            </DialogContent>
            <DialogActions style={{ width: `100%` }}>
              <Button disabled={isSubmitting} type="submit" color="primary" variant="contained" style={{ color: theme.palette.common.white }}>Validar código</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    ),
    sendToken: (
      <>
        <DialogContent>
          <DialogContentText>Nós precisamos validar o seu número de telefone, para isso enviaremos um SMS. Basta clicar no botão abaixo para continuar.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={sendToken} color="primary" variant="contained" style={{ color: theme.palette.common.white }}>Enviar código</Button>
        </DialogActions>
      </>
    ),
    errorSendingToken: (
      <>
        <DialogContent>
          <DialogContentText>Houve um erro ao enviar o token</DialogContentText>
          <DialogContentText style={{ background: `#efefef`, textAlign: `center` }}>{feedbackErrorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={sendToken} color="primary" variant="contained" style={{ color: theme.palette.common.white }}>Tentar novamente</Button>
        </DialogActions>
      </>
    ),
    // errorValidatingToken: (
    //   <>
    //     <DialogContent>
    //       <DialogContentText>Houve um erro ao validar o token</DialogContentText>
    //     </DialogContent>
    //     <DialogActions>
    //       <Button onClick={sendToken} color="primary" variant="contained" style={{ color: theme.palette.common.white }}>Tentar novamente</Button>
    //     </DialogActions>
    //   </>
    // ),
    loading: (
      <>
        <CircularProgress style={{
          margin: `16px auto`,
        }}/>
      </>
    ),
    takeSelfie: (
      <>
        {
          isMobile && context.state.feature_flag_selfie_component_mobile === true?
              <DialogContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Typography>Algumas dicas para uma boa foto:</Typography>
              <Typography><ArrowRightAltRounded/><span style={{position: 'relative', top: -7}}>Tire a foto em um ambiente claro em que apareça bem seu rosto</span></Typography>
              <Typography><ArrowRightAltRounded/><span style={{position: 'relative', top: -7}}>Tente deixar o seu rosto centralizado na foto</span></Typography>
              <Typography><ArrowRightAltRounded/><span style={{position: 'relative', top: -7}}>Tire a foto em um fundo claro e limpo, sem interferências de objetos ou quaisquer coisa do gênero</span></Typography>
              {
                openFeedbackError && <div><ErrorOutline fontSize="large" style={{color: 'red'}} /><span style={{ fontWeight: 'bold', position: 'relative', top: -10 }}>{feedbackErrorMessage}</span></div>
              }
              {
                loading ? <CircularProgress /> :
                <Button component="label" color="primary" style={{backgroundColor: '#C9E265', borderRadius: 20, color: 'black'}} >
                  <AddIcon /> <span style={{ color: "primary" }}>Tirar foto</span>
                  <input type="file" hidden onChange={sendDocumentPhotoMobile} accept=".jpg,.jpeg,.png" capture/>
                </Button>
              }
            </DialogContent>
            :
            <DesktopComponent/>
        }
      </>
    ),
  }

  React.useEffect(() => {

    if(!props.proposalData.token_validado){
      setCurrentStep('sendToken')
    } else {
      setCurrentStep('takeSelfie')
    }

  },[])

  React.useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  return (
    <Dialog onClose={close} open={props.open}>
      <DialogTitle>Selfie</DialogTitle>
      { steps[currentStep] }
      <Snackbar open={success} autoHideDuration={3000} onClose={() => {
        setSuccess(!success)
        close()
      }}>
        <Alert onClose={() => setSuccess(!success)} severity="success">Sucesso ao enviar a foto :)</Alert>
      </Snackbar>
    </Dialog>
  );
};

export default withTheme(withStyles(styles)(formSelfie));
