import React, { useEffect, useState, useRef } from 'react'
import {
  Grid, Snackbar, Typography, Button, CircularProgress, FormGroup, Chip, Checkbox
} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import { formatFloatToCurrency, formatPorcentage } from "../../lib/Utils";
import moment from 'moment';
import axios from 'axios'

import { Form, useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';

import FormActions from '../FormActions';
import FormContext from '../FormContext';
import CampoCheckBox from '../fields/CampoCheckBox';
import Aviso from '../Aviso';


const styles = (theme) => ({
  content: {
    // height: 300
  },
  helpText: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  helpText1: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  helpText2: {
    textAlign: "center",
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
  },
  asking: {
    fontWeight: 500,
    fontSize: 20,
    marginBottom: theme.spacing(3),
  },
  points: {
    position: 'relative',
    top: -8
  },
  itemRevision: {
    marginBottom: theme.spacing(2),
  },
  contract: {
    marginBottom: theme.spacing(2),
    backgroundColor: '#c7ffea',
    borderRadius: 8
  }
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const EtapaProdutoFGTSValores = (props) => {
  const context = React.useContext(FormContext);
  // const history = useHistory();
  const refInitPageResume = useRef();
  // const refInitPageValues = useRef();
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched, submitForm } = useFormikContext();
  const { fields, etapa } = props;

  const [data, setData] = useState({})
  // const [consultaSaldo, setConsultaSaldo] = useState(false)
  const [showValuesFGTS, setShowValuesFGTS] = useState(true)
  const [showRevisionValuesFGTS, setShowRevisionValuesFGTS] = useState(false)
  const [loading, setLoading] = useState(false)
  // const [openModalHowToDoIt, setOpenModalHowToDoIt] = useState(false)
  const [openFeedbackError, setOpenFeedbackError] = useState(false)
  const [feedbackErrorMessage, setFeedbackErrorMessage] = useState(false)

  const [selectedValue, setSelectedValue] = useState(null)
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [errorDescription, setErrorDescription] = useState('')
  
  const [simulateError, setSimulateError] = useState(false)

  const [valuesRevision, setValuesRevision] = useState([])
  const [totalValueDataRepasse, setTotalValueDataRepasse] = useState(0)
  
  const handleChangeStateBetweenSelectValueAndRevision = async () => {
    // submitForm()
    const selectedValues = context.state.values_saque_fgts.filter(element => element.selected === true)
    if (selectedValues.length >= 2) {
      setShowError(false)
      setLoading(true)
      props.updateResize()
      window.scrollTo({top: '0', behavior: 'smooth'});
      const token = await context.getFirebaseToken()
      let totalValueRepasse = 0
      selectedValues.forEach(element => {
        totalValueRepasse= totalValueRepasse + element.valor
      })

      setTotalValueDataRepasse(totalValueRepasse)
      // const data = {
      //   repasse: selectedValues,
      //   totalValor: totalValueDataRepasse,
      //   cpf: context.state.values.cpf,
      //   email: context.state.values.email,
      //   simulateError
      // }

      if(totalValueRepasse<300){
        setFeedbackErrorMessage('O valor total deve ser superior a R$ 300,00.');
        setOpenFeedbackError(true)
        setLoading(false)
        return;
      }
      
      const consultaSaldo = await axios.post(`${context.state.API_ENDPOINT}/bv/emprestimo-fgts/simulacao-bv`, {...context.state.values, repasse: selectedValues, valorTotalProposta: totalValueRepasse,}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (consultaSaldo.data.success !== true) {
        setErrorMessage(consultaSaldo.data.message)
        setErrorDescription(consultaSaldo.data.description)
        setShowError(true)
        setLoading(false)
        // context.changeValues({
        //   valores_simulacao_bv: consultaSaldo.data
        // })
        props.updateResize()
        window.scrollTo({top: '0', behavior: 'smooth'});
      } else {

        setValuesRevision(selectedValues)
        setData(consultaSaldo.data.data)
        context.changeValues({ simulacaoBV: consultaSaldo.data.data, prazo: selectedValues.length * 12, montante: parseFloat(consultaSaldo.data.data.valorLiberado.toFixed(2)), repasse: selectedValues, valorTotalProposta: totalValueRepasse })
        setShowValuesFGTS(!showValuesFGTS)
        setShowRevisionValuesFGTS(!showRevisionValuesFGTS)
        props.updateResize()
        window.scrollTo({top: '0', behavior: 'smooth'});  
      }
      setLoading(false)
    } else {
      setFeedbackErrorMessage('Selecione pelo menos 2 saques para continuar');
      setOpenFeedbackError(!openFeedbackError)
      setLoading(false)
    }
    
  }

  const renderValuesFGTS = () => {
    setShowValuesFGTS(!showValuesFGTS)
    setShowRevisionValuesFGTS(!showRevisionValuesFGTS)
    props.updateResize()
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  const handleSelectValue = (index, selectedValue) => {
    let values_fgts = [...context.state.values_saque_fgts];
    values_fgts[index].selected = !selectedValue
    context.changeState({values_saque_fgts: values_fgts})
  }

  return(
    <>
      <div ref={refInitPageResume}></div>

      {
        showValuesFGTS && showError !== true && loading === false &&
          <Grid item container direction="column" alignItems="center" xs={12}>
            <Typography className={props.classes.helpText}>Selecione abaixo as parcelas que você deseja bloquear do seu FGTS e descubra quanto você consegue antecipar na próxima etapa:</Typography>
            
            <FormGroup style={{width: 330}}>
                {
                  context.state.values_saque_fgts && context.state.values_saque_fgts.map((value, index) => <>
                    <div key={value.dataRepasse} style={{ display: 'flex', cursor: 'pointer'}}>
                      <Grid item xs={2}>
                        <Checkbox style={{ color: '#00b572', marginLeft: 10 }} onClick={() => handleSelectValue(index, value.selected)} checked={value.selected} />
                      </Grid>
                      <Grid item xs={10} onClick={() => handleSelectValue(index, value.selected)} style={{ cursor: 'pointer'}}>
                        <span style={{position: 'relative', top: 5}}>{`${formatFloatToCurrency(value.valor)}`}</span>
                        <Chip label={`Antecipação de ${moment(value.dataRepasse).format('YYYY')}`} style={{marginTop: 5, marginLeft: 10, cursor: 'pointer', color: value.selected? 'white' : 'black', backgroundColor: value.selected? '#00b572' : '' }} />
                      </Grid>
                    </div>
                    <span style={{color: 'grey', marginLeft: 20}}>{value.numeroParcela}º Parcela</span>
                  </>)
                }
            </FormGroup>

            <Button variant='contained' color="primary" style={{ color: 'white', marginTop: 20 }} onClick={() => handleChangeStateBetweenSelectValueAndRevision()}>consultar valor a ser liberado</Button>
          </Grid>
      }

      {loading &&
        <Grid item container direction="column" alignItems="center" xs={12}>
          <Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>Aguarde um momento</Typography>
          <Typography className={props.classes.helpText}>Estamos realizando a consulta de valores no Banco Votorantim</Typography>
          <CircularProgress />
        </Grid>  
      }

      {
        showRevisionValuesFGTS && showError !== true &&
          <div>
            <Grid item container direction="column" alignItems="center" xs={12}>
              <Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>Resumo da Simulação</Typography>
            </Grid>

            <Grid item container direction="column" alignItems="flex-start" xs={12} className={props.classes.itemRevision}>
              <Typography variant="h6" gutterBottom style={{ color: 'grey' }}>Nessa simulação, você vai receber em sua conta</Typography>
              <Typography variant="h6" gutterBottom style={{ marginTop: -13, fontWeight: 600 }}>{formatFloatToCurrency(data.valorLiberado)}</Typography>

              <Typography variant="h6" gutterBottom style={{ color: 'grey' }}>Valor total do saldo a ser bloqueado do seu FGTS</Typography>
              <Typography variant="h6" gutterBottom style={{ marginTop: -13, fontWeight: 600 }}>{formatFloatToCurrency(totalValueDataRepasse)}</Typography>  
              
              <Typography variant="h6" gutterBottom style={{color: 'grey' }}>Você está antecipando {valuesRevision.length} saque(s) aniversário referente(s) ao(s) ano(s)</Typography>
              <Grid container spacing={1}>
                {
                  valuesRevision.map(value => <Grid item xs={3} sm={2} md={1}><Chip label={`${moment(value.dataRepasse).format('YYYY')}`} key={value.dataRepasse} style={{color: '#00b572', backgroundColor: '#c7ffea', fontWeight: 'bold'}} /></Grid>)
                }
              </Grid>
              <br/>

              <Typography variant="h5" gutterBottom style={{ color: 'grey', fontWeight: 'bold' }}>Custos Detalhados</Typography>
              
              <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                <Typography variant="h6" gutterBottom style={{ color: 'grey' }}>Valor liberado + IOF</Typography>
                <Typography variant="h6" gutterBottom style={{ color: 'grey' }}>Índice(%)</Typography>
              </div>

              <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                <Typography variant="h6" gutterBottom style={{ marginTop: -13, fontWeight: 600 }}>{formatFloatToCurrency(data.valorPresente)}</Typography>
                <Typography variant="h6" gutterBottom style={{ marginTop: -13, fontWeight: 600 }}>100%</Typography>
              </div>

              <Typography variant="h6" gutterBottom style={{ color: 'grey' }}>Valor líquido de cŕedito</Typography>
              <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                <Typography variant="h6" gutterBottom style={{ marginTop: -13, fontWeight: 600 }}>{formatFloatToCurrency(data.valorLiberado)} </Typography>
                <Typography variant="h6" gutterBottom style={{ marginTop: -13, fontWeight: 600 }}>97%</Typography>
              </div>

              <Typography variant="h6" gutterBottom style={{ color: 'grey' }}>Valor do IOF</Typography>
              <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                <Typography variant="h6" gutterBottom style={{ marginTop: -13 }}>{formatFloatToCurrency(data.valorIof)}</Typography>
                <Typography variant="h6" gutterBottom style={{ marginTop: -13, fontWeight: 600 }}>3%</Typography>
              </div>
            
              <Typography variant="h6" gutterBottom style={{ color: 'grey' }}>Taxa de juros</Typography>
              <Typography variant="h6" gutterBottom style={{ marginTop: -13 }}>{formatPorcentage(data.valorTaxaNet)}% a.m / {formatPorcentage(data.taxaJurosAnual)}% a.a</Typography>
              <Typography variant="h6" gutterBottom style={{ color: 'grey' }}>CET</Typography>
              <Typography variant="h6" gutterBottom style={{ marginTop: -13 }}>{formatPorcentage(data.taxaCetMes)}% a.m / {formatPorcentage(data.taxaCetAno)}% a.a</Typography>
            </Grid>
            
            <Grid item container direction="column" alignItems="flex-start" xs={12} className={props.classes.contract}>
              <Typography variant="h6" gutterBottom style={{ textAlign: "center", color: '#00b572', margin: 40 }}>
                Ao clicar em continuar para contratação, você concorda com os termos da proposta de crédito de antecipação do FGTS e do contrato.
              </Typography>
            </Grid>
            <Form>
              <FormActions isSubmitting={ isSubmitting } disabled={ etapa!=context.state.etapa } label="Prosseguir para contratação" />
              <Aviso errors={errors} touched={touched}/>
            </Form>
            <Grid item container direction="column" alignItems="center" xs={12}>
              <Button variant='outlined' color="primary" onClick={() => renderValuesFGTS()}>Voltar para simulação</Button>
            </Grid>
            
          </div>
      }

      {
        showError &&
          <>
            <Grid item container direction="column" alignItems="center" xs={12}>
              <Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>Houve um erro ao realizar a consulta por parte do Banco Votorantim</Typography>
                <Typography className={props.classes.helpText2}>{errorMessage}</Typography>
                <Typography className={props.classes.helpText1}>{errorDescription}</Typography>
            </Grid>

            <Grid item container direction="column" alignItems="center" xs={12}>
              <Button variant='contained' color="primary" style={{ color: 'white' }} onClick={() => handleChangeStateBetweenSelectValueAndRevision()}>tentar novamente</Button>
              <br/>
            <Button variant='outlined' color="primary" onClick={() => {
              setShowValuesFGTS(true)
              setShowError(false)
              props.updateResize()
              
              }}>Voltar para simulação</Button>
            </Grid>
          </>
      }

      <Snackbar style={{position: 'relative', margin: '0 auto'}} open={openFeedbackError} autoHideDuration={3000} onClose={() => setOpenFeedbackError(!openFeedbackError)}>
        <Alert onClose={() => setOpenFeedbackError(!openFeedbackError)} severity="error">{feedbackErrorMessage}</Alert>  
      </Snackbar>
    </>
  )
}

export default withStyles(styles)(EtapaProdutoFGTSValores);