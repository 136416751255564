import React from 'react';
import FormContext from './FormContext';
import { withTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import logo from '../assets/img/logo-quero-financiar-black.svg'
import { Link } from 'react-router-dom';

const styles = theme => ({
  root:{
    background: theme.palette.primary.light,
  },
  logo:{
    display: 'inline-block',
    width: 160,
    height: 'auto',
    marginTop: theme.spacing()*2,
    marginBottom: theme.spacing(),
  },
  atendimento:{
    maxWidth: 360,
    textAlign: 'center',
    margin: `0 auto`,
    color: 'rgba(0,0,0,0.6)',
  },
  horarioAtendimento:{
    marginTop: theme.spacing()*2,
    fontSize: '14px',
    lineHeight: '18px',
    '& strong':{
      textTransform: 'uppercase',
    }
  },
  icon:{
    width: 24,
    height: 24,
    opacity: .6,
    margin: `0px 0px 0px 12px`,
  },
  email:{
    marginTop: theme.spacing(2),
  },
  phone:{
    marginTop: theme.spacing(2),
  },
  fbWrapper:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    '&:hover':{
      textDecoration: 'none',
    },
  },
  fb:{
    width: 24,
    height: 24,
    opacity: .6,
    color: theme.palette.primary.main,
  },
  qfNoFb:{
    color: 'rgba(0,0,0,.6)',
  },
  disclaimer1:{
    marginBottom: theme.spacing(3),
    fontSize: 15,
    color: 'rgba(0, 0, 0, 0.6)',
    lineHeight: '16px',
    letterSpacing: '.09px',
  },
  disclaimer2:{
    fontSize: 15,
    color: 'rgba(0, 0, 0, 0.6)',
    lineHeight: '16px',
    letterSpacing: '.09px',
  },
  disclaimerWrapper:{
    maxWidth: 1150,
    margin: `0 ${theme.spacing(2)}px`,
    // padding: `${theme.spacing(5)}px ${theme.spacing(2)}px`,
    borderTop: `1px solid rgba(0,0,0,.6)`,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up(1150)]:{
      margin: '0 auto',
    },
    textAlign: 'justify'
  },
  hr:{
    opacity: '0.6',
    borderTop: '1px solid #000',
    borderBottom: 'none',
  },
  hr2:{
    opacity: '0.6',
    borderTop: '1px solid #000',
    borderBottom: 'none',
    marginTop: theme.spacing(3),
  },
  siteName:{
    // color: 'rgba(0, 0, 0, 0.6)',
  },
  footerLine:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.6)',
    maxWidth: 1150,
    margin: `0 ${theme.spacing(2)}px`,
    paddingTop: `${theme.spacing(1)}px`,
    paddingBottom: `${theme.spacing(6)}px`,
    [theme.breakpoints.up(1150)]:{
      margin: '0 auto',
    },
    borderTop: `1px solid rgba(0,0,0,.6)`,
  },
  botoesContainer:{
    textAlign: 'center',
  },
  botao:{
    padding: theme.spacing(1),
  },
})

const Footer = (props) => {
  const context = React.useContext(FormContext);
  return (
    <div className={props.classes.root}>
      <div className={props.classes.atendimento}>
        <img className={props.classes.logo} src={logo}/>
      </div>
      {/* <Link to={'/privacidade'}><Typography gutterBottom style={{ textAlign:'center', marginBottom:16 }}>Privacidade</Typography></Link> */}
      {
        context.state.etapas[context.state.etapa-1] && ["Seu crédito","Vamos começar?","Seu perfil"].indexOf(context.state.etapas[context.state.etapa-1].titulo) != -1 &&
        <div className={props.classes.disclaimerWrapper}>
          <Typography className={props.classes.disclaimer1}>
            {
              context.state.textoLegal &&
              <React.Fragment>
                <strong>Importante: </strong>{context.state.textoLegal}
              </React.Fragment>
            }
          </Typography>
          <Typography className={props.classes.disclaimer2}>
            {
              context.state.infosAdicionais &&
              <React.Fragment>
                <strong>informações adicionais: </strong>{context.state.infosAdicionais}
              </React.Fragment>
            }
          </Typography>
        </div>
      }
      <div className={props.classes.footerLine}>
            <Typography className={props.classes.siteName}>QUEROFINANCIAR.COM - {new Date().getFullYear()}</Typography>
        <LockIcon className={props.classes.icon} />
      </div>
    </div>
  )
}
export default withTheme(withStyles(styles)(Footer));