import React from 'react'
import { Form, useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import FormActions from '../FormActions';
import FormContext from '../FormContext';
import Aviso from '../Aviso';
import CampoSelectNative from '../fields/CampoSelectNative';
import Loading from '../Loading';

const styles = theme => ({
  etapaTitulo: {
  },
});

const EtapaVeiculoMarca = (props) => {
  const context = React.useContext(FormContext);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const [isBusy, setIsBusy] = React.useState(false);

  const init = async () => {
    setIsBusy(true)
    const brands = await context.getVehicleBrands()
    context.changeState({
      veiculo_marca_options: brands,
    })
    setIsBusy(false)
  }

  React.useEffect(()=>{
    init()
  },[])

  return (
    <Form>
      <CampoSelectNative
        options={context.state.veiculo_marca_options || []}
        name="veiculo_marca"
        label="Qual é a marca do seu veículo? *"
        value={values.veiculo_marca}
        error={errors.veiculo_marca}
        touched={touched.veiculo_marca}
        placeholder={'Exemplo: Volkswagen'}
        helperText={errors.veiculo_marca && touched.veiculo_marca ? errors.veiculo_marca : ''}
        onChange={(event, data) => {
          handleChange(event);
          setFieldValue('veiculo_marca',data.value);
          setFieldValue('veiculo_marca_label',data.label);
          context.changeValues({
            veiculo_modelo: '',
          })
        }}
      />
      {
        isBusy &&
        <div style={{textAlign: `center`, width: `100%`}}>
          <Loading visible={true}/>
        </div>

      }
      <FormActions isSubmitting={ isSubmitting }  disabled={ isBusy } />
      <Aviso errors={errors} touched={touched}/>
    </Form>
  )
};

export default withStyles(styles)(EtapaVeiculoMarca);
