import React, { useState } from "react";
import { Form, useFormikContext } from "formik";
import { useTheme, withStyles } from "@material-ui/core/styles";
import FormActions from "../FormActions";
import FormContext from "../FormContext";
import CampoOpcoes from "../fields/CampoOpcoes";
import Aviso from "../Aviso";
import CampoVeiculoPlaca from "../fields/CampoVeiculoPlaca";
import CampoRadio from "../fields/CampoRadio";
import CampoSelect from "../fields/CampoSelect";
import CampoNumero from "../fields/CampoNumero";
import { useHistory } from "react-router-dom";
import getBancos from '../../lib/getBancos'
import HelpOutlineOutlined from '@material-ui/icons/HelpOutlineOutlined';

import {
  Button,
  Dialog,
  DialogContentText,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  InputLabel,
  FormControl,
  Grid,
  Select
} from "@material-ui/core";

const styles = (theme) => ({
  closeButton: {
    display: "block",
    margin: "0 0 0 auto",
  },
  selectData: {
    padding: 10,
    margin: 20,
    // border: "1px solid grey",
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: 'inset 0px 0px 0px 1px #e7e7e7;',

  },
  selectDataContainer: {
    padding: 10,
    margin: 20,
    borderRadius: '10px',
    boxShadow: 'inset 0px 0px 0px 1px #e7e7e7;',

  },
  box: {
    padding: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // width: 500,
    // height: 500,
  },
  buttons: {

  },
  sendDocumentos: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const EtapaContaSantander = (props) => {
  const context = React.useContext(FormContext);
  const {
    values,
    errors,
    isSubmitting,
    handleChange,
    touched,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();
  const { fields, etapa, classes} = props;
  const [showConditions, setShowConditions] = useState(false);

  React.useEffect(() => {
    if (context.state.values.conta_santander_debito_automatico === 'Sim') {
      const bancoSantander = getBancos().filter(banco => banco.value === '033')
      setFieldValue('banco', bancoSantander[0].value);
      setFieldValue('banco_label', bancoSantander[0].label);
      context.changeValues({
        banco: bancoSantander[0].value,
        banco_label: bancoSantander[0].label,
        banco_tipo_conta: '',
      })
    } else {
      setFieldValue('banco', '');
      setFieldValue('banco_label', '');
      setFieldValue('banco_tipo_conta', '');
      context.changeValues({
        banco: '',
        banco_label: '',
        banco_tipo_conta: '',
      })
    }
  }, [context.state.values.conta_santander_debito_automatico])

  return (
    <>
      <div style={{width: '70%', margin: '0 auto'}}>
        <span style={{ fontWeight: 'bold', position: 'relative', top: -10, fontSize: 20 }}>
          Aumente suas <span style={{ color: 'rgb(0, 181, 114)' }}>chances de aprovação em até 30%</span> na solicitação do seu empréstimo na <span style={{ color: '#fe0064', fontWeight: 'bold' }}>SIM</span>!
        </span>
        <DialogContentText>Caso você tiver uma conta Santander, você pode aumentar suas chances em até 30% de conseguir o seu empréstimo optando pela modalidade de débito em conta.</DialogContentText>
        <DialogContentText style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={ () => setShowConditions(state => !state)}>Clique aqui e saiba como funciona</DialogContentText>
        {/* <div className={classes.selectData}>
          <div className={classes.box}>
            <div style={{textAlign: 'center'}}>
              <Typography variant="h3" gutterBottom><b>Importante!</b></Typography>
            </div>
          </div>

          <div className={classes.buttons}>
            <DialogContentText>Você deverá reemitir o CRV/ Dut no Detran, em 30 dias do início do contrato, conforme previsto nos Artigo123 e 233 do Código de Trânsito Brasileiro.</DialogContentText>
          </div>
        </div> */}

      </div>

      <Form>
        {
          fields.includes("conta_santander_debito_automatico") &&
          (
            <CampoOpcoes
              label="Você possui conta Santander e deseja aumentar suas chances optando pelo débito em conta? *"
              name="conta_santander_debito_automatico"
              value={values.conta_santander_debito_automatico}
              touched={touched.conta_santander_debito_automatico}
              showError={errors.conta_santander_debito_automatico}
              options={[
                { label: "Sim", value: "Sim" },
                { label: "Não", value: "Não" },
              ]}
              classNamePrefix="quero-financiar-select"
              className="campo-selecao"
              onFocus={props.changeFocus}
              onChange={(data) => {
                setFieldValue("conta_santander_debito_automatico", data.value);
                context.changeValues({
                  conta_santander_debito_automatico: data.value,
                });
              }}
              onBlur={(e) => {
                setFieldTouched("conta_santander_debito_automatico", true);
              }}
            />
          )
        }
      <Grid container spacing={3}>

      {
        fields.includes('banco') && values.conta_santander_debito_automatico == "Sim"&&
            <Grid item xs={12}>
              <FormControl variant="outlined" style={{width: '100%'}}>
                <InputLabel>Banco</InputLabel>
                <Select
                  disabled
                  value="033"
                  label="Banco"
                  >
                  {
                    getBancos().map(banco => (
                      <option key={banco.value} value={banco.value}>{banco.label}</option>
                    ))
                  }
                </Select>
            </FormControl>
          </Grid>
      }
        {
          fields.includes('banco_agencia') && values.conta_santander_debito_automatico == "Sim" &&
            <Grid item xs={6}>
              <CampoNumero
                label="Agência sem digito"
                name="banco_agencia"
                id="banco_agencia"
                placeholder="Agência sem dígito (somente número)"
                type="tel"
                inputProps={{ maxLength: 4 }}
                value={values.banco_agencia}
                touched={touched.banco_agencia}
                onChange={handleChange}
                error={errors.banco_agencia}
                helperText={errors.banco_agencia && touched.banco_agencia ? errors.banco_agencia : ''}
                showError={errors.banco_agencia}
              />
            </Grid>
        }
        {
          fields.includes('banco_conta') && values.conta_santander_debito_automatico == "Sim" &&
            <Grid item xs={6}>
              <CampoNumero
                label="Conta com dígito"
                name="banco_conta"
                id="banco_conta"
                type="tel"
                placeholder="Conta com dígito (somente números)"
                inputProps={{ maxLength: 13 }}
                value={values.banco_conta}
                touched={touched.banco_conta}
                onChange={handleChange}
                error={errors.banco_conta}
                helperText={errors.banco_conta && touched.banco_conta ? errors.banco_conta : ''}
                showError={errors.banco_conta}
              />
            </Grid>
        }
      </Grid>


        <Dialog onClose={() => setShowConditions(state => !state)} open={showConditions}>
          <DialogTitle>Como posso aumentar minhas chances de aprovação do crédito em até 30%?</DialogTitle>
          <DialogContent>
            <DialogContentText>Primeiramente você tem que ter uma conta Santander e concordar com a opção de débito em conta. Isso é: todo mês, o banco Santander irá descontar o valor da parcela do empréstimo da sua conta Santander informada.</DialogContentText>
            <DialogContentText>O débito em conta é uma modalidade de empréstimo na qual as parcelas não são pagas por boleto, mas sim por débito automático na sua conta do Santander. No dia do pagamento, a instituição financeira retira o valor da parcela da conta corrente.</DialogContentText>
            <DialogContentText>Vale lembrar que tanto o depósito quanto ao débito das parcelas do seu empréstimo serão feitas na sua conta informada do Santander, tá?</DialogContentText>
            <DialogContentText><b>Atenção:</b> Essa chance de aumento de 30% em sua aprovação de crédito só está dispónivel para nosso parceiro <span style={{ color: '#fe0064', fontWeight: 'bold' }}>SIM</span>. Para ter essa chance de 30%, você tem que ter uma conta conta Santander e concordar com o débito em conta das parcelas do empréstimo.</DialogContentText>
            {/* <DialogContentText></DialogContentText> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowConditions(state => !state)} color="primary">fechar</Button>
          </DialogActions>
        </Dialog>

        <FormActions
          isSubmitting={isSubmitting}
          disabled={etapa != context.state.etapa}
        />
        <Aviso errors={errors} touched={touched} />
      </Form>
    </>
  );
};

export default withStyles(styles)(EtapaContaSantander);
