import React from "react";
import {
  Dialog, DialogTitle, DialogActions, DialogContentText, DialogContent, withTheme, Button, Snackbar, CircularProgress, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { Formik, Form, useFormikContext } from 'formik';
import { isMobile } from 'react-device-detect';
import AddIcon from "@material-ui/icons/Add";
import CheckCircle from '@material-ui/icons/CheckCircle';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import HelpOutlineOutlined from '@material-ui/icons/HelpOutlineOutlined';
// import CameraAlt from "@material-ui/icons/CameraAlt";
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios'
import FormContext from '../../FormContext';
import FormActions from "../../FormActions";
import ArrowRightAltRounded from '@material-ui/icons/ArrowRightAltRounded';

import FrenteDocumento from '../../../assets/img/frente_documento.png'
import VersoDocumento from '../../../assets/img/verso_documento.png'

const styles = (theme) => ({
  selectData: {
    padding: 10,
    // border: "1px solid grey",
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: 'inset 0px 0px 0px 1px #e7e7e7;',

  },
  box: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // width: 500,
    // height: 500,
  },
  buttons: {

  },
  sendDocumentos: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} style={{position: 'sticky', top: 50, left: 200}} />;
}

const formDocumentos = (props) => {

  const { classes } = props
  const context = React.useContext(FormContext);
  const urlParams = new URLSearchParams(window.location.search);
  const { isSubmitting } = useFormikContext();
  const proposalId = urlParams.get("proposal");
  const [frontPhotoDocument, setFrontPhotoDocument] = React.useState();
  const [isFrontPhotoDocumentPicked, setIsFrontPhotoDocumentPicked] = React.useState(false);
  const [backPhotoDocument, setBackPhotoDocument] = React.useState();
	const [isBackPhotoDocumentPicked, setIsBackPhotoDocumentPicked] = React.useState(false);
	const [loadingFront, setLoadingFront] = React.useState(false);
	const [loadingBack, setLoadingBack] = React.useState(false);

  const [loading, setLoading] = React.useState(true)
  const [hasErrorFront, setHasErrorFront] = React.useState(false)
  const [hasErrorBack, setHasErrorBack] = React.useState(false)
  const [messageError, setMessageError] = React.useState('')
  const [proposalData, setProposalData] = React.useState()
  const [showHowToDo, setShowHowToDo] = React.useState(false)

  const [triggerPhotoFrontSended, setTriggerPhotoFrontSended] = React.useState(false)
  const [triggerPhotoBackSended, setTriggerPhotoBackSended] = React.useState(false)

  const validateImageFormat = (format) => {
    if (
      format === 'image/jpeg' ||
      format === 'image/jpg' ||
      format === 'image/png'
    ) {
      return true
    } else {
      return false;
    }
  }

  const changeHandlerFrontPhoto = async (event) => {

    if (validateImageFormat(event.target.files[0].type)) {

      event.persist();
      setLoadingFront(true)
      setFrontPhotoDocument(event.target.files[0])
      setIsFrontPhotoDocumentPicked(true)

      try{

        const token = await context.getFirebaseToken()
        const frontPhotoFormData = new FormData();
        frontPhotoFormData.append('file', event.target.files[0])

        await axios.post(`${context.state.API_ENDPOINT}/sim/proposal-document/${proposalData.id}/DOC_IDENTIDADE_FRENTE/${event.target.files[0].name}`, frontPhotoFormData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          },
        })

        setHasErrorFront(false)
        setTriggerPhotoFrontSended(true)

      }catch(error){

        let messageError = ''
        if (error.response==undefined || error.response.data.message === 'Todos os campos devem ser informados!') {
          messageError = "Houve um erro ao enviar a foto. Por favor, tente novamente."
        } else {
          messageError = error.response.data.message
        }
        setMessageError(messageError)
        setHasErrorFront(true)

      }

    } else {

      setMessageError('Formato inválido. Envie uma foto do documento com um dos seguintes formatos: PNG ou JPG/JPEG')
      setHasErrorFront(true)

    }

    setLoadingFront(false)

  };

  const changeHandlerBackPhoto = async (event) => {

    if (validateImageFormat(event.target.files[0].type)) {

      event.persist();
      setLoadingBack(true)
      setBackPhotoDocument(event.target.files[0]);
      setIsBackPhotoDocumentPicked(true);

      try{
        
        const token = await context.getFirebaseToken()
        const backPhotoFormData = new FormData();
        backPhotoFormData.append('file', event.target.files[0])
  
        await axios.post(`${context.state.API_ENDPOINT}/sim/proposal-document/${proposalData.id}/DOC_IDENTIDADE_VERSO/${event.target.files[0].name}`, backPhotoFormData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          },
        })

        setHasErrorBack(false)
        setTriggerPhotoBackSended(true)

      }catch(error){

        let messageError = ''
        if (error.response==undefined || error.response.data.message === 'Todos os campos devem ser informados!') {
          messageError = "Houve um erro ao enviar a foto. Por favor, tente novamente."
        } else {
          messageError = error.response.data.message
        }
        setMessageError(messageError)
        setHasErrorBack(true)

      }

    } else {

      setMessageError('Formato inválido. Envie uma foto do documento com um dos seguintes formatos: PNG ou JPG/JPEG')
      setHasErrorBack(true)

    }

    setLoadingBack(false)

  };

  React.useEffect(() => {
    const getProposalData = async () => {
      try{
        const token = await context.getFirebaseToken();
        const proposalResult = await axios.get(`${context.state.API_ENDPOINT}/proposals/${proposalId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log('proposal fetched',proposalResult.data.data)
        setProposalData(proposalResult.data.data)

        if(
          proposalResult.data.data.doc_identidade_frente_enviado==true &&
          proposalResult.data.data.doc_identidade_verso_enviado==true
        ){
          context.nextStepWithoutSubmitForm()
        }

      }catch(error){
        console.log(error)
        setProposalData(null)
      }
      setLoading(false)
      props.updateResize()

    }
    getProposalData()
  }, [context.state.firebaseLoaded, triggerPhotoFrontSended, triggerPhotoBackSended])

  return (
    <>
    {
        loading === false ?
          <>
            <Typography>Proposta <span style={{ color: '#fe0064', fontWeight: 'bold' }}>SIM #{proposalData.externalId}</span></Typography>
            <DialogContentText>Basta selecionar/tirar a foto e confirmar. A foto será enviada automaticamente para a proposta assim que você confirmar a seleção da foto.</DialogContentText>
            <div className={classes.selectData}>
              <div className={classes.box}>
                {/* <p>Foto da frente do documento</p> */}
                <p>Foto da Frente do documento <HelpOutlineOutlined onClick={ () => setShowHowToDo(state => !state)} style={{position: 'relative', top: 8, cursor: 'pointer', color: 'rgb(0, 181, 114)' }} /></p>
                <img src={FrenteDocumento} alt="Foto do verso do documento" width={250} />
                {
                  proposalData.doc_identidade_frente_enviado ?
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <div><DialogContentText>Documento já enviado</DialogContentText></div>
                      <div><CheckCircle color="primary" fontSize="large"/><span style={{fontWeight: 'bold', position: 'relative', top: -10}}>Foto da frente <span style={{color: 'rgb(0, 181, 114)'}}>enviado com sucesso!</span></span></div>
                    </div>
                    :
                    isFrontPhotoDocumentPicked ?
                      <DialogContentText style={{ wordBreak: 'break-all' }}>{frontPhotoDocument.name}</DialogContentText>
                      :
                      <div style={{textAlign: 'center'}}>
                        <DialogContentText>Nenhum arquivo selecionado</DialogContentText>
                        <DialogContentText style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={ () => setShowHowToDo(state => !state)}>Clique aqui e saiba como bater essa foto</DialogContentText>
                      </div>
                }
                {
                  hasErrorFront && <div><ErrorOutline fontSize="large" style={{color: 'red'}} /><span style={{ fontWeight: 'bold', position: 'relative', top: -10 }}>{messageError}</span></div>
                }
              </div>

              <div className={classes.buttons}>
                {
                  loadingFront ? <CircularProgress />
                    :
                    proposalData.doc_identidade_frente_enviado === false ?
                    <Button component="label" color="primary" style={{backgroundColor: '#C9E265', borderRadius: 20, color: 'black'}} disabled={proposalData.doc_identidade_frente_enviado}>
                      <AddIcon /> <span style={{ color: "primary" }}>{ isMobile ? 'Tirar/Anexar': 'Anexar'} foto</span>
                      <input type="file" hidden onChange={changeHandlerFrontPhoto} accept=".jpg,.jpeg,.png"/>
                    </Button> : ''
                }

              </div>
            </div>
            <br />
            {/* <DialogContentText>Basta selecionar/tirar a foto e confirmar. A foto será enviada automaticamente para a proposta assim que você confirmar a seleção da foto.</DialogContentText> */}
            <div className={classes.selectData}>
              <div className={classes.box}>
                {/* <p>Foto do verso do documento</p> */}
                <p>Foto do Verso do documento <HelpOutlineOutlined onClick={() => setShowHowToDo(state => !state)} style={{ position: 'relative', top: 8, cursor: 'pointer', color: 'rgb(0, 181, 114)' }} /></p>
                <img src={VersoDocumento} alt="Foto do verso do documento" width={250}/>
                {
                  proposalData.doc_identidade_verso_enviado ?
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <div><DialogContentText>Documento já enviado</DialogContentText></div>
                      <div><CheckCircle color="primary" fontSize="large"/><span style={{fontWeight: 'bold', position: 'relative', top: -10}}>Foto do verso <span style={{color: 'rgb(0, 181, 114)'}}>enviado com sucesso!</span></span></div>
                    </div>
                    :
                    isBackPhotoDocumentPicked ? <DialogContentText style={{ wordBreak: 'break-all' }}>{backPhotoDocument.name}</DialogContentText>
                      :
                      <div style={{textAlign: 'center'}}>
                        <DialogContentText>Nenhum arquivo selecionado</DialogContentText>
                        <DialogContentText style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={ () => setShowHowToDo(state => !state)}>Clique aqui e saiba como bater essa foto</DialogContentText>
                      </div>
                }
                {
                  hasErrorBack && <div><ErrorOutline fontSize="large" style={{color: 'red'}} /><span style={{ fontWeight: 'bold', position: 'relative', top: -10 }}>{messageError}</span></div>
                }
              </div>

              <div className={classes.buttons}>
                {
                  loadingBack ? <CircularProgress />
                    :
                    proposalData.doc_identidade_verso_enviado === false ?
                    <Button component="label" color="primary" style={{ backgroundColor: '#C9E265', color: 'black', borderRadius: 20 }} disabled={proposalData.doc_identidade_verso_enviado}>
                      {" "}
                      <AddIcon /> <span style={{color:"primary"}}>{ isMobile ? 'Tirar/Anexar': 'Anexar'} foto</span>
                      <input type="file" hidden onChange={changeHandlerBackPhoto} accept=".jpg,.jpeg,.png"/>
                    </Button> : ''
                }
              </div>

            </div>
            <div className={classes.sendDocumentos}>
              <FormActions isSubmitting={ isSubmitting } disabled={ proposalData.doc_identidade_frente_enviado === false || proposalData.doc_identidade_verso_enviado === false } onClick={() => context.nextStepWithoutSubmitForm()} />
              {/* <FormActions onClick={() => context.nextStepWithoutSubmitForm()} label="passar para a proxima etapa - staging" /> */}

            </div>

            <Dialog onClose={() => setShowHowToDo(state => !state)} open={showHowToDo}>
              <DialogTitle>Como enviar a foto</DialogTitle>
              <DialogContent>
                <DialogContentText>Dicas importantes para uma boa foto</DialogContentText>
                <DialogContentText><ArrowRightAltRounded /><span style={{position: 'relative', top: -5}}>Bata as fotos em um ambiente claro</span></DialogContentText>
                <DialogContentText><ArrowRightAltRounded /><span style={{position: 'relative', top: -5}}>De preferência em retirar o documento da capa protetora, assim a foto sai mais legível</span></DialogContentText>
                <DialogContentText><ArrowRightAltRounded /><span style={{position: 'relative', top: -5}}>Certifique-se de ter batido uma boa foto</span></DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowHowToDo(state => !state)} color="primary">fechar</Button>
              </DialogActions>
            </Dialog>
          </>
          : <div style={{ textAlign: `center` }}><CircularProgress/></div>
    }

    </>
  );
};

export default withTheme(withStyles(styles)(formDocumentos));
