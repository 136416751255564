import React from 'react';
import MaskedInput from 'react-text-mask'
import TextField from '@material-ui/core/TextField';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      type="tel"
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/,/\d/,/\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/]}
      placeholder="_____-___"
      guide={true}
    />
  );
}

const CampoCep = (props) => {

  return (
    <div className="campo-wrapper">
      <TextField
        {...props}
        InputProps={{
          inputComponent: TextMaskCustom,
        }}
        variant="outlined"
        fullWidth
      />
    </div>
  );

}

export default CampoCep;