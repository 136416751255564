import React from 'react'
import { Form, useFormikContext } from 'formik';
// import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import FormActions from '../FormActions';
import FormContext from '../FormContext';
import Aviso from '../Aviso';
import CampoSelectNative from '../fields/CampoSelectNative';

const styles = theme => ({
  etapaTitulo: {
  },
});

const EtapaVeiculoCheckMolicar = (props) => {
  const context = React.useContext(FormContext);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const { fields, etapa } = props;
  const [isNotSelected, setIsNotSelected] = React.useState(true);

  React.useEffect(() => {
    if (context.state.values.veiculo_versao !== '') {
      setIsNotSelected(false)
    }
    setFieldValue('veiculo_modelo', context.state.values.veiculo_modelo);
    setFieldValue('veiculo_versao', context.state.values.veiculo_versao);
    setFieldValue('veiculo_marca', context.state.values.veiculo_marca);
    setFieldValue('veiculo_ano', context.state.values.veiculo_ano);
  },[]);

  return (
    <Form>
      <CampoSelectNative
        options={context.state.molicar_options}
        name="veiculo_versao"
        label="Qual é a versão do seu veículo? *"
        value={values.veiculo_versao}
        error={errors.veiculo_versao}
        touched={touched.veiculo_versao}
        helperText={errors.veiculo_versao && touched.veiculo_versao ? errors.veiculo_versao : ''}
        onChange={(event, data) => {
          handleChange(event);
          console.log(data)
          if (data !== undefined) {
            setFieldValue('veiculo_versao', data.value);
            setFieldValue('veiculo_versao_label', data.value);
            setFieldValue('veiculo_modelo', data.modelo);
            setFieldValue('veiculo_marca', data.marca);
            setFieldValue('veiculo_marca_label', data.marca);
            setFieldValue('veiculo_ano', data.anoModelo);
            setIsNotSelected(false)
        } else {
          setIsNotSelected(true)
        }

        }}
      />
      <FormActions isSubmitting={ isSubmitting } disabled={ isNotSelected }  />
      <Aviso errors={errors} touched={touched}/>
    </Form>
  )
};

export default withStyles(styles)(EtapaVeiculoCheckMolicar);
