import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  error: {
    border: `1px solid ${theme.palette.error.main}`,
  },
});

const CampoCheckBox = (props) => {
  const { wrapperClassName, ...rest } = props;
  return (
    <div className={`${props.wrapperClassName} campo-wrapper`} style={{ display: 'flex' }}>
      <FormControlLabel
        // {...rest}
        label=""
        className={rest.error ? props.classes.error : ""}
        control={
          <Checkbox
            // {...rest}
            name={rest.name}
            onChange={rest.onChange}
            checked={rest.value}
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        }
      />
      {props.children}
    </div>
  );

}


export default withStyles(styles)(CampoCheckBox);