import React from 'react'
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import FormActions from '../FormActions';
import FormContext from '../FormContext';
import CampoCpf from '../fields/CampoCpf';
import CampoTexto from '../fields/CampoTexto';
import Aviso from '../Aviso';
import CampoData from '../fields/CampoData';
import CampoOpcoes from '../fields/CampoOpcoes';
import CampoEstado from '../fields/CampoEstado'

import _ from 'lodash';

const styles = theme => ({
  campoAgencia:{
    [theme.breakpoints.up(1024)]:{
      width: '48% !important',
      marginRight: '2% !important',
    }
  },
  campoConta:{
    [theme.breakpoints.up(1024)]:{
      width: '50% !important',
    }
  }
});

const EtapaDocumentos = (props) => {
  const context = React.useContext(FormContext);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const { fields, etapa } = props;
  return (
    <Form>
      {
        fields.includes('cpf') &&
        <CampoCpf
          label="CPF *"
          name="cpf"
          onFocus={ props.changeFocus }
          onBlur={ (e)=>{
            handleBlur(e);
            // context.changeValues({
            //   cpf:e.target.value
            // });
          } }
          onChange={ handleChange }
          value={values.cpf || ""}
          error={errors.cpf && touched.cpf}
          helperText={errors.cpf && touched.cpf ? errors.cpf : ''}
          disabled={Boolean(context.state.values.cpf)}
        />
      }
      {
        fields.includes('rg') &&
        <CampoTexto
          label="RG *"
          name="rg"
          onFocus={ props.changeFocus }
          onBlur={ (e)=>{
            handleBlur(e);
            // context.changeValues({
            //   rg:e.target.value
            // });
          } }
          value={values.rg || ""}
          onChange={handleChange}
          error={errors.rg && touched.rg}
          helperText={errors.rg && touched.rg ? errors.rg : ''}
        />
      }
      {
        fields.includes('data_emissao_rg') &&
        <CampoData
          label="Data de emissão do RG *"
          name="data_emissao_rg"
          onFocus={ props.changeFocus }
          onBlur={ (e)=>{
            handleBlur(e);
            // context.changeValues({
            //   data_emissao_rg:e.target.value
            // });
          } }
          onChange={ handleChange }
          value={values.data_emissao_rg || ""}
          error={errors.data_emissao_rg && touched.data_emissao_rg}
          helperText={errors.data_emissao_rg && touched.data_emissao_rg ? errors.data_emissao_rg : ''}
        />
      }
      {
        fields.includes('orgao_emissor_rg') &&
        <CampoOpcoes
          label="Órgão Emissor do RG *"
          name="orgao_emissor_rg"
          showError={errors.orgao_emissor_rg}
          classNamePrefix="quero-financiar-select"
          className="campo-selecao"
          value={values.orgao_emissor_rg.value || ""}
          touched={touched.orgao_emissor_rg}
          onChange={ ( value ) => {
            setFieldValue('orgao_emissor_rg',value);
            // context.changeValues({
            //   orgao_emissor_rg:value
            // });
          } }
          onBlur={ ( e ) => {
              setFieldTouched('orgao_emissor_rg',true);
          } }
          options={[
              {label:'SSP', value:'SSP'},
              {label:'DPF', value:'DPF'},
              // {label:'MAE', value:'MAE'},
              // {label:'MEX', value:'MEX'},
              // {label:'MMA', value:'MMA'},
              {label:'OUTRO', value:'OUTRO'},
          ]}
        />
      }
      {
        fields.includes('estado_emissor_rg') &&
        <CampoEstado
          name="estado_emissor_rg"
          label="Estado emissor do RG *"
          value={ values.estado_emissor_rg || "" }
          error={errors.estado_emissor_rg}
          touched={touched.estado_emissor_rg}
          onChange={ ( data ) => {
            setFieldValue('estado_emissor_rg',data.value);
          }}
          onBlur={ ( e ) => {
            handleBlur(e);
            setFieldTouched('estado_emissor_rg',true);
          } }
        />
      }
      <FormActions isSubmitting={ isSubmitting } disabled={ etapa!=context.state.etapa }  />
      <Aviso errors={errors} touched={touched}/>
    </Form>
  )
}

export default withStyles(styles)(EtapaDocumentos);