import React from 'react';
import MaskedInput from 'react-text-mask'
import TextField from '@material-ui/core/TextField';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={(rawValue)=>{
          return [/[0-9a-zA-Z]/,/[0-9a-zA-Z]/,/[0-9a-zA-Z]/,/[0-9a-zA-Z]/,/[0-9a-zA-Z]/,/[0-9a-zA-Z]/,/[0-9a-zA-Z]/];
      }}
      placeholder="_______"
      guide={true}
    />
  );
}

const CampoVeiculoPlaca = (props) => {
  
  return (
    <div className="campo-wrapper">
      <TextField
        {...props}
        InputProps={{
          inputComponent: TextMaskCustom,
        }}
        variant="outlined"
        fullWidth
      />
    </div>
  );

}

export default CampoVeiculoPlaca