import theme from './Theme'

const GlobalStyles = {
  '@global': {
    '.qf-transition-enter': {
      opacity:0,
    },
    '.qf-transition-enter-active': {
      opacity:1,
      transition: 'opacity 2000ms',
    },
    '.qf-transition-exit': {
      opacity:1,
    },
    '.qf-transition-exit-active': {
      opacity:0,
      transition: 'opacity 2000ms',
    },
    'form, .solicitacao-container': {
      display: 'flex',
      flexWrap: 'wrap',
      '& fieldset':{
        border: `1px solid rgba(0, 0, 0, 0.23)`,
        borderRadius: theme.spacing(.5),
      },
      '& .campo-wrapper': {
        marginBottom: theme.spacing(3),
        position: 'relative',
        width: '100%',
      },
      '& .campo-erro': {
        color: theme.palette.error.main,
      },
    },
  }
}

export const ReactAutosuggestStyles = {
  container:{
  },
  containerOpen:{
  },
  input:{
  },
  inputOpen:{
  },
  inputFocused:{
  },
  suggestionsContainer:{
    
  },
  suggestionsContainerOpen:{
    margin: `${theme.spacing()}px 0`,
    background: theme.palette.common.white,
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: theme.spacing(.5),
    padding: theme.spacing(.5),
  },
  suggestionsList:{
    maxHeight: 120,
    overflowY: 'auto',
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  suggestion:{
    padding: `${theme.spacing(.5)}px 0`,
  },
  suggestionFirst:{
  },
  suggestionHighlighted:{
    background: 'rgba(0, 0, 0, 0.05)',
    cursor: 'pointer',
  },
  sectionContainer:{
  },
  sectionContainerFirst:{
  },
  sectionTitle:{
  },
}

export default GlobalStyles;