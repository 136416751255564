import Button from "@material-ui/core/Button";
import React from 'react';
import { withStyles, withTheme } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root:{

  },
  label: {
    color: 'white',
  },
  buttonIcon:{
    color: 'white',
    padding: theme.spacing(),
    width: 50,
    marginRight: theme.spacing(),
  },
});

const Botao = (props) => {

  const { onClick, iconImgSrc, label, classes, className, disabled, ...rest  } = props;

  return <Button disabled={disabled} className={className ? className : classes.root} color='primary' variant="contained" onClick={onClick} {...rest}>
    { iconImgSrc && <img src={iconImgSrc} className={classes.buttonIcon} /> }
    <Typography className={classes.label}>{label}</Typography>
  </Button>
}

export default withTheme(withStyles(styles)(Botao));