import React, { useState } from 'react';
import FormContext from '../FormContext';
import { ErrorMessage } from 'formik';
import { useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import CampoLabel from './CampoLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  radio:{

  }
});

const CampoOpcoes = (props) => {
  
  const theme = useTheme();
  const context = React.useContext(FormContext);
  
  return (
    <div className="campo-wrapper">
      <fieldset
        name={props.name}
        onFocus={ props.onFocus }
        className={ (context.campoAtivo==props.name) ? "ativo" : "" }
        style={{ borderColor: props.showError && props.touched ? 'red' : '' }}
      >
        {props.label &&
          <CampoLabel className="campo-titulo">{props.label}</CampoLabel>
        }

        <RadioGroup className={props.classes.root} aria-label={props.name} name={props.name} value={props.value || ""} onChange={
          (e)=>{
            props.onChange( props.options.find( (item) => item.value==e.target.value ) );
          }
        }>

        {
          props.options.map((item,index)=>(
            <FormControlLabel key={index} value={item.value} control={<Radio color="primary" />} label={item.label} />
          ))
        }
        </RadioGroup>
      </fieldset>
      <ErrorMessage name={props.name} render={obj => <div className="campo-erro">{obj}</div>}/>
    </div>
  )
}

export default withStyles(styles)(CampoOpcoes);