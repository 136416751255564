import React from "react";
import {
  Dialog, DialogTitle, DialogActions, DialogContentText, DialogContent, withTheme, Button, Snackbar, CircularProgress, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { useFormikContext } from 'formik';
import { isMobile } from 'react-device-detect';
import AddIcon from "@material-ui/icons/Add";
import CheckCircle from '@material-ui/icons/CheckCircle';
import HelpOutlineOutlined from '@material-ui/icons/HelpOutlineOutlined';
import FormActions from "../../FormActions";
import ErrorOutline from '@material-ui/icons/ErrorOutline';
// import CameraAlt from "@material-ui/icons/CameraAlt";
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios'
import FormContext from '../../FormContext';
import ArrowRightAltRounded from '@material-ui/icons/ArrowRightAltRounded';

import CRLVDocumento from '../../../assets/img/crlv-photo.jpg'

const styles = (theme) => ({
  selectData: {
    padding: 10,
    // border: "1px solid grey",
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: 'inset 0px 0px 0px 1px #e7e7e7;',

  },
  box: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // width: 500,
    // height: 500,
  },
  buttons: {

  },
  sendDocumentos: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const formCRLV = (props) => {

  const { classes } = props
  const urlParams = new URLSearchParams(window.location.search);
  const proposalId = urlParams.get("proposal");
  const { isSubmitting, submitForm} = useFormikContext();

  const context = React.useContext(FormContext);
  const [crlvPhotoDocument, setCrlvPhotoDocument] = React.useState();
  const [isCrlvPhotoDocumentPicked, setIsCrlvPhotoDocumentPicked] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [loadingPage, setLoadingPage] = React.useState(true);

  const [hasError, setHasError] = React.useState(false)
  const [messageError, setMessageError] = React.useState('')

  const [proposalData, setProposalData] = React.useState()
  const [triggerPhotoCRLVSended, setTriggerPhotoCRLVSended] = React.useState(false)
  const [showHowToDo, setShowHowToDo] = React.useState(false)
  const [showWhatIsCRLV, setShowWhatIsCRLV] = React.useState(false)

  const validateImageFormat = (format) => {
    if (
      format === 'image/jpeg' ||
      format === 'image/jpg' ||
      format === 'image/gif' ||
      format === 'application/pdf' ||
      format === 'image/png'
    ) {
      return true
    } else {
      return false;
    }
  }

  const changeHandlerDocumentCRLV = async (event) => {

    if (validateImageFormat(event.target.files[0].type)) {

      setLoading(true)
      event.persist();
      setCrlvPhotoDocument(event.target.files[0]);
      setIsCrlvPhotoDocumentPicked(true);

      try{
        
        const token = await context.getFirebaseToken()
        const formDataCRLV = new FormData();
        formDataCRLV.append('file', event.target.files[0])
  
        await axios.post(`${context.state.API_ENDPOINT}/sim/proposal-document/${proposalData.id}/CRLV/${event.target.files[0].name}`, formDataCRLV, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          },
        })

        setHasError(false)
        setTriggerPhotoCRLVSended(true)

      }catch(error){
        
        let messageError = ''
        if (error.response==undefined || error.response.data.message === 'Todos os campos devem ser informados!') {
          messageError = "Houve um erro ao enviar a foto. Por favor, tente novamente."
        } else {
          messageError = error.response.data.message
        }
        setMessageError(messageError)
        setHasError(true)
      }

    } else {
      setMessageError('Formato inválido. Envie uma foto do documento com um dos seguintes formatos: PNG, PDF, GIF ou JPG/JPEG')
      setHasError(true)
    }

    setLoading(false)

  };

  React.useEffect(() => {
    const getProposalData = async () => {
      try{
        const token = await context.getFirebaseToken();
        const proposalResult = await axios.get(`${context.state.API_ENDPOINT}/proposals/${proposalId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        setProposalData(proposalResult.data.data)
        if(proposalResult.data.data.foto_veiculo_crlv_enviado==true) context.nextStepWithoutSubmitForm()

      }catch(error){
        console.log(error)
        setProposalData(null)
      }
      setLoadingPage(false)
      props.updateResize()

    }
    getProposalData()
  }, [context.state.firebaseLoaded, triggerPhotoCRLVSended])


  return (
    <>
      {
        loadingPage === false ?
          <>
            <Typography>Proposta <span style={{ color: '#fe0064', fontWeight: 'bold' }}>SIM #{proposalData.externalId}</span></Typography>
            <p>Precisamos agora do CRLV, de seu veículo</p>
            <DialogContentText>Basta selecionar/tirar a foto e confirmar. A foto será enviada automaticamente para a proposta assim que você confirmar a seleção da foto.</DialogContentText>
            <div className={classes.selectData}>
              <div className={classes.box}>
                {/* <p>CRLV</p> */}
                <p>Foto do CRLV <HelpOutlineOutlined onClick={ () => setShowHowToDo(state => !state)} style={{position: 'relative', top: 8, cursor: 'pointer', color: 'rgb(0, 181, 114)' }} /></p>
                <DialogContentText style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={ () => setShowWhatIsCRLV(state => !state)}>Clique aqui e saiba o que é CRLV</DialogContentText>
                <img src={CRLVDocumento} alt="Foto do CRLV" width={350}/>
                {
                  proposalData.foto_veiculo_crlv_enviado ?
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <div><DialogContentText>Documento já enviado</DialogContentText></div>
                      <div><CheckCircle color="primary" fontSize="large"/><span style={{fontWeight: 'bold', position: 'relative', top: -10}}>Foto do CRLV <span style={{color: 'rgb(0, 181, 114)'}}>enviado com sucesso!</span></span></div>
                    </div>
                    :
                    isCrlvPhotoDocumentPicked ? <DialogContentText style={{ wordBreak: 'break-all' }}>{crlvPhotoDocument.name}</DialogContentText>
                      :
                      <div style={{textAlign: 'center'}}>
                        <DialogContentText>Nenhum arquivo selecionado</DialogContentText>
                        <DialogContentText style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={ () => setShowHowToDo(state => !state)}>Clique aqui e saiba como bater essa foto</DialogContentText>
                      </div>
                }
                {
                  hasError && <div><ErrorOutline fontSize="large" style={{color: 'red'}} /><span style={{ fontWeight: 'bold', position: 'relative', top: -10 }}>{messageError}</span></div>
                }
              </div>

              <div className={classes.buttons}>
                {
                  loading ? <CircularProgress />
                    :
                    proposalData.foto_veiculo_crlv_enviado === false ?
                    <Button component="label" color="primary" style={{backgroundColor: '#C9E265', borderRadius: 20, color: 'black'}} disabled={proposalData.foto_veiculo_crlv_enviado}>
                      <AddIcon /> <span>{ isMobile ? 'Tirar ': 'Anexar'} foto</span>
                      <input type="file" hidden onChange={changeHandlerDocumentCRLV} accept=".jpg,.jpeg,.png,.gif,.pdf"/>
                    </Button> : ''
                }

              </div>
            </div>
            <br/>
            <div className={classes.sendDocumentos}>
              <FormActions isSubmitting={isSubmitting} disabled={proposalData.foto_veiculo_crlv_enviado === false} onClick={() => submitForm()} />
              {/* <FormActions onClick={() => context.nextStepWithoutSubmitForm()} label="passar para a proxima etapa - staging" /> */}

            </div>

            <Dialog onClose={() => setShowHowToDo(state => !state)} open={showHowToDo}>
              <DialogTitle>Como enviar a foto</DialogTitle>
              <DialogContent>
                <DialogContentText>Dicas importantes para uma boa foto</DialogContentText>
                <DialogContentText><ArrowRightAltRounded /><span style={{position: 'relative', top: -5}}>Abra seu CRLV, de modo que apareça a frente e o verso do documento</span></DialogContentText>
                <DialogContentText><ArrowRightAltRounded /><span style={{position: 'relative', top: -5}}>É importante que esta foto apareça os dois lados do documento</span></DialogContentText>
                <DialogContentText><ArrowRightAltRounded /><span style={{position: 'relative', top: -5}}>Certifique-se de ter batido uma boa foto</span></DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowHowToDo(state => !state)} color="primary">fechar</Button>
              </DialogActions>
            </Dialog>

            <Dialog onClose={() => setShowWhatIsCRLV(state => !state)} open={showWhatIsCRLV}>
              <DialogTitle>O que é CRLV?</DialogTitle>
              <DialogContent>
                <DialogContentText>O CRLV, ou "Certificado de Registro e Licenciamento de Veículos", conhecido popularmente como licenciamento, é um documento indispensável para você manter seu veículo legalizado.</DialogContentText>
                <DialogContentText>A frente do documento possui os dados do veículo e o verso é utilizado no momento da compra/ venda do veículo. Confira na imagem ao lado como é este documento.</DialogContentText>
                {/* <DialogContentText><ArrowRightAltRounded /><span style={{position: 'relative', top: -5}}>Abra seu CRLV, de modo que apareça a frente e o verso do documento</span></DialogContentText> */}
                {/* <DialogContentText><ArrowRightAltRounded /><span style={{position: 'relative', top: -5}}>É importante que esta foto apareça os dois lados do documento</span></DialogContentText> */}
                {/* <DialogContentText><ArrowRightAltRounded /><span style={{position: 'relative', top: -5}}>Certifique-se de ter batido uma boa foto</span></DialogContentText> */}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowWhatIsCRLV(state => !state)} color="primary">fechar</Button>
              </DialogActions>
            </Dialog>


          </>
          :
          <div style={{ textAlign: `center` }}><CircularProgress/></div>
      }
    </>
  );
};

export default withTheme(withStyles(styles)(formCRLV));
