import React, { useState } from 'react';
import FormContext from '../FormContext';
import { ErrorMessage } from 'formik';
import { useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import CampoLabel from './CampoLabel';
import Select from 'react-select';

const styles = theme => ({
  select: {
    margin: `${theme.spacing()}px 0`,
    font: 'inherit',
    color: 'currentColor',
    width: `calc(100% - ${theme.spacing()*2}px)`,
    border: 0,
    margin: 0,
    display: 'block',
    "min-width": 0,
    "box-sizing": 'content-box',
    "animation-name": 'mui-auto-fill-cancel',
    "-webkit-tap-highlight-color": "transparent",
    padding: `${theme.spacing()}px`,
    background: "#f7f5f5",
  },
});

const CampoSelectNative = (props) => {
  
  const theme = useTheme();
  const context = React.useContext(FormContext);

  return (
    <div className="campo-wrapper">
      <fieldset
        name={props.name}
        onFocus={ props.onFocus }
        className={ (context.campoAtivo==props.name) ? "ativo" : "" }
        style={{ borderColor: props.error && props.touched ? 'red' : '' }}
      >
        {props.label &&
          <CampoLabel className="campo-titulo">{props.label}</CampoLabel>
        }
        <select
          name={props.name}
          className={`${props.classes.select}`}
          placeholder={props.placeholder}
          value={typeof props.value === 'object' && props.value !== null ? ((props.getValue) ? props.getValue(props.value) : props.value.value) : props.value }
          onChange={(event)=>{
            props.onChange(event, props.options.find( (item) => {
              const value = (props.getValue) ? props.getValue(item) : item.value;
              return value == event.target.value;
            }));
          }}
        >
          <option key={"-1"} value={""}>Selecione uma opção</option>
          {
            props.options && props.options.map((item, index)=>(
              <option key={index} value={(props.getValue) ? props.getValue(item) : item.value}>{item.label}</option>
            ))
          }
        </select>
      </fieldset>
      <ErrorMessage name={props.name} render={obj => <div className="campo-erro">{obj}</div>}/>
    </div>
  )
}

export default withStyles(styles)(CampoSelectNative);