import React, { useEffect, useState } from 'react'
import {
  Grid, Typography, Button, CircularProgress, DialogTitle, DialogContentText, DialogContent, DialogActions, Dialog
} from "@material-ui/core";
import ArrowRightAltRounded from '@material-ui/icons/ArrowRightAltRounded';

import axios from 'axios'
import { useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom'

import CampoCheckBox from '../fields/CampoCheckBox';
import FormContext from '../FormContext';


const styles = (theme) => ({
  content: {
    height: 350
  },
  helpText: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  helpText1: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  helpText2: {
    textAlign: "center",
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
  },
  asking: {
    // fontWeight: 500,
    fontSize: 17,
    marginBottom: theme.spacing(3),
  },
  comoFacoIssoBt:{
    margin: theme.spacing(3),
  },
  points: {
    position: 'relative',
    top: -8
  },
  stepContainer:{
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  }
});


const EtapaProdutoFGTSPreAnalise = (props) => {
  const context = React.useContext(FormContext);
  const history = useHistory();
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched, submitForm } = useFormikContext();
  const { fields, etapa } = props;

  const [preApproved, setPreApproved] = useState(false)
  const [showComponentAuthApp, setShowComponentAuthApp] = useState(true)
  const [loading, setLoading] = useState(true)
  const [loadingConsultaSaldo, setLoadingConsultaSaldo] = useState(false)
  const [openModalHowToDoIt, setOpenModalHowToDoIt] = useState(false)
  const [attemptFailed, setAttemptFailed] = useState(false)
  
  const [errorMessage, setErrorMessage] = useState('')
  const [errorDescription, setErrorDescription] = useState('')
  
  const [simulateError, setSimulateError] = useState(false)
  const [errorTimeout, setErrorTimeout] = useState(false)
  const [errorByParcel, setErrorByParcel] = useState(false)

  const preAnaliseBV = async () => {
    props.updateResize()
    setErrorTimeout(false)
    setLoading(true)
    // const data = {
    //   cpf: context.state.values.cpf,
    //   celular: context.state.values.celular,
    //   email: context.state.values.email,
    // }
    const token = await context.getFirebaseToken()
    const preAnalise = await axios.post(`${context.state.API_ENDPOINT}/bv/emprestimo-fgts/pre-analise`, context.state.values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (preAnalise.data.success !== true) {
      if (preAnalise.data.success !== true && preAnalise.data.code) {
        setShowComponentAuthApp(true)
        setLoadingConsultaSaldo(false)
        setErrorTimeout(true)
        setErrorMessage(preAnalise.data.message)
        setErrorDescription(preAnalise.data.description)
      }
      setLoading(false)
      setPreApproved(false)
    } else {
      setLoading(false)
      setPreApproved(true)
    }
    props.updateResize()
  }
  
  useEffect(() => {    
    preAnaliseBV()
    // setLoading(false)
    // setPreApproved(true)
  }, [])

  const handleConsultaSaldo = async () => {
    props.updateResize()
    setShowComponentAuthApp(false)
    setLoadingConsultaSaldo(true)
    setErrorByParcel(false)
    // const data = {
    //   cpf: context.state.values.cpf,
    //   celular: context.state.values.celular,
    //   email: context.state.values.email,
    //   simulateError
    // }
    const token = await context.getFirebaseToken()
    const consultaSaldo = await axios.post(`${context.state.API_ENDPOINT}/bv/emprestimo-fgts/consulta-saldo`, context.state.values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (consultaSaldo.data.success !== true) {
      setShowComponentAuthApp(true)
      setLoadingConsultaSaldo(false)
      setAttemptFailed(true)
      setErrorMessage(consultaSaldo.data.message)
      setErrorDescription(consultaSaldo.data.description)
      props.updateResize()
    } else {
      if (consultaSaldo.data.data.length === 1) {
        setShowComponentAuthApp(true)
        setLoadingConsultaSaldo(false)
        setAttemptFailed(true)
        setErrorByParcel(true)
        setErrorMessage('Não há parcelas suficientes para antecipar valores do FGTS')
        setErrorDescription('Infelizmente com a quantidade de parcelas em sua conta do FGTS não é possível contratar este produto. Tente novamente em um outro momento ou confire nossos outros produtos.')
        props.updateResize()
      } else {
        consultaSaldo.data.data.forEach(value => value.selected=true)
        context.changeState({values_saque_fgts: consultaSaldo.data.data})
        submitForm()
      }
    }
  }

  return(
    <>
      {showComponentAuthApp && loading &&
        <Grid item container direction="column" alignItems="center" xs={12}>
          <Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>Aguarde um momento</Typography>
          <Typography className={props.classes.helpText}>Estamos realizando a pré análise dos seus dados com o Banco Votorantim</Typography>
          <CircularProgress />
        </Grid>  
      }

      {loadingConsultaSaldo &&
        <Grid item container direction="column" alignItems="center" xs={12}>
          <Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>Aguarde um momento</Typography>
          <Typography className={props.classes.helpText}>Estamos consultando com o Banco Votorantim se você já autorizou a consulta do seu saldo.</Typography>
          <CircularProgress />
        </Grid>  
      }

      {
        errorTimeout && 
          <>
            <Grid item container direction="column" alignItems="center" xs={12}>
                <Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>Houve um erro ao realizar a consulta por parte do Banco Votorantim</Typography>
                <Typography className={props.classes.helpText2}>{errorMessage}</Typography>
                <Typography className={props.classes.helpText1}>{errorDescription}</Typography>
                <Button variant='contained' color="primary" style={{ color: 'white', marginTop: 20 }} onClick={() => preAnaliseBV()}>tentar novamente</Button>
            </Grid>
          </>
      }

      {
        showComponentAuthApp && preApproved && loading === false &&
          <>
            {
              attemptFailed ? 
              <>
                <Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>Houve um erro ao realizar a consulta por parte do Banco Votorantim</Typography>
                <Grid item container direction="column" xs={12}>
                  <Typography className={props.classes.helpText2}>{errorMessage}</Typography>
                  <Typography className={props.classes.helpText1}>{errorDescription}</Typography>
                  {/* <Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>Você ainda não autorizou o Banco Votorantim a consultar seu saldo FGTS</Typography> */}
                </Grid>
              </>
              :
              <Grid item container direction="column" xs={12}>
                <Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>No aplicativo do FGTS, você precisa realizar os seguintes passos:</Typography>
                <div className={ props.classes.stepContainer }>
                  <Typography style={{ fontWeight: 'bold' }}>Passo 1:</Typography>
                  <ul style={{ marginTop: 0 }}>
                    <li><Typography>No menu principal do aplicativo do FGTS, clique na opção "Saque Aniversário FGTS"</Typography></li>
                    <li><Typography>Escolha a modalidade "Saque Aniversário"</Typography></li>
                  </ul>
                </div>
                <div className={ props.classes.stepContainer }>
                  <Typography style={{ fontWeight: 'bold' }}>Passo 2:</Typography>
                  <ul style={{ marginTop: 0 }}>
                    <li><Typography>No menu principal, clique em "Autorizar bancos a consultarem seu FGTS"</Typography></li>
                    <li><Typography>Selecione a opção "Empréstimo Saque Aniversário". Aceite os termos e clique em "Continuar"</Typography></li>
                    <li><Typography>Em "Buscar Banco", procure e escolha por "Votorantim" ou "Banco Votorantim". Clique em "Continuar"</Typography></li>
                  </ul>
                </div>
                <div className={ props.classes.stepContainer }>
                  <Typography style={{ fontWeight: 'bold' }}>Passo 3:</Typography>
                  <ul style={{ marginTop: 0 }}>
                    <li><Typography>Uma vez já autorizado, é só clicar no botão abaixo "Sim, já autorizei" e realizar seu pedido de empréstimo em nosso site</Typography></li>
                  </ul>
                </div>
              </Grid>
            }

            {
              attemptFailed ? 
                <Grid item container direction="column" alignItems="center" xs={12}>

                {
                  errorByParcel ?
                    <Button variant='contained' color="primary" style={{ color: 'white' }} onClick={() => history.push('/area-logada/servicos-produtos')}>conferir outros produtos</Button>
                    : <Button variant='contained' color="primary" style={{ color: 'white' }} onClick={() => handleConsultaSaldo()}>Verificar novamente</Button>
                }
                { !errorByParcel && <Button variant='variant' color="primary" style={{color: '#00b572'}} onClick={() => setOpenModalHowToDoIt(true)}>como autorizar o banco a consultar o fgts?</Button>}
                {/* <Button variant='variant' color="primary" style={{color: '#00b572'}} onClick={() => console.log('clicked')}>como fazer tal coisa?</Button> */}
                </Grid>
              :
                <>
                  <Grid item container direction="column" alignItems="center" xs={12}>
                    <Typography className={props.classes.asking} variant="h4">Você já fez isso? </Typography>
                  </Grid>
                  <Grid item container direction="column" alignItems="center" xs={12}>
                    <Button variant='contained' color="primary" style={{color: 'white'}} onClick={() => handleConsultaSaldo()}>Sim, já autorizei</Button>
                  </Grid>
                </>
            }

          </>
      }

      {
        showComponentAuthApp && !preApproved && loading === false && errorTimeout === false &&
        <Grid item container direction="column" alignItems="center" xs={12}>
          <Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>Infelizmente você não está apto para solicitar essa modalidade de empréstimo</Typography>
          <Typography className={props.classes.helpText}>Mas não se preocupe! temos vários outros produtos para voce solicitar seu empréstimo e realizar seu sonho</Typography>
          <Button variant='contained' color="primary" style={{color: 'white'}} onClick={() => history.push('/area-logada/servicos-produtos')}>Clique aqui e conheça</Button>
        </Grid> 
      }

      <Dialog
        open={openModalHowToDoIt}
        onClose={() => setOpenModalHowToDoIt(false)}
      >
        <DialogTitle>Como autorizar o Banco Votorantim a consultar meu saldo FGTS?</DialogTitle>
        <DialogContent>
          <DialogContentText><b>Passo 1 - Baixe o aplicativo do FGTS em seu celular</b></DialogContentText>
          <DialogContentText><ArrowRightAltRounded/><span className={props.classes.points}>Caso não tenha o aplicativo do FGTS, será necessário instalar em seu celular</span></DialogContentText>
          <br />
          
          <DialogContentText><b>Passo 2 - Defina o Saque Aniversário no aplicativo FGTS</b></DialogContentText>
          <DialogContentText><ArrowRightAltRounded/><span className={props.classes.points}>No menu principal do aplicativo FGTS, clique na opção “Saque Aniversário do FGTS”</span></DialogContentText>
          <DialogContentText><ArrowRightAltRounded/><span className={props.classes.points}>Escolha a opção “Modalidade Saque Aniversário”</span></DialogContentText>
          <DialogContentText>Leia e avalie as regras do Saque Aniversário. Caso faça a adesão, você só poderá retornar ao Saque Rescisão após 24 meses.</DialogContentText>
          <br />
          
          <DialogContentText><b>Passo 3 - Autorize o Banco Votorantim (BV) a consultar seu saldo FGTS</b></DialogContentText>
          <DialogContentText><ArrowRightAltRounded/><span className={props.classes.points}>No menu principal, clique na opção “Autorizar bancos a consultarem seu FGTS”</span></DialogContentText>
          <DialogContentText><ArrowRightAltRounded/><span className={props.classes.points}>Selecione a opção “Empréstimo Saque Aniversário”</span></DialogContentText>
          <DialogContentText><ArrowRightAltRounded/><span className={props.classes.points}>Aceite os termos de consulta e clique em “Continuar” (leia atentamente os termos)</span></DialogContentText>
          <DialogContentText><ArrowRightAltRounded/><span className={props.classes.points}>Em Buscar Banco, procure por “Votorantim” ou “Banco Votorantim”</span></DialogContentText>
          <DialogContentText><ArrowRightAltRounded/><span className={props.classes.points}>Escolha o “Banco Votorantim” e clique em “Continuar”</span></DialogContentText>
          <br />
          
          <DialogContentText><b>Passo 4 - Após fazer isso, aqui na Quero Financiar...</b></DialogContentText>
          {
            attemptFailed ? 
              <DialogContentText><ArrowRightAltRounded/><span className={props.classes.points}>Uma vez autorizado, clique no botão  "Verificar novamente" logo após clicar em "Entendi"</span></DialogContentText>
              :
              <DialogContentText><ArrowRightAltRounded/><span className={props.classes.points}>Uma vez autorizado, clique no botão  "Sim, ja autorizei" logo após clicar em "Entendi"</span></DialogContentText>
          }
          <br/>
          <DialogContentText><b>Caso preferir, assista o guia abaixo</b></DialogContentText>
          <Grid item container direction="column" alignItems="center" xs={12}>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/DD3Yt6SHJoY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </Grid> 
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModalHowToDoIt(false)} style={{color: 'white'}} variant="contained" color="primary">entendi</Button>
        </DialogActions>
      </Dialog>

    </>
  )
}

export default withStyles(styles)(EtapaProdutoFGTSPreAnalise);