import React, { useEffect } from 'react'
import { Form, useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import CampoDinheiro from '../fields/CampoDinheiro';
import FormActions from '../FormActions';
import FormContext from '../FormContext';
import Aviso from '../Aviso';
import CampoOpcoes from '../fields/CampoOpcoes';
import {stripRenda} from '../../lib/Utils';

const styles = theme => ({
  etapaTitulo: {
  },
});

const EtapaQuantoPrecisa = (props) => {
  const context = React.useContext(FormContext);
  const [otherValue, setOtherValue] = React.useState(false);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const { fields, etapa } = props;

  useEffect(()=>{
    if(props.montanteOptions && props.montanteOptions.filter( (item) => item.value == values.montante).length==0 ){
      setFieldValue('montante', '')
    }
  },[])

  return(
    <Form>
      <CampoOpcoes
        label="De quanto você precisa? *"
        name="montante"
        value={otherValue ? 'Outro' : (values ? values.montante : "") }
        touched={touched.montante}
        showError={errors.montante && touched.montante}
        options={props.montanteOptions}
        classNamePrefix="quero-financiar-select"
        className="campo-selecao"
        onFocus={ props.changeFocus }
        onChange={ ( item ) => {
          if(item.value=='Outro'){
            setFieldValue('montante','');
            setFieldTouched('montante',false);
            setOtherValue(true);
          }else{
            setFieldValue('montante',item.value);
            setOtherValue(false);
          }
          props.updateResize()
        } }
        onBlur={ ( e ) => {
            if(!otherValue) setFieldTouched('montante',true);
        } }
      />
      {
        otherValue==true &&
        <CampoDinheiro
          label="Digite aqui quanto você precisa:"
          name="montante"
          value={values ? (values.montante ? stripRenda(values.montante) : values.montante) : ""}
          inputProps={{
            maxLength: 10,
          }}
          onFocus={ props.changeFocus }
          onChange={ (e)=>{
            setFieldValue('montante',e.target.value);
          } }
          onBlur={ (e)=>{
            // context.changeValues({
            //   montante:e.target.value
            // });
            handleBlur(e);
          } }
          error={errors.montante && touched.montante}
          helperText={errors.montante && touched.montante ? errors.montante : ''}
        />
      }
      <FormActions isSubmitting={ isSubmitting } disabled={ etapa!=context.state.etapa }  />
      <Aviso errors={errors} touched={touched}/>
    </Form>
  )
};

export default withStyles(styles)(EtapaQuantoPrecisa);
