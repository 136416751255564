// import moment from 'moment';
import * as Yup from 'yup';
import CNPJ from "@fnando/cnpj/dist/node";
// import CPF from "@fnando/cpf/dist/node";
import { stripRenda } from './Utils';

export default function getValidationSchema() {

  let validations = {};

  validations = Yup.object().shape({

    //DADOS PARA CONTATO    
    email: Yup.string().nullable()
      .required('O campo email é obrigatório.')
      .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,'Formato de email está incompleto.'),

    celular: Yup.string().nullable()
      .required('Por favor, digite seu ceular.')
      .test('test-cel', 'Ops, o celular é inválido.', 
        function(value) {
          return value ? value.match(/^\(\d{2}\)\d{5}\-\d{4}$/) : true;
        }
      ),


    //DADOS PESSOAIS
    nomeCompleto: Yup.string().nullable()
    .matches(/^(.+){3} (.+){3}/,'Por favor, preencha seu nome completo.')
      .required('Por favor, preencha seu nome completo.'),
    
    nacionalidade: Yup.string()
      .required('Diga qual sua nacionalidade.'),
    
    genero:Yup.string()
      .required('Selecione seu gênero.'),
      
    estado_civil: Yup.string()
      .required('Escolha um estado civil.'),
    
    estado_nascimento: Yup.string().required('Informe seu estado de nascimento.'),
    cidade_nascimento: Yup.string().required('Informe sua cidade de nascimento.'),
    nome_mae: Yup.string().matches(/^(.+){3} (.+){3}/,'Por favor, preencha o nome completo.').required('Digite o nome de sua mãe.'),
    
    //DADOS RESIDENCIAIS
    cep: Yup.string().nullable()
      .required('Ops, você esqueceu seu cep.')
      .matches(/^\d{5}\-\d{3}$/, 'CEP incompleto'),
    endereco: Yup.string().required('Por favor, digite seu endereço.'),
    estado: Yup.string().required('Selecione seu estado.'),
    cidade: Yup.string().required('Digite sua cidade.'),
    bairro: Yup.string().required('Por favor, diga qual seu bairro.'),
    numero: Yup.number()
      .typeError('Ops, digite apenas números no número de sua residência.')
      .required('Por favor, insira o número de sua residência.'),   
    
    //DADOS PROFISSIONAIS
    ocupacao: Yup.string().required('Selecione sua ocupação.'),
    cargo: Yup.string().required('Informe seu cargo.'),
    
    renda: Yup.string()
      .required('Por favor, diga sua renda.')
      .test('test-renda', 'Valor da renda está fora dos limites permitidos.', (val) => {
        return Number(stripRenda(val)) >= 1039 && Number(stripRenda(val)) <= 50000;
      }),
    
    aposentado_num_beneficio: Yup.string()
      .when(['ocupacao'], {
      is: (val) => ( val == "Aposentado(a)" ),
      then: Yup.string()
        .max(20,'Digite no máximo 20 digitos para seu número do benefício.')
        .required('Digite seu número de benefício.'),
    }),

    tempo_servico: Yup.string()
    .when(['ocupacao'], {
        is: (val) => (val === "Assalariado(a)" || val === "Servidor público"),
        then: Yup.string().required('Insira o tempo de serviço.')
    }),

    cnpj: Yup.string()
      .when(['ocupacao','tem_cnpj'], {
        is: (ocupacao,tem_cnpj) => ocupacao == "Empresário(a)" || (ocupacao=="Autônomo(a)/ Prof. Liberal" && tem_cnpj=="Sim"),
        then: Yup.string()
          .required('Por favo, preencha com o CNPJ.')
          .test('test-cnpj', 'Ops, o CNPJ é inválido.', 
            function(value) {
              return CNPJ.isValid(value);
            }
          )
    }),

    tem_cnpj: Yup.string()
      .when(['ocupacao'], {
        is: "Autônomo(a)/ Prof. Liberal",
        then: Yup.string().required('Selecione se possui CNPJ.')
    }),

  });

  return validations

};