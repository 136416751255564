import React from 'react'
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import FormActions from '../FormActions';
import FormContext from '../FormContext';
import CampoData from '../fields/CampoData';
import Aviso from '../Aviso';
import CampoOpcoes from '../fields/CampoOpcoes';
import _ from 'lodash';

const styles = {
  etapaTitulo: {
  },
};

const EtapaDadosPessoais = (props) => {
  
  const context = React.useContext(FormContext);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const { fields, etapa } = props;
  
  return (
    <Form>
      {
        fields.includes('genero') &&
        <CampoOpcoes
          label="Gênero *"
          name="genero"
          showError={errors.genero}
          classNamePrefix="quero-financiar-select"
          className="campo-selecao"
          value={values.genero}
          touched={touched.genero}
          onChange={ ( data ) => {
            setFieldValue('genero', data.value);
            // context.changeValues({
            //   genero:value
            // });
          } }
          onBlur={ ( e ) => {
            setFieldTouched('genero',true);
          } }
          options={[
            {label:'Feminino', value:'Feminino'},
            {label:'Masculino', value:'Masculino'},
          ]}
          placeholder="Digite ou selecione uma opção"
        />
      }
      {
        fields.includes('estado_civil') &&
        <CampoOpcoes
          label="Estado Civil *"
          name="estado_civil"
          showError={errors.estado_civil}
          classNamePrefix="quero-financiar-select"
          className="campo-selecao"
          value={values.estado_civil}
          touched={touched.estado_civil}
          onChange={ ( data ) => {
            setFieldValue('estado_civil', data.value);
          } }
          onBlur={ ( e ) => {
            setFieldTouched('estado_civil',true);
          } }
          options={[
            {label:'Solteiro', value:'Solteiro'},
            {label:'Casado', value:'Casado'},
            {label:'Viúvo', value:'Viúvo'},
            {label:'Separado', value:'Separado'},
            {label:'União Estável', value:'União Estável'},
          ]}
          placeholder="Digite ou selecione uma opção"
        />
      }
      {
        fields.includes('nacionalidade') &&
          <CampoOpcoes
            label="Nacionalidade"
            name="nacionalidade"
            id="nacionalidade"
            value={values.nacionalidade}
            classNamePrefix="quero-financiar-select"
            className="campo-selecao"
            onChange={(data) => {
              setFieldValue('nacionalidade', data.value);
            }}
            options={[
                {label:'Brasileira', value:'Brasileira'},
                {label:'Estrangeira', value:'Estrangeira'},
              ]}
            showError={errors.nacionalidade}
            helperText={errors.nacionalidade}
            error={errors.nacionalidade}
          />
      }
      {
        <CampoData
          label="Data de nascimento *"
          name="data_nascimento"
          onFocus={ props.changeFocus }
          onBlur={ handleBlur }
          onChange={ handleChange }
          value={values.data_nascimento}
          error={errors.data_nascimento}
          helperText={errors.data_nascimento}
        />
      }
      <FormActions isSubmitting={ isSubmitting } disabled={ etapa!=context.state.etapa }  />
      <Aviso errors={errors} touched={touched}/>
    </Form>
  )
}

export default withStyles(styles)(EtapaDadosPessoais);