import React, { useState } from "react";
import { Form, useFormikContext } from "formik";
import { withStyles } from "@material-ui/core/styles";
import FormContext from "../FormContext";
import FormActions from "../FormActions";
import CampoOpcoes from "../fields/CampoOpcoes";
import CampoRadio from "../fields/CampoRadio";
import Aviso from "../Aviso";
import { useHistory } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@material-ui/core";

const styles = (theme) => ({
  etapaTitulo: {},
});

const EtapaSobreVoce = (props) => {

  const context = React.useContext(FormContext);
  const {
    values,
    errors,
    isSubmitting,
    handleChange,
    touched,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();
  const { fields, etapa } = props;
  const history = useHistory();
  const theme = useTheme();

  return (
    <Form>
      {fields.includes("objetivo") && (
        <CampoOpcoes
          label="Qual é seu objetivo? *"
          name="objetivo"
          value={values.objetivo}
          touched={touched.objetivo}
          showError={errors.objetivo && touched.objetivo}
          options={
            props.objetivo_options
              ? props.objetivo_options
              : [
                  { label: "Adquirir um bem", value: "Adquirir um bem" },
                  { label: "Investir", value: "Investir" },
                  { label: "Quitar dívidas", value: "Quitar dívidas" },
                  {
                    label: "Refinanciar dívidas",
                    value: "Refinanciar dívidas",
                  },
                  { label: "Reformar", value: "Reformar" },
                  { label: "Outro", value: "Outro" },
                ]
          }
          classNamePrefix="quero-financiar-select"
          className="campo-selecao"
          onFocus={props.changeFocus}
          onChange={(data) => {
            // console.log("teste",data.value)
            setFieldValue("objetivo", data.value);
            // context.changeValues({
            //   objetivo:value
            // })
          }}
          onBlur={(e) => {
            setFieldTouched("objetivo", true);
          }}
        />
      )}
      {fields.includes("prazo") && (
        <CampoOpcoes
          label="Quer pagar em quantas parcelas (em meses)? *"
          name="prazo"
          showError={errors.prazo && touched.prazo}
          classNamePrefix="quero-financiar-select"
          className="campo-selecao"
          value={values.prazo}
          touched={touched.prazo}
          onChange={(data) => {
            setFieldValue("prazo", data.value);
            // context.changeValues({
            //   prazo:value
            // })
          }}
          onBlur={(e) => {
            setFieldTouched("prazo", true);
          }}
          options={
            props.prazoOptions
              ? props.prazoOptions
              : [
                  { label: "6", value: "6" },
                  { label: "12", value: "12" },
                  { label: "18", value: "18" },
                  { label: "24", value: "24" },
                  { label: "36", value: "36" },
                  { label: "48", value: "48" },
                  { label: "60", value: "60" },
                ]
          }
          placeholder="Digite ou selecione uma opção"
        />
      )}
      {
        (
          (
            context.state.feature_flag_egvFlow2 &&
            !context.state.values.bv_alto_potencial_de_aprovacao &&
            fields.includes("restricao")
          )
          ||
          (
            !context.state.feature_flag_egvFlow2 &&
            fields.includes("restricao")
          )
        )
        &&
        <CampoRadio
          name="restricao"
          value={values.restricao}
          label="Você possui restrição no seu nome (SPC, Serasa...)? *"
          options={[
            { label: "Sim", value: "Sim" },
            { label: "Não", value: "Não" },
            { label: "Não sei", value: "Não sei" },
          ]}
          onChange={(data) => {
            setFieldValue("restricao", data.value);
            setFieldValue("restricao_valor", '');
            context.changeValues({
              restricao: data.value,
            });
          }}
          onBlur={(e) => {
            setFieldTouched("restricao", true);
          }}
          showError={errors.restricao && touched.restricao}
          touched={touched.restricao}
        />
      }
      {
        fields.includes("restricao_valor") &&
        values.restricao == "Sim" && (
        <>
          <CampoRadio
            name="restricao_valor"
            value={values.restricao_valor}
            label="Qual o valor da restrição?"
            options={[
              { label: "Menor que R$ 2 mil", value: "Menor que R$ 2 mil" },
              { label: "Maior que R$ 2 mil", value: "Maior que R$ 2 mil" },
            ]}
            onChange={(data) => {
              setFieldValue("restricao_valor", data.value);
              context.changeValues({
                restricao_valor: data.value,
              });
            }}
            onBlur={(e) => {
              setFieldTouched("restricao_valor", true);
            }}
            showError={errors.restricao_valor && touched.restricao_valor}
            touched={touched.restricao_valor}
          />
        </>
      )}
      <FormActions
        isSubmitting={isSubmitting}
        disabled={etapa != context.state.etapa}
      />
      <Aviso errors={errors} touched={touched} />
    </Form>
  );
};

export default withStyles(styles)(EtapaSobreVoce);
