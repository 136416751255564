export default function(timestampObject){
  const timestamp = {
    seconds:0,
    nanoseconds:0,
  };
  
  timestamp.seconds = typeof timestampObject._seconds  !== 'undefined' ? timestampObject._seconds : timestampObject.seconds;
  timestamp.nanoseconds = typeof timestampObject._nanoseconds  !== 'undefined' ? timestampObject._nanoseconds : timestampObject.nanoseconds;

  return timestamp
}