export default function (state={}) {
  
  return {
    "solicitacao-emprestimo-garantia-veiculo": {
      flowName: state.feature_flag_egvFlow2 ? "egvFlow2" : "egvFlow",
      product: "Refinanciamento de Veículo",
      leadSrc: "Site: Empréstimo com Garantia de Veículos",
    },
    "solicitacao-refinanciamento-de-veiculo": {
      flowName: state.feature_flag_egvFlow2 ? "egvFlow2" : "egvFlow",
      product: "Refinanciamento de Veículo",
      leadSrc: "Site: Refinanciamento de Veículos",
    },
    "solicitacao-emprestimo-com-imovel-em-garantia": {
      flowName: "egiFlow",
      product: "Refinanciamento Imobiliário",
      leadSrc: "Site: Empréstimo com Imóvel em Garantia",
    },
    "solicitacao-emprestimo-pessoal": {
      flowName: "epFlow",
      product: "Empréstimo Pessoal",
      leadSrc: "Site: Empréstimo Pessoal",
    },
    "solicitacao-financiamento-de-veiculo": {
      flowName: "fvFlow",
      product: "Financiamento de Veículo",
      leadSrc: "Site: Financiamento de Veículos",
    },
    "solicitacao-emprestimo-com-saque-fgts": {
      flowName: "fgtsFlow",
      product: "Empréstimo com Saque FGTS",
      leadSrc: "Site: Empréstimo com Saque FGTS",
    },
    "sim-envio-documentos": {
      flowName: "simSendDocumentsFlow",
      product: "Refinanciamento de Veículo",
      leadSrc: "Site: Refinanciamento de Veículos",
    }
  }
}
