import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { withTheme, withStyles } from '@material-ui/core/styles';
import { Card, CardActionArea, CardContent, Grid, useTheme, Typography } from '@material-ui/core';

import FormContext from '../FormContext';
import MoneyBagIcon from '../../assets/img/make-loan.png';
import ShoppingCartIcon from '../../assets/img/shopping-cart.png';
import WhatsAppIcon from '../../assets/img/whatsapp-black.png';
import PrivacyIcon from '../../assets/img/privacy.png';
import FormIcon from '../../assets/img/form.png';
import Orders from '../../assets/img/binoculars-black.png';
import Home from '../../assets/img/home-icon-silhouette.png';

const styles = theme => ({
  root:{
    borderTop: `1px solid #ccc`,
  },
  cardsContainer:{
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'auto',
  },
  card:{
    margin: theme.spacing(),
    width: 100,
    height:100,
    textAlign: 'center',
    display: 'inline-block',
  },
  cardIcon:{
    width:35,
    margin: theme.spacing(),
  },
  cardLabel:{
    whiteSpace: 'break-spaces',
    fontSize: '.7rem',
  }
})

const Footer = (props) => {
  
  const context = React.useContext(FormContext);
  const {classes} = props;
  const history = useHistory();
  const theme = useTheme();
  const currentRoute = history.location.pathname;

  return (
    <footer className={classes.root}>
      <div className={classes.cardsContainer}>
        {
          [
            {
              label: "Página inicial",
              link: "/area-logada",
              icon: Home,
            },
            {
              label: "Acompanhar meus pedidos",
              link: "/area-logada/meus-pedidos",
              icon: Orders
            },
            {
              label: "Solicitar novo empréstimo",
              link: "/area-logada/novo-emprestimo",
              icon: MoneyBagIcon,
              color: theme.palette.secondary.main,
            },
            {
              label: "Serviços e Produtos",
              link: "/area-logada/servicos-produtos",
              icon: ShoppingCartIcon,
            },
            {
              label: "Dados Cadastrais",
              link: "/area-logada/dados-cadastrais",
              icon: FormIcon,
            },
            // {
            //   label: "Privacidade",
            //   link: "/privacidade",
            //   icon: PrivacyIcon,
            // }
          ].map((item,index)=>(
            <Card
              style={{
                background: (currentRoute==item.link) ? '#ccc' : (item.color ? item.color : 'rgb(0 222 140)'),
              }}
              className={classes.card}
              key={index}
            >
              <CardActionArea disabled={currentRoute==item.link} onClick={(e)=>{
                if(item.link.search("http")==-1){
                  history.push(item.link);
                }else{
                  window.open(item.link,"_blank");
                }
              }}>
                <CardContent>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    style={{marginTop: -5}}
                  >
                    <img src={item.icon} className={classes.cardIcon}/>
                    <Typography className={classes.cardLabel}>{item.label}</Typography>
                  </Grid>
                </CardContent>
              </CardActionArea>
            </Card>
          ))
        }
        <Link to={'/privacidade'}><Typography style={{ textAlign:'center' }}>Privacidade</Typography></Link>
      </div>
    </footer>
  )
}
export default withTheme(withStyles(styles)(Footer));