"use strict";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withTheme } from "@material-ui/core/styles";
import FormContext from "./FormContext";
import { Formik } from "formik";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CSSTransition } from "react-transition-group";
import getValidationSchema from "../lib/Validations";
import EtapaQuantoPrecisa from "./steps/EtapaQuantoPrecisa";
import EtapaSobreVoce from "./steps/EtapaSobreVoce";
import EtapaComoEncontrar from "./steps/EtapaComoEncontrar";
import EtapaProdutoFGTS from "./steps/EtapaProdutoFGTS";
import EtapaProdutoFGTSInstrucoes from "./steps/EtapaProdutoFGTSInstrucoes";
import EtapaProdutoFGTSPreAnalise from "./steps/EtapaProdutoFGTSPreAnalise";
import EtapaProdutoFGTSValores from "./steps/EtapaProdutoFGTSValores";
import EtapaDadosBancarios from "./steps/EtapaDadosBancarios";
import EtapaProdutoFGTSEnviar from "./steps/EtapaProdutoFGTSEnviar";
import EtapaDadosComplementaresFGTS from "./steps/EtapaDadosComplementaresFGTS";
import EtapaDocIdentificacao from "./steps/EtapaDocIdentificacao";
import EtapaOndeEncontrar from "./steps/EtapaOndeEncontrar";
import EtapaDadosPessoais from "./steps/EtapaDadosPessoais";
import EtapaDadosProfissionais from "./steps/EtapaDadosProfissionais";
import EtapaRgCpf from "./steps/EtapaRgCpf";
import EtapaReferencias from "./steps/EtapaReferencias";
import EtapaResultado from "./steps/EtapaResultado";
import EtapaEnviar from "./steps/EtapaEnviar";
import EtapaVocePossuiVeiculo from "./steps/EtapaVocePossuiVeiculo";
import EtapaVeiculoFinanciamento from "./steps/EtapaVeiculoFinanciamento";
import EtapaVeiculoMarca from "./steps/EtapaVeiculoMarca";
import EtapaVeiculoAno from "./steps/EtapaVeiculoAno";
import EtapaVeiculoModelo from "./steps/EtapaVeiculoModelo";
import EtapaVeiculoInfos from "./steps/EtapaVeiculoInfos";
import EtapaVeiculoCreditoPessoal from "./steps/EtapaVeiculoCreditoPessoal";
import EtapaVeiculoCheckMolicar from "./steps/EtapaVeiculoCheckMolicar";
import EtapaVeiculoPlaca from "./steps/EtapaVeiculoPlaca";
import EtapaVeiculoVersao from "./steps/EtapaVeiculoVersao";
import EtapaSobreSeuImovel from "./steps/EtapaSobreSeuImovel";
import EtapaCpfImovel from "./steps/EtapaCpfImovel";
import EtapaCreditoPessoalSobreVoce from "./steps/EtapaCreditoPessoalSobreVoce";
import EtapaCreditoPessoalDadosProfissionais from "./steps/EtapaCreditoPessoalDadosProfissionais";
import EtapaDocumentos from "./steps/EtapaDocumentos";
import EtapaInicial from "./steps/EtapaInicial";
import EtapaAgradecimento from "./steps/EtapaAgradecimento";
import EtapaEscolhaProduto from "./steps/EtapaEscolhaProduto";
import EtapaContaSantander from "./steps/EtapaContaSantander";
import EtapaElegibilidade from "./steps/EtapaElegibilidade";
import EtapaFinalizarPropostaSim from "./steps/EtapaFinalizarPropostaSim";

import FormDadosBancarios from "./logged-area/meus-pedidos/formDadosBancarios";
import FormSelfie from "./logged-area/meus-pedidos/formSelfie";
import FormDocumentos from "./logged-area/meus-pedidos/formDocumentos";
import FormVeiculo from "./logged-area/meus-pedidos/formVeiculo";
import FormCRLV from "./logged-area/meus-pedidos/formCRLV";
import FormFinal from "./logged-area/meus-pedidos/formFinal";
import FormResume from "./logged-area/meus-pedidos/formResume";


const Components = {
  FormDadosBancarios: FormDadosBancarios,
  FormSelfie: FormSelfie,
  FormDocumentos: FormDocumentos,
  FormVeiculo: FormVeiculo,
  FormResume: FormResume,
  FormCRLV: FormCRLV,
  FormFinal: FormFinal,
  EtapaInicial: EtapaInicial,
  EtapaQuantoPrecisa: EtapaQuantoPrecisa,
  EtapaSobreVoce: EtapaSobreVoce,
  EtapaComoEncontrar: EtapaComoEncontrar,
  EtapaProdutoFGTS: EtapaProdutoFGTS,
  EtapaProdutoFGTSInstrucoes: EtapaProdutoFGTSInstrucoes,
  EtapaProdutoFGTSPreAnalise: EtapaProdutoFGTSPreAnalise,
  EtapaContaSantander: EtapaContaSantander,
  EtapaProdutoFGTSValores: EtapaProdutoFGTSValores,
  EtapaDadosBancarios: EtapaDadosBancarios,
  EtapaDocIdentificacao: EtapaDocIdentificacao,
  EtapaDadosComplementaresFGTS: EtapaDadosComplementaresFGTS,
  EtapaProdutoFGTSEnviar: EtapaProdutoFGTSEnviar,
  EtapaVocePossuiVeiculo: EtapaVocePossuiVeiculo,
  EtapaVeiculoMarca: EtapaVeiculoMarca,
  EtapaVeiculoAno: EtapaVeiculoAno,
  EtapaVeiculoModelo: EtapaVeiculoModelo,
  EtapaVeiculoInfos: EtapaVeiculoInfos,
  EtapaVeiculoFinanciamento: EtapaVeiculoFinanciamento,
  EtapaOndeEncontrar: EtapaOndeEncontrar,
  EtapaDadosPessoais: EtapaDadosPessoais,
  EtapaDadosProfissionais: EtapaDadosProfissionais,
  EtapaRgCpf: EtapaRgCpf,
  EtapaReferencias: EtapaReferencias,
  EtapaResultado: EtapaResultado,
  EtapaEnviar: EtapaEnviar,
  EtapaSobreSeuImovel: EtapaSobreSeuImovel,
  EtapaCpfImovel: EtapaCpfImovel,
  EtapaCreditoPessoalSobreVoce: EtapaCreditoPessoalSobreVoce,
  EtapaCreditoPessoalDadosProfissionais: EtapaCreditoPessoalDadosProfissionais,
  EtapaVeiculoCreditoPessoal: EtapaVeiculoCreditoPessoal,
  EtapaDocumentos: EtapaDocumentos,
  EtapaVeiculoCheckMolicar: EtapaVeiculoCheckMolicar,
  EtapaVeiculoPlaca: EtapaVeiculoPlaca,
  EtapaEscolhaProduto: EtapaEscolhaProduto,
  EtapaAgradecimento: EtapaAgradecimento,
  EtapaElegibilidade: EtapaElegibilidade,
  EtapaFinalizarPropostaSim: EtapaFinalizarPropostaSim,
  EtapaVeiculoVersao: EtapaVeiculoVersao,
};

const styles = (theme) => ({
  root: {
    overflow: "hidden",
  },
  etapas: {
    transition:
      "transform cubic-bezier(.71,.01,.39,1.01) 1s, height cubic-bezier(.71,.01,.39,1.01) .5s",
  },
});

class QueroFinanciarFormulario extends React.Component {
  static contextType = FormContext;

  constructor(props) {
    super(props);

    this.state = {
      height: 0,
      resizeTimerID: null,
      slideIndex: 0,
    };

    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    if (this.state.resizeTimerID) {
      clearInterval(this.state.resizeTimerID);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.height != this.slideHeight()) {
      this.updateDimensions();
    }

    if (this.context.state.etapa - 1 != this.state.slideIndex) {
      if (this.slider) this.slider.slickGoTo(this.context.state.etapa - 1);
    }
  }

  updateDimensions() {
    this.setState({
      slideWidth: this.slideWidth(),
      width: this.context.state.etapas.length * this.slideWidth(),
      height: this.slideHeight(),
    });
  }

  handleHeightChange() {}

  slideWidth() {
    return document.querySelector("#quero-financiar-app-container")
      ? document.querySelector("#quero-financiar-app-container").clientWidth
      : 0;
  }

  slideHeight() {
    if (
      document.querySelectorAll(".quero-financiar-formulario-etapa")[
        this.context.state.etapa - 1
      ]
    ) {
      return document.querySelectorAll(".quero-financiar-formulario-etapa")[
        this.context.state.etapa - 1
      ].clientHeight;
    }
  }

  render() {

    // if (!this.context.state.firebaseLoaded) return null;

    const { classes, onSubmit, theme } = this.props;

    return (
      <Slider
        swipe={false}
        infinite={false}
        accessibility={false}
        draggable={false}
        arrows={false}
        ref={(slider) => (this.slider = slider)}
        beforeChange={
          (current, next) => {
            this.setState({ slideIndex: next })
          }
        }
        adaptiveHeight={true}
        style={{
          userSelect: "auto",
        }}
      >
        {this.context.state.etapas.map((etapa, index) => {
          if (!etapa.component) return;

          const EtapaComponent = Components[etapa.component];
          const etapaFields = etapa.params?.fields;
          const etapaParams = etapa.params;

          // const currentEtapa = this.context.state.etapas[this.context.state.etapa-1]

          const currentEtapaInitialValues = {};
          
          if(etapa.params){
            etapa.params.fields.map((field) => {
              currentEtapaInitialValues[field] = this.context.state.values[field];
            });
          }

          const currentEtapaValidationSchema = getValidationSchema(
            etapa.component,
            currentEtapaInitialValues,
            this.context
          );

          console.log('this.context.state.etapa', this.context.state.etapa);

          return (
            <div key={index}>
              <div
                style={{
                  padding: "30px",
                  maxWidth: this.context.state.etapa == 1 ? "" : "900px",
                  margin: "0 auto",
                }}
              >
                {
                  <CSSTransition
                    in={this.context.state.etapa - 1 == index}
                    timeout={3000}
                    classNames="qf-transition"
                    unmountOnExit
                  >
                    <Formik
                      initialValues={currentEtapaInitialValues}
                      validationSchema={currentEtapaValidationSchema}
                      onSubmit={onSubmit}
                    >
                      <EtapaComponent
                        {...etapa.params}
                        changeFocus={this.changeFocus}
                        etapa={index + 1}
                        fields={etapaFields}
                        params={etapaParams}
                        updateResize={this.updateDimensions}
                      />
                    </Formik>
                  </CSSTransition>
                }
              </div>
            </div>
          );
        })}
      </Slider>
    );
  }
}

export default withTheme(withStyles(styles)(QueroFinanciarFormulario));
