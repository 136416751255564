import React from 'react';
import { withStyles } from "@material-ui/styles";
import { useHistory } from 'react-router-dom'
import {
  Button, Link, Breadcrumbs, Typography, withTheme, Tooltip, IconButton, Fab, Grid
} from "@material-ui/core";

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import KeyboardBackspaceOutlined from '@material-ui/icons/KeyboardBackspaceOutlined';

const styles = (theme) => ({
  root:{
    marginBottom: theme.spacing(2),
  },
  breadcrumbLink: {
    cursor: `pointer`,
  },
  actualPage: {
    cursor: 'default'
  }
});

const Breadcrumb = (props) => {
  
  const history = useHistory();

  return (
    <div className={props.classes.root}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {
          props.hierarchyPages!== undefined && props.hierarchyPages.map(page => <Link color="inherit" key={page.route} className={props.classes.breadcrumbLink} onClick={() => history.push(page.route)}>{page.namePage}</Link>)
        }
        {/* <Typography color="textPrimary">{props.actualPage}</Typography> */}
        <Link color="inherit" className={props.classes.actualPage}>{props.actualPage}</Link>
      </Breadcrumbs>
      {
        props.hierarchyPages !== undefined &&
        // <Button
        //   style={{color: '#00b572'}}
        //   startIcon={<KeyboardBackspaceOutlined />}
        //   onClick={() => history.push(props.hierarchyPages.pop().route)}
        // >
        // Voltar
        //   </Button>
        <Tooltip title="Voltar">
          <Fab onClick={() => history.push(props.hierarchyPages.pop().route)} size="small" style={{backgroundColor: '#00b572'}}>
            <KeyboardBackspaceOutlined style={{color: 'white'}}/>
          </Fab>
        </Tooltip>
      }
    </div>
  )
}

export default withTheme(withStyles(styles)(Breadcrumb));