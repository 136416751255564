import React, { useEffect, useContext, useState } from "react";
import {
  Container, Grid, IconButton, withTheme, Typography, Button, Snackbar, CircularProgress
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import axios from 'axios';
import * as Yup from 'yup';
// import LoginForm from "./LoginForm";
import Header from "../../components/Header";
import Footer from "../../components/Footer"
import FormContext from '../../components/FormContext';
import Aviso from '../../components/Aviso';
import { Form, Formik, validateYupSchema } from "formik";
import CampoEmail from "../../components/fields/CampoEmail";
import CampoTexto from "../../components/fields/CampoTexto";
import CampoCheckBox from "../../components/fields/CampoCheckBox";
import MuiAlert from '@material-ui/lab/Alert';

import { useHistory } from "react-router-dom";

const styles = (theme) => ({
  root: {
    margin: theme.spacing(4),
  },
  helpText: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  button: {
    color: theme.palette.common.white,
    padding: `${theme.spacing(1.2)}px ${theme.spacing(5)}px`,
  },
  form: {
    justifyContent: "center",
  },
  formFields: {
    width: 200,
    justifyContent: "center"
  }
});

const validationFormFirstAccess = Yup.object().shape({
  nomeCompleto:Yup.string()
    .matches(/^(.+){3} (.+){3}/,'Por favor, preencha seu nome completo.')
    .required('Por favor, preencha seu nome.'),
   email: Yup.string()
    .required('O campo email é obrigatório.')
    .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Formato de email está incompleto.'),
   terms: Yup.bool()
    .required('Aceite o termo para continuar.')
    .equals([true],'Aceite o termo para continuar.')
})

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const FirstAccess = (props) => {

  const context = useContext(FormContext);
  const history = useHistory();

  const [hasUser, setHasUser] = useState(false)
  const [createdUserSuccess, setCreatedUserSuccess] = useState(false)
  const [clickedButton, setClickedButton] = useState(false)

  useEffect(() => {
    console.log(context.state)
    context.state.userLogged && history.push('/area-logada')
  }, [context.state.userLogged])
  
  
  const handleCreateFirstAccess = async (values) => {
    setClickedButton(true)
    const token = await context.getFirebaseToken();
    const data = {
      email: values.email,
      name: values.nomeCompleto,
      terms: values.terms
    }
    const result = await axios
      .post(`${context.state.API_ENDPOINT}/auth/first-access`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    if (result.data.hasUser === true) {
      //has user
      console.log('ja tem usuario... redirect to login page')
      setHasUser(true)
      setTimeout(() => {
        history.push('/login')
      }, 3000);
    } else {
      setCreatedUserSuccess(true)
      setTimeout(async () => {
        const userCredential = await context.signInWithCustomToken(result.data.token);
        await context.getUserData(userCredential.uid);
        context.changeState({ userLogged: true })
        history.push('/area-logada');
      }, 3000);
    }
    setClickedButton(false)
  }
  
  return (
    <>
      <Header/>
      <div className={props.classes.root}>
        <Container maxWidth="sm">
          <Typography variant="h2" gutterBottom style={{ textAlign: "center" }}>Primeiro Acesso</Typography>
          <Typography className={props.classes.helpText}>Informe seu e-mail e nome completo para criar seu primeiro acesso na Quero Financiar</Typography>
            <Formik
              onSubmit={(values) => handleCreateFirstAccess(values)}
              initialValues={{
                nomeCompleto: "",
                email: "",
                terms: false
              }}
              validationSchema={validationFormFirstAccess}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form className={props.classes.form}>
                  <CampoTexto
                    label="Nome Completo"
                    name="nomeCompleto"
                    id="nomeCompleto"
                    placeholder="Jose Silva Eduardo Pereira"
                    value={values.nomeCompleto}
                    onChange={handleChange}
                    error={errors.nomeCompleto}
                    helperText={errors.nomeCompleto}
                  />
                  <CampoEmail
                    label="Email"
                    name="email"
                    id="email"
                    placeholder="josesilva@email.com.br"
                    value={values.email}
                    onChange={handleChange}
                    error={errors.email}
                    helperText={errors.email}
                />
                <CampoCheckBox
                  name="terms"
                  onFocus={ props.changeFocus }
                  value={values.terms}
                  onChange={ (e)=>{
                    handleChange(e);
                  }}
                  error={errors.terms}
                >
                  <Typography className="aviso">Eu aceito os <a href="https://querofinanciar.com/sobre/termos-e-condicoes-de-uso/" target="_blank">termos de uso</a>, <a href="https://querofinanciar.com/sobre/politica-de-privacidade/" target="_blank">política de privacidade</a>, <a href="https://querofinanciar.com/sobre/termo-autorizacao-consulta/" target="_blank">termo de autorização de consulta (SCR)</a>. Além disso, aceito receber comunicações por e-mail, Whatsapp e SMS.</Typography>
                </CampoCheckBox>
                {
                  clickedButton ? <CircularProgress /> :
                    <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className={props.classes.button}
                    onClick={handleSubmit}
                    disabled={clickedButton}
                  >
                    Criar Conta
                  </Button>
                }
                <Aviso errors={errors} touched={touched}/>
                </Form>
              )}
            </Formik>
        </Container>
      </div>

      <Snackbar open={hasUser} autoHideDuration={3000} onClose={() => setHasUser(false)}>
        <Alert onClose={() => setHasUser(false)} severity={'info'}>Você ja possui usuário com esse endereço de e-mail. Você será redirecionado para a página de login.</Alert>  
      </Snackbar>

       <Snackbar open={createdUserSuccess} autoHideDuration={3000} onClose={() => setCreatedUserSuccess(false)}>
        <Alert onClose={() => setCreatedUserSuccess(false)} severity={'success'}>Sucesso ao criar o primeiro acesso! Você será redirecionado para a área logada da Quero Financiar :)</Alert>  
      </Snackbar>

      <Footer/>
    </>
  );
};

export default withTheme(withStyles(styles)(FirstAccess));
