import React from 'react';
import TextField from '@material-ui/core/TextField';
import stringSimilarity from 'string-similarity';
import { Typography } from '@material-ui/core';

const CampoEmail = (props) => {
  
  let hint = null;
  if(props.value){
    let email = props.value.toLowerCase();
    email = email.split("@")[1];
    if(email){
      const obj = {
        gmail: stringSimilarity.compareTwoStrings('gmail.com', email), 
        hotmail: stringSimilarity.compareTwoStrings('hotmail.com', email), 
        outlook: stringSimilarity.compareTwoStrings('outlook.com', email),
        outlookbr: stringSimilarity.compareTwoStrings('outlook.com.br', email),
        yahoo: stringSimilarity.compareTwoStrings('yahoo.com', email),
        yahoobr: stringSimilarity.compareTwoStrings('yahoo.com.br', email),
        icloud: stringSimilarity.compareTwoStrings('icloud.com', email)
      }
  
      const max = Math.max.apply(null,Object.keys(obj).map(function(x){ return obj[x] }));
  
      const dominio = Object.keys(obj).filter(function(x){ return obj[x] == max; })[0];
  
      if(obj[dominio] >= 0.8 && obj[dominio] < 1){
        if(dominio == "outlookbr" | dominio == "yahoobr"){
            if(dominio == "outlookbr"){
                hint = "Voce quis dizer outlook.com.br ?";
            }
            if(dominio == "yahoobr"){
                hint = "Voce quis dizer yahoo.com.br ?";
            }
        }else{
            hint = "Voce quis dizer "+dominio+".com ?";
        }
      }
    }
  }

  return (
    <div className={`${props.wrapperClassName} campo-wrapper`}>
      <TextField
        {...props}
        value={props.value || ""}
        inputProps={props.inputProps ? props.inputProps : {maxLength: 90}}
        variant="outlined"
        fullWidth
        type="email"
      />
      {
        hint &&
        <Typography variant="caption">
          {hint}
        </Typography>
      }
    </div>
  );

}

export default CampoEmail;