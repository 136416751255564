import React, { useState } from 'react'
import FormContext from '../FormContext';
import { withTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import logo from '../../assets/img/logo-quero-financiar-white-2.svg';

import {Link, useHistory, useLocation} from 'react-router-dom'
import { Button, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MenuIcon from '@material-ui/icons/Menu';

const styles = theme => ({
  root:{
    background: theme.palette.primary.main,
  },
  mainContainer:{
    display: 'flex',
    justifyContent: 'space-between',
    padding: 16
  },
  iconMenu: {
    position: 'relative',
    left:20
  },
  logo:{
    width: 160,
    height: 'auto',
    position: 'relative',
  },
  list:{
    width: 300,
  }
})

const Header = (props) => {
  
  const context = React.useContext(FormContext);
  const history = useHistory();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState();
  const { classes } = props;

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setMenuOpen(open);
  };

  return (
    <header className={classes.root}>
      <div className={classes.mainContainer}>
        <Link to="/area-logada">
          <img className={classes.logo} src={logo} alt="Logo QueroFinanciar.com"/>
        </Link>
        <Button onClick={toggleDrawer(true)} color="secondary" className={classes.iconMenu}>
          <MenuIcon/>
        </Button>
        <Drawer anchor="right" open={menuOpen} onClose={toggleDrawer(false)}>
          <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List>
              {[
                {
                  label: "Página inicial",
                  link: "/area-logada",
                },
                {
                  label: "Acompanhar meus pedidos",
                  link: "/area-logada/meus-pedidos",
                },
                {
                  label: "Solicitar novo empréstimo",
                  link: "/area-logada/novo-emprestimo",
                },
                {
                  label: "Serviços e Produtos",
                  link: "/area-logada/servicos-produtos",
                },
                {
                  label: "Dados cadastrais",
                  link: "/area-logada/dados-cadastrais",
                },
                {
                  label: "Privacidade",
                  link: "/area-logada/privacidade",
                },
                
              ].map((menuItem, index) => (
                <ListItem button key={menuItem.label} onClick={(e)=>{
                  if(menuItem.link.search('http')==-1){
                    history.push(menuItem.link);
                  }else{
                    window.open(encodeURI(`https://api.whatsapp.com/send?phone=${context.state.whatsAppNumber}&text=Olá, gostaria de tirar algumas dúvidas. Meu e-mail é ${context.state.values.email}`),"_blank");
                  }
                }}>
                  <ListItemIcon><ArrowRightIcon color="primary" /></ListItemIcon>
                  <ListItemText primary={menuItem.label} />
                </ListItem>
              ))}
              <ListItem button onClick={context.handleLogoutUser}>
                  <ListItemIcon><ArrowRightIcon color="primary" /></ListItemIcon>
                  <ListItemText primary="Sair"/>
              </ListItem>
            </List>
          </div>
        </Drawer>
      </div>
    </header>
  )
}

export default withTheme(withStyles(styles)(Header));