import React from 'react'
import FormContext from './FormContext';

import { withTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import ExitToApp from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import Typography from '@material-ui/core/Typography';
import Numeral from '../lib/Numeral';
import logo from '../assets/img/logo-quero-financiar-white-2.svg'

import {Link, useHistory, useLocation} from 'react-router-dom'

const styles = theme => ({
  root:{
    background: theme.palette.primary.main,
    // height: 180,
    borderRadius: `0px 0px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    color: theme.palette.common.white,
  },
  brandingContainer:{
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.05)',
  },
  branding:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 1150,
    margin: '0 auto',
    padding: theme.spacing(2),
  },
  contentHeader:{
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  logo:{
    width:160,
    height:'auto',
  },
  menuIcon:{
    color: theme.palette.common.white,
    width: 24,
    height: 24,
    display: 'none',
  },
  iconHeader: {
    color: theme.palette.common.white,
  },
  progressWrapper:{
    position: 'relative',
    maxWidth: theme.queroFinanciar.contentMaxWidth,
    padding: theme.spacing(2),
    width: '85%',
    [theme.breakpoints.up(theme.queroFinanciar.contentMaxWidth+100)]:{
      width: '100%',
      margin: '0 auto',
    }
  },
  progressBar:{
    position: 'relative',
    height: theme.spacing(),
    marginTop: theme.spacing(2),
  },
  etapaName:{
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  etapaIcon:{
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(),
  },
  etapaTitulo:{
    fontSize: '1.13rem',
  },
  iconeMaozinha:{
    position: 'absolute',
    top: '60px',
    right: '0px',
    width: '70px',
    height: '100px',
    [theme.breakpoints.up(1024)]:{
      display: 'none',
    }
  }
})

const icons = {
  "AttachMoneyIcon": AttachMoneyIcon,
  "PersonOutlineIcon": PersonOutlineIcon,
  "CheckIcon": CheckIcon,
  "HomeIcon": HomeIcon,
  "AssignmentIndOutlinedIcon": AssignmentIndOutlinedIcon,
  "BusinessCenterOutlinedIcon": BusinessCenterOutlinedIcon,
  "ThumbUpAltOutlinedIcon": ThumbUpAltOutlinedIcon,
  "AttachMoneyIcon": AttachMoneyIcon,
  "ErrorIcon": ErrorIcon,
}


const Header = (props) => {
  
  const context = React.useContext(FormContext);
  const history = useHistory();
  const location = useLocation();
  let Icon;

  if(context.state.etapas[context.state.etapa-1]){
    Icon = icons[context.state.etapas[context.state.etapa-1].icone];
  }

  const handleRedirectHome = () => {
    return history.push('');
  }

  const verifyUserIslogged = () => {
      if (context.state.userLogged) {
        // console.log('Usuario Logado...')
        history.push('/area-logada')
      } else {
        console.log('Usuario Anonymous...')
        // tenta executar login?
        history.push('/login')
      }
  }

  const renderActionsLoggedUser = () => {
    if (
      context.state.feature_flag_login_enabled &&
      location.pathname !== '/solicitacao-emprestimo-garantia-veiculo' &&
      location.pathname !== '/solicitacao-emprestimo-garantia-veiculo/' &&
      location.pathname !== '/solicitacao-refinanciamento-de-veiculo' &&
      location.pathname !== '/solicitacao-refinanciamento-de-veiculo/' &&
      location.pathname !== '/solicitacao-financiamento-de-veiculo' &&
      location.pathname !== '/solicitacao-financiamento-de-veiculo/' &&
      location.pathname !== '/solicitacao-emprestimo-com-imovel-em-garantia' &&
      location.pathname !== '/solicitacao-emprestimo-com-imovel-em-garantia/' &&
      location.pathname !== '/solicitacao-emprestimo-pessoal' &&
      location.pathname !== '/solicitacao-emprestimo-pessoal/'
    ) {
      return <>
        <Tooltip title="Minha conta">
          <IconButton onClick={() => verifyUserIslogged()}>
            <PersonIcon className={props.classes.iconHeader}/>
          </IconButton>
        </Tooltip>
      </>
    }else{
      return <div style={{display:`flex`, cursor:`pointer`}} onClick={()=>{
        context.handleLogoutUser('/')
      }}>
        <ExitToApp/><Typography>Sair</Typography>
      </div>
    }
  }

  return (
    <header className={props.classes.root}>
      <div className={props.classes.brandingContainer}>
        <div className={props.classes.branding}>
          <div className={props.classes.contentHeader}>
            {
              context.state.userLogged ?
                <Link to="/area-logada">
                  <img className={props.classes.logo} src={logo} alt="Logo QueroFinanciar.com"/>
                </Link>
                :
                <Link to="/">
                  <img className={props.classes.logo} src={logo} alt="Logo QueroFinanciar.com"/>
                </Link>
            }
            <div className={props.classes.iconProfile}>
              {renderActionsLoggedUser()}
            </div>
          </div>
        </div>
      </div>
      {
        props.inOrderFlow ?
        <div className={props.classes.progressWrapper}>
          {
            context.state.etapas[context.state.etapa-1] && context.state.etapas[context.state.etapa-1].resumo &&
            <Typography>
              {
                context.state.etapas[context.state.etapa-1].resumo.replace('%montante%',Numeral(context.state.values.montante).format('$0,0.00'))
              }
            </Typography>
          }
          <div className={props.classes.progressBar}>
            <div style={{
              position: 'absolute',
              width: '100%',
              height: props.theme.spacing(.5),
              background: props.theme.palette.common.black,
              opacity: .1,
            }}></div>
            <div style={{
              position: 'absolute',
              width: `${ ( (context.state.etapa/context.state.etapas.length>1) ? 1 : context.state.etapa/context.state.etapas.length) *100}%`,
              background: props.theme.palette.common.white,
              height: props.theme.spacing(.5),
              opacity: .9,
              transition: 'width cubic-bezier(.71,.01,.39,1.01) 1s',
            }}></div>
          </div>
        
          <div className={props.classes.etapaName}>
            { Icon && <Icon className={props.classes.etapaIcon}/> }
            { context.state.etapas[context.state.etapa-1] && <Typography className={props.classes.etapaTitulo}>{context.state.etapas[context.state.etapa-1].titulo}</Typography> }
          </div>
            
        </div>
        :
        props.children
      }
      
    </header>
  )
}

export default withTheme(withStyles(styles)(Header));