import React from 'react'
import { Form, useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import FormActions from '../FormActions';
import FormContext from '../FormContext';
import CampoCpf from '../fields/CampoCpf';
import CampoTexto from '../fields/CampoTexto';
import Aviso from '../Aviso';
import CampoData from '../fields/CampoData';
import CampoOpcoes from '../fields/CampoOpcoes';
import CampoSelect from '../fields/CampoSelect';
import CampoEstado from '../fields/CampoEstado';
import CampoSugestoes from '../fields/CampoSugestoes';
import _ from 'lodash';
import getBancos from '../../lib/getBancos'

const styles = theme => ({
  campoAgencia:{
    [theme.breakpoints.up(1024)]:{
      width: '48% !important',
      marginRight: '2% !important',
    }
  },
  campoConta:{
    [theme.breakpoints.up(1024)]:{
      width: '50% !important',
    }
  }
});

const EtapaCreditoPessoalSobreVoce = (props) => {
  const context = React.useContext(FormContext);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const { fields, etapa } = props;
  return (
    <Form>
      {
        fields.includes('data_nascimento') &&
        <CampoData
          label="Data de nascimento *"
          name="data_nascimento"
          onFocus={ props.changeFocus }
          onBlur={ (e)=>{
            handleBlur(e);
            // context.changeValues({
            //   data_nascimento:e.target.value
            // });
          } }
          onChange={ handleChange }
          value={values.data_nascimento}
          error={errors.data_nascimento && touched.data_nascimento}
          helperText={errors.data_nascimento && touched.data_nascimento ? errors.data_nascimento : ''}
        />
      }
      {
        fields.includes('estado_nascimento') &&
        <CampoEstado
          name="estado_nascimento"
          label="Estado de nascimento *"
          value={ values.estado_nascimento }
          error={errors.estado_nascimento}
          touched={touched.estado_nascimento}
          onChange={ async ( data ) => {
            setFieldValue('estado_nascimento', data.value );
            const cities = await context.getBrazilianCitiesByState(data.value)
            context.changeState({
              cidade_nascimento_options: cities
            });
          }}
          onBlur={ ( e ) => {
            handleBlur(e);
            setFieldTouched('estado_nascimento',true);
          } }
        />
      }
      {
        fields.includes('cidade_nascimento') &&
        <CampoSugestoes
          options={context.state.cidade_nascimento_options}
          onSuggestionSelected={
            (value)=>{
              setFieldValue('cidade_nascimento',value);
              // context.changeValues({
              //   cidade_nascimento: value
              // });
            }
          }
          inputProps={{
            className:"campo-texto",
            name: 'cidade_nascimento',
            label:"Cidade de nascimento *",
            value: values.cidade_nascimento,
            error: errors.cidade_nascimento && touched.cidade_nascimento,
            helperText: errors.cidade_nascimento && touched.cidade_nascimento ? errors.cidade_nascimento : '',
            onChange: (event, { newValue }) => {
                handleChange(event);
                setFieldValue('cidade_nascimento',newValue);
                // context.changeValues({
                //   cidade_nascimento:newValue
                // });
            },
            onBlur: (event, { highlightedSuggestion }) => {
                handleBlur(event);
                setFieldTouched('cidade_nascimento',true);
                const suggestion_index = _.findIndex(context.state.cidade_nascimento_options, (item)=>{
                  return item.label == values.cidade_nascimento;
                });
                if(suggestion_index==-1){
                  setFieldValue('cidade_nascimento','');
                };
            },
          }}
        />
      }
      {
        fields.includes('cpf') &&
        <CampoCpf
          label="CPF *"
          name="cpf"
          onFocus={ props.changeFocus }
          onBlur={ (e)=>{
            handleBlur(e);
            // context.changeValues({
            //   cpf:e.target.value
            // });
          } }
          onChange={ handleChange }
          value={values.cpf}
          error={errors.cpf && touched.cpf}
          helperText={errors.cpf && touched.cpf ? errors.cpf : ''}
          disabled={Boolean(context.state.values.cpf)}
        />
      }
      {
        fields.includes('rg') &&
        <CampoTexto
          label="RG *"
          name="rg"
          onFocus={ props.changeFocus }
          onBlur={ (e)=>{
            handleBlur(e);
            // context.changeValues({
            //   rg:e.target.value
            // });
          } }
          value={values.rg}
          onChange={handleChange}
          error={errors.rg && touched.rg}
          helperText={errors.rg && touched.rg ? errors.rg : ''}
        />
      }
      {
        fields.includes('data_emissao_rg') &&
        <CampoData
          label="Data de emissão do RG *"
          name="data_emissao_rg"
          onFocus={ props.changeFocus }
          onBlur={ (e)=>{
            handleBlur(e);
            // context.changeValues({
            //   data_emissao_rg:e.target.value
            // });
          } }
          onChange={ handleChange }
          value={values.data_emissao_rg}
          error={errors.data_emissao_rg && touched.data_emissao_rg}
          helperText={errors.data_emissao_rg && touched.data_emissao_rg ? errors.data_emissao_rg : ''}
        />
      }
      {
        fields.includes('orgao_emissor_rg') &&
        <CampoOpcoes
          label="Órgão Emissor do RG *"
          name="orgao_emissor_rg"
          showError={errors.orgao_emissor_rg}
          classNamePrefix="quero-financiar-select"
          className="campo-selecao"
          value={values.orgao_emissor_rg}
          touched={touched.orgao_emissor_rg}
          onChange={ ( data ) => {
            setFieldValue('orgao_emissor_rg', data.value);
            // context.changeValues({
            //   orgao_emissor_rg:value
            // });
          } }
          onBlur={ ( e ) => {
              setFieldTouched('orgao_emissor_rg',true);
          } }
          options={[
              {label:'SSP', value:'SSP'},
              {label:'DPF', value:'DPF'},
              // {label:'MAE', value:'MAE'},
              // {label:'MEX', value:'MEX'},
              // {label:'MMA', value:'MMA'},
              {label:'OUTRO', value:'OUTRO'},
          ]}
        />
      }
      {
        fields.includes('estado_emissor_rg') &&
        <CampoEstado
          name="estado_emissor_rg"
          label="Estado emissor do RG *"
          value={ values.estado_emissor_rg }
          error={errors.estado_emissor_rg}
          touched={touched.estado_emissor_rg}
          onChange={ ( data ) => {
            setFieldValue('estado_emissor_rg', data.value);
            // context.changeValues({
            //   estado_emissor_rg:value
            // });
          }}
          onBlur={ ( e ) => {
            handleBlur(e);
            setFieldTouched('estado_emissor_rg',true);
          } }
        />
      }
      {
        fields.includes('nacionalidade') &&
        <CampoOpcoes
          label="Nacionalidade *"
          name="nacionalidade"
          showError={errors.nacionalidade}
          classNamePrefix="quero-financiar-select"
          className="campo-selecao"
          value={values.nacionalidade}
          touched={touched.nacionalidade}
          onChange={ ( data ) => {
              setFieldValue('nacionalidade', data.value);
              // context.changeValues({
              //   nacionalidade:value
              // });
          } }
          onBlur={ ( e ) => {
              setFieldTouched('nacionalidade',true);
          } }
          options={[
              {label:'Brasileira', value:'Brasileira'},
              {label:'Estrangeira', value:'Estrangeira'},
          ]}
        />
      }
      {
        fields.includes('nome_mae') &&
        <CampoTexto
          label="Nome da mãe *"
          name="nome_mae"
          onFocus={ props.changeFocus }
          onBlur={ (e)=>{
            handleBlur(e);
            // context.changeValues({
            //   nome_mae:e.target.value
            // });
          } }
          value={values.nome_mae}
          onChange={handleChange}
          error={errors.nome_mae && touched.nome_mae}
          helperText={errors.nome_mae && touched.nome_mae ? errors.nome_mae : ''}
          placeholder="Maria Aparecida"
        />
      }
      {
        fields.includes('genero') &&
        <CampoOpcoes
          label="Gênero *"
          name="genero"
          showError={errors.genero}
          classNamePrefix="quero-financiar-select"
          className="campo-selecao"
          value={values.genero}
          touched={touched.genero}
          onChange={ ( data ) => {
            setFieldValue('genero', data.value);
            // context.changeValues({
            //   genero:value
            // });
          } }
          onBlur={ ( e ) => {
            setFieldTouched('genero',true);
          } }
          options={[
            {label:'Feminino', value:'Feminino'},
            {label:'Masculino', value:'Masculino'},
          ]}
          placeholder="Digite ou selecione uma opção"
        />
      }
      {
        fields.includes('estado_civil') &&
        <CampoOpcoes
          label="Estado Civil *"
          name="estado_civil"
          showError={errors.estado_civil}
          classNamePrefix="quero-financiar-select"
          className="campo-selecao"
          value={values.estado_civil}
          touched={touched.estado_civil}
          onChange={ ( data ) => {
            setFieldValue('estado_civil', data.value);
            // context.changeValues({
            //   estado_civil:value
            // });
          } }
          onBlur={ ( e ) => {
            setFieldTouched('estado_civil',true);
          } }
          options={[
            {label:'Solteiro', value:'Solteiro'},
            {label:'Casado', value:'Casado'},
            {label:'Viúvo', value:'Viúvo'},
            {label:'Divorciado', value:'Divorciado'},
            {label:'União Estável', value:'União Estável'},
          ]}
          placeholder="Digite ou selecione uma opção"
        />
      }
      {
        fields.includes('escolaridade') &&
        <CampoOpcoes
          label="Escolaridade *"
          name="escolaridade"
          showError={errors.escolaridade}
          classNamePrefix="quero-financiar-select"
          className="campo-selecao"
          value={values.escolaridade}
          touched={touched.escolaridade}
          onChange={ ( data ) => {
            setFieldValue('escolaridade', data.value);
            // context.changeValues({
            //   escolaridade:value
            // });
          } }
          onBlur={ ( e ) => {
            setFieldTouched('escolaridade',true);
          } }
          options={[
            {label:'Fundamental', value:'Fundamental'},
            {label:'Ensino Médio', value:'Ensino Médio'},
            {label:'Superior Completo', value:'Superior Completo'},
            {label:'Pós-Graduado', value:'Pós-Graduado'},
            {label:'Superior Incompleto', value:'Superior Incompleto'},
          ]}
          placeholder="Digite ou selecione uma opção"
        />
      }
      {
        fields.includes('banco') &&
        <CampoSelect
          name="banco"
          label="Qual o seu banco? *"
          value={ values.banco }
          error={errors.banco}
          touched={touched.banco}
          onChange={ ( data ) => {
            setFieldValue('banco', data.value);
            // context.changeValues({
            //   banco:value
            // });
          }}
          onBlur={ ( e ) => {
            handleBlur(e);
            setFieldTouched('banco',true);
          } }
          options={getBancos()}
        />
      }
      {
        fields.includes('banco_agencia') &&
        <CampoTexto
          wrapperClassName={props.classes.campoAgencia}
          label="Agência *"
          name="banco_agencia"
          onFocus={ props.changeFocus }
          onBlur={ (e)=>{
            handleBlur(e);
            // context.changeValues({
            //   banco_agencia:e.target.value
            // });
          } }
          value={values.banco_agencia}
          onChange={(e)=>{
            const re = /^[0-9\-]*$/;
            if(re.test(e.target.value)){
              handleChange(e);
            }
          }}
          error={errors.banco_agencia && touched.banco_agencia}
          helperText={errors.banco_agencia && touched.banco_agencia ? errors.banco_agencia : ''}
        />
      }
      {
        fields.includes('banco_conta') &&
        <CampoTexto
          wrapperClassName={props.classes.campoConta}
          label="Conta *"
          name="banco_conta"
          onFocus={ props.changeFocus }
          onBlur={ (e)=>{
            handleBlur(e);
            // context.changeValues({
            //   banco_conta:e.target.value
            // });
          } }
          value={values.banco_conta}
          onChange={(e)=>{
            const re = /^[0-9\-]*$/; if(re.test(e.target.value)) handleChange(e);
          }}
          error={errors.banco_conta && touched.banco_conta}
          helperText={errors.banco_conta && touched.banco_conta ? errors.banco_conta : ''}
        />
      }
      <FormActions isSubmitting={ isSubmitting } disabled={ etapa!=context.state.etapa }  />
      <Aviso errors={errors} touched={touched}/>
    </Form>
  )
}

export default withStyles(styles)(EtapaCreditoPessoalSobreVoce);