import React, { useEffect } from 'react'
import { Form, useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import FormActions from '../FormActions';
import FormContext from '../FormContext';
import CampoOpcoes from '../fields/CampoOpcoes';
import Aviso from '../Aviso';
import GrupoVeiculoFipe from './GrupoVeiculoFipe';
import CampoVeiculoPlaca from '../fields/CampoVeiculoPlaca';
import CampoRadio from '../fields/CampoRadio';

const styles = theme => ({
  etapaTitulo: {
  },
});

const EtapaVeiculoCreditoPessoal = (props) => {
  const context = React.useContext(FormContext);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const { fields, etapa } = props;
  const [isBusy, setIsBusy] = useState(false);

  useEffect(()=>{
    setIsBusy(true);
    if(context.state.values.veiculo_fipe_tipo_id){
      context.consultarMarcasFipe(context.state.values.veiculo_fipe_tipo_id).finally((result)=>{
        setIsBusy(false);
      });
    }
  },[]);

  return (
    <Form>
      {
        fields.includes('quitado') &&
        <CampoOpcoes
          label="Possui veículo quitado? *"
          name="quitado"
          value={values.quitado.value}
          touched={touched.quitado}
          showError={errors.quitado}
          onChange={ ( value ) => {
            setFieldValue('quitado',value);
            context.changeValues({});
          } }
          onBlur={ ( value ) => {
            setFieldTouched('quitado',true);
          } }
          options={[    
              {label:'Sim', value:'Sim'},
              {label:'Não', value:'Não'},
          ]}
          onFocus={ props.changeFocus }
        />
      }
      {
        values.quitado && values.quitado.value=='Sim' &&
        <React.Fragment>
          <CampoOpcoes
            label="Selecione o tipo do seu veículo *"
            name="veiculo_tipo"
            value={values.veiculo_tipo}
            touched={touched.veiculo_tipo}
            showError={errors.veiculo_tipo}
            options={props.veiculo_tipo_options ? props.veiculo_tipo_options : [
              {label:'Carro, caminhonete ou utilitário',value:'carro'},
              {label:'Moto',value:'moto'},
              {label:'Caminhão',value:'caminhao'},
            ]}
            classNamePrefix="quero-financiar-select"
            className="campo-selecao"
            onFocus={ props.changeFocus }
            onChange={ ( data ) => {
              const tipos = {
                "carro":1,
                "moto":2,
                "caminhao":3,
              }
              const tipoId = tipos[data.value];
              setFieldValue('veiculo_tipo', data.value);
              setFieldValue('veiculo_fipe_tipo_id', tipoId);
              setFieldValue('veiculo_marca', '');
              setFieldValue('veiculo_modelo', '');
              setFieldValue('veiculo_ano', '');
              context.changeState({ano_modelo_options:[]});
              context.consultarMarcasFipe(tipoId);
            } }
            onBlur={ ( value ) => { setFieldTouched('veiculo_tipo',true); } }
          />
          {
            fields.includes('tem_veiculo_placa') &&
            values.veiculo_tipo!='' &&
            <CampoOpcoes
              label="Você sabe qual a placa do veículo?"
              name="tem_veiculo_placa"
              value={values.tem_veiculo_placa.value}
              touched={touched.tem_veiculo_placa}
              showError={errors.tem_veiculo_placa}
              options={[
                {label:"Sim", value:"Sim"},
                {label:"Não", value:"Não"},
              ]}
              classNamePrefix="quero-financiar-select"
              className="campo-selecao"
              onFocus={ props.changeFocus }
              onChange={ ( value ) => {
                  setFieldValue('tem_veiculo_placa',value);
              } }
              onBlur={ ( e ) => {
                  setFieldTouched('tem_veiculo_placa',true);
              } }
            />
          }
          {
            values.veiculo_tipo!='' &&
            fields.includes('veiculo_placa') &&
            values.tem_veiculo_placa.value=='Sim' &&
            <CampoVeiculoPlaca
              label="Qual é a placa do seu veículo?"
              name="veiculo_placa"
              onFocus={ props.changeFocus }
              onBlur={ (e)=>{
                handleBlur(e);
              } }
              onChange={ handleChange }
              value={values.veiculo_placa}
              error={errors.veiculo_placa && touched.veiculo_placa}
              helperText={errors.veiculo_placa && touched.veiculo_placa ? errors.veiculo_placa : ''}
            />
          }
          {
            values.veiculo_tipo!='' &&
            values.veiculo_tipo!='caminhao' &&
            <GrupoVeiculoFipe
              changeFocus = {props.changeFocus}
              handleChange = {handleChange}
              setFieldValue = {setFieldValue}
              setFieldTouched = {setFieldTouched}
              handleBlur = {handleBlur}
              values = {values}
              errors = {errors}
              touched = {touched}
            />
          }
          {
            values.veiculo_tipo!='' &&
            values.veiculo_tipo=='caminhao' &&
            <GrupoVeiculoFipe
              changeFocus = {props.changeFocus}
              handleChange = {handleChange}
              setFieldValue = {setFieldValue}
              setFieldTouched = {setFieldTouched}
              handleBlur = {handleBlur}
              values = {values}
              errors = {errors}
              touched = {touched}
            />
          }
          {
            values.veiculo_tipo!='' &&
            values.quitado && values.quitado.value=='Não' &&
            <CampoOpcoes
              label="Está financiado por qual banco? *"
              name="banco_financiamento"
              value={values.banco_financiamento.value}
              touched={touched.banco_financiamento}
              showError={errors.banco_financiamento}
              onChange={ ( value ) => {
                setFieldValue('banco_financiamento',value);
              } }
              onBlur={ ( value ) => { setFieldTouched('banco_financiamento',true); } }
              options={[
                  {label:'Banco BV', value:'Banco BV'},
                  {label:'Outros', value:'Outros'},
              ]}
              onFocus={ props.changeFocus }
            />
          }
          {
            values.veiculo_tipo!='' &&
            fields.includes('proprietario') &&
            <CampoOpcoes
              label="Este veículo está em seu nome? *"
              name="proprietario"
              value={values.proprietario.value}
              touched={touched.proprietario}
              showError={errors.proprietario}
              onChange={ ( value ) => {
                setFieldValue('proprietario',value);
              } }
              onBlur={ ( value ) => { setFieldTouched('proprietario',true); } }
              options={[    
                  {label:'Sim', value:'Sim'},
                  {label:'Não', value:'Não'},
              ]}
              onFocus={ props.changeFocus }
            />
          }
          <CampoRadio
            name = 'restricao'
            value = {values.restricao.value}
            label = "Você possui restrição no seu nome (SPC, Serasa...)? *"
            options={[
              {label: 'Sim', value:'Sim'},
              {label: 'Não', value:'Não'},
              {label: 'Não sei', value:'Não sei'},
            ]}
            onChange={ (value)=>{
              setFieldValue('restricao',value);
            } }
            onBlur={(e)=>{
              setFieldTouched('restricao', true);
            }}
            showError={errors.restricao}
            touched={touched.restricao}
          />
        </React.Fragment>
      }
      <FormActions isSubmitting={ isSubmitting } disabled={ etapa!=context.state.etapa || isBusy } />
      <Aviso errors={errors} touched={touched}/>
    </Form>
  )
};

export default withStyles(styles)(EtapaVeiculoCreditoPessoal);