import React from 'react';
import FormContext from '../FormContext';
import CampoSelect from '../fields/CampoSelect';
import CampoModelo from '../fields/CampoModelo';
import CampoMarca from '../fields/CampoMarca';
import convertVeiculoTipoToFipe from '../../lib/convertVeiculoTipoToFipe';

const GrupoVeiculoFipe = (props) => {
  const context = React.useContext(FormContext);

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur,
  } = props;

  return (
    <React.Fragment>
      <CampoMarca
        options={context.state.veiculo_marcas}
        name="veiculo_marca"
        label="Qual é a marca do seu veículo? *"
        value={values.veiculo_marca}
        error={errors.veiculo_marca}
        touched={touched.veiculo_marca}
        inputProps={{
            className:"campo-texto",
            name: 'veiculo_marca_label',
            label:"Qual é a marca do seu veículo? *",
            value: values.veiculo_marca_label,
            placeholder: 'Digite a marca',
            error: errors.veiculo_marca && touched.veiculo_marca,
            helperText: errors.veiculo_marca && touched.veiculo_marca ? errors.veiculo_marca : '',
            onChange: (event, data) => {
              handleChange(event);
              setFieldValue('veiculo_marca_label', data.newValue);
            },
        }}
        onSuggestionSelected = { (event, suggestion) => {
          handleChange(event);
          setFieldValue('veiculo_marca',suggestion.id);
          setFieldValue('veiculo_marca_label',suggestion.value);
          setFieldValue('veiculo_modelo','');
          setFieldValue('veiculo_ano','');
          context.consultarModelosFipe( values.veiculo_tipo, suggestion.id);
        }}
        onBlur = { (event, suggestion) => {
          handleBlur(event);
          setFieldTouched('veiculo_marca',true);
          setFieldValue('veiculo_marca',suggestion.id?suggestion.id:'');
          setFieldValue('veiculo_marca_label',suggestion.value?suggestion.value:'');
          setFieldValue('veiculo_modelo','');
          setFieldValue('veiculo_ano','');
        }}
      />
      <CampoSelect
        name="veiculo_ano"
        label="Qual o ano do modelo de seu veículo? *"
        value={values.veiculo_ano}
        valueField="id"
        error={errors.veiculo_ano}
        touched={touched.veiculo_ano}
        onChange={ ( data ) => {
          setFieldValue('veiculo_ano',data.value);
          setFieldValue('veiculo_combustivel',data.idCombustivel);
          setFieldValue('veiculo_fipe_ano_id',data.id);
          setFieldValue('veiculo_modelo','');
          setFieldValue('veiculo_versao','');
          context.consultarModelosAtravesDoAnoFipe(values.veiculo_fipe_tipo_id, values.veiculo_marca, data.id, data.idCombustivel, data.value);
        } }
        onBlur={ (e) => {
            handleBlur(e);
            setFieldTouched('veiculo_ano',true);
        } }
        options={ context.state.veiculo_anos || [] }
      />
      <CampoModelo
        options={context.state.veiculo_modelos}
        name="veiculo_modelo"
        label="Qual o modelo deste veículo? *"
        value={values.veiculo_modelo}
        error={errors.veiculo_modelo}
        touched={touched.veiculo_modelo}
        inputProps={{
            className:"campo-texto",
            name: 'veiculo_versao_label',
            label:"Qual o modelo deste veículo? *",
            value: values.veiculo_versao_label,
            placeholder: 'Digite o modelo',
            error: errors.veiculo_modelo && touched.veiculo_modelo,
            helperText: errors.veiculo_modelo && touched.veiculo_modelo ? errors.veiculo_modelo : '',
            onChange: (event, { newValue }) => {
              handleChange(event);
              setFieldValue('veiculo_versao_label',newValue);
            },
        }}
        onSuggestionSelected = { (event, suggestion) => {
          handleChange(event);
          setFieldValue('veiculo_modelo',suggestion.id);
          setFieldValue('veiculo_versao_label',suggestion.label);
          context.consultarValorComTodosParametrosFipe(values.veiculo_fipe_tipo_id, values.veiculo_marca, values.veiculo_fipe_ano_id, values.veiculo_combustivel, values.veiculo_ano, suggestion.id)
          .then((data)=>{
            setFieldValue('veiculo_valor',data.Valor);
            setFieldValue('veiculo_fipe_codigo',data.CodigoFipe);
            context.checkMolicarMatch(data.CodigoFipe)
          });
        }}
        onBlur = { (event, suggestion) => {
          handleBlur(event);
          setFieldTouched('veiculo_modelo',true);
          setFieldValue('veiculo_modelo',suggestion.id?suggestion.id:'');
          setFieldValue('veiculo_versao_label',suggestion.label?suggestion.label:'');
        }}
      />
      
    </React.Fragment>
  )
}

export default GrupoVeiculoFipe;