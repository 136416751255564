import React, { useEffect, useState } from 'react';
import FormContext from '../FormContext';
import { ErrorMessage } from 'formik';
import { Chip } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import CampoLabel from './CampoLabel';

const styles = theme => ({
  chip: {
    margin: `${theme.spacing()}px ${theme.spacing(2)}px ${theme.spacing()}px 0`,
  },
});

const CampoOpcoes = (props) => {
  
  const theme = useTheme();
  const context = React.useContext(FormContext);

  useEffect(()=>{
    if(props.value){
      // console.log("checking opcoes field",props.name,props.value)
      if( !props.options.find( (item) => props.value==item.value ) ){
        props.onChange({label:'',value:''})
      }
    }
  },[])

  return (
    <div className="campo-wrapper">
      <fieldset
        name={props.name}
        onFocus={ props.onFocus }
        className={ (context.campoAtivo==props.name) ? "ativo" : "" }
        style={{ borderColor: props.showError ? 'red' : '' }}
      >
        {props.label &&
          <CampoLabel className="campo-titulo">{props.label}</CampoLabel>
        }
        <div>
        {
          props.options.map((item,index)=>{
            // console.log(props.value,"==",item.value)
            return(
            <Chip
              key={index}
              onClick={(e)=>{
                props.onChange(item);
              }}
              label={item.label}
              clickable
              style={{
                color: props.value==item.value ? theme.palette.common.white : theme.palette.text.primary,
                background: props.value==item.value ? theme.palette.primary.main : '',
              }}
              className={props.classes.chip}
            />
          )})
        }
        </div>
      </fieldset>
      <ErrorMessage name={props.name} render={obj => <div className="campo-erro">{obj}</div>}/>
    </div>
  )
}

export default withStyles(styles)(CampoOpcoes);