import React from 'react'
import { Form, useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import FormContext from '../FormContext';
import Aviso from '../Aviso';
import thumb_refin from '../../assets/img/products/egv.png'
import thumb_pessoal from '../../assets/img/products/ep.png'
import thumb_fin from '../../assets/img/products/fv.png'
import { Typography } from '@material-ui/core';
import theme from '../../Theme';
import { Button, Paper } from '@material-ui/core';

const styles = theme => ({
  melhorOpcao: {
    position: 'absolute',
    width: 50,
    left: -10,
    top: -10,
    zIndex: 0,
    fontWeight:"bold",
    textAlign:"center",
    "&::before":{
      top: -10,
      left: -5,
      width: 60,
      height: 60,
      content: "''",
      position: 'absolute',
      animation: "$spin 20s linear infinite",
      background: "#fff",
      border: "2px dotted #000",
      borderRadius: "50%",
      zIndex: -1,
    },
  },
  '@keyframes spin': {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  title:{
    [theme.breakpoints.down('md')]: {
      fontSize: '2.2rem',
    },
  }
});

const thumbs = {
  thumb_refin,
  thumb_pessoal,
  thumb_fin,
}

const EtapaEscolhaProduto = (props) => {
  const context = React.useContext(FormContext);
  const { values, errors, isSubmitting, handleChange, touched, handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const { fields, etapa } = props;

  return (
    <Form>
      <Typography variant="h2" className={props.classes.title} style={{textAlign:"center", width:"100%", marginBottom:theme.spacing(2)}}>Escolha o tipo de empréstimo:</Typography>
      <div style={{width:"100%", display:"flex", flexWrap:"wrap", justifyContent:"center", alignItems:"center"}}>
      {
        props.produtoOptions.map( (option) =>
          <Paper style={{
            width:250,
            height:420,
            padding:theme.spacing(2),
            margin:theme.spacing(),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            position: 'relative',
          }} key={option.img}>
            <React.Fragment>
            {
              option.melhorOpcao ? <span className={props.classes.melhorOpcao}>Melhor Opção</span> : null
            }
            </React.Fragment>
            <img style={{
              width: 100,
              margin: `${theme.spacing()}px auto`,
              display: 'block',
            }} src={thumbs[option.img]} />
            <Typography variant="h6" style={{
              display:"block",
              width:"80%",
              margin:`${theme.spacing(2)}px auto`,
              textAlign:"center",
              lineHeight:"100%",
              fontWeight:"bold",
            }}>{option.desc}</Typography>
            <Typography variant="h5" style={{
              // height:80,
              lineHeight:"100%",
              margin:`${theme.spacing()}px 0`,
              textAlign:"center",
            }}>{option.label}</Typography>
            <Button type="submit" variant="contained" style={{
              background:"#FFD20D",
              fontSize:"1.25rem",
              // margin:`${theme.spacing(2)}px auto 0`,
            }} onClick={(e)=>{
              setFieldValue('produto', option.value);
              if(option.value=="Refinanciamento de Veículo") setFieldValue('tem_veiculo_placa', "Sim");
              context.changeState({ produto: option.value })
            }}>ESCOLHER</Button>
          </Paper>
        )
      }
      </div>
      {/* <FormActions isSubmitting={ isSubmitting } disabled={ etapa!=context.state.etapa }  /> */}
      <Aviso errors={errors} touched={touched}/>
    </Form>
  )
};

export default withStyles(styles)(EtapaEscolhaProduto);