import bancosSource from 'bancos-brasileiros/bancos.json';

export default function(){
  
  const bancosPopularesCodigos = [
    "341",
    "001",
    "104",
    "237",
    "033",
    "260",
    "077",
    "208",
    "422",
    "756",
    "655",
    "745",
    "041",
    "004",
  ];

  const friendlyName = (bankCode)=>{
    const friendlyNames = {
      "260": "Nubank",
      "041": "Banrisul",
    };
    return friendlyNames[bankCode];
  }

  const bancosPopulares = [];
  const outrosBancos = [];

  bancosSource.map((item)=>{
    if(bancosPopularesCodigos.indexOf(item.COMPE)!=-1){
      bancosPopulares.push({
        value: item.COMPE,
        label: friendlyName(item.COMPE)? item.COMPE+" - "+item.LongName+" - "+friendlyName(item.COMPE) : item.COMPE+" - "+item.LongName,
      })
    }else{
      outrosBancos.push({
        value: item.COMPE,
        label: friendlyName(item.COMPE)? item.COMPE+" - "+item.LongName+" - "+friendlyName(item.COMPE) : item.COMPE+" - "+item.LongName,
      })
    }
  }); 
  
  return bancosPopulares.concat(outrosBancos);
}